import axios from 'axios';
import { create } from 'zustand';

const useMemberStore = create(set => ({
	members: [],
	getMembersFromKeyword: async (accessToken, keyword) => {
		try {
			const headers = {
				Authorization: `Bearer ${accessToken}`,
			};

			const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/${keyword}`, { headers });

			if (response && response.data && response.data.httpCode === 200) {
				return response.data.data;
			}
		} catch (err) {
			return [];
		}
	},
}));

export default useMemberStore;
