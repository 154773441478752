// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CKEditor */

@media print {
	body {
		margin: 0 !important;
	}
}

.editor-main-container {
	font-family: 'Lato';
	width: 100%;
	height: 100%;
	/* margin-left: auto;
	margin-right: auto; */
}

.editor-container_classic-editor .ck-content {
	font-family: 'Lato';
	line-height: 1.6;
	word-break: break-word;
}

.editor-container .editor-container_classic-editor {
	width: 100%;
	height: 100%;
}

.ck.ck-reset.ck-editor.ck-rounded-corners {
	width: 100%;
	height: 100%;
}

.ck.ck-editor__main {
	height: calc(100% - 45px);
}

.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline,
.ck-focused.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
	height: 100%;
	outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/content/note/Editor.css"],"names":[],"mappings":"AAAA,aAAa;;AAGb;CACC;EACC,oBAAoB;CACrB;AACD;;AAEA;CACC,mBAAmB;CACnB,WAAW;CACX,YAAY;CACZ;sBACqB;AACtB;;AAEA;CACC,mBAAmB;CACnB,gBAAgB;CAChB,sBAAsB;AACvB;;AAEA;CACC,WAAW;CACX,YAAY;AACb;;AAEA;CACC,WAAW;CACX,YAAY;AACb;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;;CAEC,YAAY;CACZ,aAAa;AACd","sourcesContent":["/* CKEditor */\n@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');\n\n@media print {\n\tbody {\n\t\tmargin: 0 !important;\n\t}\n}\n\n.editor-main-container {\n\tfont-family: 'Lato';\n\twidth: 100%;\n\theight: 100%;\n\t/* margin-left: auto;\n\tmargin-right: auto; */\n}\n\n.editor-container_classic-editor .ck-content {\n\tfont-family: 'Lato';\n\tline-height: 1.6;\n\tword-break: break-word;\n}\n\n.editor-container .editor-container_classic-editor {\n\twidth: 100%;\n\theight: 100%;\n}\n\n.ck.ck-reset.ck-editor.ck-rounded-corners {\n\twidth: 100%;\n\theight: 100%;\n}\n\n.ck.ck-editor__main {\n\theight: calc(100% - 45px);\n}\n\n.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline,\n.ck-focused.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {\n\theight: 100%;\n\toutline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
