//main
import { ReactComponent as ContentsIcon } from './img/content.svg';
import { ReactComponent as TitleIcon } from './img/title.svg';
import { ReactComponent as KeywordIcon } from './img/keyword.svg';
import { ReactComponent as AttendeeIcon } from './img/attendees.svg';
import { ReactComponent as OwnerIcon } from './img/owner.svg';

// contents
import { ReactComponent as CallIcon } from './img/call.svg';
import { ReactComponent as VoiceIcon } from './img/voice.svg';
import { ReactComponent as VideoIcon } from './img/video.svg';
import { ReactComponent as RecordIcon } from './img/record.svg';
import { ReactComponent as MeetingIcon } from './img/meeting.svg';

export const MAIN_FILTERS = [
	{ label: '콘텐츠 종류', value: 'type' },
	{ label: '제목', value: 'title' },
	// { label: '키워드', value: 'keyword' },
	// { label: '참석자', value: 'attendee' },
	// { label: '소유자', value: 'owner' },
];

export const getMainFilterTitle = value => {
	switch (value) {
		case 'main':
			return '필터';

		case 'type':
			return '컨텐츠 종류';

		case 'title':
			return '제목';

		case 'keyword':
			return '키워드';

		case 'attendee':
			return '참석자';

		case 'owner':
			return '소유자';

		default:
			return 'Unknown';
	}
};

export const drawMainFilterIcon = value => {
	switch (value) {
		case 'type':
			return <ContentsIcon />;

		case 'title':
			return <TitleIcon />;

		case 'keyword':
			return <KeywordIcon />;

		case 'attendee':
			return <AttendeeIcon />;

		case 'owner':
			return <OwnerIcon />;

		default:
			return <></>;
	}
};

export const CONTENTS_FILTERS = [
	{ label: '전화', value: 'CALL' },
	{ label: '음성', value: 'AUDIO' },
	{ label: '영상', value: 'VIDEO' },
	{ label: '녹음', value: 'RECORD' },
	{ label: '화상 회의', value: 'MEETING' },
];

export const getContentsFilterTitle = value => {
	switch (value) {
		case 'CALL':
			return '전화';

		case 'AUDIO':
			return '음성';

		case 'VIDEO':
			return '영상';

		case 'RECORD':
			return '녹음';

		case 'MEETING':
			return '화상 회의';

		default:
			return 'Unknown';
	}
};

export const drawContentsIcon = value => {
	switch (value) {
		case 'CALL':
			return <CallIcon />;

		case 'AUDIO':
			return <VoiceIcon />;

		case 'VIDEO':
			return <VideoIcon />;

		case 'RECORD':
			return <RecordIcon />;

		case 'MEETING':
			return <MeetingIcon />;

		default:
			return <></>;
	}
};
