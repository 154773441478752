import io from 'socket.io-client';

let socket = undefined;
let isConnected = false;

// const readNotification = (userId, uuid = '*') => {
//   socket.emit(`read`, { longTimestamp: Date.now(), userKey, uuid });
// };

const setIsConnected = state => {
	isConnected = state;
};

// 브라우저 알림 권한 확인
const notificationPermissionCheck = () => {
	try {
		if (Notification.permission !== 'granted') {
			try {
				Notification.requestPermission().then(permission => {
					if (permission !== 'granted') return;
				});
			} catch (error) {
				if (error instanceof TypeError) {
					Notification.requestPermission(permission => {
						if (permission !== 'granted') return;
					});
				} else {
					console.error(error);
				}
			}
		}
	} catch (e) {
		console.log('[NotifyManager] Cannot load notification (100)', e);
	}
};
const init = async (accessToken, callback) => {
	try {
		// WS 연결 직전 권한 확인
		notificationPermissionCheck();

		console.log('[NotifyManager] init to ' + process.env.REACT_APP_SOCKET_URL);
		socket = io(process.env.REACT_APP_SOCKET_URL, {
			auth: {
				token: accessToken,
			},
		});
		socket.on('connect', () => {
			setIsConnected(true);
		});

		socket.on('disconnect', () => {
			console.log('disconnect :: ');
			setIsConnected(false);
		});

		socket.on('history', data => {
			console.log(`History :: ${data}`);
			// setNotification(binaryToPayload(data));
		});

		// socket.on('message', ({ data, eventId }) => {
		//   socket.emit(`rev:${userId}`, { recvTime: Date.now(), eventId });
		//   callback(data);
		// });

		socket.on('message', ({ uuid, message }) => {
			console.log('[NotifyManager] message :: ', message);
			callback(message);
		});
	} catch (e) {
		console.log('[NotifyManager] Cannot load notification (101)', e);
	}
};

const isConnect = () => {
	return isConnected;
};

const disconnect = () => {
	if (socket) {
		socket.destroy();
	}
};

const isNotifyPermission = () => {
	try {
		return Notification.permission === 'granted';
	} catch (e) {
		console.log('[NotifyManager] Cannot load notification (102)', e);
	}
};

export default {
	init,
	isConnect,
	isNotifyPermission,
	disconnect,
	// readNotification,
};
