import React from 'react';
import styled from 'styled-components';

const ErrorMessage = ({ text, prompt = null, onPromptClick = null }) => {
	return (
		<ErrorContainer>
			<ErrorText>{text}</ErrorText>
			{prompt && <SignupPrompt onClick={onPromptClick}>{prompt}</SignupPrompt>}
		</ErrorContainer>
	);
};

const ErrorContainer = styled.section`
	display: flex;
	min-height: 20px;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	font: 500 14px/1 'Noto Sans', sans-serif;
`;

const ErrorText = styled.p`
	color: var(--status-status-alert, #f9463b);
	margin: 0;
`;

const SignupPrompt = styled.p`
	border-bottom: 0.6px solid var(--Blue-primary700, #1c6eff);
	color: var(--Blue-primary700, #1c6eff);
	cursor: pointer;
	margin-top: 5px; /* Ensures no extra space above */
	align-self: baseline;
`;

export default ErrorMessage;
