import React, { useEffect, useState } from 'react';
import './PageLoading.css';
import BeatLoader from 'react-spinners/BeatLoader';
import styled from 'styled-components';

const WAITING_TIME = 1000;

const Loading = ({ size = 15, color = '#1C6EFF', title, description, immediately = false }) => {
	const [isView, setIsView] = useState(immediately);

	useEffect(() => {
		if (!isView) {
			setTimeout(() => {
				setIsView(true);
			}, WAITING_TIME);
		}
	}, [isView]);

	return (
		<>
			{isView && (
				<div className='page-loading-container'>
					<div className='loading'>
						<BeatLoader size={size} color={color} />
					</div>

					{title && <div className='title'>{title}</div>}
					{description && <div className='description'>{description}</div>}
				</div>
			)}
		</>
	);
};

const SttMaking = ({ title = '음성기록을 만들고 있어요.', description = '잠시만 기다려주세요.' }) => {
	return Loading({ title, description, immediately: true });
};

const PageLoading = ({ title = '데이터를 불러오는 중이에요' }) => {
	const [isView, setIsView] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setIsView(true);
		}, WAITING_TIME);
	}, []);

	return (
		<>
			{isView && (
				<div className='page-loading-container'>
					<MessageBox>{title}</MessageBox>
				</div>
			)}
		</>
	);
};

export { Loading, PageLoading, SttMaking };

const MessageBox = styled.div`
	width: 325px;
	height: 56px;
	border-radius: 8px;
	font-size: 18px;
	font-weight: 500;
	color: #fff;
	background-color: #28323ce5;
	display: flex;
	justify-content: center;
	align-items: center;
`;
