import { Typography } from '@mui/material';

export const getHighlightText = ({ highlightText, text, sx }) => {
	if (!highlightText || '' === highlightText) {
		return text;
	} else {
		const parts = text.split(new RegExp(`(${highlightText})`, 'gi'));
		return (
			<Typography sx={sx}>
				{parts.map((part, index) =>
					part && part.toLowerCase() === highlightText.toLowerCase() ? (
						<span key={index} style={{ backgroundColor: 'yellow' }}>
							{part}
						</span>
					) : (
						part
					)
				)}
			</Typography>
		);
	}
};

export const getHighlightTypo = ({ highlightText, text, sx }) => {
	if (!highlightText || '' === highlightText) {
		return <Typography sx={sx}>{text}</Typography>;
	} else {
		const parts = text.split(new RegExp(`(${highlightText})`, 'gi'));
		return (
			<Typography sx={sx}>
				{parts.map((part, index) =>
					part && part.toLowerCase() === highlightText.toLowerCase() ? (
						<span key={index} style={{ backgroundColor: 'yellow' }}>
							{part}
						</span>
					) : (
						part
					)
				)}
			</Typography>
		);
	}
};
