import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SidebarV2 from '../../components/sidebarV2/SidebarV2';

import useContentsStore from '../../store/ContentsStore';
import Header from '../../components/body/header/Header';
import Popup from 'reactjs-popup';
import TimbloButton from '../../components/button/TimbloButton';
import Display from '../../components/popup/display/Display';
import Filter from '../../components/popup/filter/Filter';
import { ToastError, ToastInfo } from '../../components/common/toast/Toast';
import { useCookies } from 'react-cookie';
import DeleteConfirm from '../../components/popup/content/delete-confirm/DeleteConfirm';
import Download from '../../components/popup/download/Download';
import Share from '../../components/popup/share/Share';
import Attendees from '../../components/popup/attendees/Attendees';
import { drawContentIcon, drawContentTypeV2 } from '../../components/common/ContentIcon/ContentIcon';

import { ReactComponent as ExportIcon } from './img/is_export.svg';
import { ReactComponent as SharedContentIcon } from './img/is_shared.svg';

import ws from '../../libs/NotifyManager';
import useMessageStore from '../../store/MessageStore';
import { useAuthAction } from './Action';
import useDisplayStore from '../../store/DisplayStore';
import { useVoiceVisualizer } from 'react-voice-visualizer';
import useAuthStore from '../../store/AuthStore';
import moment from 'moment';
import HeaderV2 from '../../components/body/headerV2/HeaderV2';
import useModalStore from '../../store/ModalStore';
import ReSummary from '../../components/popup/resummary/ReSummary';

const MENUS = [
	{ label: '홈', value: 'home', position: 'top' },
	{ label: '회의록', value: 'contents', position: 'top' },
	{ label: '캘린더', value: 'calendar', position: 'top' },
	{ label: '알림 메시지', value: 'inbox', position: 'top' },
	{ label: '북마크', value: 'bookmark', position: 'top' },
	// { label: '검색', value: 'search', position: 'top' },
	{ label: '휴지통', value: 'recycle', position: 'top' },
	// { label: '워크스페이스 참여', value: 'workspace', position: 'bottom' },
	{ label: '개인 환경 설정', value: 'setting', position: 'bottom' },
	{ label: '로그아웃', value: 'logout', position: 'bottom' },
];

const ValidLayout = ({ sidebarRef, contentRef, calendarRef }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const recorderControls = useVoiceVisualizer();
	const { isValidSession } = useAuthAction();
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const [currentMenu, setCurrentMenu] = useState(MENUS[0]);
	const [isReady, setIsReady] = useState(false);

	const { auth } = useAuthStore();
	const { onMessage } = useMessageStore();
	const {
		selectedContent,
		setSelectedContent,
		deleteContents,
		onContentsChanged,
		updateContentTitle,
		refreshHomeContents,
	} = useContentsStore();

	useEffect(() => {
		isValidSession()
			.then(isValid => {
				if (isValid) {
					setIsReady(true);
					initNotifySocket();
				} else {
					navigate('/login', { state: { location } });
				}
			})
			.catch(() => {
				navigate('/login', { state: { location } });
			});

		const handleBeforeUnload = event => {
			const shouldReload = window.confirm('변경사항이 사라질 수 있습니다. 새로고침 하시겠습니까?');

			if (shouldReload) {
				// 페이지를 새로고침
				window.location.reload();
			} else {
				// 새로고침 취소
				event.preventDefault();
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	useEffect(() => {
		if (isReady) {
			refreshHomeContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken);
		}
	}, [isReady]);

	const initNotifySocket = () => {
		try {
			console.log('[Main] initNotifySocket');

			ws.disconnect();

			if (
				cookies &&
				cookies[process.env.REACT_APP_COOKIE_ALIAS] &&
				cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken
			) {
				ws.init(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, message => {
					onMessage(message);

					switch (message.action) {
						case 'CONTENTS_CHANGED':
							onContentsChanged(message.data);
							break;
						default:
							break;
					}
				});
			}
		} catch (e) {
			console.error(e);
			ToastError(1004);
		}
	};

	const drawHeader = () => {
		let title = '';
		let description = '';
		let leftContent = null;
		let rightContent = null;

		const onChangeTitle = text => {
			if (selectedContent) {
				updateContentTitle(
					cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
					selectedContent.contentId,
					text
				);
			}
		};

		if (selectedContent) {
			title = selectedContent.editedTitle ?? selectedContent.title;

			description = `생성일 ${moment(selectedContent.createAt)
				.locale('ko')
				.format('YY.MM.DD LT')} | 최종 수정일 ${moment(selectedContent.updateAt)
				.locale('ko')
				.format('YY.MM.DD LT')} | ${selectedContent.creatorNickName}`;

			leftContent = (
				<>
					{drawContentTypeV2(selectedContent)}
					{auth &&
					auth.user &&
					selectedContent &&
					selectedContent.creatorPID === auth.user.pid &&
					selectedContent.shareUsers?.length ? (
						<div className='status'>
							<ExportIcon />
						</div>
					) : null}
					{auth && auth.user && selectedContent && selectedContent.creatorPID !== auth.user.pid && (
						<div className='status'>
							<SharedContentIcon />
						</div>
					)}
				</>
			);
			rightContent = (
				<div className='buttons' style={{ display: 'flex', gap: '5px' }}>
					<Popup
						trigger={<TimbloButton label={'재요약'} alias='refresh' />}
						position={'bottom right'}
						arrow={false}
						nested
						keepTooltipInside='.main-content-v2'
					>
						<ReSummary />
					</Popup>
					{/* <Popup
						trigger={<TimbloButton label={'참석자'} alias='attendees' />}
						position={'bottom right'}
						arrow={false}
						nested
						keepTooltipInside='.main-content'
					>
						{close => (
							<Attendees
								onChangeSpeakerCallback={() => {
									contentRef.current.refreshContent();
								}}
								contentRef={contentRef}
							/>
						)}
					</Popup> */}
					<Popup
						trigger={<TimbloButton label={'공유'} alias='share' />}
						position={'bottom right'}
						arrow={false}
						nested
						keepTooltipInside='.main-content-v2'
					>
						<Share contentId={selectedContent.contentId} contentRef={contentRef} />
					</Popup>

					<Popup
						trigger={<TimbloButton label={'다운로드'} alias='download' />}
						position={'bottom right'}
						arrow={false}
						nested
					>
						<Download contentRef={contentRef} />
					</Popup>

					<Popup
						trigger={<TimbloButton label={'삭제'} alias='delete' />}
						position={'bottom right'}
						arrow={false}
						nested
					>
						{close => (
							<DeleteConfirm
								title='콘텐츠를 삭제하시겠습니까?'
								onCancel={close}
								onConfirm={() => {
									if (selectedContent.userAccessRole === 'OWNER') {
										deleteContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, {
											contentId: selectedContent.contentId,
										})
											.then(() => {
												setSelectedContent(null);
												ToastError(0, '선택한 콘텐츠가 삭제되었습니다.', '콘텐츠 삭제 완료');

												navigate(`/${currentMenu.value}`, { replace: true });
											})
											.catch(() => {
												ToastInfo(null, '권한이 없습니다.');
											});
									} else {
										ToastInfo('권한이 없습니다.');
									}
								}}
							/>
						)}
					</Popup>
				</div>
			);
		} else if (currentMenu && currentMenu.value === 'contents') {
			title = currentMenu.label;
			rightContent = (
				<div className='buttons' style={{ display: 'flex', gap: '5px' }}>
					<Popup
						trigger={<TimbloButton label={'필터'} alias='filter' />}
						position={'bottom right'}
						arrow={false}
						nested
						keepTooltipInside='.main-content-v2'
					>
						<Filter />
					</Popup>
					<Popup
						trigger={<TimbloButton label={'보기 방식'} alias='showType' />}
						position={'bottom right'}
						arrow={false}
						nested
						keepTooltipInside='.main-content-v2'
					>
						{close => <Display onClose={close} />}
					</Popup>
				</div>
			);
		} else if (currentMenu && currentMenu.value === 'inbox') {
			return (
				<HeaderV2
					text={currentMenu.label}
					description={'생성일 24.10.30 오후 2:00  |  최종 수정일 24.10.30 오후 2:00  |  아이유'}
					enableSearch
					buttonComponents={<button>버튼</button>}
					recentList={[]}
				/>
			);

			title = currentMenu.label;
			rightContent = (
				<div className='buttons' style={{ display: 'flex', gap: '5px' }}>
					<TimbloButton label={'전체 읽음'} alias='readAll' />
					<TimbloButton label={'전체 삭제'} alias='delete' />
				</div>
			);
		} else {
			title = currentMenu ? currentMenu.label : '';
		}

		return (
			<Header
				text={title}
				description={description}
				originText={selectedContent ? selectedContent.title : ''}
				onChangeTitle={onChangeTitle}
				isContent={selectedContent ? true : false}
				leftContent={leftContent}
				rightContent={rightContent}
				permission={selectedContent ? selectedContent.userAccessRole : null}
			/>
		);
	};

	return (
		isReady && (
			<div className='main-container'>
				<div className='sidebar'>
					<SidebarV2
						ref={sidebarRef}
						menus={MENUS}
						onChangeMenu={menu => {
							setSelectedContent(null);
							setCurrentMenu(menu);
							navigate(`/${menu.value}`);
						}}
						recorderControls={recorderControls}
						calendarRef={calendarRef}
					/>
				</div>
				<div className='main-content-v2'>
					<Outlet />
				</div>

				{/* <div className='main-content'>
					<div className='header'>{drawHeader()}</div>
					<div className='content'>
						<div className='list'>
							<Outlet />
						</div>
					</div>
				</div> */}
			</div>
		)
	);
};

export default ValidLayout;
