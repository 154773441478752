import React from 'react';
import './DeleteConfirm.css';

import { ReactComponent as WarningIcon } from './img/warning.svg';
import { ReactComponent as QuestionIcon } from './img/question.svg';

const DeleteConfirm = ({ type, title, description, onConfirm = () => {}, onCancel = () => {} }) => {
	const drawIcon = () => {
		switch (type) {
			case 'question':
				return <QuestionIcon />;

			default:
				return <WarningIcon />;
		}
	};

	return (
		<div className='popup-confirm-container'>
			<div className='title'>
				<div className='image'>{drawIcon()}</div>
				<div className='text'>{title}</div>
			</div>
			<div className='description'>{description}</div>
			<div className='separator' />
			<div className='buttons'>
				<button className='cancel' onClick={onCancel}>
					취소
				</button>
				<button className='confirm' onClick={onConfirm}>
					삭제
				</button>
			</div>
		</div>
	);
};

export default DeleteConfirm;
