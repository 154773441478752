import { create } from 'zustand';

const useDisplayStore = create(set => ({
	orderType: 'NEWEST_CREATE',
	displayType: 'list',
	onChangeType: displayType => {
		set({ displayType });
	},
	onChangeOrderType: orderType => {
		set({ orderType });
	},
}));

export default useDisplayStore;
