import React, { useEffect, useRef, useState } from 'react';
import './Main.css';
import Header from '../components/body/header/Header';
// import Search from '../components/search/Search';
import List from '../components/list/List';
import { ToastContainer } from 'react-toastify';
import '../components/common/TostifyStyle.css';

// DummyData lib
import { faker } from '@faker-js/faker';
import moment from 'moment';
import { useAuthAction } from './login/Action';
import { useNavigate } from 'react-router-dom';
import SidebarV2 from '../components/sidebarV2/SidebarV2';
import Content from '../components/content/Content';
import { drawContentIcon } from '../components/common/ContentIcon/ContentIcon';
import Popup from 'reactjs-popup';
import TimbloButton from '../components/button/TimbloButton';
import Share from '../components/popup/share/Share';
import JoinPage from './login/JoinPage';
import { Toast, ToastError, ToastInfo } from '../components/common/toast/Toast';
import Attendees from '../components/popup/attendees/Attendees';
import ws from '../libs/NotifyManager';
import { useCookies } from 'react-cookie';
import useMessageStore from '../store/MessageStore';
import useContentsStore from '../store/ContentsStore';
import Filter from '../components/popup/filter/Filter';
import Display from '../components/popup/display/Display';
import DeleteConfirm from '../components/popup/content/delete-confirm/DeleteConfirm';
import Download from '../components/popup/download/Download';
import HomeV2 from './home/Homev2';
import SearchV2 from './search/SearchV2';

const MENUS = [
	{ label: '홈', value: 'home' },
	{ label: '콘텐츠', value: 'contents' },
	{ label: '검색', value: 'search' },
	// { label: '수신함', value: 'inbox' },
	// { label: '연동', value: 'integrate' },
];

const Main = () => {
	const sidebarRef = useRef();
	const contentRef = useRef();

	const navigate = useNavigate();
	const { isValidSession } = useAuthAction();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const [currentMenu, setCurrentMenu] = useState(MENUS[0]);
	const { onMessage } = useMessageStore();
	const { onContentsChanged, deleteContents } = useContentsStore();

	const { selectedContent, setSelectedContent } = useContentsStore();

	useEffect(() => {
		isValidSession()
			.then(res => {
				if (!res) {
					navigate('/');
				}
			})
			.catch(e => {
				navigate('/login');
			});
	}, []);

	useEffect(() => {
		if (
			cookies &&
			cookies[process.env.REACT_APP_COOKIE_ALIAS] &&
			cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken
		) {
			initNotifySocket();
		}
	}, [cookies]);

	const initNotifySocket = () => {
		try {
			console.log('[Main] initNotifySocket');
			ws.disconnect();

			if (
				cookies &&
				cookies[process.env.REACT_APP_COOKIE_ALIAS] &&
				cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken
			) {
				ws.init(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, message => {
					onMessage(message);

					switch (message.action) {
						case 'CONTENTS_CHANGED':
							onContentsChanged(message.data);
							break;
						default:
							break;
					}
				});
			}
		} catch (e) {
			console.error(e);
			ToastError(1004);
		}
	};

	const drawContents = () => {
		if (selectedContent) {
			return <Content ref={contentRef} contentId={selectedContent.contentId} source={selectedContent.source} />;
		} else {
			if (currentMenu) {
				switch (currentMenu.value) {
					case 'home':
						//return <Home onClickContent={setSelectedContent} sidebarRef={sidebarRef} />;
						return <HomeV2 onClickContent={setSelectedContent} sidebarRef={sidebarRef} />;

					case 'contents':
						return <List onClickContent={setSelectedContent} />;

					// case 'search':
					// 	return (
					// 		<Search
					// 			onClickContent={content => {
					// 				setSelectedContent({ ...content, source: 'search' });
					// 			}}
					// 		/>
					// 	);
					case 'search':
						return <SearchV2 />;

					default: {
						Toast('error', '준비 중입니다..', '서비스 준비 중입니다.', true, 3000);
						return <>{currentMenu.label}</>;
					}
				}
			}
		}
	};

	const drawHeader = () => {
		let title = '';
		let leftContent = null;
		let rightContent = null;

		if (selectedContent) {
			title = selectedContent.title;
			leftContent = drawContentIcon(selectedContent.type);
			rightContent = (
				<div className='buttons' style={{ display: 'flex', gap: '5px' }}>
					<Popup
						trigger={<TimbloButton label={'참석자'} alias='attendees' />}
						position={'bottom right'}
						arrow={false}
						nested
						keepTooltipInside='.main-content'
					>
						<Attendees onChangeSpeakerCallback={() => contentRef.current.refreshContent()} />
					</Popup>
					<Popup
						trigger={<TimbloButton label={'공유'} alias='share' />}
						position={'bottom right'}
						arrow={false}
						nested
						keepTooltipInside='.main-content'
					>
						<Share contentId={selectedContent.contentId} />
					</Popup>

					{/* <TimbloButton
						label={'다운로드'}
						alias='download'
						onClick={() =>
							downloadFile(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, selectedContent)
						}
					/> */}
					<Popup
						trigger={<TimbloButton label={'다운로드'} alias='download' />}
						position={'bottom right'}
						arrow={false}
						nested
					>
						<Download contentRef={contentRef} />
					</Popup>

					<Popup
						trigger={<TimbloButton label={'삭제'} alias='delete' />}
						position={'bottom right'}
						arrow={false}
						nested
					>
						{close => (
							<DeleteConfirm
								title='콘텐츠를 삭제하시겠습니까?'
								onCancel={close}
								onConfirm={() => {
									if (contentRef && contentRef.current) {
										if (contentRef.current.isEditable) {
											deleteContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, {
												contentId: selectedContent.contentId,
											})
												.then(() => {
													setSelectedContent(null);
													ToastError(
														0,
														'선택한 콘텐츠가 삭제되었습니다.',
														'콘텐츠 삭제 완료'
													);
												})
												.catch(code => {
													ToastError(code);
												});
										} else {
											ToastInfo('권한이 없습니다.');
										}
									}
								}}
							/>
						)}
					</Popup>
				</div>
			);
		} else if (currentMenu.value === 'contents') {
			title = currentMenu.label;
			rightContent = (
				<div className='buttons' style={{ display: 'flex', gap: '5px' }}>
					<Popup
						trigger={<TimbloButton label={'필터'} alias='filter' />}
						position={'bottom right'}
						arrow={false}
						nested
						keepTooltipInside='.main-content'
					>
						<Filter />
					</Popup>
					<Popup
						trigger={<TimbloButton label={'보기 방식'} alias='showType' />}
						position={'bottom right'}
						arrow={false}
						nested
						keepTooltipInside='.main-content'
					>
						{close => <Display onClose={close} />}
					</Popup>
				</div>
			);
		} else {
			title = currentMenu.label;
		}

		return (
			<Header
				text={title}
				leftContent={leftContent}
				rightContent={rightContent}
				onCheckPermission={() => contentRef.current.isEditable}
			/>
		);
	};

	return (
		<div className='main-container'>
			<ToastContainer autoClose={false} closeButton={false} hideProgressBar={true} position='bottom-right' />
			<div className='sidebar'>
				<SidebarV2
					ref={sidebarRef}
					menus={MENUS}
					onChangeMenu={menu => {
						setSelectedContent(null);
						setCurrentMenu(menu);
					}}
				/>
			</div>
			<div className='main-content'>
				<div className='header'>{drawHeader()}</div>
				<div className='content'>
					<div className='list'>{drawContents()}</div>
				</div>
			</div>
		</div>
	);
};

export default Main;
