import React, { useEffect, useState } from 'react';
import './SearchAndReplace.css';

import { ReactComponent as ArrowIcon } from './img/arrow.svg';

const SearchAndReplace = ({
	keyword,
	setKeyword,
	searchResult,
	onReplaceAll = () => {},
	onReplace = () => {},
	onPrev = () => {},
	onNext = () => {},
}) => {
	const [findText, setFindText] = useState(keyword);
	const [replaceText, setReplaceText] = useState('');

	useEffect(() => {
		setKeyword(findText);
	}, [findText]);

	return (
		<div className='search-replace-container'>
			<div className='title'>단어 바꾸기</div>
			<div className='separator' />
			<div className='find'>
				<div className='text'>찾을 단어</div>
				<input type='text' value={findText} onChange={e => setFindText(e.target.value)} />
				<div className='result'>
					<div className='count'>{`${searchResult.currentIndex} / ${searchResult.total}`}</div>
					<div className='prev' onClick={onPrev}>
						<ArrowIcon />
					</div>
					<div className='next' onClick={onNext}>
						<ArrowIcon />
					</div>
				</div>
			</div>
			<div className='replace'>
				<div className='text'>바꿀 단어</div>
				<input type='text' value={replaceText} onChange={e => setReplaceText(e.target.value)} />
			</div>
			<div className='buttons'>
				<button className='replace-all' onClick={() => onReplaceAll(replaceText)}>
					전체 바꾸기
				</button>
				<button className='replace-only' onClick={() => onReplace(replaceText)}>
					바꾸기
				</button>
			</div>
		</div>
	);
};

export default SearchAndReplace;
