import React, { useEffect } from 'react';
import { Box, Chip, Stack, SvgIcon, Typography } from '@mui/material';
import useContentsStore from '../../../store/ContentsStore';

import { ReactComponent as SearchIcon } from '../img/search.svg';
import { ReactComponent as KeywordNoDataIcon } from '../img/keyword_no_data.svg';
import HtmlTooltip from '../../../components/tooltip/HtmlTooltip';

const HomeKeywords = ({ onClickKeyword = () => {} }) => {
	const { home } = useContentsStore();

	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',

				boxSizing: 'border-box',
			}}
		>
			<Typography
				variant='p'
				style={{
					width: '100%',
					height: '58px',
					display: 'flex',
					alignItems: 'center',
					fontSize: '20px',
					fontWeight: 600,
					color: '#292A2B',
					padding: '16px 24px',
					boxSizing: 'border-box',
				}}
			>
				최근 검색어
			</Typography>
			<Box
				sx={{
					display: 'inline-block',
					// flexWrap: 'wrap',
					width: '100%',
					height: 'calc(100% - 66px)',
					padding: '8px 24px',
					boxSizing: 'border-box',
				}}
			>
				{home && home.keywords && home.keywords.length ? (
					home.keywords.map((item, index) => (
						<Box
							key={item.keyword}
							sx={{
								width: '50%',
								display: 'inline-block',
								paddingRight: index % 2 === 0 ? '10px' : 0,
								marginBottom: '16px',
								boxSizing: 'border-box',
							}}
						>
							<HtmlTooltip
								title={
									<Typography
										sx={{
											fontSize: 16,
											fontWeight: 600,
											fontFamily: 'Noto Sans KR',
											color: '#292A2B',
										}}
									>
										{item.keyword}
									</Typography>
								}
								placement='top-start'
								slotProps={{
									popper: {
										modifiers: [
											{
												name: 'offset',
												options: {
													offset: [0, 0],
												},
											},
										],
									},
								}}
							>
								<Chip
									icon={<SearchIcon />}
									variant='outlined'
									label={item.keyword}
									sx={{
										height: 40,
										fontSize: 16,
										fontWeight: 500,
										color: '#292A2B',
										fontFamily: 'Noto Sans KR',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										padding: '8px 12px',
										borderRadius: '24px',
										border: '1px solid #E1E4E6',
										cursor: 'pointer',
										boxSizing: 'border-box',
									}}
									onClick={() => onClickKeyword(item.keyword)}
								/>
							</HtmlTooltip>
						</Box>
					))
				) : (
					<KeywordNoData />
				)}
			</Box>
		</Box>
	);
};

export default HomeKeywords;

const KeywordNoData = () => {
	return (
		<Stack
			spacing={1}
			direction={'column'}
			sx={{ width: '100%', height: '100%' }}
			justifyContent={'center'}
			alignItems={'center'}
		>
			<SvgIcon
				component='svg'
				inheritViewBox
				sx={{
					width: 88,
					height: 80,
				}}
			>
				<KeywordNoDataIcon />
			</SvgIcon>
			<Typography sx={{ fontSize: 20, fontWeight: 600, color: '#28323C', fontFamily: 'Noto Sans KR' }}>
				최근 검색어가 없습니다.
			</Typography>
			<Typography sx={{ fontSize: 16, fontWeight: 500, color: '#858C99', fontFamily: 'Noto Sans KR' }}>
				최근 검색한 검색어 기록이 없습니다.
			</Typography>
		</Stack>
	);
};
