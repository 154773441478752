// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast-container {
	min-width: 300px;
	height: 110px;
	padding: 20px;
	display: flex;
	gap: 10px;
	border-radius: 10px;
	border: 1px solid var(--popup-border-color);
	box-sizing: border-box;
	flex-direction: column;
	box-shadow: 0px 4px 4px 0px #00000040;
}

.toast-container.done {
	background-color: #e1ffe9;
}

.toast-container.error {
	background-color: #ffe9e8;
}

.toast-container > .title {
	display: flex;
	gap: 10px;
	font-size: var(--font-size-lg, 16px);
	font-weight: var(--font-weight-semibold, 600);
	color: var(--main-title-text-color, #28323c);
}

.toast-container > .separator {
	border-top: 0.4px solid var(--popup-border-color);
}

.toast-container > .description {
	font-size: var(--font-size-md, 14px);
	font-weight: var(--font-weight-regular, 400);
	color: var(--main-description-text-color, #858c99);
}
`, "",{"version":3,"sources":["webpack://./src/components/common/toast/Toast.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,aAAa;CACb,aAAa;CACb,aAAa;CACb,SAAS;CACT,mBAAmB;CACnB,2CAA2C;CAC3C,sBAAsB;CACtB,sBAAsB;CACtB,qCAAqC;AACtC;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,aAAa;CACb,SAAS;CACT,oCAAoC;CACpC,6CAA6C;CAC7C,4CAA4C;AAC7C;;AAEA;CACC,iDAAiD;AAClD;;AAEA;CACC,oCAAoC;CACpC,4CAA4C;CAC5C,kDAAkD;AACnD","sourcesContent":[".toast-container {\n\tmin-width: 300px;\n\theight: 110px;\n\tpadding: 20px;\n\tdisplay: flex;\n\tgap: 10px;\n\tborder-radius: 10px;\n\tborder: 1px solid var(--popup-border-color);\n\tbox-sizing: border-box;\n\tflex-direction: column;\n\tbox-shadow: 0px 4px 4px 0px #00000040;\n}\n\n.toast-container.done {\n\tbackground-color: #e1ffe9;\n}\n\n.toast-container.error {\n\tbackground-color: #ffe9e8;\n}\n\n.toast-container > .title {\n\tdisplay: flex;\n\tgap: 10px;\n\tfont-size: var(--font-size-lg, 16px);\n\tfont-weight: var(--font-weight-semibold, 600);\n\tcolor: var(--main-title-text-color, #28323c);\n}\n\n.toast-container > .separator {\n\tborder-top: 0.4px solid var(--popup-border-color);\n}\n\n.toast-container > .description {\n\tfont-size: var(--font-size-md, 14px);\n\tfont-weight: var(--font-weight-regular, 400);\n\tcolor: var(--main-description-text-color, #858c99);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
