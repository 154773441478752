import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import './ListV2.css';
import moment from 'moment';
import 'moment/locale/ko';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import Calendar from './Calendar';
import Item from '../common/list-item/Item';
import useDisplayStore from '../../store/DisplayStore';
import useContentsStore from '../../store/ContentsStore';
import { Toast, ToastComponent, ToastError, ToastInfo } from '../common/toast/Toast';
import { PageLoading } from '../page-status/PageLoading';
import useAuthStore from '../../store/AuthStore';
import Empty from '../page-status/Empty';
import { toast } from 'react-toastify';

import ItemV2 from '../common/list-item/ItemV2';
import HeaderV2 from '../body/headerV2/HeaderV2';

const transparentImage = new Image();
transparentImage.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';

const ListV2 = ({ sidebarRef, calendarRef, onClickContent, isCalendar = false }) => {
	const navigate = useNavigate();

	const { auth } = useAuthStore();
	const [isLoading, setIsLoading] = useState(true);
	const [isDragging, setIsDragging] = useState(false);
	const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { contents, uploads, refreshContents, fileUpload, applyedFilters } = useContentsStore();
	const { displayType, onChangeType, orderType } = useDisplayStore();

	// const [gridData, setGridData] = useState([]);

	const columns = useMemo(
		() => [
			{ headerName: '종류', field: 'type' },
			{ headerName: '회의록명', field: 'title' },
			{ headerName: '키워드', field: 'keywords' },
			{ headerName: '녹음일', field: 'createdAt' },
			{ headerName: '길이', field: 'duration' },
			{ headerName: '참석자', field: 'participates' },
			{ headerName: '공유', field: 'share' },
			{ headerName: '삭제', field: 'button' },
		],
		[]
	);

	useLayoutEffect(() => {
		if (isCalendar) {
			onChangeType('calendar');
		} else {
			onChangeType('list');
		}
	}, [isCalendar]);

	useLayoutEffect(() => {
		const params = {};

		if (Object.keys(applyedFilters).length) {
			Object.keys(applyedFilters).forEach(key => {
				params[key] =
					typeof applyedFilters[key] === 'object' ? applyedFilters[key].join(',') : applyedFilters[key];
			});
		}

		refreshContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, params)
			.catch(e => {
				console.error(e);
				ToastError(1003);
			})
			.finally(() => setIsLoading(false));
	}, [applyedFilters]);

	const drawContentList = () => {
		// const list = [
		// 	// ...uploads.list,
		// 	...contents.list.filter(content => !uploads.list.some(upload => upload.contentId === content.contentId)),
		// ];

		const list = [...contents.list];
		const renderedItems = [];

		const getDiffDays = targetDate => {
			const today = moment().locale('ko');
			const target = moment(targetDate).locale('ko');

			return today.diff(target, 'days');
		};

		const handleDragOver = event => {
			event.dataTransfer.dropEffect = 'copy';
			event.preventDefault();
			setIsDragging(true);
			setDragPosition({ x: event.clientX, y: event.clientY });
		};

		const handleDragEnter = event => {
			setIsDragging(true);
		};

		const handleDragLeave = event => {
			if (!event.currentTarget.contains(event.relatedTarget)) {
				setIsDragging(false);
			}
		};

		const handleDrop = event => {
			event.preventDefault();
			setIsDragging(false);

			const validTypes = ['audio', 'video'];
			const fileName = event.dataTransfer.files[0].name;
			const fileType = event.dataTransfer.files[0].type.split('/')[0];

			if (event.dataTransfer.files.length > 1) {
				ToastError(null, `파일 업로드는 1개씩만 할 수 있습니다.`);
				return;
			} else if (!validTypes.includes(fileType)) {
				ToastError(null, '음성 파일만 업로드할 수 있습니다.');
				return;
			} else {
				const currentToast = toast(<ToastComponent description={`${fileName} 업로드 중 - 0%`} />);

				fileUpload(
					cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
					event.dataTransfer.files[0],
					fileName,
					null,
					auth,
					() => {},
					code => {
						if (code) {
							switch (code) {
								case 401:
									ToastInfo('권한이 없습니다.');
									break;

								default:
									ToastError(code);
									break;
							}
						}
					},
					() => {},
					(id, progress) => {
						if (Number(progress) < 100) {
							toast.update(currentToast, {
								render: props => (
									<ToastComponent {...props} description={`${fileName} 업로드 중 - ${progress}%`} />
								),
							});
						} else {
							toast.update(currentToast, {
								render: props => <ToastComponent {...props} description={`${fileName} 업로드 완료`} />,
								autoClose: 3000,
							});
						}

						console.log('[progress]', progress);
					}
				);
			}
		};

		const dateFormat = date => {
			return `${getDiffDays(date) === 0 ? '오늘 ' : getDiffDays(date) === 1 ? '어제 ' : ''}M/DD (ddd)`;
		};

		list.forEach((item, index) => {
			const prev = index > 0 ? list[index - 1] : null;
			const isSameDate =
				prev && moment(prev.createAt).format('YYYYMMDD') === moment(item.createAt).format('YYYYMMDD');

			renderedItems.push(
				<>
					{!orderType.includes('TITLE') && !isSameDate && (
						<div className={`date`}>
							{/* {dateFormat(item.createAt)} */}
							{moment(item.createAt).format(dateFormat(item.createAt))}
							<div className='separator' />
						</div>
					)}
					<Item
						key={item.contentId}
						data={item}
						isDisabled={item.isDisabled}
						onClickContent={content => {
							onClickContent(content);
							navigate(`/content/${content.contentId}`);
						}}
					/>
				</>
			);
		});

		return (
			<>
				<div
					className='content-list'
					onDragOver={handleDragOver}
					onDragEnter={handleDragEnter}
					onDragLeave={handleDragLeave}
					onDrop={handleDrop}
				>
					{renderedItems}
				</div>

				{isDragging && (
					<div
						style={{
							position: 'fixed',
							top: `${dragPosition.y - 60}px`,
							left: `${dragPosition.x + 50}px`,
							padding: '10px',
							backgroundColor: '#2A53FF',
							color: '#fff',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							pointerEvents: 'none',
							borderRadius: '4px',
							boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
							zIndex: 9999,
						}}
					>
						여기에 파일을 올려주세요
					</div>
				)}
			</>
		);
	};

	const drawContentListV2 = () => {
		const list = [...contents.list];

		const handleDragOver = event => {
			event.dataTransfer.dropEffect = 'copy';
			event.preventDefault();
			setIsDragging(true);
			setDragPosition({ x: event.clientX, y: event.clientY });
		};

		const handleDragEnter = event => {
			setIsDragging(true);
		};

		const handleDragLeave = event => {
			if (!event.currentTarget.contains(event.relatedTarget)) {
				setIsDragging(false);
			}
		};

		const handleDrop = event => {
			event.preventDefault();
			setIsDragging(false);

			const validTypes = ['audio', 'video'];
			const fileName = event.dataTransfer.files[0].name;
			const fileType = event.dataTransfer.files[0].type.split('/')[0];

			if (event.dataTransfer.files.length > 1) {
				ToastError(null, `파일 업로드는 1개씩만 할 수 있습니다.`);
				return;
			} else if (!validTypes.includes(fileType)) {
				ToastError(null, '음성 파일만 업로드할 수 있습니다.');
				return;
			} else {
				const currentToast = toast(<ToastComponent description={`${fileName} 업로드 중 - 0%`} />);

				fileUpload(
					cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
					event.dataTransfer.files[0],
					fileName,
					null,
					auth,
					() => {},
					res => {
						if (res) {
							switch (res.httpCode) {
								case 401:
									ToastError(null, '권한이 없습니다.');
									break;

								default:
									ToastError(res.httpCode, res.message);
									break;
							}
						}
					},
					() => {},
					(id, progress) => {
						if (Number(progress) < 100) {
							toast.update(currentToast, {
								render: props => (
									<ToastComponent {...props} description={`${fileName} 업로드 중 - ${progress}%`} />
								),
							});
						} else {
							toast.update(currentToast, {
								render: props => <ToastComponent {...props} description={`${fileName} 업로드 완료`} />,
								autoClose: 3000,
							});
						}

						console.log('[progress]', progress);
					}
				);
			}
		};

		return (
			<>
				<HeaderV2 text='회의록' />
				<div
					className='content-list-v2'
					onDragOver={handleDragOver}
					onDragEnter={handleDragEnter}
					onDragLeave={handleDragLeave}
					onDrop={handleDrop}
				>
					<div className='header'>
						{columns.map(column => (
							<div className={column.field}>{column.headerName}</div>
						))}
					</div>
					<div className='contents'>
						{contents.list?.length &&
							contents.list.map(item => (
								<ItemV2
									data={item}
									onClickContent={content => {
										onClickContent(content);
										navigate(`/content/${content.contentId}`);
									}}
								/>
							))}
					</div>
				</div>

				{isDragging && (
					<div
						style={{
							position: 'fixed',
							top: `${dragPosition.y - 60}px`,
							left: `${dragPosition.x + 50}px`,
							padding: '10px',
							backgroundColor: '#2A53FF',
							color: '#fff',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							pointerEvents: 'none',
							borderRadius: '4px',
							boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
							zIndex: 9999,
						}}
					>
						여기에 파일을 올려주세요
					</div>
				)}
			</>
		);
	};

	return (
		<div className={`list-container v2`}>
			{isLoading ? (
				<PageLoading />
			) : displayType === 'list' ? (
				contents.list.length ? (
					// drawContentList()
					drawContentListV2()
				) : (
					<div className='list-empty'>
						<Empty
							type='contents'
							onClickRecord={sidebarRef && sidebarRef.current && sidebarRef.current.onClickRecord}
							onClickUpload={sidebarRef && sidebarRef.current && sidebarRef.current.onClickUpload}
						/>
					</div>
				)
			) : (
				<Calendar
					ref={calendarRef}
					onClickContent={content => {
						onClickContent(content);
						navigate(`/content/${content.contentId}`);
					}}
				/>
			)}
		</div>
	);
};

export default ListV2;
