import React from 'react';
import ReactDOM from 'react-dom';

const ModalessPopup = ({ isOpen, onClose, children }) => {
	if (!isOpen) return null;

	// 팝업 스타일
	const popupStyle = {
		position: 'fixed',
		bottom: '50px',
		right: '50px',
		width: '390px',
		height: '690px',
		background: '#fff',
		boxShadow: '2px 4px 28px 0px rgba(0, 0, 0, 0.15)',
		borderRadius: '16px',
		zIndex: 1000,
		padding: '20px',
	};

	return ReactDOM.createPortal(
		<div style={popupStyle}>{children}</div>,
		document.body // 팝업을 부모 DOM 외부로 렌더링
	);
};

export default ModalessPopup;
