import React, { useEffect, useState } from 'react';
import './Item.css';

import { ReactComponent as PrivateIcon } from './img/private.svg';
import { ReactComponent as PublicIcon } from './img/public.svg';
import { ReactComponent as DownloadIcon } from './img/download.svg';
import { ReactComponent as DeleteIcon } from './img/delete.svg';
import Avatar from 'react-avatar';
import useContentsStore from '../../../store/ContentsStore';
import { useCookies } from 'react-cookie';
import { drawContentIcon } from '../ContentIcon/ContentIcon';
import { getAvatarColor, isMyThumbnail } from '../../../util/Util';
import { ToastError, ToastInfo } from '../toast/Toast';
import Popup from 'reactjs-popup';
import Share from '../../popup/share/Share';
import DeleteConfirm from '../../popup/content/delete-confirm/DeleteConfirm';
import { useLocation } from 'react-router-dom';
import useAuthStore from '../../../store/AuthStore';

const MAX_DISPLAYED_SHARE_USERS_COUNT = 10;

const Item = ({ data, icon, onClickContent, isHome, isDisabled = false }) => {
	const location = useLocation();

	const { auth } = useAuthStore();
	const [cookies, setCookie, removeCookie] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const {
		contents,
		deleteContents,
		downloadFile,
		sttStatus,
		refreshHomeContents,
		refreshContentFromContentId,
		onContentsChanged,
	} = useContentsStore();
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		if (sttStatus.list) {
			sttStatus.list
				.filter(item => item.isNew)
				.map(item => {
					if (item.contentId === data.contentId) {
						switch (item.status.toUpperCase()) {
							case 'DONE': {
								refreshContentFromContentId(
									cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
									item.contentId
								).finally(() => {
									setLoading(false);
									onContentsChanged({ contentId: item.contentId, status: 'DONE', isNew: false });
								});
								break;
							}
							case 'ERROR':
								setLoading(false);
								break;
							default: {
								const isDone = data.transcribeStatus === 'DONE';

								if (!isDone) {
									setLoading(true);
								}

								break;
							}
						}
					}
				});
		}
	}, [sttStatus.list]);

	const drawShareUsersAvatar = () => {
		if (data && data.shareUsers && data.shareUsers.length) {
			return data.shareUsers.map((user, index) => {
				if (index < MAX_DISPLAYED_SHARE_USERS_COUNT) {
					return (
						<div key={user.nickName} className='avatar' style={{ right: `${20 * index}px` }}>
							<Avatar
								name={user.nickName}
								src={user.thumbnailUrl}
								size='40'
								round
								color={getAvatarColor(index).background}
								fgColor={getAvatarColor(index).color}
								maxInitials={1}
							/>
						</div>
					);
				} else if (index === MAX_DISPLAYED_SHARE_USERS_COUNT) {
					return (
						<div key={user.nickName} className='avatar' style={{ right: `${20 * index}px` }}>
							<Avatar name={`+ ${data.shareUsers.length - index}`} size='40' round maxInitials={3} />
						</div>
					);
				}
			});
		}
	};

	const drawHashtag = () => {
		if (data && data.hashTag) {
			return data.hashTag.map(tag => (
				<div key={tag} className='tag'>
					# {tag}
				</div>
			));
		}
	};

	const getDurationFormat = milliseconds => {
		let seconds = Math.floor(milliseconds / 1000);
		const hours = Math.floor(seconds / 3600);
		seconds %= 3600;
		const minutes = Math.floor(seconds / 60);
		seconds %= 60;

		let result = '';
		if (hours > 0) {
			result += `${hours}시간 `;
		}
		if (minutes > 0) {
			result += `${minutes}분 `;
		}
		if (seconds > 0) {
			result += `${seconds}초`;
		}

		return result.trim();
	};

	return (
		<div
			className={`list-item-container ${isDisabled ? 'disabled' : isLoading ? 'loading' : ''}`}
			onClick={event => {
				event.stopPropagation();
				onClickContent(data);
			}}
		>
			<div className='type'>{icon ? icon : drawContentIcon(isLoading ? 'loading' : data.type)}</div>
			<div className='info'>
				<div className='title'>{data.editedTitle ?? data.title}</div>
				<div className='hashtag'>{drawHashtag()}</div>
			</div>
			<div className='duration'>{getDurationFormat(data.duration)}</div>
			<div className='share-users'>{drawShareUsersAvatar()}</div>
			<div className='owner'>
				<div className='avatar'>
					<span>
						<Avatar
							name={data.creatorNickName}
							src={
								isMyThumbnail(auth, data.creatorEmail)
									? auth.user.profile.thumbnailUrl
									: data.creatorThumbnailUrl
							}
							// src={isMyThumbnail(auth, data.creatorEmail) data.creatorThumbnailUrl}
							size='40'
							round='5px'
							maxInitials={1}
						/>
					</span>
				</div>
				<div className='name'>{data.creatorNickName}</div>
			</div>
			<div className='buttons'>
				{/* <Popup
					trigger={
						<div
							className='share-status'
							onClick={event => {
								event.stopPropagation();
							}}
						>
							<PrivateIcon />
						</div>
					}
					arrow={false}
					position={'bottom right'}
					keepTooltipInside='.list'
				>
					<Share contentId={data.contentId} />
				</Popup> */}
				<div
					className='share-status'
					onClick={event => {
						// event.stopPropagation();
					}}
				>
					<PrivateIcon />
				</div>

				<div
					className='download'
					onClick={event => {
						event.stopPropagation();
						//다운로드
						if (!isLoading) {
							downloadFile(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, data);
						}
					}}
				>
					<DownloadIcon />
				</div>

				<Popup
					trigger={
						<div className='delete'>
							<DeleteIcon />
						</div>
					}
					arrow={false}
					position={'bottom right'}
					keepTooltipInside='.list'
				>
					{close => (
						<DeleteConfirm
							title='콘텐츠를 삭제하시겠습니까?'
							onCancel={close}
							onConfirm={() => {
								deleteContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, {
									contentId: data.contentId,
								})
									.then(() => {
										ToastError(0, '선택한 콘텐츠가 삭제되었습니다.', '콘텐츠 삭제 완료');

										const currentLocation = location.pathname.split('/')[1];

										if (currentLocation === 'home') {
											refreshHomeContents(
												cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken
											);
										}
									})
									.catch(() => {
										ToastInfo(null, '권한이 없습니다.');
									});
							}}
						/>
					)}
				</Popup>
				{/* <div
					className='delete'
					onClick={event => {
						if (!isLoading) {
							event.stopPropagation();
							//삭제
							setLoading(true);
							deleteContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, data)
								.then(() => {
									ToastError(0, '선택한 콘텐츠가 삭제되었습니다.', '콘텐츠 삭제 완료');
									if (isHome) {
										refreshHomeContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken);
									}
								})
								.catch(code => {
									if (code === 401) {
										ToastError(null, '권한이 없습니다.');
									} else {
										ToastError(code);
									}
								})
								.finally(() => {
									setLoading(false);
								});
						}
					}}
				>
					<DeleteIcon />
				</div> */}
			</div>
		</div>
	);
};

export default Item;
