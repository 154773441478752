import React from 'react';
import './ReSummary.css';

import { ReactComponent as LargeIcon } from './img/large.svg';
import { ReactComponent as MediumIcon } from './img/medium.svg';
import { ReactComponent as SmallIcon } from './img/small.svg';
import { Box, Stack, SvgIcon, Typography } from '@mui/material';

const ReSummary = ({ onSummary = () => {} }) => {
	return (
		<Box
			sx={{
				width: 146,
				height: 135,
				border: '1px solid #CED3D6',
				boxSizing: 'border-box',
				borderRadius: '8px',
				zIndex: 2,
				background: '#fff',
			}}
		>
			<Stack>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						padding: '10px',
						height: '44px',
						boxSizing: 'border-box',
						cursor: 'pointer',
						borderRadius: '8px 8px 0 0 ',
						gap: '8px',

						'&:hover': {
							background: '#F9FAFB',
						},
					}}
					onClick={() => onSummary('large')}
				>
					<SvgIcon inheritViewBox sx={{ width: 24, height: 24 }}>
						<LargeIcon />
					</SvgIcon>
					<Typography sx={{ fontSize: 14, fontWeight: 500, color: '#292A2B' }}>라지</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						padding: '10px',
						cursor: 'pointer',
						gap: '8px',
						'&:hover': {
							background: '#F9FAFB',
						},
					}}
					onClick={() => onSummary('medium')}
				>
					<SvgIcon inheritViewBox sx={{ width: 24, height: 24 }}>
						<MediumIcon />
					</SvgIcon>
					<Typography sx={{ fontSize: 14, fontWeight: 500, color: '#292A2B' }}>미디엄</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						padding: '10px',
						margin: 0,
						cursor: 'pointer',
						gap: '8px',
						'&:hover': {
							background: '#F9FAFB',
						},
						borderRadius: '0 0 8px 8px',
					}}
					onClick={() => onSummary('small')}
				>
					<SvgIcon inheritViewBox sx={{ width: 24, height: 24 }}>
						<SmallIcon />
					</SvgIcon>
					<Typography sx={{ fontSize: 14, fontWeight: 500, color: '#292A2B' }}>스몰</Typography>
				</Box>
			</Stack>
		</Box>
	);
};

export default ReSummary;
