import { Box, Paper, Stack, SvgIcon, Typography } from '@mui/material';
import React from 'react';

import { ReactComponent as CopyIcon } from './img/copy.svg';
import { ReactComponent as PasteIcon } from './img/paste.svg';
import { ReactComponent as HighlightIcon } from './img/highlight.svg';

const ContextMenu = ({ isBookmark, onToggleBookmark, onCopyClipboard, onPasteNote, onClose }) => {
	return (
		<Box
			sx={{
				width: 150,
				height: 136,
				boxSizing: 'border-box',
				border: `1px solid rgba(206, 211, 214, 1)`,
				borderRadius: '5px',
			}}
		>
			<Stack direction='column'>
				<Paper
					sx={{
						boxShadow: 'none',
						height: 44,
						display: 'flex',
						alignItems: 'center',
						padding: '10px',
						gap: '8px',
						boxSizing: 'border-box',
						borderRadius: '8px 8px 0 0',
						boxShadow: 'none',
						cursor: 'pointer',
						'&:hover': {
							backgroundColor: '#F0F0F0',
						},
					}}
					onClick={() => {
						onToggleBookmark();
						onClose();
					}}
				>
					<SvgIcon inheritViewBox sx={{ width: 24, height: 24 }}>
						<HighlightIcon />
					</SvgIcon>
					<Typography sx={{ fontSize: 14, fontWeight: 500, fontFamily: 'Noto Sans KR' }}>
						{isBookmark ? '북마크 제거' : '북마크 추가'}
					</Typography>
				</Paper>

				<Paper
					sx={{
						boxShadow: 'none',
						height: 44,
						display: 'flex',
						alignItems: 'center',
						padding: '10px',
						gap: '8px',
						boxSizing: 'border-box',
						// borderRadius: '8px',
						boxShadow: 'none',
						cursor: 'pointer',
						'&:hover': {
							backgroundColor: '#F0F0F0',
						},
					}}
					onClick={() => {
						onCopyClipboard();
						onClose();
					}}
				>
					<SvgIcon inheritViewBox sx={{ width: 24, height: 24 }}>
						<CopyIcon />
					</SvgIcon>
					<Typography sx={{ fontSize: 14, fontWeight: 500, fontFamily: 'Noto Sans KR' }}>
						내용 복사
					</Typography>
				</Paper>
				<Paper
					sx={{
						boxShadow: 'none',
						height: 44,
						display: 'flex',
						alignItems: 'center',
						padding: '10px',
						gap: '8px',
						boxSizing: 'border-box',
						borderRadius: '0 0 8px 8px',
						boxShadow: 'none',
						cursor: 'pointer',
						'&:hover': {
							backgroundColor: '#F0F0F0',
						},
					}}
					onClick={() => {
						onPasteNote();
						onClose();
					}}
				>
					<SvgIcon inheritViewBox sx={{ width: 24, height: 24 }}>
						<PasteIcon />
					</SvgIcon>
					<Typography sx={{ fontSize: 14, fontWeight: 500, fontFamily: 'Noto Sans KR' }}>
						노트에 붙여넣기
					</Typography>
				</Paper>
			</Stack>
		</Box>
	);
};

export default ContextMenu;
