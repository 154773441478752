import React, { useEffect } from 'react';
import styled from 'styled-components';
import SocialLoginButton from './SocialLoginButton';
import EmailLoginForm from './EmailLoginForm';
import { useNavigate } from 'react-router-dom';

import TimbloLogo from './img/sk-poc-logo.png';
import AppleIcon from './img/apple-icon.svg';
import GoogleIcon from './img/google-icon.svg';
import { Divider, LoginContainer, LoginHeader, LoginWrapper, Logo, LogoImage } from './LoginComponents';
import { useAuthAction } from './Action';

const LoginPage = () => {
	const navigate = useNavigate();
	const { isValidSession } = useAuthAction();

	useEffect(() => {
		isValidSession().then(res => {
			if (res) {
				navigate('/');
			}
		});
	}, []);

	return (
		<LoginContainer>
			<LoginWrapper>
				<Logo>
					<LogoImage loading='lazy' src={TimbloLogo} alt='Timblo Logo' />
				</Logo>
				{/* <LoginHeader>팀블로 로그인</LoginHeader> */}
				{/* <SocialLoginButton icon={GoogleIcon} text='Google로 계속하기' provider='Google' />
				<SocialLoginButton icon={AppleIcon} text='Apple로 계속하기' provider='Apple' /> */}
				<Divider />
				<EmailLoginForm />
			</LoginWrapper>
		</LoginContainer>
	);
};

export default LoginPage;
