import React, { useState, useEffect, useLayoutEffect } from 'react';
import './RecycleV2.css';
import { useCookies } from 'react-cookie';
import useContentsStore from '../../store/ContentsStore';
import Box from '@mui/material/Box';
import { ToastError, ToastInfo } from '../../components/common/toast/Toast';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Chip, SvgIcon, Tooltip, Typography } from '@mui/material';
import { isVisible } from 'ckeditor5';
import ContentGrid from '../../components/content/common/ContentGrid';
import HeaderV2 from '../../components/body/headerV2/HeaderV2';
import { PageLoading } from '../../components/page-status/PageLoading';

import { ReactComponent as NoDataImg } from './img/recycle_no_data.svg';

const formatDateToKoreanStyle = dateString => {
	const date = new Date(dateString);

	// 년, 월, 일
	const year = String(date.getFullYear()).slice(-2); // YY
	const month = String(date.getMonth() + 1).padStart(2, '0'); // MM
	const day = String(date.getDate()).padStart(2, '0'); // DD

	// 시간 (12시간제로 변환)
	let hours = date.getHours(); // 24시간제
	const minutes = String(date.getMinutes()).padStart(2, '0'); // MM
	const isPM = hours >= 12;
	const period = isPM ? '오후' : '오전';

	if (hours > 12) hours -= 12; // 오후 시간
	if (hours === 0) hours = 12; // 오전 12시 처리
	const formattedHours = String(hours).padStart(2, '0'); // HH

	// 포맷 조합
	return `${year}. ${month}. ${day} ${period} ${formattedHours}:${minutes}`;
};

const formatMillisecondsToTime = ms => {
	// 총 시간 계산
	const totalSeconds = Math.floor(ms / 1000); // 초 단위로 변환
	const hours = Math.floor(totalSeconds / 3600); // 시간 계산
	const minutes = Math.floor((totalSeconds % 3600) / 60); // 분 계산
	const seconds = totalSeconds % 60; // 초 계산

	// 두 자리 숫자로 포맷
	const formattedHours = String(hours).padStart(2, '0');
	const formattedMinutes = String(minutes).padStart(2, '0');
	const formattedSeconds = String(seconds).padStart(2, '0');

	return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

const RecycleV2 = () => {
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { recycles, refreshRecycles, restoreContents, truncateContents } = useContentsStore();
	const [isLoading, setIsLoading] = useState(true);

	const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

	useLayoutEffect(() => {
		refreshRecycles(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken).finally(() => setIsLoading(false));
	}, []);

	return (
		<>
			<HeaderV2 text='휴지통' enableSearch />
			<div>
				{isLoading ? (
					<PageLoading />
				) : recycles.list && recycles.list.length ? (
					<ContentGrid
						data={recycles.list}
						columnVisibilityModel={{ share: false, restore: true, delete: false, truncate: true }}
						enableDetail={false}
					/>
				) : (
					<Box
						sx={{
							width: '100%',
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '8px',
						}}
					>
						<SvgIcon inheritViewBox sx={{ width: 87, height: 87, marginBottom: '16px' }}>
							<NoDataImg />
						</SvgIcon>
						<Typography
							sx={{ fontSize: 24, fontWeight: 700, fontFamily: 'Noto Sans KR', color: '#28323C' }}
						>
							휴지통이 비어 있습니다.
						</Typography>
						<Typography
							sx={{ fontSize: 18, fontWeight: 400, fontFamily: 'Noto Sans KR', color: '#858C99' }}
						>
							삭제된 회의록이 여기에 표시됩니다.
						</Typography>
					</Box>
				)}
			</div>
		</>
	);
};

export default RecycleV2;
