import React, { useState } from 'react';
import './TabMenu.css';

const TabMenu = ({
	text,
	onClick = () => {},
	color = '#A2A7B1',
	hoveredColor = '#007AFF',
	border = '1px solid transparent',
	hoveredBorderColor = '1px solid #007AFF',
	backgroundColor = 'transparent',
	hoveredBackgroundColor = 'transparent',
	style = {},
}) => {
	const [isHovered, setIsHovered] = useState(false);

	return (
		<div
			className={`body-tab-menu`}
			style={{
				color: isHovered ? hoveredColor : color,
				background: isHovered ? hoveredBackgroundColor : backgroundColor,
				border: isHovered ? hoveredBorderColor : border,
				...style,
			}}
			onClick={() => onClick && onClick(text)}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			{text}
		</div>
	);
};

export default TabMenu;
