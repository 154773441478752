import React, { useEffect, useLayoutEffect, useState } from 'react';
import './Inbox.css';
import moment from 'moment';
import { Box, Button, Stack, SvgIcon, Tab, Tabs, Typography } from '@mui/material';
import useInboxStore from '../../store/InboxStore';

import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import InboxItem from './InboxItem';
import useMessageStore from '../../store/MessageStore';
import HeaderV2 from '../../components/body/headerV2/HeaderV2';
import { ReactComponent as NoDataImg } from './img/inbox_no_data.svg';
import { PageLoading } from '../../components/page-status/PageLoading';
import ModalConfirm from '../../components/common/modal/ModalConfirm';
import { ToastInfo } from '../../components/common/toast/Toast';

const InboxV2 = () => {
	const navigate = useNavigate();
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const [selectedTab, setSelectedTab] = useState(0);
	const { refreshInbox, deleteInbox, readInbox, readAll, inbox } = useMessageStore();
	const [isLoading, setIsLoading] = useState(true);

	useLayoutEffect(() => {
		refreshInbox(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken).finally(() => setIsLoading(false));
	}, []);

	const onTabChanged = (event, newValue) => {
		setSelectedTab(newValue);
	};

	const getFilteredDatas = () => {
		switch (selectedTab) {
			case 0:
				return inbox;
			case 1:
				return inbox.filter(item => item.captureType === 'CONTENT');
			case 2:
				return inbox.filter(item => item.captureType === 'SHARE');
			case 3:
				return inbox.filter(item => item.captureType === 'ERROR');
			default:
				return inbox;
		}
	};

	const deleteItem = async item => {
		// //삭제
		// if (
		// 	await dialogs.confirm('알림메시지 목록에서 삭제됩니다.', {
		// 		okText: '확인',
		// 		cancelText: '취소',
		// 		title: '알림 메시지를 삭제할까요?',
		// 	})
		// ) {
		// 	//삭제하자.
		deleteInbox(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, item.inboxId)
			.then(() => {
				//성공
				ToastInfo('알림 메시지를 삭제했습니다.', '삭제 완료');
			})
			.catch(() => {
				//실패
			});
	};

	const readItem = async item => {
		//읽음
		await readInbox(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, item.inboxId);
	};
	const onChangedItem = ({ type, item }) => {
		switch (type) {
			case 'delete':
				deleteItem(item);
				break;
			default:
				break;
		}
	};

	const onClickItem = async item => {
		//읽음 처리
		await readItem(item);
		navigate(`/content/${item.contentId}`);
	};

	const readAllItem = async () => {
		await readAll(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken);
		refreshInbox(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken);
		ToastInfo('알림 메시지를 모두 확인 처리했습니다.', '전체 확인 완료');
	};

	return (
		<>
			<HeaderV2 text='알림 메시지' enableSearch />
			<Box className='inbox-container-v2'>
				<Box sx={{ display: 'flex', alignItems: 'center', borderBottom: 1, borderColor: 'divider' }}>
					<Tabs
						value={selectedTab}
						onChange={onTabChanged}
						sx={{
							flex: 1,
							padding: '0 32px',

							'& .MuiTab-root': {
								fontSize: 20,
								fontWeight: 600,
								fontFamily: 'Noto Sans KR',
								padding: '16px 24px',
							},
							'& .MuiTabs-indicator': {
								height: 4,
							},

							'&.Mui-selected': {
								color: '#2A53FF',
							},
						}}
					>
						<Tab label='전체' />
						<Tab label='회의록' />
						<Tab label='공유' />
						<Tab label='오류' />
					</Tabs>
					<ModalConfirm
						title='알림 메시지를 모두 확인할까요?'
						description='모든 알림이 확인 처리됩니다.'
						onConfirm={() => readAllItem()}
					>
						<Button
							variant='outlined'
							size='small'
							sx={{
								width: 96,
								height: 40,
								borderRadius: '4px',
								border: `1px solid #CED3D6`,
								color: '#4D5256',
								fontWeight: 500,
								fontSize: 16,
								fontFamily: 'Noto Sans KR',
								marginRight: '32px',
							}}
						>
							전체 확인
						</Button>
					</ModalConfirm>
				</Box>
				<Box
					sx={{
						width: '100%',
						height: 'calc(100% - 66px)',
						padding: '0 32px',
						boxSizing: 'border-box',
						overflow: 'auto',
					}}
				>
					{isLoading ? (
						<PageLoading />
					) : 0 < inbox.length ? (
						getFilteredDatas().map(item => (
							<Stack
								sx={{
									borderBottom: 1,
									borderColor: 'divider',
									flexGrow: 1,
									height: '110px',
									padding: '24px 0',
									boxSizing: 'border-box',
								}}
							>
								<InboxItem key={item.id} item={item} onClick={onClickItem} onChanged={onChangedItem} />
							</Stack>
						))
					) : (
						<InboxNoData />
					)}
				</Box>
			</Box>
		</>
	);
};

export default InboxV2;

const InboxNoData = () => {
	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
			}}
		>
			<SvgIcon
				component='svg'
				inheritViewBox
				sx={{
					width: 92,
					height: 85,
				}}
			>
				<NoDataImg />
			</SvgIcon>
			<Typography
				sx={{ fontSize: 24, fontWeight: 700, color: '#28323C', fontFamily: 'Noto Sans KR', paddingTop: '24px' }}
			>
				알림 메시지가 없습니다.
			</Typography>
			<Typography
				sx={{ fontSize: 18, fontWeight: 400, color: '#858C99', fontFamily: 'Noto Sans KR', paddingTop: '8px' }}
			>
				받은 알림 메시지가 없습니다.
			</Typography>
		</Box>
	);
};
