import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { formatDate } from '@fullcalendar/core/index.js';
import FullCalendar from '@fullcalendar/react';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import './Calendar.css';
import moment from 'moment';
import 'moment/locale/ko';
import { drawContentIcon } from '../common/ContentIcon/ContentIcon';

import { ReactComponent as DotIcon } from './img/dot.svg';
import { ReactComponent as ArrowLeftIcon } from './img/arrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from './img/arrowRight.svg';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import Avatar from 'react-avatar';
import { getAvatarColor } from '../../util/Util';
import Empty from '../page-status/Empty';
import useContentsStore from '../../store/ContentsStore';
import { ToastError } from '../common/toast/Toast';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import ContentCard from '../content/ContentCard';
import useFilterStore from '../../store/FilterStore';

const MAX_DISPLAYED_SHARE_USERS_COUNT = 10;

const getDiffDays = targetDate => {
	const today = moment();
	const target = moment(targetDate);

	return today.diff(target, 'days');
};

const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];

const Calendar = forwardRef(({ onClickContent }, ref) => {
	const calendarRef = useRef();
	const navigate = useNavigate();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const [currentMonth, setCurrentMonth] = useState(null);
	const [selectedDate, setSelectedDate] = useState(moment());
	const [cards, setCards] = useState([]);

	const { contents, getCalendarContents, setSelectedContent, applyedFilters } = useContentsStore();
	const { orderType, shareType, contentType, getFilteredData } = useFilterStore();

	useImperativeHandle(ref, () => ({
		refreshCalendar: refreshCalendarContents,
	}));

	useEffect(() => {
		refreshCards();
	}, [selectedDate, orderType, shareType, contentType]);

	useEffect(() => {
		refreshCalendarContents();
	}, []);

	const refreshCalendarContents = () => {
		const calendarApis = calendarRef.current.getApi();

		if (calendarApis) {
			const startDate = moment(calendarApis.view.activeStart).format('YYYYMMDD');
			const endDate = moment(calendarApis.view.activeEnd).format('YYYYMMDD');

			const month = moment(calendarApis.view.currentStart).format('YYYY년 MM월');

			getCalendarContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, startDate, endDate);
			setCurrentMonth(month);
		}
	};

	const refreshCards = async () => {
		const headers = {
			Authorization: `Bearer ${cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken}`,
		};

		const response = await axios.get(
			`${process.env.REACT_APP_API_URL}/contents?date=${moment(selectedDate).format('YYYYMMDD')}&withShared=true`,
			{ headers }
		);

		if (response && response.status === 200) {
			const filteredData = getFilteredData(response.data.data, shareType, contentType, orderType);
			setCards(filteredData ?? response.data.data);
		}
	};

	// const refreshCards = useCallback(async () => {
	// 	const headers = {
	// 		Authorization: `Bearer ${cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken}`,
	// 	};

	// 	const response = await axios.get(
	// 		`${process.env.REACT_APP_API_URL}/contents?date=${moment(selectedDate).format('YYYYMMDD')}&withShared=true`,
	// 		{ headers }
	// 	);

	// 	if (response && response.status === 200) {
	// 		const filteredData = getFilteredData(response.data.data, shareType, contentType, orderType);
	// 		setCards(filteredData ?? response.data.data);
	// 	}
	// }, [selectedDate, orderType, shareType, contentType]);

	const drawDateTitle = () => {
		return moment(selectedDate).locale('ko').format('YYYY. MM. DD. dddd');
	};

	const drawContentCard = useCallback(
		data => {
			const getDurationFormat = milliseconds => {
				let seconds = Math.floor(milliseconds / 1000);
				const hours = Math.floor(seconds / 3600);
				seconds %= 3600;
				const minutes = Math.floor(seconds / 60);
				seconds %= 60;

				let result = '';
				if (hours > 0) {
					result += `${hours}시간 `;
				}
				if (minutes > 0) {
					result += `${minutes}분 `;
				}
				if (seconds > 0) {
					result += `${seconds}초`;
				}

				return result.trim();
			};

			let isVisible = true;

			if (applyedFilters && Object.keys(applyedFilters).length) {
				if (
					(applyedFilters.title && !data.title.includes(applyedFilters.title)) ||
					!data.editedTitle.includes(applyedFilters.title)
				) {
					isVisible = false;
				} else if (applyedFilters.type && !applyedFilters.type.some(type => type === data.type)) {
					isVisible = false;
				}
			}

			if (isVisible) {
				return (
					<ContentCard
						content={data}
						onClickCard={onClickContent}
						refreshContent={() => {
							refreshCards();
						}}
					/>

					// <div className='card' onClick={() => onClickContent(data)}>
					// 	<div className='top'>
					// 		<div className='icon'>{drawContentIcon(data.type)}</div>
					// 		<div className='text'>{data.editedTitle ?? data.title}</div>
					// 		<div className='dot'>
					// 			<DotIcon />
					// 		</div>
					// 	</div>
					// 	{data.hashTag && (
					// 		<div className='hashtags'>
					// 			{data.hashTag && data.hashTag.map(tag => <div className='tag'>{`# ${tag}`}</div>)}
					// 		</div>
					// 	)}
					// 	{data.shareUsers && data.shareUsers.length ? (
					// 		<div className='share-users'>
					// 			{data.shareUsers.map((user, index) => {
					// 				if (index < MAX_DISPLAYED_SHARE_USERS_COUNT) {
					// 					return (
					// 						<span
					// 							key={user.nickName}
					// 							className='avatar'
					// 							style={{ right: `${20 * index}px` }}
					// 						>
					// 							<Avatar
					// 								name={user.nickName}
					// 								src={user.thumbnailUrl}
					// 								size='40'
					// 								round
					// 								color={getAvatarColor(index).background}
					// 								fgColor={getAvatarColor(index).color}
					// 								maxInitials={1}
					// 							/>
					// 						</span>
					// 					);
					// 				} else if (index === MAX_DISPLAYED_SHARE_USERS_COUNT) {
					// 					return (
					// 						<span
					// 							key={user.nickName}
					// 							className='avatar'
					// 							style={{ right: `${20 * index}px` }}
					// 						>
					// 							<Avatar
					// 								name={`+ ${data.shareUsers.length - index}`}
					// 								size='40'
					// 								round
					// 								maxInitials={10}
					// 							/>
					// 						</span>
					// 					);
					// 				}
					// 			})}
					// 		</div>
					// 	) : (
					// 		<></>
					// 	)}
					// 	<div className='bottom'>
					// 		<div className='duration'>{getDurationFormat(data.duration)}</div>
					// 		<div className='owner'>
					// 			<div className='avatar'>
					// 				<Avatar
					// 					src={data.creatorThumbnailUrl}
					// 					name={data.creatorNickName}
					// 					size={24}
					// 					round
					// 					maxInitials={1}
					// 				/>
					// 			</div>
					// 			<div className='name'>{data.creatorNickName}</div>
					// 		</div>
					// 	</div>
					// </div>
				);
			}
		},
		[applyedFilters, contents.calendar, cards]
	);

	const CustomEvent = useCallback(
		({ event }) => {
			const content = contents.calendar.find(content => content.contentId === event.id);
			let isVisible = true;

			if (applyedFilters && Object.keys(applyedFilters).length) {
				if (applyedFilters.title && !content.title.includes(applyedFilters.title)) {
					isVisible = false;
				} else if (applyedFilters.type && !applyedFilters.type.some(type => type === content.type)) {
					isVisible = false;
				}
			}

			if (isVisible) {
				return (
					<EventContainer title={event.title}>
						<div style={{ width: '14px', height: '16px' }}>{drawContentIcon(content.type, 14)}</div>

						<div
							style={{
								flex: 1,
								display: 'block',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
							}}
						>
							{event.title}
						</div>
					</EventContainer>
				);
			} else {
				return <></>;
			}
		},
		[contents.calendar, applyedFilters]
	);

	const handleEventClick = event => {
		const target = contents.calendar.find(content => content.contentId === event.event.id);

		if (target) {
			setSelectedContent(target);
			navigate(`/content/${event.event.id}`);
		} else {
			ToastError(1000);
		}
	};

	const handlePrev = () => {
		calendarRef.current.getApi().prev();
		refreshCalendarContents();
	};

	const handleNext = () => {
		calendarRef.current.getApi().next();
		refreshCalendarContents();
	};

	const handleToday = () => {
		calendarRef.current.getApi().today();
		refreshCalendarContents();
		setSelectedDate(moment());
	};

	return (
		<div className='calendar-container'>
			<div className='calendar'>
				<div className='header'>
					<div className='prev' onClick={handlePrev}>
						<ArrowLeftIcon />
					</div>
					<div className='date'>{currentMonth}</div>
					<div className='next' onClick={handleNext}>
						<ArrowRightIcon />
					</div>
					<div className='today' onClick={handleToday}>
						오늘
					</div>
				</div>
				<div className='component'>
					<FullCalendar
						ref={calendarRef}
						height={'100%'}
						dayHeaderContent={arg => daysOfWeek[arg.date.getDay()]}
						dayHeaderClassNames={arg => {
							const day = arg.date.getDay();
							if (day === 0) {
								return 'sunday';
							} else if (day === 6) {
								return 'saturday';
							}
							return '';
						}}
						dayCellClassNames={arg => {
							const day = arg.date.getDay();
							if (day === 0) {
								return 'sunday';
							} else if (day === 6) {
								return 'saturday';
							}
							return '';
						}}
						expandRows={true}
						plugins={[dayGridPlugin, interactionPlugin]}
						dateClick={date => setSelectedDate(date.date)}
						selectable={true}
						selectAllow={selectInfo => {
							const start = moment(selectInfo.startStr);
							const end = moment(selectInfo.endStr).subtract(1, 'days');
							return moment(start).format('YYYY-MM-DD') === moment(end).format('YYYY-MM-DD');
						}}
						events={contents.calendar}
						eventContent={event => <CustomEvent event={event.event} />}
						headerToolbar={false}
						eventClick={handleEventClick}
						dayMaxEvents={3}
						dayMaxEventRows={true}
						moreLinkText='more'
						eventBackgroundColor='transparent'
						eventBorderColor='transparent'
						eventTextColor='#28323c'
						moreLinkClick={info => {
							setSelectedDate(info.date);
							return 'dayGridMonth';
						}}
					/>
				</div>
			</div>
			<div className='day-contents' style={{ backgroundColor: '#F9FAFB' }}>
				<div className='date-text'>{drawDateTitle()}</div>
				<div className='cards'>
					{cards && cards.length ? (
						cards.map(card => drawContentCard(card))
					) : (
						<div className='empty'>
							<Empty type='calendar' />
						</div>
					)}
				</div>
			</div>
		</div>
	);
});

export default Calendar;

const EventContainer = styled.div`
	width: 100%;
	height: 18px;
	gap: 5px;
	display: flex;
	align-items: center;
	padding: 5px 5px;
	cursor: pointer;
	background: transparent;
	border-radius: 5px;

	&:hover {
		background: #e2e5e9;
	}
`;
