import { useState } from 'react';
import { HyperLink, Input, Label } from '../LoginComponents';
import ConfirmButton from '../../../components/button/ConfirmButton';
import axios from 'axios';

const EmailJoinForm = ({ emailParam, emailCertifyResult, setEmailCertifyResult, onComplete }) => {
	const [email, setEmail] = useState(emailParam);
	const [emailCertifyCode, setEmailCertifyCode] = useState(null);

	const onEmailCertifyRequest = () => {
		let data = JSON.stringify({
			email: email,
		});

		let config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/auth/timblo/certified?t=${Date.now()}`,
			headers: {
				'Content-Type': 'application/json',
				'x-referer': '/account/signup',
			},
			data: data,
		};
		axios
			.request(config)
			.then(response => {
				switch (response.data.httpCode) {
					case 200:
						setEmailCertifyResult({
							code: 200,
							accessToken: response.data.data.accessToken,
						});
						break;
					default:
						setEmailCertifyResult({
							code: response.data.httpCode,
							message: `장애가 발생하였습니다. (${response.data.httpCode})`,
						});
						break;
				}
			})
			.catch(error => {
				setEmailCertifyResult({
					code: 400,
					message: '장애가 발생하였습니다. (400)',
				});
				console.log(error);
			});
	};

	const onResendCertifyCode = () => {
		if (emailCertifyResult && emailCertifyResult.accessToken) {
			let config = {
				method: 'post',
				url: `${process.env.REACT_APP_API_URL}/auth/timblo/recertified`,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${emailCertifyResult.accessToken}`,
					'x-referer': '/account/signup',
				},
			};

			axios.request(config).then(response => {
				switch (response.data.httpCode) {
					case 200:
						break;
					default:
						setEmailCertifyResult({
							code: response.data.httpCode,
							message: `장애가 발생하였습니다. (${response.data.httpCode})`,
						});
						break;
				}
			});
		} else {
			console.error('accessToken not found');
		}
	};

	const onEmailCertify = () => {
		if (emailCertifyResult && emailCertifyResult.accessToken) {
			let data = {
				code: emailCertifyCode,
			};
			let config = {
				method: 'post',
				url: `${process.env.REACT_APP_API_URL}/auth/timblo/verification`,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${emailCertifyResult.accessToken}`,
				},
				data: data,
			};

			axios.request(config).then(response => {
				switch (response.data.httpCode) {
					case 200:
						onComplete && onComplete(email);
						break;
					case 201:
						setEmailCertifyResult({
							code: response.data.httpCode,
							message: `인증코드가 일치하지 않습니다.`,
						});
						break;
					default:
						setEmailCertifyResult({
							code: response.data.httpCode,
							message: `장애가 발생하였습니다. (${response.data.httpCode})`,
						});
						break;
				}
			});
		}
	};

	return (
		<>
			<Label htmlFor='email'>이메일</Label>
			<Input
				type='email'
				id='email'
				placeholder='이메일을 입력해주세요.'
				aria-label='이메일을 입력해주세요.'
				value={email}
				disabled={emailParam && true}
				onChange={e => setEmail(e.target.value)}
				// disabled={emailCertifyResult && 200 === emailCertifyResult.code}
				// readOnly
				// onChange={e => {
				// 	setEmail(e.target.value);
				// }}
			/>
			{emailCertifyResult && (200 === emailCertifyResult.code || 201 === emailCertifyResult.code) ? (
				<>
					<Label htmlFor='email'>인증코드</Label>
					<Input
						type='text'
						id='emailCertifyCode'
						placeholder='인증코드를 입력해주세요.'
						aria-label='인증코드를 입력해주세요.'
						value={emailCertifyCode}
						onChange={e => {
							setEmailCertifyCode(e.target.value);
						}}
					/>

					<HyperLink
						onClick={() => {
							onResendCertifyCode();
						}}
					>
						인증 코드를 다시 보내드릴까요?
					</HyperLink>
					<ConfirmButton
						label={'이메일 인증하기'}
						isActive={emailCertifyCode}
						onClick={() => {
							onEmailCertify();
						}}
					/>
				</>
			) : (
				<>
					{emailCertifyResult && 200 !== emailCertifyResult.code && (
						<Label color={'#F9463B'}>
							{emailCertifyResult && emailCertifyResult.message
								? emailCertifyResult.message
								: '인증코드를 다시 확인해 주세요.'}
						</Label>
					)}
					<ConfirmButton
						label={'이메일로 계속하기'}
						isActive={email}
						onClick={() => {
							onEmailCertifyRequest();
						}}
					/>
				</>
			)}
		</>
	);
};

export default EmailJoinForm;
