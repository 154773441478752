import React, { useRef } from 'react';
import './TextBox.css';
import Popup from 'reactjs-popup';
import { ReactComponent as DotIcon } from './img/dot.svg';
import Function from '../../popup/content/function/Function';
import useContentsStore from '../../../store/ContentsStore';
import { useCookies } from 'react-cookie';
import { ToastError, ToastInfo } from '../toast/Toast';
import { onCopyText } from '../../../util/Util';

/**
 *
 */
const TextBox = ({
	contentId = null,
	data = null,
	title,
	body,
	type,
	useFunction = true,
	style = {},
	titleStyle = {},
	bodyStyle = {},
	onClickBox = () => {},
	isHighlight = false,
	refreshContent = () => {},
	editable,
}) => {
	const boxRef = useRef();
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { addHighlight, deleteHighlight, onAppendDataToNote } = useContentsStore();

	const onToggleHighlight = isDelete => {
		if (editable) {
			if (isDelete) {
				onDeleteHighlight();
			} else {
				onAddhighlight();
			}
		} else {
			ToastInfo('권한이 없습니다.');
		}
	};

	const onAddhighlight = () => {
		contentId &&
			addHighlight(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId, type)
				.then(res => {
					switch (res.code) {
						case 200:
							ToastInfo('하이라이트가 추가 되었습니다.');
							refreshContent();
							break;
						case 401:
							ToastError(0, '권한이 없습니다.');
							break;
						default:
							ToastError(res.message);
							break;
					}
				})
				.catch(e => {
					ToastError(1016);
				});
	};

	const onDeleteHighlight = () => {
		deleteHighlight(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId, type)
			.then(res => {
				switch (res.code) {
					case 200:
						ToastInfo('하이라이트가 삭제 되었습니다.');
						refreshContent();
						break;
					case 401:
						ToastError(0, '권한이 없습니다.');
						break;
					default:
						ToastError(res.message);
						break;
				}
			})
			.catch(e => {
				ToastError(1016);
			});
	};

	const onPasteNote = () => {
		if (editable) {
			onAppendDataToNote(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId, data);
		} else {
			ToastInfo('권한이 없습니다.');
		}
	};

	const onComment = () => {
		ToastInfo('아직 지원하지 않는 기능입니다.');
	};

	return (
		<div ref={boxRef} className='text-box-container' style={style} onClick={() => onClickBox()}>
			<div className='title' style={titleStyle}>
				{/* <Title> */}
				<div className='box'>
					<div className='text'>{title}</div>
					{useFunction && (
						<Popup
							trigger={
								<div className='dot'>
									<DotIcon />
								</div>
							}
							position={'bottom right'}
							keepTooltipInside='.main-content-v2'
						>
							{close => (
								<Function
									onClickHighlight={() => onToggleHighlight(isHighlight)}
									onClickCopyText={() =>
										onCopyText(boxRef.current.innerText, () =>
											ToastInfo('내용이 성공적으로 복사되었습니다.', '내용 복사 완료')
										)
									}
									onClickPasteNote={onPasteNote}
									onClickComment={onComment}
									onClose={close}
									isHighlight={isHighlight}
								/>
							)}
						</Popup>
					)}
				</div>
				{/* </Title> */}
			</div>
			<div className='body' style={bodyStyle}>
				{body}
			</div>
		</div>
	);
};

export default TextBox;
