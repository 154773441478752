import './TimbloButton.css';

import { ReactComponent as ShowType } from './img/showType.svg';
import { ReactComponent as Filter } from './img/filter.svg';
import { ReactComponent as RecycleBin } from './img/recycleBin.svg';
import { ReactComponent as Share } from './img/share.svg';
import { ReactComponent as Download } from './img/download.svg';
import { ReactComponent as AddAttendees } from './img/addAttendees.svg';
import { ReactComponent as List } from './img/list.svg';
import { ReactComponent as Delete } from './img/delete.svg';
import { ReactComponent as ReadAll } from './img/read-all.svg';
import { ReactComponent as Refresh } from './img/refresh.svg';

import { forwardRef, useEffect } from 'react';
import useContentsStore from '../../store/ContentsStore';

const TimbloButton = forwardRef(({ label, alias = 'showType', mode = 'normal', onClick }, ref) => {
	const { applyedFilters } = useContentsStore();

	const drawIcon = () => {
		switch (alias) {
			case 'showType':
				return <ShowType />;
			case 'filter':
				return <Filter />;
			case 'delete':
			case 'recycle':
			case 'recycleBin':
				return <RecycleBin />;
			case 'share':
				return <Share />;
			case 'download':
				return <Download />;
			case 'attendees':
			case 'addAttendees':
				return <AddAttendees />;
			case 'list':
				return <List />;
			case 'delete':
				return <Delete />;
			case 'readAll':
				return <ReadAll />;

			case 'refresh':
				return <Refresh />;
			default:
				return <span>Cannot find icon</span>;
		}
	};

	return (
		<button ref={ref} className={`timblo-button ${mode}`} onClick={onClick}>
			{drawIcon()}
			{alias === 'filter' && Object.keys(applyedFilters).length ? <div className='circle' /> : null}
			<span className={`timblo-button-label ${mode}`}>{label}</span>
		</button>
	);
});

export default TimbloButton;
