import { useRef, useState } from 'react';
import styled from 'styled-components';
import ConfirmButton from '../../../components/button/ConfirmButton';

import uploadImage from '../img/profile-upload.svg';
import { Input, Label, ProfileImage, ProfileUpload } from '../LoginComponents';
import axios from 'axios';

const ProfileForm = ({ email, accessToken, onComplete }) => {
	const fileInputRef = useRef(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [nickName, setNickName] = useState('');
	const [password, setPassword] = useState('');
	const [joinResult, setJoinResult] = useState(null);
	const [thumbnail, setThumbnail] = useState(uploadImage);
	const [isNickNameUsable, setNickNameUsable] = useState(-1);
	const [isLoading, setLoading] = useState(false);

	const onSignUp = () => {
		const formData = new FormData();
		formData.append('pass', password);
		formData.append('name', '');
		formData.append('nickName', nickName);
		formData.append('thumbnail', selectedFile);

		const headers = {
			'Content-Type': 'multipart/form-data; boundary=**',
			Authorization: `Bearer ${accessToken}`,
		};

		setLoading(true);
		axios
			.post(`${process.env.REACT_APP_API_URL}/auth/timblo/signup`, formData, {
				headers: headers,
			})
			.then(response => {
				if (200 === response.data.httpCode) {
					onComplete && onComplete(true);
				} else {
					setJoinResult({ code: response.data.httpCode });
				}
			})
			.catch(error => {
				console.error('에러 발생:', error);
				setLoading(false);
				setJoinResult({ code: 400 });
			});
	};

	const onFileChange = event => {
		const file = event.target.files[0];
		if (file) {
			setSelectedFile(file);
			setThumbnail(URL.createObjectURL(file));
		}
	};

	const onNickNameCheck = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
		};
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/auth/nickname`,
				{
					nickName: nickName,
				},
				{
					headers: headers,
				}
			)
			.then(response => {
				switch (response.data.httpCode) {
					case 200:
						setNickNameUsable(1);
						setJoinResult({ code: 200 });
						break;
					case 512:
						setNickNameUsable(0);
						break;
					default:
						setJoinResult({ code: response.data.httpCode });
						break;
				}
			})
			.catch(error => {
				console.error('에러 발생:', error);
				setJoinResult({ code: 401 });
			});
	};

	const drawNickNameUsable = () => {
		return (
			0 <= isNickNameUsable && (
				<Label color={isNickNameUsable === 1 ? '#0000FF' : '#F9463B'}>
					{isNickNameUsable === 1 ? '사용가능한 닉네임입니다.' : '이미 사용중인 닉네임입니다.'}
				</Label>
			)
		);
	};

	return (
		<>
			{!accessToken ? (
				<>
					{' '}
					<Label color={'#F9463B'}>세션이 만료되었습니다.</Label>
				</>
			) : (
				<>
					<ProfileUpload>
						<ProfileImage
							src={thumbnail}
							alt='Profile'
							onClick={() => {
								!isLoading && fileInputRef.current.click();
							}}
						/>
						<input type='file' ref={fileInputRef} style={{ display: 'none' }} onChange={onFileChange} />
						<UploadText>사진 추가</UploadText>
					</ProfileUpload>
					<Description>
						이제 마지막 단계입니다.
						<br />
						<span>
							닉네임은 공유나 참석자 등록할 때,
							<br />
							검색에 활용되니 이름을 사용하는 것이 좋아요.
						</span>
					</Description>

					<Label htmlFor='email'>이메일</Label>
					<Input
						type='email'
						id='email'
						placeholder='이메일을 입력해주세요.'
						aria-label='이메일을 입력해주세요.'
						value={email}
						disabled={true}
					/>

					<Label htmlFor='nickName'>닉네임</Label>
					<Input
						type='text'
						id='nickName'
						placeholder='닉네임을 입력해주세요.'
						aria-label='닉네임을 입력해주세요.'
						value={nickName}
						onChange={e => {
							setNickName(e.target.value);
						}}
						onBlur={onNickNameCheck}
						disabled={isLoading}
					/>
					{drawNickNameUsable()}

					<Label htmlFor='password'>비밀번호</Label>
					<Input
						type='password'
						id='password'
						placeholder='비밀번호를 입력해주세요.'
						aria-label='비밀번호를 입력해주세요.'
						value={password}
						onChange={e => {
							setPassword(e.target.value);
						}}
						disabled={isLoading}
					/>

					{joinResult && 200 !== joinResult.code && (
						<Label color={'#F9463B'}>
							{`${joinResult && joinResult.message ? joinResult.message : '장애가 발생하였습니다.'}(${
								joinResult.code
							})`}
						</Label>
					)}
					<ConfirmButton
						label={'시작하기'}
						isActive={nickName && password && accessToken && 1 === isNickNameUsable}
						onClick={() => {
							onSignUp();
						}}
						isLoading={isLoading}
					/>
				</>
			)}
		</>
	);
};

export default ProfileForm;

const UploadText = styled.span`
	margin-top: 4px;
`;

const Description = styled.p`
	margin-top: 20px;
	width: 100%;
	color: #858c99;
	text-align: center;
	font: 500 18px/24px Noto Sans, sans-serif;
	span {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
	}
`;
