import React from 'react';
import { styled, Tooltip, tooltipClasses } from '@mui/material';

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
	({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#fff',
			fontSize: 14,
			border: '1px solid #CED3D6',
			color: '#000',
			maxWidth: '600px',
			display: 'flex',
			flexWrap: 'wrap',
			gap: '8px',
		},
	})
);

export default HtmlTooltip;
