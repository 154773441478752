import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import './Note.css';
import './Editor.css';

import {
	ClassicEditor,
	AccessibilityHelp,
	AutoLink,
	Autosave,
	Bold,
	Essentials,
	FindAndReplace,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	Highlight,
	Italic,
	Link,
	Paragraph,
	SelectAll,
	Strikethrough,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	Underline,
	Undo,
	Markdown,
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';

import './Editor.css';
import useContentsStore from '../../../store/ContentsStore';
import { useCookies } from 'react-cookie';

const editorConfig = {
	toolbar: {
		items: [
			'undo',
			'redo',
			'|',
			'findAndReplace',
			'selectAll',
			'|',
			'fontSize',
			'fontFamily',
			'fontColor',
			'fontBackgroundColor',
			'|',
			'bold',
			'italic',
			'underline',
			'strikethrough',
			'|',
			'link',
			'insertTable',
			'highlight',
			'|',
			'accessibilityHelp',
		],
		// shouldNotGroupWhenFull: false,
	},
	plugins: [
		AccessibilityHelp,
		AutoLink,
		Autosave,
		Bold,
		Essentials,
		FindAndReplace,
		FontBackgroundColor,
		FontColor,
		FontFamily,
		FontSize,
		Highlight,
		Italic,
		Link,
		Paragraph,
		SelectAll,
		Strikethrough,
		Table,
		TableCaption,
		TableCellProperties,
		TableColumnResize,
		TableProperties,
		TableToolbar,
		Underline,
		Undo,
	],
	fontFamily: {
		supportAllValues: true,
	},
	fontSize: {
		options: [10, 12, 14, 'default', 18, 20, 22],
		supportAllValues: true,
	},
	language: 'ko',
	table: {
		contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
	},
	isReadOnly: true,
};

const WAITING_TIME = 1500;

const Note = forwardRef(({ contentId, editable = false }, ref) => {
	const editorRef = useRef(null);
	const timerRef = useRef();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const [text, setText] = useState(null);
	const [instance, setInstance] = useState(null);
	const { selectedContent, getNoteData, updateNoteData } = useContentsStore();

	useImperativeHandle(ref, () => ({
		refreshNote: refreshNote,
	}));

	useEffect(() => {
		if (contentId) {
			refreshNote();
		}

		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, []);

	useEffect(() => {
		if (instance) {
			if (!editable) {
				instance.enableReadOnlyMode('lock');
			}
		}
	}, [instance]);

	useEffect(() => {
		if (selectedContent) {
			refreshNote();
		}
	}, [selectedContent]);

	const refreshNote = () => {
		getNoteData(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId).then(data =>
			setText(data.content)
		);
	};

	const onSave = text => {
		updateNoteData(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId, text);
	};

	return (
		<div className='note-container'>
			<div className='editor-main-container'>
				<div
					className='editor-container editor-container_classic-editor'
					style={{ width: '100%', height: '100%' }}
				>
					<div className='editor-container__editor' style={{ width: '100%', height: '100%' }}>
						{text !== null && (
							<CKEditor
								ref={editorRef}
								editor={ClassicEditor}
								config={{
									...editorConfig,
									autosave: {
										waitingTime: WAITING_TIME,
										save(editor) {
											const doc = document.getElementsByClassName('ck-editor__editable');
											if (doc && doc[0]) {
												onSave(doc[0].innerHTML);
											} else {
												const data = editor.getData();
												onSave(data);
											}
										},
									},
								}}
								onReady={editor => setInstance(editor)}
								data={text}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
});

export default Note;
