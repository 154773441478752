import React, { useState, useEffect, useMemo, useRef, useLayoutEffect } from 'react';
import './ContentGrid.css';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import useContentsStore from '../../../store/ContentsStore';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { ToastError, ToastInfo } from '../../common/toast/Toast';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Chip, SvgIcon, Tooltip, Typography } from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { drawContentTypeV2, drawGridIcons } from '../../common/ContentIcon/ContentIcon';
import { formatDateToKoreanStyle, formatMillisecondsToTime } from '../../../util/Util';
import useAuthStore from '../../../store/AuthStore';
import TruncateButton from '../../common/button/TruncateButton';
import ShareButton from '../../common/button/ShareButton';
import useFilterStore from '../../../store/FilterStore';
import DeleteButton from '../../common/button/DeleteButton';
import ModalConfirm from '../../common/modal/ModalConfirm';
import HtmlTooltip from '../../tooltip/HtmlTooltip';
import OverflowTypography from './OverFlowTypography';
import OverFlowItem from './OverFlowItem';
import { getHighlightText, getHighlightTypo } from './HighlightText';

const ContentGrid = ({ data, columnVisibilityModel, highlightText = '', enableDetail = true, isHome }) => {
	// const dialogs = useDialogs();
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const navigate = useNavigate();
	const { auth } = useAuthStore();
	const { sttStatus, isSearching, deleteContents, restoreContents, truncateContents, refreshContentFromContentId } =
		useContentsStore();
	const { shareType, contentType, orderType, getFilteredData } = useFilterStore();

	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [resizeEvent, setResizeEvent] = useState(null);
	const resizeTimeout = useRef(null); // To hold the timeout reference

	const [columnSize, setColumnSize] = useState({});

	useLayoutEffect(() => {
		if (data && !data.id) {
			const filteredData = getFilteredData(data, shareType, contentType, orderType);

			if (filteredData) {
				setRows(filteredData);
			} else {
				setRows([]);
			}

			setIsLoading(false);
		} else {
			setRows([]);
			setIsLoading(false);
		}
	}, [data, shareType, contentType, orderType]);

	useEffect(() => {
		if (sttStatus.list) {
			sttStatus.list
				.filter(item => item.isNew)
				.map(item => {
					const target = data.find(content => content.contentId === item.contentId);

					if (target) {
						switch (item.status.toUpperCase()) {
							case 'ERROR':
							case 'DONE': {
								refreshContentFromContentId(
									cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
									target.contentId
								);
								break;
							}

							default: {
								break;
							}
						}
					}
				});
		}
	}, [sttStatus.list]);

	/**
	 * 컨텐츠 클릭
	 * @param {content} content
	 */
	const onContentClick = contentId => {
		if (enableDetail) {
			if (contentId) {
				if ('' !== highlightText) {
					navigate(`/content/${contentId}?highlight=${highlightText}`);
				} else {
					navigate(`/content/${contentId}`);
				}
			}
		}
	};

	/**
	 * 복구
	 * @param {content} param0
	 */
	const onRecoveryClick = ({ contentId }) => {
		restoreContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId)
			.then(() => {
				ToastInfo('선택된 회의록이 복구되었습니다.', '복구 완료');
			})
			.catch(code => {
				ToastError(code, '복구에 실패했습니다.', '복구 실패');
			});
	};

	/**
	 * 완전 삭제
	 * @param {content} content
	 */
	const onTruncateClick = async content => {
		// const confirm = await dialogs.confirm('회의록이 영구적으로 삭제되어 복구할 수 없습니다.', {
		// 	okText: '확인',
		// 	cancelText: '취소',
		// 	title: '영구삭제 할까요?',
		// });

		const confirm = window.confirm('회의록이 영구적으로 삭제되어 복구할 수 없습니다.');
		if (confirm) {
			truncateContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, content.contentId)
				.then(() => {
					ToastInfo('선택된 회의록이 영구삭제 되었습니다.', '삭제 완료');
				})
				.catch(code => {
					ToastError(code, '삭제에 실패했습니다.', '삭제 실패');
				});
		}
		// if (confirm) {
		// 	truncateContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, content.contentId)
		// 		.then(() => {
		// 			ToastInfo('선택된 회의록이 영구삭제 되었습니다.', '삭제 완료');
		// 		})
		// 		.catch(code => {
		// 			ToastError(code, '삭제에 실패했습니다.', '삭제 실패');
		// 		});
		// }
	};

	const drawChips = params => {
		if (params && params.value && params.value.length) {
			return params.value.map((hashTag, index) => {
				return (
					<Chip
						key={index}
						label={`#${hashTag}`}
						sx={{
							flexShrink: 0,
							border: '1px solid #ECEDEF', // 테두리
							backgroundColor: '#F3F5F7', // 배경색
							color: '#417EEA', // 글자색
							fontWeight: 'regular',
							overflow: 'hidden', // Chip이 넘칠 경우 숨김
							whiteSpace: 'nowrap', // Chip의 텍스트는 한 줄로 유지
							textOverflow: 'ellipsis' /* 넘치는 텍스트를 ...으로 표시 */,
						}}
					/>
				);
			});
		} else {
			return null;
		}
	};

	const columns = useMemo(
		() => [
			{
				field: 'type',
				headerName: '종류',
				minWidth: 100,
				maxWidth: 100,
				width: 100,
				align: 'center',
				headerAlign: 'center',
				sortable: false,
				headerClassName: 'custom-header',

				renderCell: params => {
					return (
						<Box
							sx={{
								display: 'flex', // 부모 컨테이너를 flex로 설정
								justifyContent: 'center', // 수평 왼쪽 정렬
								alignItems: 'center', // 수직 가운데 정렬
								width: '100%',
								height: '100%',
								gap: '4px',
								fontFamily: 'Noto Sans KR',
								paddingLeft: '20px',
								boxSizing: 'border-box',
							}}
						>
							<HtmlTooltip
								title={
									params.row.fileName ? (
										<div
											style={{
												color: '#28323C',
												fontSize: '16px',
												fontWeight: 600,
												fontFamily: 'Noto Sans KR',
											}}
										>
											{params.row.fileName}
										</div>
									) : (
										''
									)
								}
								placement='top-start'
								slotProps={{
									popper: {
										modifiers: [
											{
												name: 'offset',
												options: {
													offset: [-10, 0],
												},
											},
										],
									},
								}}
							>
								<Box sx={{ width: 40, height: 40, display: 'flex' }}>
									{drawContentTypeV2(auth, params.row, 40)}
								</Box>
							</HtmlTooltip>
						</Box>
					);
				},
			},
			{
				field: 'title',
				headerName: '회의록명',
				minWidth: 200,
				width: columnSize && columnSize.title ? Number(columnSize.title) : isHome ? 340 : 450,
				headerAlign: 'center',
				sortable: false,

				renderCell: params => {
					return (
						<Box
							sx={{
								display: 'flex', // 부모 컨테이너를 flex로 설정
								justifyContent: 'flex-start', // 수평 왼쪽 정렬
								alignItems: 'center', // 수직 가운데 정렬
								width: '100%',
								height: '100%',
								gap: '4px',
							}}
						>
							<OverFlowItem
								tooltipItem={
									<div
										style={{
											color: '#28323C',
											fontSize: '16px',
											fontWeight: 600,
											fontFamily: 'Noto Sans KR',
										}}
									>
										{params.row.editedTitle ?? params.row.title}
									</div>
								}
								item={getHighlightTypo({
									highlightText: highlightText,
									text: params.row.editedTitle ?? params.row.title,
									sx: {
										fontWeight: 600,
										display: 'block', // Typography는 block으로 설정
										width: '100%', // 셀 너비에 맞게
										overflow: 'hidden', // 넘칠 경우 숨김 처리
										whiteSpace: 'nowrap', // 텍스트 한 줄로
										textOverflow: 'ellipsis', // 넘치면 ... 표시
										fontFamily: 'Noto Sans KR',
										fontSize: '16px',
										color: '#28323C',
									},
								})}
								columnSize={columnSize}
								type='title'
							/>
						</Box>
					);
				},
			},
			{
				field: 'hashTag',
				headerName: '키워드',
				minWidth: 200,
				width: columnSize && columnSize.hashTag ? Number(columnSize.hashTag) : isHome ? 400 : 520,
				headerAlign: 'center',
				sortable: false,
				renderCell: params => {
					if (params.value) {
						return (
							<OverFlowItem
								columnSize={columnSize}
								tooltipItem={drawChips(params)}
								item={
									<Box
										sx={{
											display: 'inline-block', // 부모 컨테이너를 flex로 설정
											overflow: 'hidden',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis',
											// justifyContent: 'flex-start', // 수평 왼쪽 정렬
											// alignItems: 'center', // 수직 가운데 정렬
											width: '100%',
											height: '100%',
											gap: '4px',
											fontFamily: 'Noto Sans KR',
										}}
									>
										{params.value?.map((hashTag, index) => {
											return (
												<Chip
													key={index}
													label={getHighlightTypo({
														highlightText: highlightText,
														text: `#${hashTag}`,
														sx: {
															fontFamily: 'Noto Sans KR',
															flexShrink: 0,
															color: '#417EEA', // 글자색
															overflow: 'hidden', // Chip이 넘칠 경우 숨김
															whiteSpace: 'nowrap', // Chip의 텍스트는 한 줄로 유지
															textOverflow: 'ellipsis' /* 넘치는 텍스트를 ...으로 표시 */,
															marginRight: '4px',
															fontSize: 14,
														},
													})}
													sx={{
														flexShrink: 0,
														fontFamily: 'Noto Sans KR',
														border: '1px solid #ECEDEF', // 테두리
														backgroundColor: '#F3F5F7', // 배경색
														overflow: 'hidden', // Chip이 넘칠 경우 숨김
														whiteSpace: 'nowrap', // Chip의 텍스트는 한 줄로 유지
														marginRight: '4px',
													}}
												/>
											);
										})}
									</Box>
								}
								type={'keyword'}
							/>
						);
					} else {
						return <></>;
					}
				},
			},
			{
				field: 'createAt',
				headerName: '생성일',
				minWidth: 200,
				flex: 1,
				align: 'center',
				headerAlign: 'center',
				sortable: false,
				renderCell: params => {
					return (
						<Typography
							sx={{
								fontWeight: 'regular',
								display: 'flex',
								justifyContent: 'center', // 수평 가운데 정렬
								alignItems: 'center', // 수직 가운데 정렬
								height: '100%', // 셀의 전체 높이 사용
								overflow: 'hidden', // 넘칠 경우 숨김
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								fontFamily: 'Noto Sans KR',
								color: '#292A2B',
								fontSize: '16px',
							}}
						>
							{formatDateToKoreanStyle(params.value)}
						</Typography>
					);
				},
			},
			{
				field: 'duration',
				headerName: '소요시간',
				minWidth: 100,
				maxWidth: 100,
				align: 'center',
				headerAlign: 'center',
				sortable: false,
				renderCell: params => {
					return (
						<Typography
							sx={{
								fontWeight: 'regular',
								display: 'flex',
								justifyContent: 'center', // 수평 가운데 정렬
								alignItems: 'center', // 수직 가운데 정렬
								height: '100%', // 셀의 전체 높이 사용
								overflow: 'hidden', // 넘칠 경우 숨김
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								fontFamily: 'Noto Sans KR',
								color: '#292A2B',
								fontSize: '16px',
							}}
						>
							{formatMillisecondsToTime(params.value)}
						</Typography>
					);
				},
			},
			{
				field: 'attendee',
				headerName: '참석자',
				minWidth: 80,
				maxWidth: 80,
				align: 'center',
				headerAlign: 'center',
				sortable: false,
				renderCell: params => {
					return (
						<Typography
							sx={{
								fontWeight: 'regular',
								display: 'flex',
								justifyContent: 'center', // 수평 가운데 정렬
								alignItems: 'center', // 수직 가운데 정렬
								height: '100%', // 셀의 전체 높이 사용
								overflow: 'hidden', // 넘칠 경우 숨김
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								fontFamily: 'Noto Sans KR',
								color: '#292A2B',
								fontSize: '16px',
							}}
						>
							{params.row.transcribeStatus === 'DONE' && params.row.speakerInfo
								? `${params.row.speakerInfo.length}명`
								: '-'}
						</Typography>
					);
				},
			},
			{
				field: 'restore',
				headerName: '복구',
				minWidth: 80,
				align: 'center',
				headerAlign: 'center',
				sortable: false,
				renderCell: params => {
					return (
						<Box
							sx={{
								height: '100%',
								width: '100%',
								display: 'flex',
								overflow: 'hidden',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Button
								sx={{
									minWidth: '40px',
									width: '40px',
									height: '40px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									border: '1px solid #E1E4E6',
									borderRadius: '8px',
									padding: 0,
									outline: 'none', // 아웃라인 제거
									'&:focus': {
										outline: 'none',
									},
								}}
								onClick={event => {
									event.stopPropagation();
									onRecoveryClick(params.row);
								}}
							>
								{drawGridIcons('restore')}
							</Button>
						</Box>
					);
				},
			},
			{
				field: 'share',
				headerName: '공유',
				minWidth: 80,
				align: 'center',
				headerAlign: 'center',
				sortable: false,
				renderCell: params => {
					return (
						<Box
							sx={{
								height: '100%',
								width: '100%',
								display: 'flex',
								overflow: 'hidden',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<ShareButton content={params.row} />
						</Box>
					);
				},
			},
			{
				field: 'delete',
				headerName: '삭제',
				minWidth: 80,
				align: 'center',
				headerAlign: 'center',
				sortable: false,
				renderCell: params => {
					return (
						<Box
							sx={{
								height: '100%',
								width: '100%',
								display: 'flex',
								overflow: 'hidden',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<DeleteButton
								title={'회의록을 삭제할까요?'}
								description='회의록이 휴지통으로 이동합니다.'
								size={40}
								onConfirm={() => {
									deleteContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, {
										contentId: params.row.contentId,
									})
										.then(() => {
											ToastError(0, '선택한 콘텐츠가 삭제되었습니다.', '콘텐츠 삭제 완료');
										})
										.catch(() => {
											ToastError(0, '권한이 없습니다.');
										});
								}}
							/>
						</Box>
					);
				},
			},
			{
				field: 'truncate',
				headerName: '영구삭제',
				minWidth: 80,
				align: 'center',
				headerAlign: 'center',
				sortable: false,
				renderCell: params => {
					return (
						<Box
							sx={{
								height: '100%',
								width: '100%',
								display: 'flex',
								overflow: 'hidden',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<DeleteButton
								title={'영구삭제 할까요?'}
								description='회의록이 영구적으로 삭제되어 복구할 수 없습니다.'
								size={40}
								onConfirm={() => {
									truncateContents(
										cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
										params.row.contentId
									)
										.then(() => ToastInfo('선택된 회의록이 영구삭제 되었습니다.', '삭제 완료'))
										.catch(() => ToastError(0, '삭제에 실패했습니다.', '삭제 실패'));
								}}
							/>
						</Box>
					);
				},
			},
		],
		[columnSize]
	);

	return (
		<Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
			<DataGrid
				loading={isLoading}
				rows={rows}
				columns={columns}
				onColumnResize={e => {
					// Clear the previous timeout to reset the debounce timer
					if (resizeTimeout.current) {
						clearTimeout(resizeTimeout.current);
					}

					// Set a new timeout to execute the action after the resize has finished
					resizeTimeout.current = setTimeout(() => {
						setResizeEvent(e);

						setColumnSize({ ...columnSize, [e.colDef.field]: e.width });

						// `params` contains details like `field` (column name), `width` (new width after resize)
					}, 300); // 300ms debounce time (can be adjusted as needed)
				}}
				columnVisibilityModel={columnVisibilityModel}
				disableColumnMenu
				hideFooter
				columnHeaderHeight={isHome ? 36 : 56}
				rowHeight={isHome ? 64 : 88}
				onRowClick={event => onContentClick(event.row.contentId)}
				sx={{
					border: 'none',
					'& .MuiDataGrid-columnHeaders': {
						fontFamily: 'Noto Sans KR',
						pointerEvents: 'none',
					},

					'& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator': {
						pointerEvents: 'all',
					},
					'& .MuiDataGrid-row:hover': {
						backgroundColor: '#F9FAFB', // 원하는 색상으로 변경
					},

					'& .MuiDataGrid-columnHeader': {
						color: '#878D91',
						fontWeight: '500',
						fontSize: '16px',
						textAlign: 'center',
						borderTop: '1px solid #CED3D6',
						borderBottom: '0.6px solid #CED3D6',
						height: 100,
					},

					'& .MuiDataGrid-cell': {
						outline: 'none !important',
						border: 'none',
					},

					'& .MuiDataGrid-cell:focus': {
						outline: 'none',
					},

					'& .MuiDataGrid-columnHeader:focus': {
						outline: 'transparent',
					},

					'& .MuiDataGrid-row': {
						border: 'none',
					},
				}}
			/>
		</Box>
	);
};

export default ContentGrid;
