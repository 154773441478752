import React, { useEffect, useRef } from 'react';
import './ChronologicalSummary.css';
import Popup from 'reactjs-popup';

import { ReactComponent as DotIcon } from './img/dot.svg';
import Function from '../../popup/content/function/Function';
import { ToastError, ToastInfo } from '../../common/toast/Toast';
import { onCopyText } from '../../../util/Util';
import { useCookies } from 'react-cookie';
import useContentsStore from '../../../store/ContentsStore';

const ChronologicalSummary = ({ contentId, highlights, data, editable = false, refreshContent, onSeekTime }) => {
	const summaryRef = useRef([]);
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);

	const { addHighlight, deleteHighlight, selectedContent, onAppendDataToNote } = useContentsStore();

	const onClickHighlight = item => {
		if (editable) {
			if (isHighlight(item)) {
				onDeleteHighlight(item);
			} else {
				onAddhighlight(item);
			}
		} else {
			ToastInfo('권한이 없습니다.');
		}
	};

	const onAddhighlight = item => {
		addHighlight(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId, `summaryTime`, item.index)
			.then(res => {
				switch (res.code) {
					case 200:
						ToastInfo('하이라이트가 추가 되었습니다.');
						refreshContent();
						break;
					case 401:
						ToastError(0, '권한이 없습니다.');
						break;
					default:
						ToastError(res.message);
						break;
				}
			})
			.catch(e => {
				ToastError(1016);
			});
	};

	const onDeleteHighlight = item => {
		deleteHighlight(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId, `summaryTime`, item.index)
			.then(res => {
				switch (res.code) {
					case 200:
						ToastInfo('하이라이트가 삭제 되었습니다.');
						refreshContent();
						break;
					case 401:
						ToastError(0, '권한이 없습니다.');
						break;
					default:
						ToastError(res.message);
						break;
				}
			})
			.catch(e => {
				ToastError(1016);
			});
	};

	const onClickCopyText = idx => {
		const text = summaryRef.current[idx].innerText;

		onCopyText(text, () => ToastInfo('내용이 성공적으로 복사되었습니다.', '내용 복사 완료'));
	};

	const onClickPasteNote = item => {
		if (editable) {
			item = {
				type: 'summaryTime',
				data: item,
			};

			onAppendDataToNote(
				cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
				selectedContent.contentId,
				item
			);
		} else {
			ToastInfo('권한이 없습니다.');
		}
	};

	const isHighlight = item => {
		return highlights && highlights[0] && highlights[0].data.some(highlight => highlight.index === item.index);
	};

	const onClickStartTime = time => {
		const startTime = time.split('~')[0];

		const [hours, minutes, seconds] = startTime.split(':').map(Number);
		const startSec = hours * 60 * 60 + minutes * 60 + seconds;

		onSeekTime(startSec);
	};

	return (
		<div className='chronological-summary-container'>
			<div className='title'>📈 구간별 주제 & 요약</div>
			<div className='content'>
				{data && data.length ? (
					data.map((item, idx) => (
						<div
							ref={el => (summaryRef.current[idx] = el)}
							key={idx}
							className='section'
							style={isHighlight(item) ? { background: '#F6FFF7' } : {}}
						>
							<Popup
								trigger={
									<div className='dot'>
										<DotIcon />
									</div>
								}
								position={['bottom right', 'top right']}
								keepTooltipInside='.chronological-summary-container'
							>
								{close => (
									<Function
										onClickHighlight={() => onClickHighlight(item)}
										onClickCopyText={() => onClickCopyText(idx)}
										onClickPasteNote={() => onClickPasteNote(item)}
										onClose={close}
										isHighlight={isHighlight(item)}
									/>
								)}
							</Popup>
							<div className='time' onClick={() => onClickStartTime(item.time)}>
								{item.time}
							</div>
							<div className='title'>{item.topic}</div>
							<div className='summary'>
								{item['summary'].map((summary, idx) => (
									<li key={idx}>{summary}</li>
								))}
							</div>
						</div>
					))
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default ChronologicalSummary;
