import { Button } from '@mui/material';

const Keyword = ({ label, onClick }) => {
	return (
		<Button
			onClick={onClick}
			sx={{
				alignSelf: 'flex-start',
				display: 'flex',
				padding: '8px',
				justifyContent: 'center',
				gap: '8px',
				borderRadius: '48px',
				border: '1px solid #ECEDEF',
				background: '#F3F5F7',
			}}
		>
			{label}
		</Button>
	);
};

export default Keyword;
