const TemplateDatas = [
	{
		id: 1,
		name: `1AI사업부 주간보고 템플릿`,
		content: `구간 요약
00:00:00~00:00:00 : 프로젝트 진행 및 이슈 사항
요약
ㆍ
ㆍ
ㆍ
☆★☆★☆

참석자
참석자 1, 참석자 2, 아이유, 홍길동
직급을 제외합니다.
홍길동 님은 외부 참석 인사입니다.

키워드
키워드 1, 키워드 2, 키워드 3, 키워드 4
`,
	},
	{
		id: 2,
		name: `AI사업부 주간보고 템플릿`,
		content: `구간 요약
00:00:00~00:00:00 : 프로젝트 진행 및 이슈 사항
요약
ㆍ
ㆍ
ㆍ
☆★☆★☆

참석자
참석자 1, 참석자 2, 아이유, 홍길동
직급을 제외합니다.
홍길동 님은 외부 참석 인사입니다.

키워드
키워드 1, 키워드 2, 키워드 3, 키워드 4`,
	},
	{
		id: 3,
		name: `AI사업부 주간보고 템플릿`,
		content: `## ■ 구간요약
{{time}} : 프로젝트 진행 및 이슈 사항
{{topic}}

### 요약
{{summary}}
☆★☆★☆

## 참석자
{{speakerInfo}}
직급을 제외합니다
홍길동 님은 외부 참석 인사입니다.

## 키워드
{{keywords}}`,
	},
	{
		id: 4,
		name: `AI사업부 주간보고 템플릿`,
		content: ``,
	},
	{
		id: 5,
		name: `AI사업부 주간보고 템플릿`,
		content: ``,
	},
	{
		id: 6,
		name: `AI사업부 주간보고 템플릿`,
		content: ``,
	},
	{
		id: 7,
		name: `AI사업부 주간보고 템플릿`,
		content: ``,
	},
	{
		id: 8,
		name: `AI사업부 주간보고 템플릿`,
		content: ``,
	},
	{
		id: 9,
		name: `AI사업부 주간보고 템플릿`,
		content: ``,
	},
	{
		id: 10,
		name: `AI사업부 주간보고 템플릿`,
		content: ``,
	},
];

export default TemplateDatas;
