// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-container {
	width: 100%;
	height: 100%;
	/* overflow: auto; */
}

.list-empty {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.list-container.dragging {
	background-color: #f0f0f0;
	border-color: #aaa;
}
`, "",{"version":3,"sources":["webpack://./src/components/list/List.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;CACZ,oBAAoB;AACrB;;AAEA;CACC,YAAY;CACZ,aAAa;CACb,mBAAmB;CACnB,uBAAuB;AACxB;;AAEA;CACC,yBAAyB;CACzB,kBAAkB;AACnB","sourcesContent":[".list-container {\n\twidth: 100%;\n\theight: 100%;\n\t/* overflow: auto; */\n}\n\n.list-empty {\n\theight: 100%;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.list-container.dragging {\n\tbackground-color: #f0f0f0;\n\tborder-color: #aaa;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
