import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom'; // useParams 가져오기

import useContentsStore from '../../store/ContentsStore';
import { useCookies } from 'react-cookie';
import HeaderV2 from '../../components/body/headerV2/HeaderV2';
import { Box, SvgIcon, Tab, Tabs, Typography } from '@mui/material';
import ContentGrid from '../../components/content/common/ContentGrid';

import { ReactComponent as SearchingImg } from './img/searching.svg';
import { ReactComponent as SearchNoDataImg } from './img/search_no_data.svg';
import useFilterStore from '../../store/FilterStore';

import { ThreeDots } from 'react-loader-spinner';

const SearchV2 = () => {
	const location = useLocation();

	const navigate = useNavigate();
	//const { keyword } = useParams(); // URL에서 keyword 가져오기
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { searchContents, search, isSearching, refreshHomeContents } = useContentsStore();
	const { orderType, contentType, shareType, getFilteredData } = useFilterStore();
	const [selectedTab, setSelectedTab] = useState(0);
	const query = new URLSearchParams(useLocation().search);
	const keyword = query.get('keyword');
	const start = query.get('start');
	const end = query.get('end');

	useEffect(() => {
		onSearch();
	}, [keyword, selectedTab]);

	useEffect(() => {
		console.log('쿼리', query);
	}, [query]);

	useEffect(() => {
		console.log('결과', search.list);
	}, [search]);

	const onTabChanged = (event, newValue) => {
		setSelectedTab(newValue);
	};

	const onSearch = async () => {
		const getFilter = () => {
			switch (selectedTab) {
				case 0:
					return 'all';
				case 1:
					return 'title';
				case 2:
					return 'detail';
				case 3:
					return 'hashTag';
				case 4:
					return 'creator';
				default:
					return 'all';
			}
		};
		const httpCode = await searchContents(
			cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
			keyword,
			getFilter(),
			start,
			end,
			1
		);

		if (httpCode === 200) {
			setTimeout(() => refreshHomeContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken), 1000);
		}
	};

	return (
		<>
			<HeaderV2
				text={
					<>
						<Typography variant='h6' component='div'>
							<Typography
								variant='h6'
								component='span'
								sx={{ color: '#1C6EFF', fontWeight: 700, fontSize: 24, fontFamily: 'Noto Sans KR' }}
							>
								{`'${keyword}'`}
							</Typography>{' '}
							<Typography
								variant='h6'
								component='span'
								sx={{ color: '#292A2B', weight: 'bold', fontSize: 24, fontFamily: 'Noto Sans KR' }}
							>
								검색 결과
							</Typography>
						</Typography>
					</>
				}
				enableSearch
				enableFilter
				enableOrder
				enableRange
				searchKeyword={keyword}
			/>
			<Box className='search-container'>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs
						value={selectedTab}
						onChange={onTabChanged}
						sx={{
							flex: 1,
							padding: '0 32px',

							'& .MuiTab-root': {
								fontSize: 20,
								fontWeight: 600,
								fontFamily: 'Noto Sans KR',
								padding: '16px 24px',
							},

							'& .MuiTabs-indicator': {
								height: 4,
							},
						}}
					>
						<Tab label='전체' />
						<Tab label='회의록명' />
						<Tab label='음성 기록' />
						<Tab label='키워드' />
						<Tab label='소유자' />
					</Tabs>
				</Box>

				{isSearching ? (
					<Box
						sx={{
							width: '100%',
							height: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
						}}
					>
						<SvgIcon inheritViewBox sx={{ width: 70, height: 71 }}>
							<SearchingImg />
						</SvgIcon>
						<Typography
							sx={{
								fontSize: 24,
								fontWeight: 700,
								color: '#28323C',
								fontFamily: 'Noto Sans KR',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								position: 'relative',
								gap: '10px',
								marginTop: '15px',
								marginRight: '30px',

								'& svg': {
									position: 'absolute',
									top: 5,
								},
							}}
						>
							검색 중 <ThreeDots color='#1C6EFF' width={30} height={'100%'} />
						</Typography>
					</Box>
				) : (
					<>
						{search.list && search.list.length > 0 ? (
							<ContentGrid
								data={search.list}
								highlightText={keyword}
								columnVisibilityModel={{
									share: true,
									restore: false,
									delete: true,
									truncate: false,
								}}
							/>
						) : (
							<SearchNoData />
						)}
					</>
				)}
			</Box>
		</>
	);
};

export default SearchV2;

const SearchNoData = () => {
	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
			}}
		>
			<SvgIcon inheritViewBox sx={{ width: 85, height: 79 }}>
				<SearchNoDataImg />
			</SvgIcon>
			<Typography
				sx={{ fontSize: 24, fontWeight: 700, color: '#28323C', fontFamily: 'Noto Sans KR', paddingTop: '24px' }}
			>
				검색 결과가 없습니다.
			</Typography>
			<Typography
				sx={{
					width: '100%',
					fontSize: 16,
					fontWeight: 500,
					color: '#858C99',
					fontFamily: 'Noto Sans KR',
					whiteSpace: 'pre-line',
					textAlign: 'center',
					paddingTop: '8px',
				}}
			>
				원하시는 검색어를 찾을 수 없습니다.
				<br />
				다른 검색어를 입력해보세요!
			</Typography>
		</Box>
	);
};
