import axios from 'axios';
import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';
import FileSaver from 'file-saver';
import { ToastError, ToastInfo } from '../components/common/toast/Toast';
import moment from 'moment';

const COUNT_PER_PAGE = 10;

const useContentsStore = create((set, get) => ({
	selectedContent: null,
	selectedContentHighlight: [],
	applyedFilters: {},
	contents: {
		list: [],
		calendar: [],
	},
	recycles: {
		list: [],
	},
	uploads: {
		list: [],
	},
	isSearching: false,
	search: {
		currentPage: 1,
		list: [],
	},
	searchHistory: {
		keywords: [],
		contents: [],
	},
	home: {
		contents: [],
		Highlight: [],
		keywords: [],
	},
	bookmarks: {
		list: [],
	},
	sttStatus: {
		list: [],
	},

	getContents: (accessToken, contentId, source = 'none', tab = null) => {
		return new Promise(async (resolve, reject) => {
			console.log('[useContentsStore] getContents', contentId, source);
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};
				const res = await axios.get(
					`${process.env.REACT_APP_API_URL}/contents/${contentId}?source=${source}${
						tab ? `&tab=${tab}` : ''
					}`,
					{ headers }
				);
				console.log('[useContentsStore]', res.data);
				switch (res.data.httpCode) {
					case 200:
						resolve({ code: 200, message: 'ok', data: res.data.data });
						break;
					case 403:
						//음성인식 실패
						resolve({ code: 403, message: res.data.message });
						break;
					case 404:
						//권한 없음
						resolve({ code: 404, message: res.data.message });
						break;
					case 422:
						// STT 진행 중
						resolve({ code: 422, message: res.data.message });
						break;

					case 423:
						//조회할 수 없는 컨텐츠
						resolve({ code: 423, message: res.data.message });
						break;
					default:
						reject({ code: res.data.httpCode, message: res.data.message });
						break;
				}
			} catch (e) {
				console.error(e);
				reject({ code: 400, message: 'Something Happend' });
			}
		});
	},

	downloadFile: (accessToken, { contentId, title }) => {
		const url = `${process.env.REACT_APP_API_URL}/contents/download/${contentId}?accessToken=${accessToken}`;
		FileSaver.saveAs(url, title);
	},
	getShareUsers: (accessToken, contentId) => {
		return new Promise(async (resolve, reject) => {
			console.log('[useContentsStore] getShareUsers', contentId);
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};
				const res = await axios.get(`${process.env.REACT_APP_API_URL}/contents/share/${contentId}`, {
					headers,
				});

				switch (res.data.httpCode) {
					case 200:
						resolve({ code: 200, message: 'ok', data: res.data.data });
						break;
					case 401:
						//권한 없음
						resolve({ code: 401, message: res.data.message });
						break;
					default:
						reject();
						break;
				}
			} catch (e) {
				console.error(e);
				reject();
			}
		});
	},
	addBookmark: (accessToken, contentId, key, data, id = null) => {
		return new Promise(async (resolve, reject) => {
			const headers = {
				Authorization: `Bearer ${accessToken}`,
			};

			axios
				.post(
					`${process.env.REACT_APP_API_URL}/bookmark/${contentId}?item=${key}${
						key === 'mergedSegments' || key === 'summaryTime' ? `&itemId=${id}` : ''
					}`,
					null,
					{
						headers,
					}
				)
				.then(async response => {
					console.log('[useContentsStore] addBookmark response', response.data);

					switch (response.data.httpCode) {
						case 200:
							resolve({ code: 200, message: 'ok' });
							break;
						case 401:
							resolve({ code: 401, message: response.data.message });
							break;
						default:
							reject();
							break;
					}
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	deleteBookmark: (accessToken, contentId, key, id = null) => {
		return new Promise(async (resolve, reject) => {
			const headers = {
				Authorization: `Bearer ${accessToken}`,
			};

			axios
				.delete(
					`${process.env.REACT_APP_API_URL}/bookmark/${contentId}?item=${key}${
						key === 'mergedSegments' || key === 'summaryTime' ? `&itemId=${id}` : ''
					}`,
					{
						headers,
					}
				)
				.then(async response => {
					console.log('[useContentsStore] deleteBookmark response', response.data);

					switch (response.data.httpCode) {
						case 204:
							resolve({ code: 200, message: 'ok' });
							break;
						case 401:
							resolve({ code: 401, message: response.data.message });
							break;
						default:
							reject();
							break;
					}
				})
				.catch(error => {
					reject(error);
				});
		});
	},

	addShareUser: (accessToken, contentId, email) => {
		return new Promise(async (resolve, reject) => {
			const headers = {
				Authorization: `Bearer ${accessToken}`,
			};

			axios
				.post(
					`${process.env.REACT_APP_API_URL}/contents/share`,
					{
						contentId: contentId,
						email: email,
					},
					{
						headers,
					}
				)
				.then(async response => {
					console.log('[useContentsStore] addShareUser response', response.data);

					switch (response.data.httpCode) {
						case 200:
							resolve({ code: 200, message: 'ok', data: response.data.data });
							break;
						case 400:
							resolve({ code: 400, message: response.data.message });
							break;
						case 500:
							resolve({ code: 500, message: response.data.message });
							break;
						default:
							resolve(response.data);
							break;
					}
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	updateShareUser: (accessToken, { contentId, email, role }) => {
		return new Promise(async (resolve, reject) => {
			const headers = {
				Authorization: `Bearer ${accessToken}`,
			};

			axios
				.put(
					`${process.env.REACT_APP_API_URL}/contents/share`,
					{
						contentId: contentId,
						email: email,
						permission: role,
					},
					{
						headers,
					}
				)
				.then(async response => {
					console.log('[useContentsStore] editShareUser response', response.data);

					switch (response.data.httpCode) {
						case 200:
							resolve({ code: 200, message: 'ok', data: response.data.data });
							break;
						case 400:
							resolve({ code: 400, message: response.data.message });
							break;
						case 401:
							resolve({ code: 401, message: response.data.message });
							break;
						default:
							reject();
							break;
					}
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	deleteShareUser: (accessToken, { contentId, email }) => {
		return new Promise(async (resolve, reject) => {
			const headers = {
				Authorization: `Bearer ${accessToken}`,
			};

			axios
				.delete(`${process.env.REACT_APP_API_URL}/contents/share/${contentId}/${email}`, {
					headers: headers,
				})
				.then(async response => {
					console.log('[useContentsStore] deleteShareUser response', response.data);
					switch (response.data.httpCode) {
						case 204:
							resolve({ code: 204, message: 'ok' });
							break;
						case 400:
							resolve({ code: 400, message: response.data.message });
							break;
						case 401:
							resolve({ code: 401, message: response.data.message });
							break;
						default:
							reject();
							break;
					}
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	refreshContents: (accessToken, params = {}) => {
		return new Promise(async (resolve, reject) => {
			console.log('[useContentsStore] refreshContents');
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};

				const res = await axios.get(`${process.env.REACT_APP_API_URL}/contents`, { headers, params });

				switch (res.data.httpCode) {
					case 200:
						const sortedContents = res.data.data.sort((a, b) => moment(b.createAt) - moment(a.createAt));
						set(state => ({ contents: { ...state.contents, list: sortedContents } }));
						resolve();
						break;
					default:
						set(state => ({ contents: { ...state.contents } }));
						reject();
						break;
				}
			} catch (e) {
				console.error(e);
				reject();
			}
		});
	},
	refreshContentFromContentId: (accessToken, contentId) => {
		return new Promise(async (resolve, reject) => {
			console.log('[useContentsStore] refreshContents');
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};

				const res = await axios.get(`${process.env.REACT_APP_API_URL}/contents`, {
					headers,
					params: { contentId },
				});

				switch (res.data.httpCode) {
					case 200: {
						set(state => ({
							contents: {
								...state.contents,
								list: state.contents.list.map(content =>
									content.contentId === res.data.data[0].contentId ? res.data.data[0] : content
								),
							},
							home: {
								...state.home,
								contents: state.home.contents.map(content =>
									content.contentId === res.data.data[0].contentId ? res.data.data[0] : content
								),
							},
						}));
						resolve(res.data.data[0]);
						break;
					}

					default:
						reject();
						break;
				}
			} catch (e) {
				console.error(e);
				reject();
			}
		});
	},

	refreshHomeContents: accessToken => {
		return new Promise(async (resolve, reject) => {
			console.log('[useContentsStore] refreshHomeContents');
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};
				const res = await axios.get(`${process.env.REACT_APP_API_URL}/home?limit=${COUNT_PER_PAGE}`, {
					headers,
				});
				switch (res.data.httpCode) {
					case 200:
						set(state => ({ home: { ...res.data.data } }));
						resolve();
						break;
					default:
						reject();
						break;
				}
			} catch (e) {
				console.error(e);
				reject();
			}
		});
	},
	refreshBookmarks: accessToken => {
		return new Promise(async (resolve, reject) => {
			console.log('[useContentsStore] refreshBookmarks');
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};
				const res = await axios.get(`${process.env.REACT_APP_API_URL}/bookmark`, {
					headers,
				});
				switch (res.data.httpCode) {
					case 200:
						set(state => ({ bookmarks: { list: [...res.data.data] } }));
						resolve();
						break;
					default:
						reject();
						break;
				}
			} catch (e) {
				console.error(e);
				reject();
			}
		});
	},
	searchContents: (
		accessToken,
		keyword,
		filter = 'all',
		startDate,
		endDate = null,
		page = 1,
		take = COUNT_PER_PAGE
	) => {
		return new Promise(async (resolve, reject) => {
			set({ isSearching: true });

			console.log('[useContentsStore] searchContents', keyword, filter, page, take, startDate, endDate);
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};

				if (1 === page) {
					//결과를 지운다
					set(state => ({ search: { list: [], currentPage: page, totalNumber: 0 } }));
				}

				const res = await axios.get(
					`${
						process.env.REACT_APP_API_URL
					}/search?keyword=${keyword}&filter=${filter}&page=${page}&take=${take}&startDate=${startDate}${
						endDate && `&endDate=${endDate}`
					} `,
					{ headers }
				);
				set({ isSearching: false });
				switch (res.data.httpCode) {
					case 200:
						set(state => ({
							search: {
								list: [...state.search.list, ...res.data.data.contents],
								currentPage: res.data.data.currentPage,
								totalNumber: res.data.data.totalNumber,
							},
						}));
						resolve(res.data.httpCode);
						break;
					default:
						reject();
						break;
				}
			} catch (e) {
				set({ isSearching: false });
				console.error(e);
				reject();
			}
		});
	},
	refreshRecycles: accessToken => {
		return new Promise(async (resolve, reject) => {
			console.log('[useContentsStore] refreshRecycles', accessToken);
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};
				const res = await axios.get(`${process.env.REACT_APP_API_URL}/contents/recycle`, { headers });
				switch (res.data.httpCode) {
					case 200:
						set(state => ({ recycles: { list: res.data.data } }));
						resolve();
						break;
					default:
						reject();
						break;
				}
			} catch (e) {
				console.error(e);
				reject();
			}
		});
	},
	refreshSearchHistory: accessToken => {
		return new Promise(async (resolve, reject) => {
			console.log('[useContentsStore] refreshSearchHistory');
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};
				const res = await axios.get(`${process.env.REACT_APP_API_URL}/search/history/keyword`, { headers });
				switch (res.data.httpCode) {
					case 200:
						const res2 = await axios.get(`${process.env.REACT_APP_API_URL}/search/history/content`, {
							headers,
						});
						switch (res.data.httpCode) {
							case 200:
								set(state => ({
									searchHistory: { keywords: res.data.data, contents: res2.data.data },
								}));
								resolve();
								break;
							default:
								reject();
								break;
						}

						break;
					default:
						reject();
						break;
				}
			} catch (e) {
				console.error(e);
				reject();
			}
		});
	},
	deleteContents: (accessToken, { contentId }) => {
		return new Promise(async (resolve, reject) => {
			console.log('[useContentsStore] deleteContents', contentId);
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};
				const res = await axios.delete(`${process.env.REACT_APP_API_URL}/contents/recycle/${contentId}`, {
					headers,
				});
				console.log('[useContentsStore] deleteContents response', res.data);
				switch (res.data.httpCode) {
					case 200:
						set(state => ({
							contents: {
								list: state.contents.list.filter(item => item.contentId !== contentId),
								calendar: state.contents.calendar.filter(item => item.contentId !== contentId),
							},
						}));
						resolve();
						break;
					default:
						reject(res.data.httpCode);
						break;
				}
			} catch (e) {
				console.error(e);
				reject();
			}
		});
	},
	restoreContents: (accessToken, contentId) => {
		return new Promise(async (resolve, reject) => {
			console.log('[useContentsStore] restoreContents', contentId);
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};
				const res = await axios.delete(`${process.env.REACT_APP_API_URL}/contents/restore/${contentId}`, {
					headers,
				});
				console.log('[useContentsStore] restoreContents response', res.data);
				switch (res.data.httpCode) {
					case 200:
						const { contents } = get();
						const sortedContents = [...contents.list, res.data.data].sort(
							(a, b) => moment(b.createAt) - moment(a.createAt)
						);

						set(state => ({
							contents: {
								...state.contents,
								list: sortedContents,
							},
							recycles: {
								list: state.recycles.list.filter(item => item.contentId !== contentId),
							},
						}));

						resolve();
						break;
					default:
						reject();
						break;
				}
			} catch (e) {
				console.error(e);
				reject();
			}
		});
	},
	truncateContents: (accessToken, contentId) => {
		return new Promise(async (resolve, reject) => {
			console.log('[useContentsStore] truncateContents', contentId);
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};
				const res = await axios.delete(`${process.env.REACT_APP_API_URL}/contents/truncate/${contentId}`, {
					headers,
				});
				console.log('[useContentsStore] truncateContents response', res.data);
				switch (res.data.httpCode) {
					case 200:
						set(state => ({
							recycles: {
								list: state.recycles.list.filter(item => item.contentId !== contentId),
							},
						}));
						resolve();
						break;
					default:
						reject();
						break;
				}
			} catch (e) {
				console.error(e);
				reject();
			}
		});
	},
	onContentsChanged: ({ contentId, status, isNew = true }) => {
		set(state => {
			const existingIndex = state.sttStatus.list.findIndex(item => item.contentId === contentId);

			let updatedList;
			if (existingIndex !== -1) {
				// contentId가 이미 존재하는 경우 교체
				updatedList = [...state.sttStatus.list];
				updatedList[existingIndex] = { contentId, status, isNew };
			} else {
				// contentId가 없는 경우 새로 추가
				updatedList = [...state.sttStatus.list, { contentId, status, isNew }];
			}
			return {
				sttStatus: {
					list: updatedList,
				},
			};
		});
	},
	fileUpload: (accessToken, file, name, option, type, auth, onOk, onError, onFinal, onProgress) => {
		console.log('[useContentsStore] fileUpload', file);
		if (auth) {
			const formData = new FormData();
			formData.append('file', file, name);
			formData.append('type', type ? type : file.type);

			formData.append('lang', option.langValue);
			formData.append('attendeeNum', option.attendeesValue);
			formData.append('summary', option.summaryValue);

			const tempId = uuidv4();
			set(state => ({
				uploads: {
					list: [
						...state.uploads.list,
						{
							contentId: tempId,
							title: file.name,
							type: file.mimeType,
							creatorNickName: auth.user.profile.nickName,
							creatorThumbnailUrl: auth.user.profile.thumbnailUrl,
							isNew: true,
							isDisabled: true,
						},
					],
				},
			}));

			const headers = {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${accessToken}`,
			};

			axios
				.post(`${process.env.REACT_APP_API_URL}/contents/upload`, formData, {
					headers,
					onUploadProgress: progressEvent => {
						const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
						onProgress && onProgress(tempId, percentCompleted);
						if (100 === percentCompleted) {
							console.log('[useContentsStore] upload complete');
						}
					},
				})
				.then(async response => {
					console.log('[useContentsStore] upload response', response.data);
					switch (response.data.httpCode) {
						case 200:
							onOk && onOk(response.data.data.content);

							//여기서 list에 append하는 코드
							set(state => ({
								uploads: {
									list: state.uploads.list.filter(item => item.contentId !== tempId),
								},
								contents: {
									...state.contents,
									list: [
										{ ...response.data.data.content, isNew: true, updateAt: new Date() },
										...state.contents.list.filter(
											item => item.contentId !== response.data.data.content.contentId
										),
									],
								},
								home: {
									...state.home,
									contents: [
										{ ...response.data.data.content, isNew: true, updateAt: new Date() },
										...state.home.contents.filter(
											item => item.contentId !== response.data.data.content.contentId
										),
									],
								},
							}));

							break;

						default:
							onError && onError(response.data);
							break;
					}
					onFinal && onFinal();
				})
				.catch(error => {
					console.error(error);
					onError && onError(400);
					onFinal && onFinal();
				});
		} else {
			onError && onError(403);
		}
	},
	setSelectedContent: content => {
		set({ selectedContent: content });
	},

	setApplyedFilters: filters => {
		set({ applyedFilters: filters });
	},

	deleteSearchHistoryKeyword: async (accessToken, keywordId) => {
		try {
			const headers = {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${accessToken}`,
			};

			const response = await axios.delete(
				`${process.env.REACT_APP_API_URL}/search/history/keyword/${keywordId}`,
				{
					headers,
				}
			);

			if (response && response.data && response.data.httpCode === 204) {
				set(prev => ({
					home: {
						...prev.home,
						keywords: prev.home.keywords.filter(keyword => keyword.keywordId !== keywordId),
					},
				}));
			}
		} catch (e) {
			ToastError(1000);
		}
	},

	deleteSearchHistoryAllKeyword: async accessToken => {
		try {
			const headers = {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${accessToken}`,
			};

			const response = await axios.delete(`${process.env.REACT_APP_API_URL}/search/history/keyword`, {
				headers,
			});

			if (response && response.data && response.data.httpCode === 204) {
				set(prev => ({
					home: {
						...prev.home,
						keywords: [],
					},
				}));
			}
		} catch (e) {
			ToastError(1000);
		}
	},

	deleteSearchHistoryContent: async (accessToken, contentId) => {
		try {
			const headers = {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${accessToken}`,
			};

			const response = await axios.delete(
				`${process.env.REACT_APP_API_URL}/search/history/content/${contentId}`,
				{
					headers,
				}
			);

			if (response && response.data && response.data.httpCode === 204) {
				set(prev => ({
					searchHistory: {
						...prev.searchHistory,
						contents: prev.searchHistory.contents.filter(content => content.contentId !== contentId),
					},
				}));
			}
		} catch (e) {
			ToastError(1000);
		}
	},

	onSortContents: type => {
		set(state => {
			const updatedContents = [...state.contents.list];

			switch (type) {
				case 'NEWEST_CREATE':
					updatedContents.sort((a, b) => new Date(b.createAt) - new Date(a.createAt));
					break;

				case 'OLDEST_CREATE':
					updatedContents.sort((a, b) => new Date(a.createAt) - new Date(b.createAt));
					break;

				case 'NEWEST_UPDATE':
					updatedContents.sort((a, b) => new Date(b.updateAt) - new Date(a.updateAt));
					break;

				case 'TITLE_ASC':
					updatedContents.sort((a, b) => a.title.localeCompare(b.title));
					break;

				case 'TITLE_DESC':
					updatedContents.sort((a, b) => b.title.localeCompare(a.title));
					break;

				default:
					break;
			}

			return { contents: { ...state.contents, list: updatedContents } };
		});
	},

	getCalendarContents: async (accessToken, startDate, endDate) => {
		try {
			const headers = {
				Authorization: `Bearer ${accessToken}`,
			};

			const response = await axios.get(`${process.env.REACT_APP_API_URL}/contents/calendar`, {
				headers,
				params: {
					startDate,
					endDate,
				},
			});

			if (response && response.data && response.data.httpCode === 200) {
				const data = [...response.data.data];

				const parsingCalendarFormat = data.map(item => ({
					...item,
					id: item.contentId,
					start: moment(item.createAt).format('YYYY-MM-DD'),
					end: moment(item.createAt).add(1, 'days').format('YYYY-MM-DD'),
					title: item.editedTitle ?? item.title,
				}));

				set(prev => ({
					contents: {
						...prev.contents,
						calendar: parsingCalendarFormat,
					},
				}));
			}
		} catch (e) {
			ToastError(1000);
		}
	},
	getNoteData: async (accessToken, contentId) => {
		try {
			const headers = {
				Authorization: `Bearer ${accessToken}`,
			};

			const response = await axios.get(`${process.env.REACT_APP_API_URL}/contents/${contentId}/note`, {
				headers,
			});

			if (response && response.data && response.data.httpCode === 200) {
				return response.data.data;
			} else {
				ToastError(1000);

				return;
			}
		} catch (err) {
			ToastError(1000);
		}
	},

	updateNoteData: async (accessToken, contentId, text) => {
		try {
			const headers = {
				Authorization: `Bearer ${accessToken}`,
			};

			const response = await axios.patch(
				`${process.env.REACT_APP_API_URL}/contents/${contentId}/note`,
				{
					content: text,
				},
				{
					headers,
				}
			);

			if (response && response.data && response.data.httpCode !== 200) {
				ToastError(1000);
			}
		} catch (err) {
			ToastError(1000);
		}
	},

	onAppendDataToNote: async (accessToken, contentId, body) => {
		try {
			const headers = {
				Authorization: `Bearer ${accessToken}`,
			};

			const response = await axios.post(`${process.env.REACT_APP_API_URL}/contents/${contentId}/note`, body, {
				headers,
			});

			if (response && response.data && response.data.httpCode === 200) {
				ToastInfo('노트에 성공적으로 복사되었습니다.');
			} else {
				ToastError(1001, '요청 중 장애가 발생했습니다.');
			}
		} catch (err) {
			ToastError(1000, '요청 중 장애가 발생했습니다.');
		}
	},

	updateContentTitle: (accessToken, contentId, title) => {
		return new Promise(async (resolve, reject) => {
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};

				const response = await axios.patch(
					`${process.env.REACT_APP_API_URL}/contents/${contentId}/title`,
					{
						title,
					},
					{
						headers,
					}
				);

				if (response && response.data) {
					switch (response.data.httpCode) {
						case 200:
							ToastInfo('컨텐츠 이름이 변경되었습니다.');
							resolve();
							return;

						case 401:
							ToastError(response.data.httpCode, '권한이 없습니다.');
							reject();
							return;

						default:
							ToastError(response.data.httpCode, response.data.message);
							reject();
							return;
					}
				} else {
					reject();
				}
			} catch (err) {
				ToastError(1000, '요청 중 장애가 발생했습니다.');
				reject();
			}
		});
	},

	updateContentKeywords: async (accessToken, contentId, keywords, onSuccess, onError) => {
		try {
			const headers = {
				Authorization: `Bearer ${accessToken}`,
			};

			const response = await axios.patch(
				`${process.env.REACT_APP_API_URL}/contents/${contentId}/keywords`,
				{ keywords },
				{
					headers,
				}
			);

			if (response && response.data && response.data.httpCode === 200) {
				ToastInfo('키워드가 수정됐습니다.');

				onSuccess && onSuccess();
			} else {
				ToastError(response.data.httpCode, response.data.message);

				onError && onError();
			}
		} catch (err) {
			ToastError(1000, '요청 중 장애가 발생했습니다.');

			onError && onError();
		}
	},

	updateContentAttendee: async (accessToken, contentId, speakerId, name, onSuccess, onError) => {
		try {
			const headers = {
				Authorization: `Bearer ${accessToken}`,
			};

			const response = await axios.patch(
				`${process.env.REACT_APP_API_URL}/contents/${contentId}/attendees/${speakerId}`,
				{ displayName: name },
				{
					headers,
				}
			);

			if (response && response.data && response.data.httpCode === 200) {
				ToastInfo('참석자가 수정됐습니다.');

				onSuccess && onSuccess();
			} else {
				ToastError(response.data.httpCode, response.data.message);

				onError && onError();
			}
		} catch (err) {
			ToastError(1000, '요청 중 장애가 발생했습니다.');

			onError && onError();
		}
	},
}));

export default useContentsStore;
