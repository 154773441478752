import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './Header.css';

import { ReactComponent as EditIcon } from './img/edit.svg';
import { ToastError } from '../../common/toast/Toast';

const Header = ({
	leftContent,
	rightContent,
	text = 'Untitled',
	description = '',
	originText = '',
	onChangeTitle = () => {},
	isContent = false,
	permission,
}) => {
	const prevTextRef = useRef(text);
	const [editMode, setEditMode] = useState(false);
	const elementRef = useRef();

	useEffect(() => {
		if (prevTextRef.current) {
			prevTextRef.current = text;
		}
	}, [text]);

	useEffect(() => {
		if (editMode) {
			elementRef.current.focus();
		} else {
			if (elementRef.current.innerText !== prevTextRef.current) {
				if (elementRef.current.innerText.trim() === '') {
					ToastError(null, '입력된 텍스트가 없습니다.');
					elementRef.current.innerText = originText;
				} else {
					onChangeTitle(elementRef.current.innerText.replace('\n', ''));
				}

				prevTextRef.current = elementRef.current.innerText;
			}
		}

		elementRef.current.scrollLeft = 0;
	}, [editMode]);

	return (
		<div className='body-header'>
			<div className='left-content'>{leftContent && leftContent}</div>
			<div className='info'>
				<div className='top'>
					<div
						ref={elementRef}
						className='title'
						contentEditable={editMode}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								setEditMode(false);
							} else if (e.key === 'Escape') {
								elementRef.current.innerText = text;

								setEditMode(false);
							}
						}}
						spellCheck={false}
						suppressContentEditableWarning
					>
						{text}
					</div>
					{isContent && permission && permission !== 'VIEWER' && (
						<div
							className={`edit-title ${editMode ? 'is-edit' : ''}`}
							title='제목 수정'
							onClick={() => {
								setEditMode(prev => !prev);
							}}
						>
							{editMode ? '저장' : <EditIcon />}
						</div>
					)}
				</div>
				{description && <div className='bottom'>{description}</div>}
			</div>

			<div className='right-content'>{rightContent && rightContent}</div>
		</div>
	);
};

export default Header;
