// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body-tab-menu {
	padding: 8px 12px;
	border-radius: 20px;
	cursor: pointer;
	display: inline-flex;
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out, border 0.2s ease-in-out;
	font-size: 16px;
	font-weight: 600;
}

.body-tab-menu:hover {
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out, border 0.2s ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/body/tabMenu/TabMenu.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB,mBAAmB;CACnB,eAAe;CACf,oBAAoB;CACpB,wFAAwF;CACxF,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,wFAAwF;AACzF","sourcesContent":[".body-tab-menu {\n\tpadding: 8px 12px;\n\tborder-radius: 20px;\n\tcursor: pointer;\n\tdisplay: inline-flex;\n\ttransition: background 0.2s ease-in-out, color 0.2s ease-in-out, border 0.2s ease-in-out;\n\tfont-size: 16px;\n\tfont-weight: 600;\n}\n\n.body-tab-menu:hover {\n\ttransition: background 0.2s ease-in-out, color 0.2s ease-in-out, border 0.2s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
