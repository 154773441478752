import { useEffect, useState } from 'react';
import Content from '../components/content/Content';

const ContentsViewer = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const [contentId, setContentId] = useState(urlParams.get('contentId'));

	useEffect(() => {
		console.log('contentId', contentId);
	}, [contentId]);

	return <>{contentId ? <Content contentId={contentId} /> : <div>Not found</div>}</>;
};

export default ContentsViewer;
