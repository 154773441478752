import React, { useState } from 'react';
import styled from 'styled-components';

function InputField({
	value,
	setValue,
	setIsFocused,
	placeholder,
	type = 'text',
	isVisiblePw = false,
	pwRef,
	onEnter,
}) {
	const handleTextChange = e => {
		setValue(e.target.value);
	};

	const handleInputType = () => {
		if (type === 'password') {
			if (isVisiblePw) {
				return 'text';
			} else {
				return 'password';
			}
		} else {
			return 'text';
		}
	};

	return (
		<FieldWrapper>
			<Input
				ref={pwRef}
				type={handleInputType()}
				value={value}
				onChange={handleTextChange}
				placeholder={placeholder}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
				onKeyDown={e => {
					if (e.key === 'Enter') {
						console.log('enter');
						onEnter(e);
					} else if (e.key === 'Escape') {
						setValue('');
					}
				}}
			/>
		</FieldWrapper>
	);
}

const FieldWrapper = styled.div`
	align-self: stretch;
	display: flex;
	align-items: center;
	gap: 2px;
	color: var(--dark-text, #292a2b);
	white-space: nowrap;
	font: 500 16px/1 Noto Sans, sans-serif;
	flex: 1;
`;

const Input = styled.input`
	border: none;
	background: transparent;
	color: inherit;
	font: inherit;
	outline: none;
	width: 100%;
	margin: auto 0;
	font-size: 14px;
	font-weight: 500;
	color: #292a2b;
	@media (max-width: 991px) {
		white-space: initial;
	}
`;

export default InputField;
