// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-function-container {
	width: 200px;
	min-height: 190px;
	border-radius: 10px;
	border: 1px solid var(--popup-border-color, #c5c8ce);
	box-sizing: border-box;
	display: inline-flex;
	gap: 10px;
	flex-direction: column;
	padding: 20px;
	box-shadow: 0px 4px 4px 0px #00000040;
	color: var(--main-title-text-color, #28323c);
	background-color: #fff;
}

.popup-function-container > .title {
	font-size: var(--font-size-lg, 16px);
	font-weight: var(--font-weight-semibold, 600);
	flex: 0 1 auto;
}

.popup-function-container > .separator {
	border-top: 0.4px solid var(--popup-border-color, #c5c8ce);
}

.popup-function-container > .functions {
	display: flex;
	flex-direction: column;
	flex: 1 1;
	gap: 10px;
}

.popup-function-container > .functions > .function {
	display: flex;
	cursor: pointer;
	gap: 5px;
	height: 28px;
	align-items: center;
	font-size: var(--font-size-md, 14px);
	font-weight: var(--font-weight-regular, 400);
}

.popup-function-container > .functions > .function > .icon {
	width: 20px;
	display: flex;
	align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/popup/content/function/Function.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,iBAAiB;CACjB,mBAAmB;CACnB,oDAAoD;CACpD,sBAAsB;CACtB,oBAAoB;CACpB,SAAS;CACT,sBAAsB;CACtB,aAAa;CACb,qCAAqC;CACrC,4CAA4C;CAC5C,sBAAsB;AACvB;;AAEA;CACC,oCAAoC;CACpC,6CAA6C;CAC7C,cAAc;AACf;;AAEA;CACC,0DAA0D;AAC3D;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,SAAO;CACP,SAAS;AACV;;AAEA;CACC,aAAa;CACb,eAAe;CACf,QAAQ;CACR,YAAY;CACZ,mBAAmB;CACnB,oCAAoC;CACpC,4CAA4C;AAC7C;;AAEA;CACC,WAAW;CACX,aAAa;CACb,mBAAmB;AACpB","sourcesContent":[".popup-function-container {\n\twidth: 200px;\n\tmin-height: 190px;\n\tborder-radius: 10px;\n\tborder: 1px solid var(--popup-border-color, #c5c8ce);\n\tbox-sizing: border-box;\n\tdisplay: inline-flex;\n\tgap: 10px;\n\tflex-direction: column;\n\tpadding: 20px;\n\tbox-shadow: 0px 4px 4px 0px #00000040;\n\tcolor: var(--main-title-text-color, #28323c);\n\tbackground-color: #fff;\n}\n\n.popup-function-container > .title {\n\tfont-size: var(--font-size-lg, 16px);\n\tfont-weight: var(--font-weight-semibold, 600);\n\tflex: 0 1 auto;\n}\n\n.popup-function-container > .separator {\n\tborder-top: 0.4px solid var(--popup-border-color, #c5c8ce);\n}\n\n.popup-function-container > .functions {\n\tdisplay: flex;\n\tflex-direction: column;\n\tflex: 1;\n\tgap: 10px;\n}\n\n.popup-function-container > .functions > .function {\n\tdisplay: flex;\n\tcursor: pointer;\n\tgap: 5px;\n\theight: 28px;\n\talign-items: center;\n\tfont-size: var(--font-size-md, 14px);\n\tfont-weight: var(--font-weight-regular, 400);\n}\n\n.popup-function-container > .functions > .function > .icon {\n\twidth: 20px;\n\tdisplay: flex;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
