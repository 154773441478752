// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiDataGrid-row:hover {
	cursor: pointer;
}

.custom-header {
	padding-left: 30px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/content/common/ContentGrid.css"],"names":[],"mappings":"AAAA;CACC,eAAe;AAChB;;AAEA;CACC,6BAA6B;AAC9B","sourcesContent":[".MuiDataGrid-row:hover {\n\tcursor: pointer;\n}\n\n.custom-header {\n\tpadding-left: 30px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
