import { Box, Stack, Typography } from '@mui/material';
import Avatar from 'react-avatar';
import { ThreeDots } from 'react-loader-spinner';

function formatToMeridianTime(date) {
	// 시와 분 추출
	const hours = date.getHours();
	const minutes = date.getMinutes();

	// 오전/오후 계산
	const period = hours < 12 ? '오전' : '오후';

	// 12시간제로 변환 (0시는 12시로 표시)
	const hourIn12Format = hours % 12 || 12;

	// 분을 두 자리로 변환
	const formattedMinutes = minutes.toString().padStart(2, '0');

	return `${period} ${hourIn12Format}:${formattedMinutes}`;
}

const Balloon = ({ index, message }) => {
	const drawBalloon = type => {
		switch (type) {
			case 'me':
				return (
					<Stack
						key={`chat-${index}`}
						sx={{
							width: '100%',
							paddingBottom: '16px',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-end', // 오른쪽 정렬
							gap: '8px',
						}}
					>
						<Box
							sx={{
								background: '#1C6EFF',
								borderRadius: '24px 16px 0px 24px',
								display: 'flex',
								padding: '8px',
								justifyContent: 'center',
								alignItems: 'center', // 오타 수정
								gap: '8px',
								alignSelf: 'flex-end', // 오른쪽 정렬
							}}
						>
							<Typography sx={{ color: '#FFFFFF' }}>{message.text}</Typography>
						</Box>
					</Stack>
				);
			case 'bot':
			case 'botWorking':
				return (
					<Stack
						direction={'row'} // 한 줄로 배치
						key={`chat-${index}`}
						sx={{
							display: 'flex',
							width: '100%',
							paddingBottom: '16px',
							alignItems: 'flex-start', // 왼쪽 정렬
							justifyContent: 'flex-start',
							gap: '8px',
						}}
					>
						<Box
							sx={{
								width: '32px',
								height: '32px',
								border: '1px solid #CED3D6', // 테두리 색
								borderRadius: '50%', // 동그라미 모양
								backgroundColor: '#FFFFFF', // 하얀 배경
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Box
								component='img'
								src='/asset/images/aibizavatar.png' // 이미지 경로
								alt='Avatar'
								sx={{
									width: '18px', // 이미지 크기
									height: '15px',
									objectFit: 'contain', // 이미지 비율 유지
								}}
							/>
						</Box>
						<Box
							sx={{
								background: '#F3F5F7',
								borderRadius: '0px 24px 24px 16px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								alignSelf: 'flex-start', // 왼쪽 정렬
								padding: '6px 8px 14px 8px',
								position: 'relative', // 시간 위치를 위한 기준
							}}
						>
							{type === 'botWorking' ? (
								<Box
									component='img'
									src='/asset/images/threedot.gif' // 이미지 파일 경로
									sx={{
										width: '38px', // 이미지 너비 조정
										height: '23px', // 이미지 높이 조정
									}}
								/>
							) : (
								<Typography sx={{ color: '#000000' }}>{message.text}</Typography>
							)}
						</Box>
						<Typography
							sx={{
								flexShrink: 0,
								color: '#878D91',
								fontWeight: '400',
								fontSize: '12px',
								lineHeight: '18px',
								display: 'flex',
								alignSelf: 'flex-end', // 아래쪽 정렬
							}}
						>
							{message.time && formatToMeridianTime(message.time)}
						</Typography>
					</Stack>
				);
			default:
				return <></>;
		}
	};

	return drawBalloon(message.type);
};

export default Balloon;
