import React, { useCallback, useEffect, useRef, useState } from 'react';
import './SpeakerEdit.css';
import Avatar from 'react-avatar';
import Loading from 'react-loading';

import { ReactComponent as AddIcon } from './img/add.svg';
import { ReactComponent as CheckIcon } from './img/check.svg';

import { getAvatarColor } from '../../../../util/Util';

import AsyncCreatableSelect from 'react-select/async-creatable';

import { components } from 'react-select';
import useMemberStore from '../../../../store/MemberStore';
import { useCookies } from 'react-cookie';
import useAttendeeStore from '../../../../store/AttendeeStore';
import useContentsStore from '../../../../store/ContentsStore';
import useFunctionStore from '../../../../store/FunctionStore';

const CustomOption = props => {
	return (
		<components.Option {...props}>
			<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
				<Avatar src={props.data.thumbnailUrl} name={props.data.value} round size={20} />

				{props.data.label}
			</div>
		</components.Option>
	);
};

const OPTIONS = [
	{ label: '현재 영역만', value: 0 },
	{ label: '현재 영역부터', value: 1 },
	{ label: '전체', value: 2 },
];

const SpeakerEdit = ({ contentId, segment, speakerList, onChangeSegmentSpeaker, speakerId, onClose }) => {
	const timerRef = useRef();
	const inputRef = useRef();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { getMembersFromKeyword } = useMemberStore();

	const [inputValue, setInputValue] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [selectedSpeakerId, setSelectedSpeakerId] = useState(speakerId);
	const [selectedOption, setSelectedOption] = useState(OPTIONS[0].value);

	const { attendees, onAddAttendee } = useAttendeeStore();
	const { updateSegmentSpeaker } = useFunctionStore();

	const drawSpeakers = speaker => {
		return (
			<div className='attendee' onClick={() => setSelectedSpeakerId(speaker.speakerId)}>
				<div className='avatar'>
					<Avatar
						src={speaker.thumbnailUrl}
						name={speaker.displayName ?? speaker.name}
						round
						size={20}
						color={getAvatarColor(speaker.speakerId).background}
						fgColor={getAvatarColor(speaker.speakerId).color}
						maxInitials={1}
					/>
				</div>
				<div className='name'>{speaker.displayName ?? speaker.name}</div>
				<div className='delete'>{selectedSpeakerId === speaker.speakerId && <CheckIcon />}</div>
			</div>
		);
	};

	const debouncedPromiseOptions = (inputValue, callback) => {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}

		timerRef.current = setTimeout(async () => {
			if (inputValue.trim()) {
				if (
					cookies &&
					cookies[process.env.REACT_APP_COOKIE_ALIAS] &&
					cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken
				) {
					const members = await getMembersFromKeyword(
						cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
						inputValue
					);

					const updatedMembers = members.map(member => ({
						...member,
						label: member.nickName,
						value: member.nickName,
					}));

					callback(updatedMembers);
				} else {
					callback([]);
				}
			} else {
				callback([]);
			}
		}, 500);
	};

	const handleChangeOption = event => {
		setSelectedOption(event.target.value);
	};

	const handleCreateOption = name => {
		const data = {
			displayName: name,
			name,
		};

		onAddAttendee(
			cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
			contentId,
			data,
			attendee => {
				setSelectedSpeakerId(attendee.speakerId);
				onChangeSegmentSpeaker();
			},
			() => {
				inputRef.current.clearValue();
			}
		);
	};

	const handleConfirm = () => {
		setIsLoading(true);

		updateSegmentSpeaker(
			cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
			contentId,
			segment.startTime,
			selectedSpeakerId,
			selectedOption,
			segments => {
				onChangeSegmentSpeaker(segments);
				setIsLoading(false);
				onClose();
			}
		);
	};

	const handleSelectOption = option => {
		if (option) {
			const data = {
				pid: option.pid,
				name: option.nickName,
			};

			onAddAttendee(
				cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
				contentId,
				data,
				attendee => {
					setSelectedSpeakerId(attendee.speakerId);
					onChangeSegmentSpeaker();
					setInputValue('');
					inputRef.current.clearValue();
				},
				() => {
					inputRef.current.clearValue();
				}
			);
		}
	};

	return (
		<div className='popup-speaker-edit-container'>
			<div className='title'>참석자</div>
			<div className='separator' />
			{/* <div className='input'>
				<div className='avatar'>
					<AddIcon />
				</div>

				<AsyncCreatableSelect
					ref={inputRef}
					components={{
						DropdownIndicator: () => null,
						IndicatorSeparator: () => null,
						LoadingIndicator: () => null,
						Option: CustomOption,
					}}
					cacheOptions
					defaultOptions
					loadOptions={debouncedPromiseOptions}
					inputValue={inputValue}
					onInputChange={e => setInputValue(e)}
					placeholder='참석자 입력'
					noOptionsMessage={() => null}
					onCreateOption={handleCreateOption}
					onChange={handleSelectOption}
					formatCreateLabel={inputValue => `"${inputValue}" 추가`}
					styles={{
						container: (baseStyles, state) => ({
							...baseStyles,
							border: '1px solid transparent',
							width: '100%',
						}),
						control: (baseStyles, state) => ({
							...baseStyles,
							border: '1px solid transparent',
							width: '100%',
							boxShadow: 'none',
							'&:hover': {
								borderColor: 'none',
							},
							cursor: 'text',
							paddingLeft: 0,
						}),
						input: (baseStyles, state) => ({
							...baseStyles,
							margin: 0,
							maxWidth: '140px',
						}),

						valueContainer: baseStyles => ({
							...baseStyles,
							margin: 0,
							padding: 0,
							width: '80px !important',
						}),
						loadingIndicator: baseStyles => ({
							...baseStyles,
							display: 'none',
						}),
						placeholder: baseStyles => ({
							...baseStyles,
							width: '80px',
						}),
					}}
				/>
			</div> */}
			<div className='list'>
				{speakerList && speakerList.length ? (
					speakerList.map(speaker => drawSpeakers(speaker))
				) : (
					<div className='empty'>참석자 없음</div>
				)}
			</div>
			<div className='separator' />
			<div className='options'>
				{OPTIONS.map((option, idx) => (
					<label className='option' htmlFor={`option ${idx}`}>
						<input
							id={`option ${idx}`}
							type='radio'
							name='option'
							value={option.value}
							defaultChecked={option.value === selectedOption}
							onChange={handleChangeOption}
						/>
						{option.label}
					</label>
				))}
			</div>
			<button
				className='confirm'
				disabled={!isLoading && selectedSpeakerId === segment.speakerId ? true : false}
				onClick={handleConfirm}
			>
				{isLoading ? <Loading type='spin' color='red' width={15} height={15} /> : '수정'}
			</button>
		</div>
	);
};

export default SpeakerEdit;
