import React, { useEffect, useRef, useState } from 'react';
import './PrivateSetting.css';
import Select from 'react-select';

import { ReactComponent as SelectIcon } from './img/select.svg';
import useAuthStore from '../../../../../store/AuthStore';
import { ToastError, ToastInfo } from '../../../../common/toast/Toast';
import { useCookies } from 'react-cookie';

const LANGS = [
	{ label: '한국어', value: 'ko' },
	{ label: '영어', value: 'en' },
];

const PrivateSetting = ({ onClose }) => {
	const inputRef = useRef();
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { auth, updateNickName, updateSttLang, updateThumbnail, refreshAuth } = useAuthStore();

	const [nickname, setNickname] = useState(auth.user.profile.nickName);
	const [lang, setLang] = useState(LANGS[0]);
	const [file, setFile] = useState(null);
	const [previewUrl, setPreviewUrl] = useState(null);

	useEffect(() => {
		setPreviewUrl(auth.user.profile.thumbnailUrl);
	}, [auth.user.profile.thumbnailUrl]);

	useEffect(() => {
		setNickname(auth.user.profile.nickName);
	}, [auth.user.profile.nickName]);

	useEffect(() => {
		setLang(LANGS.find(lang => lang.value === auth.user.config.transcribeLang));
	}, [auth.user.config.transcribeLang]);

	const handleClickSelectIcon = () => {
		if (inputRef && inputRef.current) {
			inputRef.current.click();
		}
	};

	const handleFileChange = event => {
		const file = event.target.files[0];

		if (file) {
			setFile(file);

			const imageReader = new FileReader();
			imageReader.onloadend = () => {
				setPreviewUrl(imageReader.result);
			};

			imageReader.readAsDataURL(file);
		}
	};

	const privateUpdate = async ({ nickName, lang, file }) => {
		let isChanged = false;
		//닉네임 업데이트
		try {
			if (auth.user.profile.nickName !== nickName) {
				await updateNickName(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, nickName);
				isChanged = true;
			}

			if (auth.user.config.transcribeLang !== lang) {
				await updateSttLang(
					cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
					lang === 'none' ? 'ko' : lang
				);

				isChanged = true;
			}

			if (file) {
				//파일 교체
				await updateThumbnail(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, file);
				isChanged = true;
			}

			if (isChanged) {
				await refreshAuth(cookies[process.env.REACT_APP_COOKIE_ALIAS]);
				ToastInfo('저장되었습니다.');
			}

			onClose && onClose();
		} catch (code) {
			console.error(code);
			ToastError(code);
		}
	};

	return (
		<div className='private-setting-container'>
			{auth ? (
				<>
					<div className='title'>개인 환경 설정</div>
					<div className='separator' />
					<div className='select'>
						<div className='image' onClick={handleClickSelectIcon}>
							{previewUrl ? (
								<img
									src={previewUrl}
									style={{ width: '100%', height: '100%', borderRadius: '50%' }}
									alt=''
								/>
							) : (
								<SelectIcon />
							)}
						</div>
						<input
							ref={inputRef}
							style={{ display: 'none' }}
							type='file'
							onChange={handleFileChange}
							accept='image/*'
						/>
					</div>
					<div className='nickname'>
						<div className='title'>닉네임</div>
						<input type='text' value={nickname} onChange={e => setNickname(e.target.value)} />
					</div>
					{/* <div className='lang'>
						<div className='title'>음성인식 언어</div>
						<Select
							options={LANGS}
							value={lang}
							defaultValue={lang}
							onChange={obj => setLang(obj)}
							isSearchable={false}
							styles={{
								control: styles => ({
									...styles,
									height: 42,
								}),
							}}
						/>
					</div> */}
					<button
						className='submit'
						onClick={() => {
							privateUpdate({ nickName: nickname, lang: lang ? lang.value : 'ko', file });
						}}
					>
						저장
					</button>
				</>
			) : (
				<>Cannot find user</>
			)}
		</div>
	);
};

export default PrivateSetting;
