import React from 'react';
import { useAuthAction } from './Action';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

const CheckCookies = () => {
	const location = useLocation();
	const { isValidCookie } = useAuthAction();

	if (isValidCookie()) {
		return <Outlet />;
	} else {
		return <Navigate to={`/login`} state={location} />;
	}
};

export default CheckCookies;
