import React from 'react';
import styled from 'styled-components';

const ActionLink = ({ label, id, onClick }) => {
	return (
		<StyledLink id={id} onClick={onClick}>
			{label}
		</StyledLink>
	);
};

const StyledLink = styled.div`
	font-size: 14px;
	color: var(--Dark-grey400, #878d91);
	font-weight: 500;
	line-height: 1;
	text-decoration: none;
	cursor: pointer;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
`;

export default ActionLink;
