import React, { useEffect, useLayoutEffect, useState } from 'react';
import HeaderV2 from '../body/headerV2/HeaderV2';
import ContentGrid from '../content/common/ContentGrid';
import { useCookies } from 'react-cookie';
import useContentsStore from '../../store/ContentsStore';
import { ToastError } from '../common/toast/Toast';
import Calendar from './Calendar';
import { PageLoading } from '../page-status/PageLoading';
import { useNavigate } from 'react-router-dom';
import useDisplayStore from '../../store/DisplayStore';
import Empty from '../page-status/Empty';
import { Box, SvgIcon, Typography } from '@mui/material';

import { ReactComponent as NoDataImg } from './img/content_no_data.svg';

const ListV3 = ({ sidebarRef, calendarRef, onClickContent, isCalendar }) => {
	const navigate = useNavigate();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { contents, refreshContents, deleteContents, applyedFilters } = useContentsStore();

	const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const { displayType, onChangeType, orderType } = useDisplayStore();

	useLayoutEffect(() => {
		const params = {};

		if (Object.keys(applyedFilters).length) {
			Object.keys(applyedFilters).forEach(key => {
				params[key] =
					typeof applyedFilters[key] === 'object' ? applyedFilters[key].join(',') : applyedFilters[key];
			});
		}

		refreshContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, params)
			.catch(e => {
				console.error(e);
				ToastError(1003);
			})
			.finally(() => setIsLoading(false));
	}, [applyedFilters]);

	useLayoutEffect(() => {
		if (isCalendar) {
			onChangeType('calendar');
		} else {
			onChangeType('list');
		}
	}, [isCalendar]);

	return (
		<>
			<HeaderV2 text={displayType === 'list' ? '회의록' : '캘린더'} enableFilter enableOrder enableSearch />

			<div>
				{isLoading ? (
					<PageLoading />
				) : displayType === 'list' ? (
					contents.list.length ? (
						<ContentGrid
							data={contents.list}
							columnVisibilityModel={{ share: true, delete: true, truncate: false, restore: false }}
							enableDetail={true}
						/>
					) : (
						<Box
							sx={{
								width: '100%',
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '8px',
							}}
						>
							<SvgIcon inheritViewBox sx={{ width: 88, height: 88, marginBottom: '16px' }}>
								<NoDataImg />
							</SvgIcon>
							<Typography
								sx={{ fontSize: 24, fontWeight: 700, fontFamily: 'Noto Sans KR', color: '#28323C' }}
							>
								회의록이 없습니다.
							</Typography>
							<Typography
								sx={{ fontSize: 18, fontWeight: 400, fontFamily: 'Noto Sans KR', color: '#858C99' }}
							>
								등록된 회의록이 없습니다.
								<br />
								새로운 회의록을 작성하여 기록을 남겨보세요.
							</Typography>
						</Box>
					)
				) : (
					<Calendar
						ref={calendarRef}
						onClickContent={content => {
							onClickContent(content);
							navigate(`/content/${content.contentId}`);
						}}
					/>
				)}
			</div>
		</>
	);
};

export default ListV3;
