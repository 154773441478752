import React, { useEffect, useLayoutEffect, useState } from 'react';
import moment from 'moment';
import {
	Box,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Chip,
	Divider,
	IconButton,
	Stack,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import useInboxStore from '../../store/InboxStore';
import { useCookies } from 'react-cookie';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { formatMillisecondsToTime, formatToKoreanTime } from '../../util/Util';
import { drawContentTypeV2 } from '../common/ContentIcon/ContentIcon';
import useAuthStore from '../../store/AuthStore';
import TruncateButton from '../common/button/TruncateButton';
import ShareButton from '../common/button/ShareButton';
import DeleteButton from '../common/button/DeleteButton';
import useContentsStore from '../../store/ContentsStore';
import { ToastError, ToastInfo } from '../common/toast/Toast';

const ContentCard = ({ content, onClickCard = () => {}, refreshContent }) => {
	const { auth } = useAuthStore();
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { deleteContents } = useContentsStore();

	const onDelete = () => {
		//삭제
	};

	const onShare = () => {
		//공유
	};

	return (
		<Card
			className='content-card-item'
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '16px',
				marginBottom: '10px',
				border: '1px solid var(--Light-light300, #E1E4E6);',
				borderRadius: '8px', // 둥근 사각형
				padding: '16px',
				cursor: 'pointer',
				flexShrink: 0,
				'& .MuiCardContent-root': {
					padding: 0,
				},
			}}
		>
			<CardActions
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					height: '32px',
					padding: '0',
				}}
				onClick={() => onClickCard(content)}
			>
				<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
					<Typography sx={{ fontSize: '14px', fontWeight: '500', fontFamily: 'Noto Sans KR' }}>
						{formatToKoreanTime(content.createAt)}
					</Typography>
				</Box>
				<DeleteButton
					size={32}
					contentId={content.contentId}
					onConfirm={() => {
						deleteContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, {
							contentId: content.contentId,
						})
							.then(() => {
								setTimeout(() => {
									refreshContent();
								}, 500);

								ToastInfo('회의록이 삭제되었습니다.');
							})
							.catch(e => {
								console.error('DeleteContents Error : ', e);
								ToastError(null, '장애가 발생했습니다.');
							});
					}}
					title={'회의록을 삭제할까요?'}
					description='회의록이 휴지통으로 이동합니다.'
				/>
			</CardActions>
			<CardContent sx={{ marginTop: '0px' }} onClick={() => onClickCard(content)}>
				<Box sx={{ display: 'flex', alignItems: 'center', height: 24 }}>
					{drawContentTypeV2(auth, content, 24, false, true)}
					<Typography
						sx={{
							fontSize: '16px',
							fontWeight: '600',
							marginLeft: '8px',
							fontFamily: 'Noto Sans KR',
							height: 22,
							width: '100%',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
						}}
					>
						{content.editedTitle ?? content.title}
					</Typography>
				</Box>
				{content.hashTag && content.hashTag.length > 0 && (
					<Box
						sx={{
							display: 'inline-block',
							// gap: '8px', // Chip 간 간격
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							marginTop: '16px',
							width: '100%',
						}}
					>
						{content.hashTag.map((tag, index) => (
							<Chip
								key={index}
								label={`#${tag}`}
								sx={{
									background: '#F3F5F7',
									color: '#417EEA',
									flexShrink: 0, // Chip 크기가 줄어들지 않도록 설정
									fontFamily: 'Noto Sans KR',
									marginLeft: '8px',
								}}
							/>
						))}
					</Box>
				)}
			</CardContent>
			<CardActions
				sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 0 0 0' }}
			>
				<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} onClick={() => onClickCard(content)}>
					<Typography
						sx={{
							fontSize: '14px',
							fontWeight: '500',
							color: 'var(--Dark-grey400, #878D91)',
							fontFamily: 'Noto Sans KR',
						}}
					>
						길이
					</Typography>
					<Typography
						sx={{
							fontSize: '14px',
							fontWeight: '500',
							color: 'var(--Dark-grey700, #292A2B)',
							fontFamily: 'Noto Sans KR',
						}}
					>
						{formatMillisecondsToTime(content.duration)}
					</Typography>
					<Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
					<Typography
						sx={{
							fontSize: '14px',
							fontWeight: '500',
							color: 'var(--Dark-grey400, #878D91)',
							fontFamily: 'Noto Sans KR',
						}}
					>
						참석자
					</Typography>
					<Typography
						sx={{
							fontSize: '14px',
							fontWeight: '500',
							color: 'var(--Dark-grey700, #292A2B)',
							fontFamily: 'Noto Sans KR',
						}}
					>
						{content.speakerInfo?.length}명
					</Typography>
				</Box>
				<ShareButton size={32} content={content} />
			</CardActions>
		</Card>
	);
};

export default ContentCard;
