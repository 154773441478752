import React from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const LOADER_SIZE = 20;

const ConfirmButton = ({ label = ' ', isActive = false, onClick, isLoading = false }) => {
	return (
		<StyledButton variant='contained' disabled={!isActive} onClick={onClick} loadersize={LOADER_SIZE}>
			{isLoading ? <PropagateLoader color='#fff' loading={isLoading} size={LOADER_SIZE} /> : label}
		</StyledButton>
	);
};

const StyledButton = styled(Button)(({ theme, loadersize }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '50px',
	borderRadius: '5px',
	backgroundColor: '#1C6EFF',
	width: '100%',
	color: theme.palette.common.white,
	textAlign: 'center',
	padding: '10px 0',
	fontWeight: 700,
	fontSize: '16px',
	fontFamily: 'Noto Sans, sans-serif',
	border: 'none',
	cursor: 'pointer',
	marginTop: '24px',

	'&:disabled': {
		backgroundColor: '#E1E4E6',
		color: '#A9AFB3',
		cursor: 'not-allowed',
	},

	'& span': {
		'& span': {
			top: `-${loadersize / 2}px`,
			left: `-${loadersize / 2}px`,
		},
	},

	[theme.breakpoints.down('md')]: {
		whiteSpace: 'normal',
	},
}));

export default ConfirmButton;
