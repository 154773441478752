import React, { useEffect } from 'react';
import styled from 'styled-components';

import { ReactComponent as CheckedIcon } from './img/checked.svg';
import { ReactComponent as UnCheckedIcon } from './img/unchecked.svg';

const IdSaveCheckbox = ({ value, setValue }) => {
	return (
		<CheckboxWrapper onClick={() => setValue(prev => !prev)}>
			{value ? <CheckedIcon /> : <UnCheckedIcon />}

			<CheckboxLabel>아이디 저장</CheckboxLabel>
		</CheckboxWrapper>
	);
};

const CheckboxWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	gap: 2px;
	color: var(--Dark-grey400, #878d91);
	text-align: center;
	justify-content: start;
	font: 500 14px/1 Noto Sans, sans-serif;
	cursor: pointer;
	height: 25px;
	gap: 5px;
`;

const CheckboxLabel = styled.label`
	align-self: stretch;
	margin: auto 0;
	cursor: pointer;
	user-select: none;
`;

export default IdSaveCheckbox;
