import React, { useLayoutEffect, useState } from 'react';
import {
	Box,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
	Select,
	MenuItem,
	Button,
	Typography,
	Modal,
} from '@mui/material';
import useAuthStore from '../../../store/AuthStore';

const LABEL_WIDTH = 80;
const MAX_ATTENDEES_COUNT = 10;

const UploadOption = ({ fileType = 'file', isOpen, onClose, onSubmit }) => {
	const [summaryValue, setSummaryValue] = useState('large');
	const [attendeesValue, setAttendeesValue] = useState(99);
	const [langValue, setLangValue] = useState('ko');

	const { auth } = useAuthStore();

	useLayoutEffect(() => {
		if (auth && auth.user && auth.user.config) {
			setLangValue(
				!auth.user.config.transcribeLang || auth.user.config.transcribeLang === 'none'
					? 'ko'
					: auth.user.config.transcribeLang
			);
		}
	}, [auth]);

	const getTitle = () => {
		switch (fileType) {
			case 'record':
				return '녹음 파일을 저장합니다.';

			default:
			case 'file':
				return '업로드 파일을 저장합니다.';
		}
	};

	const handleOnSubmit = () => {
		onSubmit({ summaryValue, attendeesValue, langValue });
	};

	return (
		<Modal open={isOpen}>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					display: 'flex',
					flexDirection: 'column',
					gap: '24px',
					borderRadius: '16px',
					padding: '24px',
					boxSizing: 'border-box',
					width: 446,
					// height: 316,
					background: '#fff',
					fontFamily: 'Noto Sans KR',
				}}
			>
				<Typography
					sx={{
						height: 26,
						fontWeight: 600,
						fontSize: 20,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						color: '#0D062D',
						fontFamily: 'Noto Sans KR',
					}}
				>
					{getTitle()}
				</Typography>
				{/* <FormControl
					sx={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'row',
						height: 24,
					}}
				>
					<FormLabel
						sx={{
							width: LABEL_WIDTH,
							fontSize: 16,
							fontWeight: 500,
							color: '#28323C',
							fontFamily: 'Noto Sans KR',
						}}
					>
						요약
					</FormLabel>
					<RadioGroup value={summaryValue} onChange={e => setSummaryValue(e.target.value)} row>
						<FormControlLabel
							value='large'
							control={<Radio />}
							sx={{ fontFamily: 'Noto Sans KR' }}
							label='라지'
						/>
						<FormControlLabel
							value='medium'
							control={<Radio />}
							sx={{ fontFamily: 'Noto Sans KR' }}
							label='미디움'
						/>
						<FormControlLabel
							value='small'
							control={<Radio />}
							sx={{ fontFamily: 'Noto Sans KR' }}
							label='스몰'
						/>
					</RadioGroup>
				</FormControl> */}

				<FormControl
					sx={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'row',
						height: 40,
					}}
				>
					<FormLabel
						sx={{
							width: LABEL_WIDTH,
							fontSize: 16,
							fontWeight: 500,
							color: '#28323C',
							fontFamily: 'Noto Sans KR',
						}}
					>
						참석자 수
					</FormLabel>
					<Select
						value={attendeesValue}
						onChange={e => setAttendeesValue(e.target.value)}
						sx={{ height: 40, width: 82 }}
						MenuProps={{
							PaperProps: {
								sx: {
									maxHeight: '236px', // 메뉴 리스트의 최대 높이 설정
									overflow: 'auto', // 길어질 경우 스크롤 표시
								},
							},
						}}
					>
						<MenuItem sx={{ fontFamily: 'Noto Sans KR' }} value={99}>
							자동
						</MenuItem>
						{Array.from({ length: MAX_ATTENDEES_COUNT }).map((_, index) => (
							<MenuItem sx={{ fontFamily: 'Noto Sans KR' }} value={index + 1}>
								{index + 1}
							</MenuItem>
						))}
					</Select>
					<FormLabel
						sx={{
							paddingLeft: '10px',
							fontFamily: 'Noto Sans KR',
							fontSize: 18,
							fontWeight: 500,
							color: '#292A2B',
						}}
					>
						명
					</FormLabel>
				</FormControl>

				<FormControl
					sx={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'row',
						height: 24,
					}}
				>
					<FormLabel
						sx={{
							width: LABEL_WIDTH,
							fontSize: 16,
							fontWeight: 500,
							color: '#28323C',
							fontFamily: 'Noto Sans KR',
						}}
					>
						언어
					</FormLabel>
					<RadioGroup row value={langValue} onChange={e => setLangValue(e.target.value)}>
						<FormControlLabel
							sx={{ fontFamily: 'Noto Sans KR' }}
							value='ko'
							control={<Radio />}
							label='한국어'
						/>
						<FormControlLabel
							sx={{ fontFamily: 'Noto Sans KR' }}
							value='en'
							control={<Radio />}
							label='영어'
						/>
					</RadioGroup>
				</FormControl>

				<Button
					variant='contained'
					color='primary'
					sx={{ height: 58, fontFamily: 'Noto Sans KR', fontSize: 20, fontWeight: 600 }}
					onClick={handleOnSubmit}
				>
					확인
				</Button>
			</Box>
		</Modal>
	);
};

export default UploadOption;
