import { Box, Button, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useFilterStore from '../../../store/FilterStore';

const FilterV2 = () => {
	const { SHARED_FILTER_LIST, TYPE_FILTER_LIST, shareType, contentType, onChangeShareType, onChangeContentType } =
		useFilterStore();

	return (
		<Box
			sx={{
				width: 270,
				height: 190,
				border: '1px solid #CED3D6',
				borderRadius: '8px',
				boxSizing: 'border-box',
				boxShadow: 'rgba(0, 0, 0, 0.1)',
				padding: '16px',
				display: 'flex',
				flexDirection: 'column',
				gap: '16px',
				background: '#fff',
				zIndex: 1,
			}}
		>
			<Stack spacing={1}>
				<Typography sx={{ fontSize: 16, fontWeight: 600, fontFamily: 'Noto Sans KR' }}>공유</Typography>
				<Box sx={{ display: 'flex', justifyContent: 'center', gap: '4px' }}>
					{SHARED_FILTER_LIST.map((item, index) => {
						return (
							<Button
								key={index}
								// variant={shareType === item.value ? 'contained' : 'outlined'}
								onClick={() => onChangeShareType(item.value)}
								sx={{
									height: 36,
									padding: '8px 16px',
									borderRadius: '40px',
									fontSize: 14,
									fontFamily: 'Noto Sans KR',
									color: shareType === item.value ? '#1C6EFF' : '#292A2B',
									border: shareType === item.value ? '1px solid transparent' : '1px solid #E1E4E6',
									background: shareType === item.value ? '#DBECFF' : 'none',
								}}
							>
								{item.label}
							</Button>
						);
					})}
				</Box>
			</Stack>

			<Stack spacing={1}>
				<Typography sx={{ fontSize: 16, fontWeight: 600, fontFamily: 'Noto Sans KR' }}>유형</Typography>
				<Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '4px' }}>
					{TYPE_FILTER_LIST.map((item, index) => {
						return (
							<Button
								key={index}
								// variant={contentType === item.value ? 'contained' : 'outlined'}
								onClick={() => onChangeContentType(item.value)}
								sx={{
									height: 36,
									padding: '8px 16px',
									borderRadius: '40px',
									fontSize: 14,
									fontFamily: 'Noto Sans KR',
									color: contentType === item.value ? '#1C6EFF' : '#292A2B',
									border: contentType === item.value ? '1px solid transparent' : '1px solid #E1E4E6',
									background: contentType === item.value ? '#DBECFF' : 'none',
								}}
							>
								{item.label}
							</Button>
						);
					})}
				</Box>
			</Stack>
		</Box>
	);
};

export default FilterV2;
