import React from 'react';
import { Button, Dialog, DialogActions, DialogContentText, DialogTitle, IconButton } from '@mui/material';

const ModalConfirm = ({
	title = '',
	description = '',
	confirmText = '확인',
	cancelText = '취소',
	onConfirm,
	onCancel,
	onError,
	children,
}) => {
	const [open, setOpen] = React.useState(false);

	const handleOpen = e => {
		e.stopPropagation();
		setOpen(true);
	};

	const handleCancel = event => {
		event.stopPropagation();
		onCancel && onCancel();
		setOpen(false);
	};

	const handleConfirm = event => {
		event.stopPropagation();
		onConfirm && onConfirm();
		setOpen(false);

		// deleteContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, { contentId })
		// 	.then(() => {
		// 		onConfirm && onConfirm();
		// 		setOpen(false);
		// 	})
		// 	.catch(code => {
		// 		onError && onError(code);
		// 		setOpen(false);
		// 	});
	};

	return (
		<>
			{children && React.cloneElement(children, { onClick: handleOpen })}

			<Dialog open={open} onClose={handleCancel} maxWidth={'sm'}>
				<DialogTitle
					sx={{
						width: 300,
						fontSize: '20px',
						fontWeight: 600,
						textAlign: 'center',
						color: 'var(--Dark-grey700, #292A2B)',
						paddingBottom: '4px',
						fontFamily: 'Noto Sans KR',
						borderRadius: '50%',
					}}
				>
					{title}
				</DialogTitle>
				<DialogContentText
					sx={{
						fontSize: '14px',
						fontWeight: 400,
						textAlign: 'center',
						color: 'var(--Dark-grey400, #878D91)',
						marginLeft: '32px',
						marginRight: '32px',
						marginBottom: '16px',
						fontFamily: 'Noto Sans KR',
					}}
				>
					{description}
				</DialogContentText>
				<DialogActions sx={{ justifyContent: 'center', marginBottom: '12px' }}>
					<Button
						sx={{
							background: '#E1E4E6',
							color: '#4D5256',
							width: '152px',
							height: '48px',
							fontFamily: 'Noto Sans KR',
							fontSize: 18,
							fontWeight: 500,
						}}
						onClick={handleCancel}
					>
						{cancelText}
					</Button>
					<Button
						sx={{
							background: 'var(--Blue-primary700, #1C6EFF)',
							color: 'white',
							width: '152px',
							height: '48px',
							fontFamily: 'Noto Sans KR',
							fontSize: 18,
							fontWeight: 500,
						}}
						onClick={handleConfirm}
						autoFocus
					>
						{confirmText}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ModalConfirm;
