import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { jwtDecode } from 'jwt-decode';
import useAuthStore from '../../store/AuthStore';

export const useAuthAction = () => {
	const [cookies, setCookie, removeCookie] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { auth, refreshAuth } = useAuthStore();

	const isValidCookie = () => {
		console.log('[useAuthAction] isValid cookie', cookies);
		return (
			cookies &&
			cookies[process.env.REACT_APP_COOKIE_ALIAS] &&
			cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken
		);
	};

	const refreshToken = () => {
		console.log('[useAuthAction] refresh token');
		return new Promise((resolve, reject) => {
			if (isValidCookie() && cookies[process.env.REACT_APP_COOKIE_ALIAS].refreshToken) {
				axios
					.put(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
						token: cookies[process.env.REACT_APP_COOKIE_ALIAS].refreshToken,
					})
					.then(response => {
						if (response.data.httpCode === 200) {
							const time =
								3600 *
								Number(
									process.env.REACT_APP_COOKIE_EXPIRE_HOUR
										? process.env.REACT_APP_COOKIE_EXPIRE_HOUR
										: 1
								); //default 1시간
							const expiration = new Date(Date.now() + time * 1000);

							const data = {
								...response.data.data.tokens,
								refreshToken: cookies[process.env.REACT_APP_COOKIE_ALIAS].refreshToken,
							};

							setCookie(process.env.REACT_APP_COOKIE_ALIAS, data, {
								path: '/',
								expires: expiration,
								domain: process.env.REACT_APP_DOMAIN,
							});

							console.log('[useAuthAction] save cookie to local storage');
							localStorage.setItem('timblo-cookie', JSON.stringify(data));

							resolve({ code: 200 });
						} else {
							resolve({ code: response.data.httpCode });
						}
					})
					.catch(error => {
						console.error('[useAuthAction] refresh token error', error);
						resolve({ code: 400 });
					});
			} else {
				//쿠키 없음
				resolve({ code: 401 });
			}
		});
	};

	const isValidSession = useCallback(async (redirectTo = '/') => {
		try {
			if (isValidCookie()) {
				const decoded = jwtDecode(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken);

				// 현재 시간 (밀리초를 초로 변환)
				const currentTime = Date.now() / 1000;

				// 토큰이 만료되었는지 확인
				if (decoded.exp && decoded.exp < currentTime) {
					//navigate(redirectTo);
					removeCookie(process.env.REACT_APP_COOKIE_ALIAS);
					console.log('[useAuthAction] isValidSession', false);

					//리프레시 토큰이 있으면 재발급 받는다.
					if (await refreshToken()) {
						await refreshAuth(cookies[process.env.REACT_APP_COOKIE_ALIAS]);
						return true;
					} else {
						console.log('[useAuthAction] isValidSession x', false);
						removeCookie(process.env.REACT_APP_COOKIE_ALIAS);
						return false; // 토큰이 만료됨
					}
				} else {
					await refreshAuth(cookies[process.env.REACT_APP_COOKIE_ALIAS]);
					return true; // 토큰이 유효함
				}
			} else {
				//로그인 안됨.
				//navigate(redirectTo);
				console.log('[useAuthAction] isValidSession c', false);
				removeCookie(process.env.REACT_APP_COOKIE_ALIAS);
				return false;
			}
		} catch (e) {
			console.log('[useAuthAction] isValidSession e', false);
			removeCookie(process.env.REACT_APP_COOKIE_ALIAS);
			return false;
		}
	}, []);

	return { isValidSession, isValidCookie };
};
