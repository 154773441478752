import moment from 'moment';
export const getSortedContents = (contents, limit = 0) => {
	const temp = contents.sort((a, b) => {
		// 첫 번째 조건: isNew가 true인 항목을 우선
		if (a.isNew && !b.isNew) return -1;
		if (!a.isNew && b.isNew) return 1;

		// 두 번째 조건: updateAt을 기준으로 내림차순 정렬
		return new Date(b.updateAt) - new Date(a.updateAt);
	});

	if (0 < limit) {
		return temp.slice(0, limit); // 상위 10개 항목을 반환
	} else {
		return temp;
	}
};

export const getAvatarColor = input => {
	const COLORS = [
		{ background: '#EDE7F6', color: '#651FFF' },
		{ background: '#E3F2FD', color: '#3B78FF' },
		{ background: '#FFEBEE', color: '#FF1744' },
		{ background: '#FBE9E7', color: '#FF3D00' },
		{ background: '#FFF8E1', color: '#FF8F00' },
		{ background: '#E8F5E9', color: '#00C853' },
		{ background: '#E0F2F1', color: '#00BFA5' },
		{ background: '#F5F5F5', color: '#757575' },
	];

	if (typeof input === 'number') {
		return COLORS[input % COLORS.length];
	}

	let hash = 0;
	for (let i = 0; i < input.length; i++) {
		hash = input.charCodeAt(i) + ((hash << 5) - hash);
	}

	const index = Math.abs(hash) % COLORS.length;
	return COLORS[index];
};

export const onCopyText = async (text, callback) => {
	console.log('copyText : ', text);

	if (navigator.clipboard) {
		try {
			await navigator.clipboard.writeText(text); // 클립보드에 텍스트 복사
			console.log('Text copied to clipboard');
			callback && callback(); // 다국어 콜백 호출
		} catch (error) {
			console.error('Failed to copy text: ', error);
		}
	} else {
		// Clipboard API를 지원하지 않는 브라우저에서 fallback
		const textarea = document.createElement('textarea');
		textarea.value = text;

		document.body.appendChild(textarea);
		textarea.focus();
		textarea.select();

		try {
			const successful = document.execCommand('copy');
			if (successful) {
				console.log('Text copied using execCommand');
				callback && callback(); // 다국어 콜백 호출
			} else {
				throw new Error('Fallback copy failed');
			}
		} catch (error) {
			console.error('Failed to copy text: ', error);
		} finally {
			document.body.removeChild(textarea);
		}
	}
};

export const isMyThumbnail = (auth, creatorEmail) => {
	let result = false;

	if (auth) {
		const email = auth.user?.profile?.email;

		if (email === creatorEmail) {
			return true;
		}
	}

	return result;
};

export async function audioBufferToWav(audioBuffer, targetSampleRate = 16000) {
	// 고품질 재샘플링 수행
	const resampledBuffer = await resampleAudioBuffer(audioBuffer, targetSampleRate);

	const numOfChan = resampledBuffer.numberOfChannels;
	const length = resampledBuffer.length * numOfChan * 2 + 44;
	const buffer = new ArrayBuffer(length);
	const view = new DataView(buffer);
	const channels = [];
	const sampleRate = targetSampleRate;

	let offset = 0;
	let pos = 0;

	// Write WAV header
	setUint32(0x46464952); // "RIFF"
	setUint32(length - 8); // file length - 8
	setUint32(0x45564157); // "WAVE"

	setUint32(0x20746d66); // "fmt " chunk
	setUint32(16); // length = 16
	setUint16(1); // PCM (uncompressed)
	setUint16(numOfChan);
	setUint32(sampleRate);
	setUint32(sampleRate * 2 * numOfChan); // avg. bytes/sec
	setUint16(numOfChan * 2); // block-align
	setUint16(16); // 16-bit

	setUint32(0x61746164); // "data" - chunk
	setUint32(length - pos - 4); // chunk length

	// Extract channel data and prepare for interleaving
	for (let i = 0; i < resampledBuffer.numberOfChannels; i++) {
		channels.push(resampledBuffer.getChannelData(i));
	}

	// Interleave and process audio data
	while (pos < length) {
		for (let i = 0; i < numOfChan; i++) {
			// Clamp values and apply dithering for 16-bit conversion
			let sample = channels[i][offset];
			sample = Math.max(-1, Math.min(1, sample)); // Clamp between -1 and 1
			sample = applyDithering(sample); // Apply dithering
			sample = (sample * 32767) | 0; // Convert to 16-bit signed integer
			view.setInt16(pos, sample, true); // Write to DataView
			pos += 2;
		}
		offset++; // Move to the next sample
	}

	function setUint16(data) {
		view.setUint16(pos, data, true);
		pos += 2;
	}

	function setUint32(data) {
		view.setUint32(pos, data, true);
		pos += 4;
	}

	// Apply dithering to reduce quantization noise
	function applyDithering(sample) {
		const noise = ((Math.random() - 0.5) * 2) / 32768; // Generate small random noise
		return sample + noise; // Add noise to the sample
	}

	return buffer;
}

// export async function audioBufferToWav(audioBuffer, targetSampleRate = 16000) {
// 	const resampledBuffer = await resampleAudioBuffer(audioBuffer, targetSampleRate);

// 	const numOfChan = resampledBuffer.numberOfChannels,
// 		length = resampledBuffer.length * numOfChan * 2 + 44,
// 		buffer = new ArrayBuffer(length),
// 		view = new DataView(buffer),
// 		channels = [],
// 		sampleRate = targetSampleRate;
// 	let offset = 0,
// 		pos = 0;

// 	// Write WAV header
// 	setUint32(0x46464952); // "RIFF"
// 	setUint32(length - 8); // file length - 8
// 	setUint32(0x45564157); // "WAVE"

// 	setUint32(0x20746d66); // "fmt " chunk
// 	setUint32(16); // length = 16
// 	setUint16(1); // PCM (uncompressed)
// 	setUint16(numOfChan);
// 	setUint32(sampleRate);
// 	setUint32(sampleRate * 2 * numOfChan); // avg. bytes/sec
// 	setUint16(numOfChan * 2); // block-align
// 	setUint16(16); // 16-bit

// 	setUint32(0x61746164); // "data" - chunk
// 	setUint32(length - pos - 4); // chunk length

// 	// Write interleaved data
// 	for (let i = 0; i < resampledBuffer.numberOfChannels; i++) channels.push(resampledBuffer.getChannelData(i));

// 	while (pos < length) {
// 		for (let i = 0; i < numOfChan; i++) {
// 			// interleave channels
// 			let sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
// 			sample = (0.5 + sample * 32767) | 0; // scale to 16-bit signed int
// 			view.setInt16(pos, sample, true); // write 16-bit sample
// 			pos += 2;
// 		}
// 		offset++; // next source sample
// 	}

// 	function setUint16(data) {
// 		view.setUint16(pos, data, true);
// 		pos += 2;
// 	}

// 	function setUint32(data) {
// 		view.setUint32(pos, data, true);
// 		pos += 4;
// 	}

// 	return buffer;
// }

function resampleAudioBuffer(audioBuffer, targetSampleRate) {
	const offlineContext = new OfflineAudioContext(
		audioBuffer.numberOfChannels,
		audioBuffer.duration * targetSampleRate,
		targetSampleRate
	);

	const bufferSource = offlineContext.createBufferSource();
	bufferSource.buffer = audioBuffer;
	bufferSource.connect(offlineContext.destination);
	bufferSource.start(0);

	return offlineContext.startRendering();
}

export const formatDateToKoreanStyle = dateString => {
	const date = new Date(dateString);

	// 년, 월, 일
	const year = String(date.getFullYear()).slice(-2); // YY
	const month = String(date.getMonth() + 1).padStart(2, '0'); // MM
	const day = String(date.getDate()).padStart(2, '0'); // DD

	// 시간 (12시간제로 변환)
	let hours = date.getHours(); // 24시간제
	const minutes = String(date.getMinutes()).padStart(2, '0'); // MM
	const isPM = hours >= 12;
	const period = isPM ? '오후' : '오전';

	if (hours > 12) hours -= 12; // 오후 시간
	if (hours === 0) hours = 12; // 오전 12시 처리
	const formattedHours = String(hours).padStart(2, '0'); // HH

	// 포맷 조합
	return `${year}. ${month}. ${day} ${period} ${formattedHours}:${minutes}`;
};

export const formatMillisecondsToTime = ms => {
	// 총 시간 계산
	const totalSeconds = Math.floor(ms / 1000); // 초 단위로 변환
	const hours = Math.floor(totalSeconds / 3600); // 시간 계산
	const minutes = Math.floor((totalSeconds % 3600) / 60); // 분 계산
	const seconds = totalSeconds % 60; // 초 계산

	// 두 자리 숫자로 포맷
	const formattedHours = String(hours).padStart(2, '0');
	const formattedMinutes = String(minutes).padStart(2, '0');
	const formattedSeconds = String(seconds).padStart(2, '0');

	return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export function formatDateToModern(date) {
	const now = moment(); // 현재 시간
	const inputDate = moment(date); // 입력 받은 날짜

	if (inputDate.isSame(now, 'day')) {
		// 입력 날짜가 오늘인 경우
		return `오늘 ${inputDate.format('A hh:mm')}`; // 'A'는 오전/오후를 표시
	} else if (inputDate.isSame(now.clone().subtract(1, 'days'), 'day')) {
		// 입력 날짜가 어제인 경우
		return `어제 ${inputDate.format('A hh:mm')}`;
	} else {
		// 입력 날짜가 오늘도 어제도 아닌 경우
		return inputDate.format('YY.MM.DD A hh:mm'); // 요청한 포맷
	}
}

export function formatToKoreanTime(isoDateString) {
	const date = new Date(isoDateString);
	const options = {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true, // 12시간제를 활성화
	};

	// 한국 시간대를 명시적으로 설정
	const formatter = new Intl.DateTimeFormat('ko-KR', options);
	const formattedTime = formatter.format(date);

	return formattedTime;
}

export const isUUID = value => {
	const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
	return uuidRegex.test(value);
};

export const getBlockTitle = key => {
	if (key) {
		switch (key) {
			case 'topics':
				return '주제';

			case 'speakerInfo':
				return '참석자';

			case 'keywords':
				return '키워드';

			case 'summary':
				return '전체 요약';

			case 'issues':
				return '이슈';

			case 'tasks':
				return '할일';

			case 'summaryTime':
				return '주제별 상세 요약';

			case 'segments':
			case 'mergedSegments': {
				return '음성 기록';
			}

			default:
				return '';
		}
	}
};

export const getBlockInfo = key => {
	if (key) {
		switch (key) {
			case 'topics':
				return 'AI가 선정한 회의 주제로 요점을 쉽게 파악해 보세요.';

			case 'speakerInfo':
				return 'AI가 구분한 회의 참석자를 한눈에 확인해 보세요.';

			case 'keywords':
				return 'AI가 자동 추출한 키워드를 통해 회의의 핵심을 파악해 보세요.';

			case 'summary':
				return 'AI가 자동 추출한 키워드를 통해 회의의 핵심을 파악해 보세요.';

			case 'issues':
				return 'AI가 정리한 이슈를 빠르게 점검해 보세요.';

			case 'tasks':
				return 'AI가 정리한 할 일을 체크해 보세요.';

			default:
				return '';
		}
	}
};
