import React, { useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Badge from '@mui/material/Badge';
import { Box, SvgIcon, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import useContentsStore from '../../../store/ContentsStore';
import ContentGrid from '../../../components/content/common/ContentGrid';

import { ReactComponent as ContentsNoDataIcon } from '../img/contents_no_data.svg';
const HomeContents = () => {
	const { home } = useContentsStore();

	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Typography
				variant='p'
				style={{
					display: 'block',
					width: '100%',
					height: '58px',
					fontSize: '20px',
					fontWeight: 600,
					padding: '16px 24px',
					boxSizing: 'border-box',
				}}
			>
				최근 회의록
			</Typography>

			<Box
				sx={{
					flex: 1,
					overflow: 'auto',
					display: 'flex',
					flexDirection: 'column',
					gap: '10px',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{home.contents && home.contents.length ? (
					<ContentGrid
						data={home.contents}
						columnVisibilityModel={{ restore: false, delete: false, share: false, truncate: false }}
						isHome
					/>
				) : (
					<>
						<SvgIcon inheritViewBox sx={{ width: 50, height: 57 }}>
							<ContentsNoDataIcon />
						</SvgIcon>
						<Typography
							sx={{
								fontSize: 18,
								fontWeight: 700,
								color: '#28323C',
								fontFamily: 'Noto Sans KR',
							}}
						>
							최근 회의록이 없습니다.
						</Typography>
						<Typography
							sx={{
								fontSize: 14,
								fontWeight: 500,
								color: '#858C99',
								fontFamily: 'Noto Sans KR',
							}}
						>
							최근 등록된 회의록이 없습니다.
						</Typography>
					</>
				)}
			</Box>
		</Box>
	);
};

export default HomeContents;
