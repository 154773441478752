import { create } from 'zustand';
import axios from 'axios';

const MAX_QUEUE_CNT = 20;

const useMessageStore = create(set => ({
	queue: {
		messages: [],
	},
	onMessage: message => {
		console.log('[useMessageStore] onMessage', message);
		try {
			set(state => {
				const updatedMessages = [...state.queue.messages, message];

				if (updatedMessages.length > MAX_QUEUE_CNT) {
					updatedMessages.shift(); // Remove the oldest message
				}

				return {
					queue: {
						messages: updatedMessages,
					},
				};
			});
		} catch (e) {
			console.error(e);
		}
	},
	inbox: [],
	refreshInbox: accessToken => {
		return new Promise(async (resolve, reject) => {
			console.log('[useMessageStore] refreshInbox');
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};
				const res = await axios.get(`${process.env.REACT_APP_API_URL}/inbox`, {
					headers,
				});

				switch (res.data.httpCode) {
					case 200:
						set(() => ({ inbox: [...res.data.data] }));
						resolve({ code: 200, message: 'ok', data: res.data.data });
						break;
					case 401:
						//권한 없음
						resolve({ code: 401, message: res.data.message });
						break;
					default:
						reject();
						break;
				}
			} catch (e) {
				console.error(e);
				reject();
			}
		});
	},
	deleteInbox: (accessToken, inboxId) => {
		return new Promise(async (resolve, reject) => {
			console.log('[useMessageStore] deleteInbox');
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};
				const res = await axios.delete(`${process.env.REACT_APP_API_URL}/inbox/${inboxId}`, {
					headers,
				});
				switch (res.data.httpCode) {
					case 204:
						console.log('[useMessageStore] deleted', inboxId);
						set(state => ({
							inbox: state.inbox.filter(item => item.inboxId !== inboxId),
						}));

						resolve({ code: 200, message: 'ok' });
						break;
					case 400:
						resolve({ code: 400, message: res.data.message });
					case 401:
						//권한 없음
						resolve({ code: 401, message: res.data.message });
						break;
					default:
						reject();
						break;
				}
			} catch (e) {
				console.error(e);
				reject();
			}
		});
	},
	readInbox: (accessToken, inboxId) => {
		return new Promise(async (resolve, reject) => {
			console.log('[useMessageStore] readInbox');
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};
				const res = await axios.patch(`${process.env.REACT_APP_API_URL}/inbox/${inboxId}`, null, {
					headers,
				});
				switch (res.data.httpCode) {
					case 200:
						resolve({ code: 200, message: 'ok' });
						break;
					case 400:
						resolve({ code: 400, message: res.data.message });
					case 401:
						//권한 없음
						resolve({ code: 401, message: res.data.message });
						break;
					default:
						reject();
						break;
				}
			} catch (e) {
				console.error(e);
				reject();
			}
		});
	},
	readAll: accessToken => {
		return new Promise(async (resolve, reject) => {
			console.log('[useMessageStore] readAll');
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};
				const res = await axios.patch(`${process.env.REACT_APP_API_URL}/inbox`, null, {
					headers,
				});
				switch (res.data.httpCode) {
					case 200:
						resolve({ code: 200, message: 'ok' });
						break;
					case 400:
						resolve({ code: 400, message: res.data.message });
					case 401:
						//권한 없음
						resolve({ code: 401, message: res.data.message });
						break;
					default:
						reject();
						break;
				}
			} catch (e) {
				console.error(e);
				reject();
			}
		});
	},
}));

export default useMessageStore;
