// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-loading-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.page-loading-container > * {
	display: flex;
	align-items: center;
	justify-content: center;
}

.page-loading-container > .loading {
	height: 100px;
}

.page-loading-container > .title {
	color: var(--main-title-text-color, #28323c);
	font-size: var(--font-size-xxl, 24px);
	font-weight: var(--font-weight-bold, 700);
	padding-bottom: 5px;
}

.page-loading-container > .description {
	color: var(--main-description-text-color, #858c99);
	font-size: var(--font-size-xl, 18px);
	font-weight: var(--font-weight-regular, 400);
}

.page-loading-container > .contents-load-message-box {
}
`, "",{"version":3,"sources":["webpack://./src/components/page-status/PageLoading.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;CACZ,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,sBAAsB;AACvB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;AACxB;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,4CAA4C;CAC5C,qCAAqC;CACrC,yCAAyC;CACzC,mBAAmB;AACpB;;AAEA;CACC,kDAAkD;CAClD,oCAAoC;CACpC,4CAA4C;AAC7C;;AAEA;AACA","sourcesContent":[".page-loading-container {\n\twidth: 100%;\n\theight: 100%;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tflex-direction: column;\n}\n\n.page-loading-container > * {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.page-loading-container > .loading {\n\theight: 100px;\n}\n\n.page-loading-container > .title {\n\tcolor: var(--main-title-text-color, #28323c);\n\tfont-size: var(--font-size-xxl, 24px);\n\tfont-weight: var(--font-weight-bold, 700);\n\tpadding-bottom: 5px;\n}\n\n.page-loading-container > .description {\n\tcolor: var(--main-description-text-color, #858c99);\n\tfont-size: var(--font-size-xl, 18px);\n\tfont-weight: var(--font-weight-regular, 400);\n}\n\n.page-loading-container > .contents-load-message-box {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
