import React from 'react';
import TimbloJoin from './form/TimbloJoin';
import TimbloLogin from './form/TimbloLogin';
import './TimbloAuth.css';

import TimbloLogo from './img/sk-poc-logo.png';

const TimbloAuth = ({ mode = 'login' /* join*/, path = '/main' }) => {
	return (
		<div className='timblo-auth-container'>
			<div className='logo'>
				<img src={TimbloLogo} />
			</div>
			<div className='form'>{mode === 'login' ? <TimbloLogin path={path} /> : <TimbloJoin path={path} />}</div>
		</div>
	);
};

export default TimbloAuth;
