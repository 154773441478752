import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import './TranscriptionVS.css';

import Slider from 'rc-slider';

import Loading from 'react-loading';
import 'rc-slider/assets/index.css';

import { ReactComponent as SearchIcon } from './img/search.svg';
import { ReactComponent as SearchVsIcon } from './img/searchvs.svg';
import { ReactComponent as VolumeIcon } from './img/volume.svg';
import { ReactComponent as SoundVsIcon } from './img/soundvs.svg';
import { ReactComponent as PlaybackRateIcon } from './img/playback.svg';
import { ReactComponent as PlaybackRateVsIcon } from './img/palybackvs.svg';
import { ReactComponent as SizeIcon } from './img/size.svg';
import { ReactComponent as PlayIcon } from './img/play.svg';
import { ReactComponent as PlayVsIcon } from './img/playvs.svg';
import { ReactComponent as PauseVsIcon } from './img/pausevs.svg';
import { ReactComponent as PauseIcon } from './img/pause.svg';
import { ReactComponent as MutedIcon } from './img/muted.svg';
import { ReactComponent as ArrowIcon } from './img/arrow.svg';
import { ReactComponent as ClearIcon } from './img/clear.svg';

import { useCookies } from 'react-cookie';
import SttRow from './SttRow';
import useContentsStore from '../../../store/ContentsStore';
import useFunctionStore from '../../../store/FunctionStore';
import SearchAndReplace from '../../popup/replace/SearchAndReplace';
import { Virtuoso } from 'react-virtuoso';
import { ToastError } from '../../common/toast/Toast';
import { Box, Button, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import SttRowVS from './SttRowVS';

const playRates = [0.5, 1, 1.5, 2];

const Transcription = forwardRef(
	(
		{
			type,
			source,
			data,
			onChangeSegmentSpeaker = () => {},
			speakerInfo,
			noteRef,
			editable,
			highlights,
			refreshContent,
		},
		ref
	) => {
		const dataContainerRef = useRef();
		const virtualRef = useRef(null);
		const dataRef = useRef([]);

		const MERGE_MILLISECONDS = process.env.REACT_APP_MERGE_SENTENCE_MILLISECONDS ?? 30000;

		const [cookies, setCookie, removeCookie] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
		const audioRef = useRef();
		const [audioSrc, setAudioSrc] = useState(null);
		const [isEditMode, setIsEditMode] = useState(false);
		const [isUpdateLoading, setIsUpdateLoading] = useState(false);
		const { selectedContent, setSelectedContent } = useContentsStore();
		const { updateSegments } = useFunctionStore();

		const [mergedTexts, setMergedTexts] = useState([]);
		const [isTracking, setIsTracking] = useState(false);

		// Resize
		const [playerHeight, setPlayerHeight] = useState(120);
		const [isResizing, setIsResizing] = useState(false);

		// Highlights
		const [keyword, setKeyword] = useState('');
		const [searchResult, setSearchResult] = useState({
			currentIndex: 0,
			total: 0,
		});

		//Audio State
		const [currentTime, setCurrentTime] = useState(0);
		const [duration, setDuration] = useState(0);
		const [playing, setPlaying] = useState(false);
		const [isDragging, setIsDragging] = useState(false);
		const [playbackRate, setPlaybackRate] = useState(1);
		const [isMuted, setIsMuted] = useState(false);
		const [seekStep, setSeekStep] = useState({ prev: 5, next: 5 });
		const [isPip, setIsPip] = useState(false);
		const [onReady, setOnReady] = useState(false);

		useImperativeHandle(ref, () => ({
			onSeekTime: startSec => {
				onSeekTime(startSec);

				const target = findNextIndex(mergedTexts, startSec * 1000);

				if (target > -1) {
					onScrollToIndex(target, 'auto');
				}
			},
			mergedTexts: mergedTexts,
		}));

		useEffect(() => {
			const handleOnWheel = () => {
				setIsTracking(false);
			};

			if (dataContainerRef.current) {
				dataContainerRef.current.addEventListener('wheel', handleOnWheel);
			}

			return () => {
				if (document.pictureInPictureElement) {
					document.exitPictureInPicture();
				}

				if (dataContainerRef.current) {
					dataContainerRef.current.removeEventListener('wheel', handleOnWheel);
				}
			};
		}, []);

		useEffect(() => {
			if (!isEditMode) {
				if (playing) {
					if (mergedTexts && mergedTexts.length) {
						const milliseconds = currentTime * 1000;

						const target = findNextIndex(mergedTexts, milliseconds);

						if (target > -1) {
							if (isTracking) {
								onScrollToIndex(target);
							}

							const listItems = document.querySelectorAll('.stt-row-container');

							if (listItems.length) {
								listItems.forEach(item => item.classList.remove('highlighted'));

								const elementTarget = Array.from(listItems).find(
									element =>
										element.classList.contains(`stt-row-container`) &&
										element.classList.contains(`${target}`)
								);

								if (elementTarget) {
									elementTarget.classList.add('highlighted');
								}
							}
						}
					}
				} else {
					const listItems = document.querySelectorAll('.stt-row-container');
					if (listItems.length) {
						listItems.forEach(item => item.classList.remove('highlighted'));
					}
				}
			} else {
				const listItems = document.querySelectorAll('.stt-row-container');
				if (listItems.length) {
					listItems.forEach(item => item.classList.remove('highlighted'));
				}
			}
		}, [currentTime, mergedTexts, playing, isEditMode, isTracking]);

		useEffect(() => {
			if (data.length) {
				data.map((item, index) => (dataRef.current[index] = item));
			} else {
				dataRef.current = [];
			}
		}, [data]);

		useEffect(() => {
			if (audioRef && audioRef.current) {
				if (playing) {
					setIsTracking(true);
					audioRef.current.play();
				} else {
					audioRef.current.pause();
				}
			}
		}, [playing]);

		useEffect(() => {
			if (isDragging) {
				setPlaying(false);
			}
		}, [isDragging]);

		useEffect(() => {
			if (isPip) {
				const videoElement = audioRef.current;

				if (!videoElement) return;

				const updateCurrentTime = () => {
					setCurrentTime(videoElement.currentTime);
				};

				const handlePause = () => {
					setPlaying(false);
				};

				const handlePlay = () => {
					setPlaying(true);
				};

				videoElement.addEventListener('pause', handlePause);
				videoElement.addEventListener('play', handlePlay);

				const intervalId = setInterval(() => {
					if (playing) {
						updateCurrentTime();
					}
				}, 1000);

				return () => {
					clearInterval(intervalId);
					videoElement.removeEventListener('pause', handlePause);
					videoElement.removeEventListener('play', handlePlay);
				};
			}
		}, [playing, isPip]);

		useEffect(() => {
			if (data && data.length) {
				const mergedSegments = [];
				let currentSegment = null;
				let currentStartTime = 0;

				data.forEach(segment => {
					if (!currentSegment) {
						currentSegment = { ...segment };
						currentStartTime = segment.startTime;
					} else if (
						segment.startTime < currentStartTime + Number(MERGE_MILLISECONDS) &&
						segment.speakerId === currentSegment.speakerId
					) {
						currentSegment.text += ' ' + segment.text;
						currentSegment.endTime = segment.endTime;
						currentSegment.duration = currentSegment.endTime - currentSegment.startTime;
					} else {
						mergedSegments.push(currentSegment);
						currentSegment = { ...segment };
						currentStartTime = segment.startTime;
					}
				});

				if (currentSegment) {
					mergedSegments.push(currentSegment);
				}
				setMergedTexts(mergedSegments);
			}
		}, [data]);

		const findNextIndex = (arr, time) => {
			let low = 0;
			let high = arr.length - 1;

			while (low <= high) {
				const mid = Math.floor((low + high) / 2);
				if (arr[mid].startTime <= time && (mid === arr.length - 1 || arr[mid + 1].startTime > time)) {
					return mid;
				} else if (arr[mid].startTime <= time) {
					low = mid + 1;
				} else {
					high = mid - 1;
				}
			}

			return -1;
		};

		const onScrollToIndex = (index, behavior = 'smooth') => {
			// virtualRef.current.scrollToIndex({
			// 	index,
			// 	align: 'center',
			// 	behavior,
			// });
		};

		const highlightedData = useMemo(() => {
			if (!keyword) {
				setSearchResult({ currentIndex: 0, total: 0 });

				if (isEditMode) {
					return data.map(item => [item.text]);
				} else {
					return mergedTexts.map(item => [item.text]);
				}
			}

			if (isEditMode) {
				return data.map(item => {
					const regex = new RegExp(`(${keyword})`, 'gi');
					return item.text.split(regex);
				});
			} else {
				return mergedTexts.map(item => {
					const regex = new RegExp(`(${keyword})`, 'gi');
					return item.text.split(regex);
				});
			}
		}, [data, mergedTexts, keyword, isEditMode]);

		const matchIndices = useMemo(() => {
			if (!keyword) return [];
			let indices = [];
			highlightedData.forEach((parts, dataIndex) => {
				parts.forEach((part, partIndex) => {
					if (part.toLowerCase() === keyword.toLowerCase()) {
						indices.push({ dataIndex, partIndex });
					}
				});
			});

			return indices;
		}, [highlightedData, keyword]);

		const totalMatches = matchIndices.length;

		useEffect(() => {
			if (virtualRef.current && matchIndices[searchResult.currentIndex]) {
				onScrollToIndex(matchIndices[searchResult.currentIndex].dataIndex, 'auto');
			}
		}, [searchResult, matchIndices, isEditMode]);

		useEffect(() => {
			if (type === 'video') {
				const videoElement = audioRef.current;

				const handleEnterPiP = () => {
					setIsPip(true);
				};

				const handleLeavePiP = () => {
					setIsPip(false);
				};

				videoElement.addEventListener('enterpictureinpicture', handleEnterPiP);
				videoElement.addEventListener('leavepictureinpicture', handleLeavePiP);

				return () => {
					videoElement.removeEventListener('enterpictureinpicture', handleEnterPiP);
					videoElement.removeEventListener('leavepictureinpicture', handleLeavePiP);
				};
			}
		}, [type]);

		const onChangePlaybackRate = event => {
			setPlaybackRate(event.target.value);

			if (audioRef.current) {
				audioRef.current.playbackRate = event.target.value;
			}
		};

		const toggleMute = () => {
			if (audioRef.current) {
				const newMutedState = !isMuted;
				audioRef.current.muted = newMutedState;
				setIsMuted(newMutedState);
			}
		};

		const convertTimeFormat = milliseconds => {
			let seconds = Math.floor(milliseconds / 1000);
			let minutes = Math.floor(seconds / 60);
			let hours = Math.floor(minutes / 60);

			seconds = seconds % 60;
			minutes = minutes % 60;

			const pad = num => num.toString().padStart(2, '0');

			if (hours > 0) {
				return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
			} else {
				return `${pad(minutes)}:${pad(seconds)}`;
			}
		};

		const onSeekTime = useCallback(startSec => {
			if (audioRef && audioRef.current) {
				audioRef.current.currentTime = startSec;
				if (type === 'audio') {
					setPlaying(true);
				} else {
					audioRef.current.play();
				}
			}
		}, []);

		const onTogglePlay = () => {
			if (onReady) {
				if (audioRef.current) {
					if (playing) {
						audioRef.current.pause();
					} else {
						audioRef.current.play();
					}
					setPlaying(!playing);
				}
			} else {
				ToastError(null, '음성 파일이 로드되지 않아 재생할 수 없습니다.');
			}
		};

		const drawPlayer = () => {
			const handleSliderChangeComplete = value => {
				audioRef.current.currentTime = value;
				setCurrentTime(value);
				setIsDragging(false);
				setPlaying(true);
			};

			return (
				<>
					{type === 'audio' && (
						<Box sx={{ display: 'none' }}>
							<audio
								ref={audioRef}
								src={source}
								preload='auto'
								onTimeUpdate={handleTimeUpdate}
								onLoadedMetadata={handleLoadedMetadata}
							/>
						</Box>
					)}

					{type === 'video' && (
						<Box style={{ display: isPip ? 'none' : 'flex' }}>
							<video
								ref={audioRef}
								src={source}
								preload='auto'
								controls
								onTimeUpdate={handleTimeUpdate}
								onLoadedMetadata={handleLoadedMetadata}
							/>
						</Box>
					)}

					<Stack
						spacing={1.5}
						sx={{
							display: type === 'video' ? (!isPip ? 'none' : 'flex') : 'flex',
							px: 4,
							flexShrink: 0,
						}}
					>
						<Stack spacing={1.5} sx={{ py: 2 }}>
							<Stack spacing={0.25}>
								<Slider
									value={currentTime}
									max={duration}
									step={0.01}
									onChange={value => {
										if (!isDragging) {
											setIsDragging(true);
										}

										handleSliderChangeComplete(value);
									}}
									onChangeComplete={handleSliderChangeComplete}
									style={{
										height: '10px',
									}}
								/>
								<Stack direction={'row'} justifyContent={'space-between'}>
									<Typography
										sx={theme => ({ ...theme.typography.body4, color: theme.palette.grey[400] })}
									>
										{convertTimeFormat(currentTime * 1000)}
									</Typography>
									<Typography
										sx={theme => ({ ...theme.typography.body4, color: theme.palette.grey[400] })}
									>
										{convertTimeFormat(duration * 1000)}
									</Typography>
								</Stack>
							</Stack>
							<Stack direction={'row'} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
								{isMuted ? (
									<MutedIcon
										onClick={toggleMute}
										sx={theme => ({ color: theme.palette.grey[400] })}
									/>
								) : (
									<SoundVsIcon
										onClick={toggleMute}
										sx={theme => ({ color: theme.palette.grey[400] })}
									/>
								)}

								<Stack
									spacing={4}
									direction={'row'}
									sx={{ alignItems: 'center', justifyContent: 'space-between' }}
								>
									<Box
										onClick={() => onSeekTime(currentTime - Number(seekStep.prev))}
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											position: 'relative',
											cursor: 'pointer',
										}}
									>
										<PlaybackRateVsIcon />
										<Box sx={theme => ({ ...theme.typography.body6, position: 'absolute' })}>
											{seekStep.prev}
										</Box>
									</Box>
									<Box
										onClick={onTogglePlay}
										sx={{
											display: 'flex',
										}}
									>
										{playing ? <PauseVsIcon /> : <PlayVsIcon />}
									</Box>
									<Box
										onClick={() => onSeekTime(currentTime + Number(seekStep.next))}
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											position: 'relative',
											cursor: 'pointer',
											transform: 'scaleX(-1)',
										}}
									>
										<PlaybackRateVsIcon />
										<Box
											sx={theme => ({
												...theme.typography.body6,
												position: 'absolute',
												transform: 'scaleX(-1)',
											})}
										>
											{seekStep.next}
										</Box>
									</Box>
								</Stack>

								<Box
									sx={theme => ({
										display: 'flex',
										minWidth: '54px',
										height: '30px',
										//backgroundColor: theme.palette.light[200],
										backgroundColor: 'hsla(192, 14%, 93%, 1)',
										borderRadius: '4px',
										boxSizing: 'border-box',
										px: 0.5,
									})}
								>
									<Select
										value={playbackRate}
										onChange={onChangePlaybackRate}
										disableUnderline
										variant='standard'
										displayEmpty
										MenuProps={{
											PaperProps: {
												style: {
													border: '1px solid hsla(192, 14%, 93%, 1)',
												},
											},
										}}
										style={{ minWidth: 54, minHeight: 30 }} // Select의 기본 Width 설정
										sx={theme => ({
											...theme.typography.title4,

											// '& .MuiSelect-icon': {
											// 	fontSize: '16px',
											// },
											'& .MuiSelect-select': {
												p: 0,
												height: 30,
												display: 'flex',
												flexGrow: 1,
												alignItems: 'center',
												justifyContent: 'center',
											},
										})}
									>
										{playRates.map(rate => (
											<MenuItem
												key={rate}
												value={rate}
												sx={theme => ({
													...theme.typography.title4,
													display: 'flex',
													minWidth: '54px',
													height: '30px',
													borderRadius: '4px',
													boxSizing: 'border-box',
												})}
											>
												{rate}X
											</MenuItem>
										))}
									</Select>
								</Box>

								{/* <Box
									sx={{
										display: 'flex',
										minWidth: '61px',
										height: '30px',
										backgroundColor: 'hsla(192, 14%, 93%, 1)',
										borderRadius: '4px',
										boxSizing: 'border-box',
										pl: 1,
									}}
								>
									

										<Select
											value={playbackRate}
											onChange={onChangePlaybackRate}
											disableUnderline
											variant='standard'
											displayEmpty
											MenuProps={{
												PaperProps: {
													style: {
														width: '69px',
														border: '1px solid hsla(192, 14%, 93%, 1)',
														marginTop: '0px', // 팝업이 Select 바로 아래에 붙도록 설정
													},
												},
												anchorOrigin: {
													vertical: 'bottom',
													horizontal: 'left', // Select의 왼쪽을 기준으로 팝업 위치 설정
												},
												transformOrigin: {
													vertical: 'top',
													horizontal: 'left', // 팝업의 왼쪽 상단이 Select와 정렬되도록 설정
												},
												getContentAnchorEl: null, // 기본 앵커 설정 제거 (오른쪽으로 치우치는 문제 해결)
											}}
											style={{ minWidth: 61 }} // Select의 기본 Width 설정
											sx={theme => ({
												...theme.typography.title4,
												'& .MuiInputBase-root': {},
												'& .MuiSelect-icon': {
													fontSize: '16px',
												},
												'& .MuiSelect-select': {
													p: 0,
													minWidth: 0,
												},
											})}
										>
											{playRates.map(rate => (
												<MenuItem
													key={rate}
													value={rate}
													sx={theme => ({
														...theme.typography.title4,
														width: '69px',
													})}
												>
													{rate}X
												</MenuItem>
											))}
										</Select>
									
								</Box> */}

								<Box display={type === 'video' ? 'flex' : 'none'}>
									{type === 'video' && (
										<SizeIcon
											onClick={() => {
												document.exitPictureInPicture();
												setIsPip(false);
											}}
										/>
									)}
								</Box>
							</Stack>
						</Stack>
					</Stack>
				</>
			);
		};

		const handleTimeUpdate = () => {
			if (audioRef && audioRef.current) {
				setCurrentTime(audioRef.current.currentTime);
			}
		};

		const handleLoadedMetadata = data => {
			setDuration(data.target.duration);

			setOnReady(true);
		};

		const onUpdateSegments = () => {
			setIsUpdateLoading(true);

			const segments = dataRef.current;

			const diffSegments = segments.filter(segment => {
				const target = data.find(item => segment.segmentId === item.segmentId);

				if (target.text !== segment.text) {
					return true;
				} else {
					return false;
				}
			});

			if (diffSegments && diffSegments.length) {
				const updatedSegments = data.map(seg => {
					const target = diffSegments.find(item => item.segmentId === seg.segmentId);

					if (target) {
						return { ...seg, text: target.text };
					} else {
						return seg;
					}
				});

				updateSegments(
					cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
					selectedContent.contentId,
					diffSegments,
					() => {
						setSelectedContent({ ...selectedContent, segments: updatedSegments });
						setIsUpdateLoading(false);
						setIsEditMode(false);
						refreshContent();
					}
				);
			} else {
				setIsUpdateLoading(false);
				setIsEditMode(false);
			}
		};

		const handleChangeText = useCallback((index, text) => {
			return (dataRef.current[index] = { ...dataRef.current[index], text });
		}, []);

		return (
			<>
				{drawPlayer()}
				<Stack sx={{ flexGrow: 1 }}>
					<Stack spacing={2} direction={'row'} sx={{ px: 4, py: 2 }}>
						<Stack
							spacing={1}
							direction={'row'}
							sx={theme => ({
								alignItems: 'center',
								width: '100%',
								height: '40px',
								border: `1px solid #E1E4E6`,
								backgroundColor: `#F9FAFB`,
								borderRadius: 1,
								px: 2,
								py: 1,
							})}
						>
							<SearchVsIcon style={{ width: '24px', height: '24px', fontSize: '17px' }} />
							<input
								type='text'
								value={keyword}
								onChange={e => {
									setKeyword(e.target.value);
									setIsTracking(false);
								}}
								onKeyDown={e => {
									if (e.key === 'Escape') {
										setKeyword('');
									} else if (e.key === 'Enter') {
										setSearchResult(prev => ({
											...prev,
											currentIndex: (prev.currentIndex + 1) % totalMatches,
										}));
									}
								}}
								placeholder='검색어를 입력해주세요.'
								style={{
									display: 'flex',
									flexGrow: 1,
									backgroundColor: 'hsla(210, 20%, 98%, 1)',
									border: 'none',
									minWidth: '0px',
									outline: 'none',
									fontSize: '16px',
									fontWeight: 400,
									lineHeight: '22px',
									boxSizing: 'border-box',
								}}
							/>

							<Stack
								spacing={1.25}
								direction={'row'}
								sx={{
									alignItems: 'center',
									userSelect: 'none',
									color: '#858c99',
									fontSize: '12px',
									minWidth: '106px',
								}}
							>
								{keyword && (
									<>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												cursor: 'pointer',
											}}
										>
											{totalMatches > 0 ? searchResult.currentIndex + 1 : 0} / {totalMatches}
										</Box>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												cursor: 'pointer',
											}}
											onClick={() => {
												setSearchResult(prev => ({
													...prev,
													currentIndex: (prev.currentIndex - 1 + totalMatches) % totalMatches,
												}));
												setIsTracking(false);
											}}
										>
											<ArrowIcon />
										</Box>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												cursor: 'pointer',
												transform: 'scaleY(-1)',
											}}
											onClick={() => {
												setSearchResult(prev => ({
													...prev,
													currentIndex: (prev.currentIndex + 1) % totalMatches,
												}));

												setIsTracking(false);
											}}
										>
											<ArrowIcon />
										</Box>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												cursor: 'pointer',
											}}
											onClick={() => {
												setKeyword('');
												setSearchResult({
													currentIndex: 0,
													total: 0,
												});
											}}
										>
											<ClearIcon />
										</Box>
									</>
								)}
							</Stack>
						</Stack>
						<Button
							sx={theme => ({
								minWidth: '58px',
								borderRadius: 0.5,
								py: 0.5,
								border: `1px solid #CED3D6`,
								color: '#4D5256',
							})}
						>
							편집
						</Button>
					</Stack>

					<Box
						className={`stt ${type} ${isPip ? 'pip' : ''} ${isEditMode ? 'edit' : ''}`}
						sx={{ display: 'flex', flex: '1 1 auto' }}
					>
						{/* <Box className='content' ref={dataContainerRef}>
							<Virtuoso
								ref={virtualRef}
								style={{ height: '100%' }}
								totalCount={isEditMode ? data.length : mergedTexts.length}
								itemContent={index => (
									<SttRowVS
										key={index}
										index={index}
										data={isEditMode ? data[index] : mergedTexts[index]}
										text={isEditMode ? dataRef.current[index].text : mergedTexts[index].text}
										onChangeText={(index, text) => handleChangeText(index, text)}
										onChangeSegmentSpeaker={onChangeSegmentSpeaker}
										speakerList={speakerInfo}
										onSeekTime={onSeekTime}
										editMode={isEditMode}
										onChangeMode={boolean => setIsEditMode(boolean)}
										noteRef={noteRef}
										editable={editable}
										isHighlight={
											highlights &&
											highlights[0] &&
											highlights[0].data &&
											highlights[0].data.some(
												highlight => highlight.segmentId === mergedTexts[index].segmentId
											)
										}
										refreshContent={refreshContent}
										keyword={keyword}
										textHighlighted={highlightedData}
										matchIndices={matchIndices}
										searchResult={searchResult}
										isLastElement={mergedTexts.length === index + 1}
									/>
								)}
							/>
						</Box>

						{!isEditMode && playing && !isTracking && (
							<Button
								//className='tracking'
								sx={{
									position: 'absolute',
									left: '42%',
									bottom: '70px',
									padding: '10px 15px',
									borderRadius: '10px',
									backgroundColor: '#fff',
									border: '1px solid #e9e9e9',
									cursor: 'pointer',
								}}
								onClick={() => setIsTracking(true)}
							>
								현재 위치로
							</Button>
						)} */}
					</Box>
				</Stack>
			</>
		);
	}
);

export default React.memo(Transcription);
