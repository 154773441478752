import { create } from 'zustand';

const useModalStore = create(set => ({
	isOpenModal: false,
	onToggleModal: () => {
		set(prev => ({ isOpenModal: !prev }));
	},
}));

export default useModalStore;
