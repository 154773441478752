import { useEffect, useState } from 'react';
import './TimbloLogin.css';
import axios from 'axios';

import { useCookies } from 'react-cookie';
import useAuthStore from '../store/AuthStore';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import { ReactComponent as Apple } from './img/apple.svg';
import { ReactComponent as Google } from './img/google.svg';

const TimbloLogin = ({ redirectPath = '/home' }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { login } = useAuthStore();
	const [cookies, setCookie, removeCookie] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const [mode, setMode] = useState(0); //0: 대기, 1: 가입요구, 2: 로그인 허용, 3: 장애 발생
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [isLoading, setLoading] = useState(false);
	const [message, setMessage] = useState(null);

	useEffect(() => {
		if (!email || '' === email) {
			setMode(0);
		}
	}, [email]);

	const onGoogleLogin = () => {
		console.log('[TimbloLogin] google login');
	};

	const onAppleLogin = () => {
		console.log('[TimbloLogin] apple login');
	};

	const onEmailLogin = e => {
		console.log('[TimbloLogin] email login');
		e.preventDefault();
		setLoading(true);
		login(
			{ email: email, password: password },
			tokens => {
				console.log('[TimbloLogin] login ok', tokens);

				const time =
					3600 *
					Number(process.env.REACT_APP_COOKIE_EXPIRE_HOUR ? process.env.REACT_APP_COOKIE_EXPIRE_HOUR : 1); //default 1시간
				const expiration = new Date(Date.now() + time * 1000);
				setCookie(process.env.REACT_APP_COOKIE_ALIAS, tokens, {
					path: '/',
					expires: expiration,
				});
				console.log('[TimbloLogin] save cookie to local storage', tokens);
				localStorage.setItem('timblo-cookie', JSON.stringify(tokens));

				const redirectUrl = searchParams.get('redirectUrl');
				const responseToken = searchParams.get('responseToken');

				// Redirect 로직 추가
				if (redirectUrl) {
					window.location.href = `${redirectUrl}?${
						responseToken ? `accessToken=${tokens.accessToken}&refreshToken=${tokens.refreshToken}` : ``
					}`;
				} else {
					const from = location.state?.pathname ?? redirectPath;
					navigate(from, { replace: true });
				}
			},
			code => {
				console.log('[TimbloLogin] login failed', code);
				switch (code) {
					case 403:
					case 506:
						setMessage('비밀번호가 일치하지 않거나 회원정보가 존재하지 않습니다.');
						break;
					default:
						setMessage(`장애가 발생하였습니다. (${code})`);
						break;
				}
			},
			() => {
				setLoading(false);
			}
		);
	};

	const checkEmail = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}/auth/email`, { email: email })
			.then(res => {
				switch (res.data.httpCode) {
					case 200:
						setMode(2);
						break;
					case 528:
						setMode(1);
						break;
					default:
						setMode(3);
						setMessage(`장애가 발생하였습니다. (${res.data.httpCode})`);
						break;
				}
			})
			.catch(() => {
				setMode(3);
				setMessage(`장애가 발생하였습니다. (400)`);
			});
	};

	const drawMode = () => {
		switch (mode) {
			case 0:
				return (
					<button
						className='continue-email-button'
						disabled={null !== email && '' !== email ? false : true}
						onClick={() => {
							checkEmail();
						}}
					>
						이메일로 계속하기
					</button>
				);

			case 1:
				return (
					<>
						<div>가입한 이메일 계정이 없습니다.</div>
						<button>해당 이메일로 가입하시겠어요?</button>
						<button className='continue-email-button' disabled={true}>
							이메일로 계속하기
						</button>
					</>
				);

			case 2:
				return (
					<>
						<div className='title'>비밀번호</div>
						<input
							type='text'
							placeholder='비밀번호'
							value={password}
							onChange={e => {
								setPassword(e.target.value);
							}}
						/>
						<button
							onClick={() => {
								onEmailLogin();
							}}
						>
							로그인
						</button>
					</>
				);

			default:
				return <></>;
		}
	};

	return (
		<div className='timblo-login-container'>
			{/* <div className='title'>팀블로 로그인</div> */}
			{/* <div className='login-buttons'>
				<button onClick={onGoogleLogin}>
					<Google />
					Google로 계속하기
				</button>
				<button onClick={onAppleLogin}>
					<Apple />
					Apple로 계속하기
				</button>
			</div> */}
			<div className='separator' />
			<div className='form'>
				<div className='title'>이메일</div>
				<input
					type='text'
					placeholder='이메일을 입력해주세요.'
					value={email}
					onChange={e => {
						setEmail(e.target.value);
					}}
				/>
				{message && <div className='message'>{message}</div>}
				{drawMode()}
			</div>

			{/* <div>
				<button
					onClick={() => {
						onGoogleLogin();
					}}
				>
					구글 로그인
				</button>
			</div>
			<div>
				<button
					onClick={() => {
						onAppleLogin();
					}}
				>
					애플 로그인
				</button>
			</div>
			<div>
				<input
					type='text'
					placeholder='이메일'
					value={email}
					onChange={e => {
						setEmail(e.target.value);
					}}
				/>
				<span>{message}</span>
				<div>
					{mode === 0 ? (
						<button
							className='continue-email-button'
							disabled={null !== email && '' !== email ? false : true}
							onClick={() => {
								checkEmail();
							}}
						>
							이메일로 계속하기
						</button>
					) : mode === 1 ? (
						<>
							<div>가입한 이메일 계정이 없습니다.</div>
							<button>해당 이메일로 가입하시겠어요?</button>
							<button className='continue-email-button' disabled={true}>
								이메일로 계속하기
							</button>
						</>
					) : mode === 2 ? (
						<div>
							<input
								type='text'
								placeholder='비밀번호'
								value={password}
								onChange={e => {
									setPassword(e.target.value);
								}}
							/>
							<button
								onClick={() => {
									onEmailLogin();
								}}
							>
								로그인
							</button>
						</div>
					) : (
						<div>Unknown</div>
					)}
				</div>
			</div> */}
		</div>
	);
};

export default TimbloLogin;
