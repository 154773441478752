import React, { useEffect, useLayoutEffect, useState } from 'react';
import './Display.css';

import Select from 'react-select';

import { ReactComponent as CalendarIcon } from './img/calendar.svg';
import { ReactComponent as ListIcon } from './img/list.svg';
import { ReactComponent as OrderIcon } from './img/order.svg';
import useDisplayStore from '../../../store/DisplayStore';
import useContentsStore from '../../../store/ContentsStore';

const ORDERS = [
	{ label: '최신 생성순', value: 'NEWEST_CREATE' },
	{ label: '오래된 생성순', value: 'OLDEST_CREATE' },
	{ label: '최신 수정순', value: 'NEWEST_UPDATE' },
	{ label: '제목 내림차순', value: 'TITLE_ASC' },
	{ label: '제목 오름차순', value: 'TITLE_DESC' },
];

const Display = () => {
	const [order, setOrder] = useState(null);
	const { displayType, onChangeType, orderType, onChangeOrderType } = useDisplayStore();
	const { onSortContents } = useContentsStore();

	useLayoutEffect(() => {
		const option = ORDERS.find(order => order.value === orderType) ?? ORDERS[0];
		setOrder(option);
	}, []);

	return (
		<div className='popup-display-container'>
			<div className='title'>보기 방식</div>
			<div className='separator' />
			<div className='buttons'>
				<button
					className={`${displayType === 'list' ? 'active' : ''}`}
					onClick={() => {
						onChangeType('list');
					}}
				>
					<ListIcon />
					<div>목록</div>
				</button>
				<button
					className={`${displayType === 'calendar' ? 'active' : ''}`}
					onClick={() => {
						onChangeType('calendar');
					}}
				>
					<CalendarIcon />
					<div>달력</div>
				</button>
			</div>
			{displayType === 'list' && (
				<>
					<div className='separator' />

					<div className='order'>
						<div className='icon'>
							<OrderIcon />
						</div>
						<div className='text'>정렬</div>
						<div className='select'>
							<Select
								options={ORDERS}
								value={order}
								defaultValue={order}
								onChange={obj => {
									setOrder(obj);
									onChangeOrderType(obj.value);
									onSortContents(obj.value);
								}}
								isSearchable={false}
								styles={{
									control: styles => ({
										...styles,
										height: 30,
										fontSize: 14,
										width: '125px',
									}),
									loadingIndicator: styles => ({
										...styles,
										display: 'none',
									}),
									dropdownIndicator: styles => ({
										...styles,
										width: '30px',
									}),
									indicatorSeparator: () => ({
										display: 'none',
									}),
									valueContainer: styles => ({
										...styles,
										paddingRight: 0,
									}),
								}}
							/>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Display;
