// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../node_modules/react-toastify/dist/ReactToastify.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
	--toastify-toast-width: auto !important;
}

.Toastify__toast {
	padding: 0 !important;
}

.Toastify__toast-body {
	padding: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/TostifyStyle.css"],"names":[],"mappings":"AAEA;CACC,uCAAuC;AACxC;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,qBAAqB;AACtB","sourcesContent":["@import url('react-toastify/dist/ReactToastify.css');\n\n:root {\n\t--toastify-toast-width: auto !important;\n}\n\n.Toastify__toast {\n\tpadding: 0 !important;\n}\n\n.Toastify__toast-body {\n\tpadding: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
