import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './SidebarV2.css';
import Avatar from 'react-avatar';
import Popup from 'reactjs-popup';

import { ReactComponent as ArrowIcon } from './img/arrow.svg';
import { ReactComponent as RecordIcon } from './img/record.svg';
import { ReactComponent as UploadIcon } from './img/upload.svg';
import { ReactComponent as HomeIcon } from './img/home.svg';
import { ReactComponent as ContentsIcon } from './img/contents.svg';
import { ReactComponent as SearchIcon } from './img/search.svg';
import { ReactComponent as InboxIcon } from './img/inbox.svg';
import { ReactComponent as IntegrateIcon } from './img/integrate.svg';
import { ReactComponent as MeetingIcon } from './img/meeting.svg';
import { ReactComponent as CalendarIcon } from './img/calendar.svg';
import { ReactComponent as RecycleIcon } from './img/recycle.svg';
import { ReactComponent as SettingIcon } from './img/setting.svg';
import { ReactComponent as LogoutIcon } from './img/logout.svg';
import { ReactComponent as BookmarkIcon } from './img/bookmark.svg';
import { ReactComponent as SKLogo } from './img/sk_logo.svg';

import useAuthStore from '../../store/AuthStore';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import PrivateSetting from '../popup/setting/action/privateSetting/PrivateSetting';

import { Modal } from '@mui/material';
import useContentsStore from '../../store/ContentsStore';
import { Toast, ToastInfo, ToastComponent, ToastError } from '../common/toast/Toast';
import { toast } from 'react-toastify';
import RecorderV2 from '../popup/record/RecorderV2';
import UploadOption from '../popup/upload/UploadOption';

const SidebarV2 = forwardRef(({ menus = [], onChangeMenu = () => {}, recorderControls, calendarRef }, ref) => {
	const inputRef = useRef();
	const recordRef = useRef();

	const navigate = useNavigate();

	const { auth, logout } = useAuthStore();
	const { fileUpload } = useContentsStore();
	const [cookies, removeCookie] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);

	const [selectedMenu, setSelectedMenu] = useState(menus[0]);
	const [selectedPopup, setSelectedPopup] = useState(null);

	const [fileType, setFileType] = useState(null);
	const [file, setFile] = useState(null);
	const [fileName, setFileName] = useState('');
	const [isOpenModal, setIsOpenModal] = useState(false);

	useImperativeHandle(ref, () => ({
		onClickUpload: () => inputRef.current.click(),
		onClickRecord: () => recordRef.current.click(),
	}));

	useEffect(() => {
		if (file) {
			setIsOpenModal(true);
		}
	}, [file]);

	const drawMenus = () => {
		const drawMenuIcon = menu => {
			switch (menu) {
				case '':
				case 'home':
					return <HomeIcon />;

				case 'contents':
					return <MeetingIcon />;

				case 'search':
					return <SearchIcon />;

				case 'inbox':
					return <InboxIcon />;

				case 'integrate':
					return <IntegrateIcon />;

				case 'calendar':
					return <CalendarIcon />;

				case 'recycle':
					return <RecycleIcon />;

				case 'setting':
					return <SettingIcon />;

				case 'logout':
					return <LogoutIcon />;

				case 'bookmark':
					return <BookmarkIcon />;

				default:
					return <>{menu}</>;
			}
		};

		const onClickMenu = menu => {
			setSelectedMenu(menu);

			onChangeMenu && onChangeMenu(menu);
		};

		return menus
			.filter(menu => menu.position === 'top')
			.map(menu => (
				<div
					key={menu.value}
					className={`menu ${selectedMenu === menu ? 'active' : ''}`}
					onClick={() => onClickMenu(menu)}
				>
					<div className='icon'>{drawMenuIcon(menu.value)}</div>
					<div className='text'>{menu.label}</div>
				</div>
			));
	};

	const drawBottomMenus = () => {
		const drawMenuIcon = menu => {
			switch (menu) {
				case 'setting':
					return <SettingIcon />;

				case 'logout':
					return <LogoutIcon />;

				default:
					return <>{menu}</>;
			}
		};

		const onClickMenu = menu => {
			if (menu.value === 'logout') {
				onLogout();
			}
		};

		return menus
			.filter(menu => menu.position === 'bottom')
			.map(menu =>
				menu.value === 'setting' ? (
					<Popup
						trigger={
							<div key={menu.value} className={`menu`}>
								<div className='icon'>{drawMenuIcon(menu.value)}</div>
								<div className='text'>{menu.label}</div>
							</div>
						}
						position={'top left'}
					>
						{close => <PrivateSetting onClose={() => close()} />}
					</Popup>
				) : (
					<div
						key={menu.value}
						className={`menu ${selectedMenu === menu ? 'active' : ''}`}
						onClick={() => onClickMenu(menu)}
					>
						<div className='icon'>{drawMenuIcon(menu.value)}</div>
						<div className='text'>{menu.label}</div>
					</div>
				)
			);
	};

	const onLogout = () => {
		logout(
			cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
			() => {
				navigate('/');
			},
			code => {
				console.log('logout failed', code);
			},
			() => {
				removeCookie(process.env.REACT_APP_COOKIE_ALIAS);
			}
		);
	};

	const handleFileChange = event => {
		const file = event.target.files[0];

		if (file) {
			setFile(file);
			setFileName(file.name);
			setFileType('file');
		}
	};

	const handleOnRecordComplete = (file, fileName) => {
		if (file && fileName) {
			setFile(file);
			setFileName(fileName);
			setFileType('record');
		}
	};

	const handleOnSubmit = options => {
		setIsOpenModal(false);

		if (file && fileName) {
			const currentToast = toast(<ToastComponent description={`${fileName} 업로드 중 - 0%`} />);

			fileUpload(
				cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
				file,
				fileName,
				options,
				null,
				auth,
				() => {},
				res => {
					if (res) {
						switch (res.httpCode) {
							case 401:
								ToastError(null, '권한이 없습니다.');
								break;

							default:
								ToastError(res.httpCode, res.message);
								break;
						}
					}
				},
				() => {
					setFile(null);
					setFileName('');
				},
				(id, progress) => {
					if (Number(progress) < 100) {
						toast.update(currentToast, {
							render: props => (
								<ToastComponent {...props} description={`${fileName} 업로드 중 - ${progress}%`} />
							),
						});
					} else {
						toast.update(currentToast, {
							render: props => <ToastComponent {...props} description={`${fileName} 업로드 완료`} />,
							autoClose: 3000,
						});
					}

					console.log('[progress]', progress);
				}
			);
		}
	};

	return (
		<div className='sidebar-container'>
			{auth && auth.member && auth.member.workspace ? (
				<>
					<div className='logo'>
						<SKLogo />
					</div>

					<div className='buttons'>
						<Popup
							trigger={
								<div className='button'>
									<div className='icon' ref={recordRef}>
										<RecordIcon />
									</div>
									<div className='text'>녹음</div>
								</div>
							}
							className='recorder-v2-popup'
							position={'bottom left'}
							keepTooltipInside='.main-container'
							nested
						>
							{close => (
								<RecorderV2
									recorderControls={recorderControls}
									onClose={close}
									onRecordComplete={handleOnRecordComplete}
								/>
							)}
							{/* {close => <Record onClose={close} />} */}
						</Popup>

						<div
							className='button'
							onClick={() => {
								if (inputRef && inputRef.current) {
									inputRef.current.click();
								}
							}}
						>
							<div className='icon'>
								<UploadIcon />
							</div>
							<div className='text'>파일 업로드</div>

							<input
								ref={inputRef}
								style={{ display: 'none' }}
								type='file'
								onChange={event => {
									handleFileChange(event);
									// 같은 파일을 다시 선택할 수 있도록 input 값을 초기화
									if (inputRef && inputRef.current) {
										inputRef.current.value = '';
									}
								}}
								accept='audio/*,video/*'
							/>
						</div>
					</div>
					<div className='menus'>
						{/* <div className='title'>전체 메뉴</div> */}
						<div className='menu-list'>{drawMenus()}</div>
						<div className='separator' />
						<div className='workspace'>
							<div className='avatar'>
								<Avatar name={auth.member.workspace.name} size='32' round='10px' color='#007AFF' />
							</div>
							<div className='title'>{auth.member.workspace.name}</div>
						</div>
						<div className='menu-list'>{drawBottomMenus()}</div>
					</div>
				</>
			) : (
				<div
					onClick={() => {
						onLogout();
					}}
				>
					Cannot find member
				</div>
			)}

			{isOpenModal && <UploadOption fileType={fileType} isOpen={isOpenModal} onSubmit={handleOnSubmit} />}
		</div>
	);
});

export default SidebarV2;
