// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-container {
	width: 100%;
	height: 100%;
	display: flex;
	gap: 10px;
	padding: 10px 40px;
	box-sizing: border-box;
	position: relative;
}

.content-container > .buttons {
	position: absolute;
	right: 40px;
	top: -55px;
	display: flex;
	gap: 5px;
}

.content-container > .left {
	min-width: 500px;
	flex: 0.6 1;
}

.content-container > * > .tabs {
	display: flex;
	gap: 20px;
	padding-bottom: 15px;
}

.content-container > .left > .view {
	overflow: auto;
	height: calc(100% - 57px);
}

.content-container > .right {
	max-width: 40%;
	flex: 0.4 1;
}

.content-container > .right > .view {
	height: 100%;
}

.content-container > .right > .tabs > .text {
	font-size: var(--font-size-lg, 16px);
	font-weight: var(--font-weight-semibold, 600);
	color: var(--main-title-text-color, #28323c);
	height: 40px;
}

.content-container > .infomation {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/content/Content.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;CACZ,aAAa;CACb,SAAS;CACT,kBAAkB;CAClB,sBAAsB;CACtB,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;CAClB,WAAW;CACX,UAAU;CACV,aAAa;CACb,QAAQ;AACT;;AAEA;CACC,gBAAgB;CAChB,WAAS;AACV;;AAEA;CACC,aAAa;CACb,SAAS;CACT,oBAAoB;AACrB;;AAEA;CACC,cAAc;CACd,yBAAyB;AAC1B;;AAEA;CACC,cAAc;CACd,WAAS;AACV;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,oCAAoC;CACpC,6CAA6C;CAC7C,4CAA4C;CAC5C,YAAY;AACb;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB","sourcesContent":[".content-container {\n\twidth: 100%;\n\theight: 100%;\n\tdisplay: flex;\n\tgap: 10px;\n\tpadding: 10px 40px;\n\tbox-sizing: border-box;\n\tposition: relative;\n}\n\n.content-container > .buttons {\n\tposition: absolute;\n\tright: 40px;\n\ttop: -55px;\n\tdisplay: flex;\n\tgap: 5px;\n}\n\n.content-container > .left {\n\tmin-width: 500px;\n\tflex: 0.6;\n}\n\n.content-container > * > .tabs {\n\tdisplay: flex;\n\tgap: 20px;\n\tpadding-bottom: 15px;\n}\n\n.content-container > .left > .view {\n\toverflow: auto;\n\theight: calc(100% - 57px);\n}\n\n.content-container > .right {\n\tmax-width: 40%;\n\tflex: 0.4;\n}\n\n.content-container > .right > .view {\n\theight: 100%;\n}\n\n.content-container > .right > .tabs > .text {\n\tfont-size: var(--font-size-lg, 16px);\n\tfont-weight: var(--font-weight-semibold, 600);\n\tcolor: var(--main-title-text-color, #28323c);\n\theight: 40px;\n}\n\n.content-container > .infomation {\n\twidth: 100%;\n\theight: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
