import React, { useCallback, useEffect, useRef, useState } from 'react';
import './Attendees.css';

import Avatar from 'react-avatar';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { debounce } from 'lodash';
import { components } from 'react-select';
import { useCookies } from 'react-cookie';
import { getAvatarColor } from '../../../util/Util';

import { ReactComponent as AddIcon } from './img/add.svg';
import { ReactComponent as DeleteIcon } from './img/delete.svg';

import useMemberStore from '../../../store/MemberStore';
import useAttendeeStore from '../../../store/AttendeeStore';
import useContentsStore from '../../../store/ContentsStore';
import Popup from 'reactjs-popup';
import DeleteConfirm from '../content/delete-confirm/DeleteConfirm';
import { ToastError, ToastInfo } from '../../common/toast/Toast';

const CustomOption = props => {
	return (
		<components.Option {...props}>
			<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
				<Avatar src={props.data.thumbnailUrl} name={props.data.value} round size={20} />

				{props.data.label}
			</div>
		</components.Option>
	);
};

const Attendees = ({ onChangeSpeakerCallback = () => {}, contentRef }) => {
	const timerRef = useRef();
	const inputRef = useRef();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { selectedContent } = useContentsStore();
	const { getMembersFromKeyword } = useMemberStore();
	const { attendees, onAddAttendee, onDeleteAttendee } = useAttendeeStore();

	const [inputValue, setInputValue] = useState('');

	const drawSpeakers = speaker => {
		const handleDeleteSpeaker = close => {
			if (contentRef?.current?.isEditable) {
				onDeleteAttendee(
					cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
					selectedContent.contentId,
					speaker.speakerId,
					() => {
						ToastInfo('삭제되었습니다.');
						onChangeSpeakerCallback();
						close();
					},
					response => ToastError(response.httpCode, response.message)
				);
			} else {
				ToastInfo('권한이 없습니다.');
			}
		};

		return (
			<div className='attendee'>
				<div className='avatar'>
					<Avatar
						src={speaker.thumbnailUrl}
						name={speaker.displayName ?? speaker.name}
						round
						size={20}
						color={getAvatarColor(speaker.speakerId).background}
						fgColor={getAvatarColor(speaker.speakerId).color}
						maxInitials={1}
					/>
				</div>
				<div className='name'>{speaker.displayName ?? speaker.name}</div>
				<Popup
					trigger={
						<div className='delete'>
							<DeleteIcon />
						</div>
					}
					position={'bottom right'}
					nested
				>
					{close => (
						<DeleteConfirm
							title={
								<>
									참석자<span>({speaker.displayName ?? speaker.name})</span>를 삭제하시겠습니까?
								</>
							}
							description={`삭제하시면 원본 기록에 지정한 참석자도 모두 삭제되니 유의하시기 바랍니다.`}
							onConfirm={() => handleDeleteSpeaker(close)}
							onCancel={close}
						/>
					)}
				</Popup>
			</div>
		);
	};

	// const debouncedPromiseOptions = debounce((inputValue, callback) => {
	// 	if (inputValue.trim()) {
	// 		new Promise(async resolve => {
	// 			if (
	// 				cookies &&
	// 				cookies[process.env.REACT_APP_COOKIE_ALIAS] &&
	// 				cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken
	// 			) {
	// 				const members = await getMembersFromKeyword(
	// 					cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
	// 					inputValue
	// 				);

	// 				const updatedMembers = members.map(member => ({
	// 					...member,
	// 					label: member.nickName,
	// 					value: member.nickName,
	// 				}));

	// 				resolve(updatedMembers);
	// 			} else {
	// 				resolve([]);
	// 			}
	// 		}).then(callback);
	// 	} else {
	// 		callback();
	// 		return [];
	// 	}
	// }, 500);

	const debouncedPromiseOptions = (inputValue, callback) => {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}

		timerRef.current = setTimeout(async () => {
			if (inputValue.trim()) {
				if (
					cookies &&
					cookies[process.env.REACT_APP_COOKIE_ALIAS] &&
					cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken
				) {
					const members = await getMembersFromKeyword(
						cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
						inputValue
					);

					const updatedMembers = members.map(member => ({
						...member,
						label: member.nickName,
						value: member.nickName,
					}));

					callback(updatedMembers);
				} else {
					callback([]);
				}
			} else {
				callback([]);
			}
		}, 500);
	};

	const handleCreateAttendee = name => {
		const data = {
			displayName: name,
		};

		onAddAttendee(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, selectedContent.contentId, data, () => {
			setInputValue('');
			onChangeSpeakerCallback();
		});
	};

	const handleSelectAttendee = option => {
		const data = {
			pid: option.pid,
			name: option.nickName,
		};

		onAddAttendee(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, selectedContent.contentId, data, () => {
			setInputValue('');
			onChangeSpeakerCallback();
		});
	};

	return (
		<div className='popup-attendee-list-container'>
			<div className='title'>참석자</div>
			<div className='separator' />
			<div className='input'>
				<div className='avatar'>
					<AddIcon />
				</div>

				<AsyncCreatableSelect
					ref={inputRef}
					isDisabled={!contentRef?.current?.isEditable ?? true}
					components={{
						DropdownIndicator: () => null,
						IndicatorSeparator: () => null,
						LoadingIndicator: () => null,
						Option: CustomOption,
					}}
					cacheOptions
					defaultOptions
					loadOptions={debouncedPromiseOptions}
					inputValue={inputValue}
					onInputChange={e => setInputValue(e)}
					placeholder='참석자 입력'
					noOptionsMessage={() => null}
					onCreateOption={handleCreateAttendee}
					onChange={handleSelectAttendee}
					formatCreateLabel={inputValue => `"${inputValue}" 추가`}
					styles={{
						container: (baseStyles, state) => ({
							...baseStyles,
							border: '1px solid transparent',
							width: '100%',
						}),
						control: (baseStyles, state) => ({
							...baseStyles,
							border: '1px solid transparent',
							width: '100%',
							boxShadow: 'none',
							'&:hover': {
								borderColor: 'none',
							},
							cursor: 'text',
							paddingLeft: 0,
						}),
						input: (baseStyles, state) => ({
							...baseStyles,
							margin: 0,
							maxWidth: '140px',
						}),

						valueContainer: baseStyles => ({
							...baseStyles,
							margin: 0,
							padding: 0,
							width: '80px !important',
						}),
						loadingIndicator: baseStyles => ({
							...baseStyles,
							display: 'none',
						}),
						placeholder: baseStyles => ({
							...baseStyles,
							width: '80px',
						}),
					}}
				/>
			</div>
			<div className='list'>
				{attendees && attendees.length ? (
					attendees.map(speaker => drawSpeakers(speaker))
				) : (
					<div className='empty'>참석자 없음</div>
				)}
			</div>
		</div>
	);
};

export default Attendees;
