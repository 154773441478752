// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-box-container {
	width: calc(100% - 10px);
	display: inline-flex;
	padding: 20px 25px;
	gap: 20px;
	flex-direction: column;
	border-radius: 10px;
	border: 0.4px solid transparent;
	box-sizing: border-box;
	margin-bottom: 10px;
	transition: border 0.2s ease-in-out;
	background-color: #fff;
}

.text-box-container:last-child {
	margin-bottom: 0;
}

.text-box-container:hover {
	border: 1px solid #c5c8ce;
	transition: border 0.2s ease-in-out;
}

.text-box-container:hover .dot {
	display: flex !important;
	justify-content: center;
}

.text-box-container > .title > .box {
	display: flex;
	justify-content: space-between;
	font-size: 16px;
	font-weight: 600;
	color: #28323c;
	width: 100%;
}

.text-box-container > .title > .box > .text {
	flex: 1 1;
}

.text-box-container > .title > .box > .dot {
	width: 20px;
	display: none;
	cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/text-box/TextBox.css"],"names":[],"mappings":"AAAA;CACC,wBAAwB;CACxB,oBAAoB;CACpB,kBAAkB;CAClB,SAAS;CACT,sBAAsB;CACtB,mBAAmB;CACnB,+BAA+B;CAC/B,sBAAsB;CACtB,mBAAmB;CACnB,mCAAmC;CACnC,sBAAsB;AACvB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,yBAAyB;CACzB,mCAAmC;AACpC;;AAEA;CACC,wBAAwB;CACxB,uBAAuB;AACxB;;AAEA;CACC,aAAa;CACb,8BAA8B;CAC9B,eAAe;CACf,gBAAgB;CAChB,cAAc;CACd,WAAW;AACZ;;AAEA;CACC,SAAO;AACR;;AAEA;CACC,WAAW;CACX,aAAa;CACb,eAAe;AAChB","sourcesContent":[".text-box-container {\n\twidth: calc(100% - 10px);\n\tdisplay: inline-flex;\n\tpadding: 20px 25px;\n\tgap: 20px;\n\tflex-direction: column;\n\tborder-radius: 10px;\n\tborder: 0.4px solid transparent;\n\tbox-sizing: border-box;\n\tmargin-bottom: 10px;\n\ttransition: border 0.2s ease-in-out;\n\tbackground-color: #fff;\n}\n\n.text-box-container:last-child {\n\tmargin-bottom: 0;\n}\n\n.text-box-container:hover {\n\tborder: 1px solid #c5c8ce;\n\ttransition: border 0.2s ease-in-out;\n}\n\n.text-box-container:hover .dot {\n\tdisplay: flex !important;\n\tjustify-content: center;\n}\n\n.text-box-container > .title > .box {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tfont-size: 16px;\n\tfont-weight: 600;\n\tcolor: #28323c;\n\twidth: 100%;\n}\n\n.text-box-container > .title > .box > .text {\n\tflex: 1;\n}\n\n.text-box-container > .title > .box > .dot {\n\twidth: 20px;\n\tdisplay: none;\n\tcursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
