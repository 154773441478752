import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import styled from 'styled-components';
import { HyperLink, Input, Label } from './LoginComponents';
import ConfirmButton from '../../components/button/ConfirmButton';
import { useCookies } from 'react-cookie';
import useAuthStore from '../../store/AuthStore';

const EmailLoginForm = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [mode, setMode] = useState(0); //0: 대기, 1: 가입요구, 2: 로그인 허용, 3: 장애 발생
	const [message, setMessage] = useState(null);
	const [isLoading, setLoading] = useState(false);
	const { auth, login, setCookies } = useAuthStore();
	const [cookies, setCookie, removeCookie] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const [searchParams] = useSearchParams();

	useEffect(() => {
		setMode(0);
	}, [email]);

	const onEmailContinue = e => {
		e.preventDefault();

		//중복확인을 해본 후 결정
		checkEmail();
	};

	const onJoin = e => {
		navigate('/join?email=' + email);
	};

	const onLogin = e => {
		e.preventDefault();

		setLoading(true);
		login(
			{ email: email, password: password },
			tokens => {
				console.log('[Login] login ok', tokens);

				const time =
					3600 *
					Number(process.env.REACT_APP_COOKIE_EXPIRE_HOUR ? process.env.REACT_APP_COOKIE_EXPIRE_HOUR : 1); //default 1시간
				const expiration = new Date(Date.now() + time * 1000);
				setCookie(process.env.REACT_APP_COOKIE_ALIAS, tokens, {
					path: '/',
					expires: expiration,
				});
				console.log('[Login] save cookie to local storage', tokens);
				localStorage.setItem('timblo-cookie', JSON.stringify(tokens));

				const redirectUrl = searchParams.get('redirectUrl');
				const responseToken = searchParams.get('responseToken');

				// Redirect 로직 추가
				if (redirectUrl) {
					window.location.href = `${redirectUrl}?${
						responseToken ? `accessToken=${tokens.accessToken}&refreshToken=${tokens.refreshToken}` : ``
					}`;
				} else {
					const from = location.state?.pathname ?? '/home';

					navigate(from, { replace: true });
				}
			},
			code => {
				console.log('login failed', code);
				switch (code) {
					case 403:
					case 506:
						setMessage('비밀번호가 일치하지 않거나 회원정보가 존재하지 않습니다.');
						break;
					default:
						setMessage(`장애가 발생하였습니다. (${code})`);
						break;
				}
			},
			() => {
				setLoading(false);
			}
		);
	};

	const checkEmail = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}/auth/email`, { email: email })
			.then(res => {
				switch (res.data.httpCode) {
					case 200:
						setMode(2);
						break;
					case 528:
						setMode(1);
						break;
					default:
						setMode(3);
						setMessage(`장애가 발생하였습니다. (${res.data.httpCode})`);
						break;
				}
			})
			.catch(() => {
				setMode(3);
				setMessage(`장애가 발생하였습니다. (400)`);
			});
	};

	const drawForm = () => {
		switch (mode) {
			case 0:
				return (
					<>
						{message && (
							<Label style={{ marginTop: '10px' }} color={'#F9463B'}>
								{message}
							</Label>
						)}
						<ConfirmButton
							isActive={email}
							type='submit'
							onClick={onEmailContinue}
							label='이메일로 계속하기'
						/>
					</>
				);
				break;
			case 1:
				return (
					<>
						<Label color={'#F9463B'}>가입한 이메일 계정이 없습니다.</Label>
						<HyperLink onClick={onJoin}>해당 이메일로 가입하시겠어요?</HyperLink>

						{message && (
							<Label style={{ marginTop: '10px' }} color={'#F9463B'}>
								{message}
							</Label>
						)}
						<ConfirmButton
							isActive={true}
							type='submit'
							onClick={onEmailContinue}
							label={'이메일로 계속하기'}
						/>
					</>
				);
			case 2:
				return (
					<>
						<Label htmlFor='password' style={{ marginTop: '10px' }}>
							비밀번호
						</Label>
						<Input
							type='password'
							id='password'
							placeholder='비밀번호를 입력해주세요.'
							aria-label='비밀번호를 입력해주세요.'
							disabled={isLoading}
							onChange={e => {
								setPassword(e.target.value);
							}}
						/>
						{message && (
							<Label style={{ marginTop: '10px' }} color={'#F9463B'}>
								{message}
							</Label>
						)}
						<ConfirmButton
							isActive={password}
							type='submit'
							onClick={onLogin}
							isLoading={isLoading}
							label={'로그인'}
						/>
					</>
				);
			default:
				return null;
		}
	};

	return (
		<form>
			<InputWrapper>
				<Label htmlFor='email'>이메일</Label>
				<Input
					type='email'
					id='email'
					placeholder='이메일을 입력해주세요.'
					aria-label='이메일을 입력해주세요.'
					onChange={e => {
						setEmail(e.target.value);
					}}
				/>
				{drawForm()}
			</InputWrapper>
		</form>
	);
};

const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	font-family: Noto Sans, sans-serif;
	color: var(--grey-grey400, #858c99);
`;

const SubmitButton = styled.button`
	width: 100%;
	border-radius: 5px;
	background-color: var(--primary-primary100, #005cff);
	color: var(--primary-primary400, #ffffff);
	text-align: center;
	padding: 10px 0;
	font: 700 16px Noto Sans, sans-serif;
	border: none;
	cursor: pointer;
	margin-top: 20px;
	border: 1px solid #c5c8ce;
	&:disabled {
		background-color: var(--primary-primary100, #f5f8ff);
		color: var(--primary-primary400, #80adff);
	}
`;

export default EmailLoginForm;
