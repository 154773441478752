import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import './Content.css';

import TabMenu from '../body/tabMenu/TabMenu';
import Transcription from './transcription/Transcription';
// import Transcription from './transcription/TranscriptionOrigin';
import ChronologicalSummary from './chronologicalSummary/ChronologicalSummary';
import useContentsStore from '../../store/ContentsStore';
import { useCookies } from 'react-cookie';
import { ToastError, ToastInfo } from '../common/toast/Toast';
import { PageLoading, SttMaking } from '../page-status/PageLoading';
import SummaryV2 from './summaryV2/SummaryV2';
import Highlights from '../../pages/home/Highlights';
import Note from './note/Note';
import Empty from '../page-status/Empty';
import useAttendeeStore from '../../store/AttendeeStore';
import { useLocation, useNavigate } from 'react-router-dom';
import MyTemplate from './template/MyTemplate';
import Tooltip from '../tooltip/Tooltip';
import HeaderV2 from '../body/headerV2/HeaderV2';
import { drawContentTypeV2 } from '../common/ContentIcon/ContentIcon';
import useAuthStore from '../../store/AuthStore';
import moment from 'moment';
import TimbloButton from '../button/TimbloButton';
import Popup from 'reactjs-popup';
import Download from '../popup/download/Download';
import DeleteConfirm from '../popup/content/delete-confirm/DeleteConfirm';
import Share from '../popup/share/Share';
import ReSummary from '../popup/resummary/ReSummary';
import ShareButton from '../common/button/ShareButton';

const LEFT_TABS = [
	{ label: '요약', value: 'summary' },
	// {label : '대화 분석', value : 'analysis'},
	{ label: '구간 요약', value: 'chronological' },
	{ label: '하이라이트', value: 'highlight' },
	{ label: '노트', value: 'note' },
	// { label: 'My 템플릿', value: 'template' },
];

const RIGHT_TABS = [{ label: '원본 기록', value: 'origin' }];

const Content = forwardRef(({ source = 'none' }, ref) => {
	const navigate = useNavigate();

	const noteRef = useRef();
	const transcriptionRef = useRef();
	const location = useLocation();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const [currentLeftTab, setCurrentLeftTab] = useState(LEFT_TABS[0]);
	const [currentRightTab, setCurrentRightTab] = useState(RIGHT_TABS[0]);
	const [contentId, setContentId] = useState(null);
	const [content, setContent] = useState(null);
	const [isEditable, setIsEditable] = useState(false);
	const [status, setStatus] = useState({ code: 200, message: 'Not found' });
	const [isLoading, setLoading] = useState(true);
	const { auth } = useAuthStore();
	const {
		getContents,
		sttStatus,
		selectedContent,
		setSelectedContent,
		refreshContentFromContentId,
		updateContentTitle,
		deleteContents,
	} = useContentsStore();
	const { refreshAttendees } = useAttendeeStore();

	const makeStreamUrl = useCallback(
		id => {
			let url = '';

			if (
				cookies[process.env.REACT_APP_COOKIE_ALIAS] &&
				cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken
			) {
				if (id) {
					url = `${process.env.REACT_APP_API_URL}/contents/download/${id}?accessToken=${
						cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken
					}`;
				}
			}

			return url;
		},
		[cookies]
	);

	useImperativeHandle(ref, () => ({
		content: content,
		isEditable: isEditable,
		refreshContent: refreshContent,
		getMergedText: getMergedText,
	}));

	useEffect(() => {
		if (sttStatus.list) {
			const item = sttStatus.list.find(item => item.contentId === contentId);

			if (item) {
				switch (item.status.toUpperCase()) {
					case 'DONE':
					case 'ERROR':
						refreshContent();
						setLoading(false);
						break;
					default:
						setLoading(true);
						break;
				}
			}
		}
	}, [sttStatus.list]);

	useLayoutEffect(() => {
		if (selectedContent) {
			// content 조회

			console.log('[Content] id', selectedContent.contentId);
			setContentId(selectedContent.contentId);
		} else {
			const uuid = location.pathname.split('/')[2] ?? null;

			if (uuid) {
				setContentId(uuid);
				refreshContentFromContentId(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, uuid).then(
					res => res && setSelectedContent(res)
				);
			}
		}
	}, [location]);

	useEffect(() => {
		return () => {
			setSelectedContent(null);
		};
	}, []);

	useEffect(() => {
		if (contentId) {
			refreshContent();
			refreshAttendees(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId);
		}
	}, [contentId]);

	const getMergedText = () => {
		return transcriptionRef.current.mergedTexts;
	};

	const refreshContent = useCallback(() => {
		getContents(
			cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
			contentId,
			selectedContent?.source ?? source
		)
			.then(data => {
				switch (data.code) {
					case 200:
						setStatus({ code: 200, message: 'OK' });
						setContent(data.data);
						setIsEditable(data.data.meta.permission === 'VIEWER' ? false : true);

						break;
					case 403:
					case 404:
						setStatus({ code: data.code, message: 'Loading...' });
						break;

					case 422:
						setStatus({ code: data.code, message: 'Stt Making' });
						break;

					case 423:
						//조회할 수 없는 컨텐츠입니다.
						setStatus({ code: data.code, message: 'Error' });
						break;
					default:
						//장애 발생
						setStatus({ code: data.code, message: 'Something happened' });
						ToastError(1002);
						break;
				}
			})
			.catch(e => {
				setStatus({ code: e.code, message: e.message });
			})
			.finally(() => setLoading(false));
	}, [contentId]);

	const drawLeftView = () => {
		if (currentLeftTab) {
			switch (currentLeftTab.value) {
				case 'summary':
				case 'aiSummary': {
					const aiResult = { ...content.aiResult };
					delete aiResult['speakerSummary'];

					// 데이터가 존재하지 않는 필드의 경우 키 삭제
					Object.keys(aiResult).forEach(key => {
						if (Array.isArray(aiResult[key]) && aiResult[key].length === 0) {
							delete aiResult[key];
						}
					});

					return (
						<SummaryV2
							data={{
								...aiResult,
								speakerInfo: content.speakerInfo,
							}}
							contentId={contentId}
							highlights={content.highlights}
							onToggleHighlight={refreshContent}
							editable={isEditable}
						/>
					);
				}

				case 'chronological':
					return (
						<ChronologicalSummary
							data={content.summaryTime}
							highlights={content.highlights.filter(highlight => highlight.key === 'summaryTime')}
							editable={isEditable}
							contentId={contentId}
							refreshContent={refreshContent}
							onSeekTime={startSec => transcriptionRef?.current?.onSeekTime(startSec)}
						/>
					);

				case 'highlight':
					return (
						<Highlights
							data={content.highlights.map(highlight => ({
								...highlight,
								contentId,
							}))}
							onToggleHighlight={refreshContent}
							editable={isEditable}
						/>
					);

				case 'note':
					return (
						<Note
							ref={noteRef}
							contentId={content.meta.contentId}
							editable={content.meta.permission !== 'VIEWER'}
						/>
					);

				case 'template':
					return <MyTemplate />;
			}
		}
	};

	const drawContentStatus = () => {
		switch (status.code) {
			case 404:
				return <Empty type='contents-result' />;

			case 413:
				return <Empty type='deleted-content' />;

			case 422:
				return <SttMaking />;

			case 423:
			default:
				return <Empty type='error' />;
		}
	};

	return (
		<>
			{content && (
				<HeaderV2
					style={{ background: '#f9fafb' }}
					text={content && content.meta ? content.meta.editedTitle ?? content.meta.title : ''}
					description={
						content && content.file
							? `생성일 ${moment(content.file.createAt)
									.locale('ko')
									.format('YY.MM.DD LT')} |  최종 수정일 ${moment(content.file.updateAt)
									.locale('ko')
									.format('YY.MM.DD LT')}  |  ${content.meta.lastUpdator}`
							: ''
					}
					icon={drawContentTypeV2(auth, content?.meta, 48, true)}
					enableEdit
					onChangeTitle={(text, onSuccess, onError) =>
						updateContentTitle(
							cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
							content.meta.contentId,
							text
						)
							.then(() => {
								onSuccess && onSuccess();
							})
							.catch(() => {
								console.log('??????');
								console.log(onError);
								onError && onError();
							})
					}
					buttonComponents={
						<>
							{/* <Popup
								trigger={<TimbloButton label={'재요약'} alias='refresh' />}
								position={'bottom right'}
								arrow={false}
								// nested
							>
								{close => <ReSummary onSummary={type => close()} />}
							</Popup> */}

							<Popup
								trigger={<TimbloButton label={'공유'} alias='share' />}
								position={'bottom right'}
								arrow={false}
								nested
							>
								<Share
									contentId={content.meta.contentId}
									contentRef={ref}
									permission={content.meta.permission}
								/>
							</Popup>
							<Popup
								trigger={<TimbloButton label={'다운로드'} alias='download' />}
								position={'bottom right'}
								arrow={false}
								nested
							>
								<Download contentRef={ref} />
							</Popup>

							<Popup
								trigger={<TimbloButton label={'삭제'} alias='delete' />}
								position={'bottom right'}
								arrow={false}
								nested
							>
								{close => (
									<DeleteConfirm
										title='콘텐츠를 삭제하시겠습니까?'
										onCancel={close}
										onConfirm={() => {
											if (selectedContent.userAccessRole === 'OWNER') {
												deleteContents(
													cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
													{
														contentId: selectedContent.contentId,
													}
												)
													.then(() => {
														setSelectedContent(null);
														ToastError(
															0,
															'선택한 콘텐츠가 삭제되었습니다.',
															'콘텐츠 삭제 완료'
														);

														navigate(`/contents`, { replace: true });
													})
													.catch(() => {
														ToastError(null, '권한이 없습니다.');
													});
											} else {
												ToastError(null, '권한이 없습니다.');
											}
										}}
									/>
								)}
							</Popup>
						</>
					}
				/>
			)}

			<div className='content-container'>
				{isLoading ? (
					<div className='infomation'>
						<PageLoading />
					</div>
				) : (
					<>
						{status.code !== 200 ? (
							<div className='infomation'>{drawContentStatus()}</div>
						) : (
							<>
								<div className='left'>
									<div className='tabs'>
										{LEFT_TABS.map((tab, idx) => (
											<TabMenu
												key={idx}
												text={tab.label}
												backgroundColor='transparent'
												onClick={text => {
													const targetTab = LEFT_TABS.find(tab => tab.label === text);
													setCurrentLeftTab(targetTab);
												}}
												style={
													currentLeftTab.label === tab.label
														? {
																background: 'transparent',
																color: '#A2A7B1',
																border: '1px solid #A2A7B1',
														  }
														: {}
												}
											/>
										))}
									</div>
									<div className='view'>{content && drawLeftView()}</div>
								</div>
								<div className='right'>
									<div className='tabs'>
										<div className='text'>원본 기록</div>

										{/* {RIGHT_TABS.map(tab => (
										<TabMenu
											text={tab.label}
											backgroundColor='transparent'
											onClick={text => {
												const targetTab = RIGHT_TABS.find(tab => tab.label === text);
												setCurrentRightTab(targetTab);
											}}
											style={
												currentRightTab.label === tab.label
													? { background: '#2A53FF', color: '#FFFFFF' }
													: {}
											}
										/>
									))} */}
									</div>

									<div className='view'>
										{content && content.file && (
											<Transcription
												ref={transcriptionRef}
												type={content.file.mimeType.split('/')[0]}
												source={makeStreamUrl(contentId)}
												data={content.segments}
												onChangeSegmentSpeaker={refreshContent}
												speakerInfo={content.speakerInfo}
												noteRef={noteRef}
												editable={content.meta.permission !== 'VIEWER'}
												refreshContent={refreshContent}
												highlights={content.highlights.filter(
													highlight => highlight.key === 'segments'
												)}
											/>
										)}
									</div>
								</div>
							</>
						)}
					</>
				)}
			</div>
		</>
	);
});

export default Content;
