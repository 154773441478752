import React, { useRef, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton, Paper, Stack, SvgIcon, Tooltip, Typography, Popover, Collapse } from '@mui/material';
import { ReactComponent as CopyIcon } from './img/copy.svg';
import { ReactComponent as PasteIcon } from './img/paste.svg';
import { ReactComponent as HighlightIcon } from './img/highlight.svg';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const SummaryBlock = ({
	title = 'Unknown',
	isBookmark,
	info = '',
	children,
	enableContextMenu,
	onToggleBookmark = () => {},
	onCopyClipboard = () => {},
	onPasteNote = () => {},
}) => {
	const boxRef = useRef();

	const [isOpen, setIsOpen] = useState(true);
	const [isHover, setIsHover] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	return (
		<Box
			ref={boxRef}
			sx={{
				width: '100%',
				flexShrink: 0,
				padding: '24px 16px',
				display: 'inline-flex',
				flexDirection: 'column',
				borderBottom: `0.6px solid #E1E4E6`,
				boxSizing: 'border-box',
				background: isBookmark ? '#F5FEF5 !important' : isHover ? '#F9FAFB' : '#fff',
				transition: `background 0.3s ease-in-out`,
			}}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<Stack spacing={1} direction='row' sx={{ height: 24, alignItems: 'center' }}>
				<Typography sx={{ fontSize: 20, fontWeight: 600, color: '#292A2B', fontFamily: 'Noto Sans KR' }}>
					{title}
				</Typography>
				<Tooltip title={info}>
					<SvgIcon inheritViewBox sx={{ width: 20, height: '100%' }}>
						<InfoOutlinedIcon sx={{ color: '#A9AFB3' }} />
					</SvgIcon>
				</Tooltip>

				{enableContextMenu && isHover && (
					<IconButton
						sx={{
							width: 24,
							height: 24,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							border: '1px solid #E1E4E6',
							borderRadius: '4.8px',
							boxSizing: 'border-box',
							marginLeft: 'auto !important',

							'&:hover': {
								border: '1px solid #5793FF',
							},

							'&:hover > svg': {
								color: '#5793FF',
							},
						}}
						onClick={event => setAnchorEl(event.currentTarget)}
					>
						<MoreVertIcon fontSize={'small'} />
					</IconButton>
				)}
				<IconButton
					sx={{ marginLeft: isHover ? 'none !important' : 'auto !important' }}
					onClick={e => {
						e.stopPropagation();
						setIsOpen(prev => !prev);
					}}
				>
					{isOpen ? <KeyboardArrowDownIcon sx={{ rotate: '180deg' }} /> : <KeyboardArrowDownIcon />}
				</IconButton>

				<Popover
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					onClose={() => setAnchorEl(null)}
					anchorOrigin={{
						vertical: 30,
						horizontal: 0,
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 125,
					}}
				>
					<ContextMenu
						isBookmark={isBookmark}
						onToggleBookmark={onToggleBookmark}
						onCopyClipboard={() => onCopyClipboard(boxRef.current.innerText)}
						onPasteNote={onPasteNote}
						onClose={() => setAnchorEl(null)}
					/>
				</Popover>
			</Stack>

			<Collapse in={isOpen} timeout='auto' sx={{ transition: 'all 0.3s ease-in-out' }}>
				<Paper sx={{ background: 'inherit', boxShadow: 'none', paddingTop: '25px' }}>{children}</Paper>
			</Collapse>
		</Box>
	);
};

export default SummaryBlock;

const ContextMenu = ({ isBookmark, onToggleBookmark, onCopyClipboard, onPasteNote, onClose }) => {
	return (
		<Box
			sx={{
				width: 150,
				height: 136,
				boxSizing: 'border-box',
				border: `1px solid rgba(206, 211, 214, 1)`,
				borderRadius: '5px',
			}}
		>
			<Stack direction='column'>
				<Paper
					sx={{
						boxShadow: 'none',
						height: 44,
						display: 'flex',
						alignItems: 'center',
						padding: '10px',
						gap: '8px',
						boxSizing: 'border-box',
						borderRadius: '8px 8px 0 0',
						boxShadow: 'none',
						cursor: 'pointer',
						'&:hover': {
							backgroundColor: '#F0F0F0',
						},
					}}
					onClick={() => {
						onToggleBookmark();
						onClose();
					}}
				>
					<SvgIcon inheritViewBox sx={{ width: 24, height: 24 }}>
						<HighlightIcon />
					</SvgIcon>
					<Typography sx={{ fontSize: 14, fontWeight: 500, fontFamily: 'Noto Sans KR' }}>
						{isBookmark ? '북마크 제거' : '북마크 추가'}
					</Typography>
				</Paper>

				<Paper
					sx={{
						boxShadow: 'none',
						height: 44,
						display: 'flex',
						alignItems: 'center',
						padding: '10px',
						gap: '8px',
						boxSizing: 'border-box',
						// borderRadius: '8px',
						boxShadow: 'none',
						cursor: 'pointer',
						'&:hover': {
							backgroundColor: '#F0F0F0',
						},
					}}
					onClick={() => {
						onCopyClipboard();
						onClose();
					}}
				>
					<SvgIcon inheritViewBox sx={{ width: 24, height: 24 }}>
						<CopyIcon />
					</SvgIcon>
					<Typography sx={{ fontSize: 14, fontWeight: 500, fontFamily: 'Noto Sans KR' }}>
						내용 복사
					</Typography>
				</Paper>
				<Paper
					sx={{
						boxShadow: 'none',
						height: 44,
						display: 'flex',
						alignItems: 'center',
						padding: '10px',
						gap: '8px',
						boxSizing: 'border-box',
						borderRadius: '0 0 8px 8px',
						boxShadow: 'none',
						cursor: 'pointer',
						'&:hover': {
							backgroundColor: '#F0F0F0',
						},
					}}
					onClick={() => {
						onPasteNote();
						onClose();
					}}
				>
					<SvgIcon inheritViewBox sx={{ width: 24, height: 24 }}>
						<PasteIcon />
					</SvgIcon>
					<Typography sx={{ fontSize: 14, fontWeight: 500, fontFamily: 'Noto Sans KR' }}>
						노트에 붙여넣기
					</Typography>
				</Paper>
			</Stack>
		</Box>
	);
};
