import React from 'react';
import './DateBox.css';

import moment from 'moment';
// import Item from "./Item";
import Item from '../common/list-item/Item';

const getDiffDays = targetDate => {
	const today = moment();
	const target = moment(targetDate);

	return today.diff(target, 'days');
};
const DateBox = ({
	date,
	data,
	dateFormat = `${getDiffDays(date) === 0 ? '오늘 ' : getDiffDays(date) === 1 ? '어제 ' : ''}M/DD (ddd)`,
	onClickContent,
	onDownload,
	onDelete,
}) => {
	const getDateText = () => {
		if (date) {
			return moment(date).format(dateFormat);
		} else {
			return 'Invaild';
		}
	};

	return (
		<div className='date-box-container'>
			{data && data.length ? (
				<>
					<div className={`date`}>
						{getDateText()}
						<div className='separator' />
					</div>
					<div className={`items`}>
						{data &&
							data.length &&
							data.map(item => (
								<Item
									key={item.contentId}
									data={item}
									onClickContent={onClickContent}
									onDownload={onDownload}
									onDelete={onDelete}
									isDisabled={item.isDisabled}
									isList
								/>
							))}
					</div>
				</>
			) : (
				<></>
			)}
		</div>
	);
};

export default DateBox;
