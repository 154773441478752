import React, { useEffect, useRef, useState } from 'react';
import useContentsStore from './store/ContentsStore';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import JoinPage from './pages/login/JoinPage';

import './App.css';
// import Search from './components/search/Search';
import LoginPageV3 from './pages/login/LoginPageV3';
import ValidLayout from './pages/login/ValidLayout';
import Content from './components/content/Content';
import CheckCookies from './pages/login/CheckCookies';
import ListV2 from './components/list/ListV2';
import TimbloAuth from './pages/auth/TimbloAuth';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastError } from './components/common/toast/Toast';
import RecycleV2 from './pages/recycle/RecycleV2';
import HomeV2 from './pages/home/Homev2';
import Bookmark from './pages/bookmark/BookmarkPage';
import InboxV2 from './pages/inbox/InboxV2';
import ListV3 from './components/list/ListV3';

import { createTheme, CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import SearchV2 from './pages/search/SearchV2';
import ContentV2 from './components/content/ContentV2';
import BookmarkPage from './pages/bookmark/BookmarkPage';
import JoinPageV2 from './pages/login/JoinPageV2';

// import { AppProvider } from '@toolpad/core';

const AppV2 = () => {
	const sidebarRef = useRef();
	const contentRef = useRef();
	const calendarRef = useRef();

	const { setSelectedContent } = useContentsStore();

	useEffect(() => {
		const onToastError = event => {
			console.error(event);
			ToastError(null, '처리 중 장애가 발생했습니다.');
		};

		// 새로고침 전에 이벤트 리스너 추가
		window.addEventListener('error', onToastError);

		return () => {
			window.removeEventListener('error', onToastError);
		};
	}, []);

	const lightTheme = createTheme({
		palette: {
			mode: 'light', // 여기에서 "dark"로 변경하여 다크 모드를 강제 설정 가능
		},
	});

	return (
		<div className='App'>
			<ToastContainer autoClose={false} closeButton={false} hideProgressBar={true} position='bottom-right' />

			{/* <AppProvider theme={lightTheme}> */}
			<ErrorBoundary fallbackRender={() => null}>
				<Router>
					<Routes>
						<Route path='/loginV2' element={<TimbloAuth mode={'login'} path={'/main'} />} />
						<Route path='/joinV2' element={<TimbloAuth mode={'join'} path={'/main'} />} />
						<Route path='/login' element={<LoginPageV3 />} />
						<Route path='/join' element={<JoinPage />} />
						<Route path='/joinnew' element={<JoinPageV2 />} />
						<Route element={<CheckCookies />}>
							<Route
								element={
									<ValidLayout
										sidebarRef={sidebarRef}
										contentRef={contentRef}
										calendarRef={calendarRef}
									/>
								}
							>
								<Route
									path='/'
									element={
										<HomeV2
											sidebarRef={sidebarRef}
											onClickContent={content => {
												setSelectedContent(content);
											}}
										/>
									}
								/>
								<Route
									path='/main'
									element={
										<HomeV2
											sidebarRef={sidebarRef}
											onClickContent={content => {
												setSelectedContent(content);
											}}
										/>
									}
								/>
								<Route
									path='/home'
									element={
										<HomeV2
											sidebarRef={sidebarRef}
											onClickContent={content => {
												setSelectedContent(content);
											}}
										/>
									}
								/>
								{/* <Route
									path='/contents'
									element={
										<ListV2
											sidebarRef={sidebarRef}
											onClickContent={content => {
												setSelectedContent(content);
											}}
										/>
									}
								/> */}
								<Route
									path='/contents'
									element={
										<ListV3
											sidebarRef={sidebarRef}
											onClickContent={content => {
												setSelectedContent(content);
											}}
										/>
									}
								/>
								{/* <Route
									path='/search/:text'
									element={
										<Search
											onClickContent={content => {
												setSelectedContent({ ...content, source: 'search' });
											}}
										/>
									}
								/> */}
								<Route path='/search' element={<SearchV2 />} />
								<Route path='/search/:keyword' element={<SearchV2 />} />
								<Route path='/inbox' element={<InboxV2 />} />
								<Route
									path='/calendar'
									element={
										<ListV3
											sidebarRef={sidebarRef}
											onClickContent={content => {
												setSelectedContent(content);
											}}
											calendarRef={calendarRef}
											isCalendar
										/>
									}
								/>
								<Route path='/content/:contentId' element={<ContentV2 ref={contentRef} />} />
								<Route path='/recycle' element={<RecycleV2 />} />
								<Route path='/bookmark' element={<BookmarkPage />} />
							</Route>
						</Route>
					</Routes>
				</Router>
			</ErrorBoundary>
			{/* </AppProvider> */}
		</div>
	);
};

export default AppV2;
