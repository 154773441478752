import React, { useEffect, useLayoutEffect, useState } from 'react';
import './Function.css';

import { ReactComponent as CommentIcon } from './img/comment.svg';
import { ReactComponent as CopyIcon } from './img/copy.svg';
import { ReactComponent as HighlightIcon } from './img/highlight.svg';
import { ReactComponent as PasteIcon } from './img/paste.svg';
import { ReactComponent as EditIcon } from './img/edit.svg';

const Function = ({
	onClickEdit = () => {},
	onClickHighlight = () => {},
	onClickCopyText = () => {},
	onClickPasteNote = () => {},
	onClickComment = () => {},
	onClose = () => {},
	isTranscription = false,
	isHighlight,
}) => {
	const [displayedFunctions, setDisplayedFunctions] = useState([]);

	useLayoutEffect(() => {
		setDisplayedFunctions(filteredFunctions());
	}, []);

	const functions = [
		{ label: '원본 기록 편집', value: 'edit' },
		{ label: `하이라이트 ${isHighlight ? '삭제' : '추가'}`, value: 'highlight' },
		{ label: '내용 복사', value: 'copy' },
		{ label: '노트에 붙여넣기', value: 'paste' },
		// { label: '댓글', value: 'comment' },
	];

	const filteredFunctions = () => {
		if (!isTranscription) {
			return functions.filter(func => func.value !== 'edit');
		} else {
			return functions;
		}
	};

	const drawFunctions = () => {
		const drawFunctionIcon = value => {
			switch (value) {
				case 'edit':
					return <EditIcon />;

				case 'highlight':
					return <HighlightIcon />;

				case 'copy':
					return <CopyIcon />;

				case 'paste':
					return <PasteIcon />;

				case 'comment':
					return <CommentIcon />;

				default:
					return <></>;
			}
		};

		const handleClicked = value => {
			switch (value) {
				case 'edit':
					onClickEdit();
					break;

				case 'highlight':
					onClickHighlight(isHighlight);
					break;

				case 'copy':
					onClickCopyText();
					break;

				case 'paste':
					onClickPasteNote();
					break;

				case 'comment':
					onClickComment();
					break;

				default:
					break;
			}

			onClose();
		};

		return displayedFunctions.map((func, idx) => (
			<div key={idx} className='function' onClick={() => handleClicked(func.value)}>
				<div className='icon'>{drawFunctionIcon(func.value)}</div>
				<div className='text'>{func.label}</div>
			</div>
		));
	};

	return (
		<div className='popup-function-container'>
			<div className='title'>기능</div>
			<div className='separator' />
			<div className='functions'>{drawFunctions()}</div>
		</div>
	);
};

export default Function;
