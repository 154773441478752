import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Button, Link, Input, Stack, Backdrop, CircularProgress } from '@mui/material';
import axios from 'axios';
import ConfirmButton from '../../../components/button/ConfirmButton';

const EmailJoinFormV2 = ({ emailParam, emailCertifyResult, setEmailCertifyResult, onComplete, step, setStep }) => {
	const [accessToken, setAccessToken] = useState(null);
	const [email, setEmail] = useState(emailParam);
	const [isAlreadyJoined, setAlreadyJoined] = useState(false);
	const [joinedEmail, setJoinedEmail] = useState('');

	const [password, setPassword] = useState('');
	const [passwordErrorText, setPasswordErrorText] = useState('');
	const [codeErrorText, setCodeErrorText] = useState('');
	const [emailCertifyCode, setEmailCertifyCode] = useState(null);
	const [isCodeSent, setCodeSent] = useState(false);
	const [joinResult, setJoinResult] = useState(null);
	const [isLoading, setLoading] = useState(false);

	function isValidEmail(email) {
		// 이메일 형식을 검증하기 위한 정규 표현식
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	useEffect(() => {
		if ('' !== password && !isValidPassword(password)) {
			//setErrorText('비밀번호를 다시 확인해 주세요.');
		} else {
			setPasswordErrorText('');
		}
	}, [password]);

	useEffect(() => {
		if (email !== joinedEmail) {
			setAlreadyJoined(false);
		}
	}, [email]);

	const onSignUp = () => {
		const formData = new FormData();
		formData.append('pass', password);
		formData.append('name', '');
		formData.append('nickName', '');
		//formData.append('thumbnail', selectedFile);

		const headers = {
			'Content-Type': 'multipart/form-data; boundary=**',
			Authorization: `Bearer ${accessToken}`,
		};

		setLoading(true);
		axios
			.post(`${process.env.REACT_APP_API_URL}/auth/timblo/signup`, formData, {
				headers: headers,
			})
			.then(response => {
				if (200 === response.data.httpCode) {
					onComplete && onComplete(true);
				} else {
					alert(`장애가 발생 하였습니다. (${response.data.httpCode})`);
				}
			})
			.catch(error => {
				alert('장애가 발생 하였습니다. (65)');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onEmailCertifyRequest = async () => {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/auth/timblo/certified?t=${Date.now()}`,
					{ email },
					{
						headers: {
							'Content-Type': 'application/json',
							'x-referer': '/account/signup',
						},
					}
				);

				const { httpCode, data } = response.data;
				if (200 === httpCode) {
					setAccessToken(response.data.data.accessToken);
				}

				console.log('[onEmailCertifyRequest]', response.data);
				resolve(httpCode);
			} catch (error) {
				console.error(error);
				resolve(400);
			}
		});
	};

	const onResendCertifyCode = async () => {
		console.log('onResendCertifyCode', accessToken);
		if (accessToken) {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/auth/timblo/recertified`,
					{},
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${accessToken}`,
							'x-referer': '/account/signup',
						},
					}
				);

				if (response.data.httpCode !== 200) {
					setEmailCertifyResult({
						code: response.data.httpCode,
						message: `Error occurred. (${response.data.httpCode})`,
					});
					setCodeErrorText(`코드 발송이 실패하였습니다. (${response.data.httpCode})`);
				} else {
					setCodeSent(true);
				}
			} catch (error) {
				console.error('Resend failed', error);
				setCodeErrorText('코드 발송이 실패하였습니다. (-300)');
			}
		} else {
			console.error('accessToken not found');
			setCodeErrorText('코드 발송이 실패하였습니다. (-400)');
		}
	};

	const onEmailCertify = async () => {
		return new Promise(async (resolve, reject) => {
			if (accessToken) {
				try {
					const response = await axios.post(
						`${process.env.REACT_APP_API_URL}/auth/timblo/verification`,
						{ code: emailCertifyCode },
						{
							headers: {
								'Content-Type': 'application/json',
								Authorization: `Bearer ${accessToken}`,
							},
						}
					);

					const { httpCode } = response.data;
					resolve(httpCode);
				} catch (error) {
					console.error(error);
					resolve(400);
				}
			}
		});
	};

	const isValidPassword = password => {
		// 조건: 8~20자, 국문/영문, 숫자, 특수문자를 포함
		const lengthRegex = /^.{8,20}$/; // 길이가 8~20자인지 확인
		const combinationRegex = /(?=.*[A-Za-z가-힣])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>~`_+=-])/; // 영문/한글, 숫자, 특수문자 포함 여부

		// 모든 조건을 만족하는지 확인
		const isValid = lengthRegex.test(password) && combinationRegex.test(password);

		return isValid;
	};

	return (
		<Box>
			<Backdrop open={isLoading} sx={{ zIndex: theme => theme.zIndex.drawer + 1, color: '#fff' }}>
				<CircularProgress color='inherit' />
			</Backdrop>

			<Stack>
				<Typography htmlFor='email' sx={{ fontSize: '14px', fontWeight: '500', color: '#374553' }}>
					이메일
				</Typography>
			</Stack>
			<Stack sx={{ marginBottom: '24px', marginTop: '8px' }}>
				<Input
					type='text'
					value={email}
					placeholder={'이메일을 입력해 주세요.'}
					onChange={e => setEmail(e.target.value)}
					disabled={step > 2}
				/>
			</Stack>

			{2 === step && isAlreadyJoined && (
				<Stack sx={{ marginTop: '16px' }}>
					<Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#F9463B' }}>
						{'이미 가입한 이메일 입니다.'}
					</Typography>
					<Typography
						component='a'
						href='/login' // 원하는 링크 경로로 변경
						sx={{
							fontSize: '12px',
							fontWeight: '500',
							color: '#1C6EFF',
							textDecoration: 'underline',
							cursor: 'pointer',
						}}
					>
						{'해당 계정으로 로그인 하시겠어요?'}
					</Typography>
				</Stack>
			)}

			{step > 2 && (
				<>
					<Stack>
						<Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#374553' }}>비밀번호</Typography>
					</Stack>
					<Stack sx={{ marginTop: '8px' }}>
						<Input
							type='password'
							value={password}
							disabled={step === 4}
							placeholder={'비밀번호를 입력해 주세요.'}
							onChange={e => setPassword(e.target.value)}
						/>
					</Stack>

					<Stack>
						<Typography
							sx={{ fontSize: '11px', fontWeight: '500', color: '#878D91', marginBottom: '24px' }}
						>
							{'*국문/영문, 숫자, 특수문자를 조합하여 8~20자로 입력해주세요.'}
						</Typography>
					</Stack>

					{'' !== passwordErrorText && '' !== password && (
						<Stack sx={{ marginTop: '0px' }}>
							<Typography htmlFor='email' sx={{ fontSize: '12px', fontWeight: '500', color: ' #F9463B' }}>
								{passwordErrorText}
							</Typography>
						</Stack>
					)}
				</>
			)}

			{step > 3 && (
				<>
					<Stack>
						<Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#374553' }}>인증코드</Typography>
					</Stack>
					<Stack sx={{ marginBottom: '24px', marginTop: '8px', gap: '8px' }} direction={'row'}>
						<Input
							sx={{ flex: 1 }}
							type='text'
							value={emailCertifyCode}
							placeholder={'인증코드를 입력해 주세요.'}
							onChange={e => setEmailCertifyCode(e.target.value)}
						/>
						<Button
							sx={{
								width: '80px',
								background: '#1C6EFF',
								color: '#FFFFFF',
								padding: '8px',
								display: 'flex',
								gap: '8px',
								borderRadius: '8px',
								fontSize: '14px',
							}}
							onClick={() => {
								onResendCertifyCode();
							}}
						>
							{`재발송`}
						</Button>
					</Stack>

					{'' !== codeErrorText && (
						<Stack>
							<Typography
								color='#F9463B'
								sx={{
									fontSize: '14px',
									fontWeight: '500',
									marginTop: '-8px',
								}}
							>
								{codeErrorText}
							</Typography>
						</Stack>
					)}

					{isCodeSent && (
						<Stack>
							<Typography
								color='#1C6EFF'
								sx={{
									fontSize: '14px',
									fontWeight: '500',
								}}
							>
								{'이메일로 인증코드를 발송했습니다.'}
							</Typography>
						</Stack>
					)}
				</>
			)}

			<>
				<ConfirmButton
					label={step !== 4 ? '계속하기' : '시작하기'}
					onClick={async () => {
						switch (step) {
							case 2:
								switch (await onEmailCertifyRequest()) {
									case 200:
										setStep(3);
										break;
									case 201:
										setAlreadyJoined(true);
										setJoinedEmail(email);
										break;
									default:
										//장애
										alert('장애가 발생하였습니다.');
										break;
								}

							//setStep(3);
							case 3:
								if (isValidPassword(password)) {
									setStep(4);
								} else {
									setPasswordErrorText('비밀번호를 다시 확인해 주세요.');
								}
								break;
							case 4:
								let code = await onEmailCertify();
								switch (code) {
									case 200:
										onSignUp();
										break;
									case 201:
										setCodeErrorText('인증코드를 다시 확인해 주세요.');
										break;
									default:
										alert(`장애가 발생하였습니다. (${code})`);
										break;
								}

								break;
							default:
								alert('장애가 발생하였습니다.');
								break;
						}
					}}
					isActive={
						step === 2 ? isValidEmail(email) && !isAlreadyJoined : step === 3 ? password : emailCertifyCode
					}
				/>
				{/* <ConfirmButton label='계속하기' onClick={onEmailCertifyRequest} isActive={isValidEmail(email)} /> */}
			</>
		</Box>
	);
};

export default EmailJoinFormV2;
