import React from 'react';
import './Tooltip.css';

const Tooltip = ({ children, text, position = 'top' }) => (
	<div className='tooltip-container'>
		{children}
		<div className={`tooltip-text tooltip-${position}`}>{text}</div>
	</div>
);

export default Tooltip;
