import React, { useEffect, useRef, useState } from 'react';
import './SttRowVS.css';
//import Avatar from 'react-avatar';
import Avatar from '@mui/material/Avatar';
import { getAvatarColor, onCopyText } from '../../../util/Util';

import { ReactComponent as LearningIcon } from './img/learning.svg';
import { ReactComponent as DotIcon } from './img/dot.svg';
import Popup from 'reactjs-popup';
import Function from '../../popup/content/function/Function';
import Attendees from '../../popup/attendees/Attendees';
import SpeakerEdit from '../../popup/content/speakerEdit/SpeakerEdit';
import { ToastError, ToastInfo } from '../../common/toast/Toast';
import useContentsStore from '../../../store/ContentsStore';
import { useCookies } from 'react-cookie';
import useAuthStore from '../../../store/AuthStore';
import { Box, Stack, Typography } from '@mui/material';

function getInitials(name) {
	return name
		.split(' ')
		.map(n => n[0])
		.join('')
		.slice(0, 1); // 최대 1글자
}

const SttRow = ({
	index,
	data,
	text,
	onChangeText = () => {},
	onChangeSegmentSpeaker,
	speakerList = [],
	editMode = false,
	onChangeMode = () => {},
	onSeekTime = () => {},
	noteRef,
	editable,
	isHighlight,
	refreshContent,
	styles = {},
	keyword,
	textHighlighted,
	matchIndices,
	searchResult,
	isLastElement,
}) => {
	const containerRef = useRef();
	const inputRef = useRef();
	const rowRef = useRef();
	const avatarRef = useRef();
	const textRef = useRef(text);

	const [isEdit, setIsEdit] = useState(editMode);
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);

	// Learning
	const [isLearning, setIsLearning] = useState(false);

	const { auth } = useAuthStore();
	const { selectedContent, onAppendDataToNote, addHighlight, deleteHighlight } = useContentsStore();

	useEffect(() => {
		return () => {
			if (text !== textRef.current) {
				onChangeText(index, textRef.current);
			}
		};
	}, []);

	useEffect(() => {
		const handleWheel = () => {
			if (document.activeElement === inputRef.current) {
				inputRef.current.blur();
			}
		};

		if (containerRef.current) {
			containerRef.current.addEventListener('wheel', handleWheel);
		}

		return () => {
			if (containerRef.current) {
				containerRef.current.removeEventListener('wheel', handleWheel);
			}
		};
	}, []);

	useEffect(() => {
		setIsEdit(editMode);
	}, [editMode]);

	const getSpeaker = speakerId => {
		const speaker = speakerList && speakerList.find(item => item.speakerId === speakerId);
		if (speaker) {
			return speaker.displayName ?? speaker.name;
		} else {
			return { name: '알 수 없음' };
		}
	};

	const getThumbnail = speakerId => {
		const speaker = speakerList && speakerList.find(item => item.speakerId === speakerId);
		if (speaker && speaker.thumbnailUrl) {
			if (auth && auth.user && auth.user.profile && auth.user.profile.email === speaker.email) {
				return auth.user.profile.thumbnailUrl;
			} else {
				return speaker.thumbnailUrl;
			}
		} else {
			return null;
		}
	};

	const convertTimeFormat = milliseconds => {
		let seconds = Math.floor(milliseconds / 1000);
		let minutes = Math.floor(seconds / 60);
		let hours = Math.floor(minutes / 60);

		seconds = seconds % 60;
		minutes = minutes % 60;

		const pad = num => num.toString().padStart(2, '0');

		// if (hours > 0) {
		return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
		// } else {
		// 	return `${pad(minutes)}:${pad(seconds)}`;
		// }
	};

	const handlePasteNote = () => {
		if (editable) {
			onAppendDataToNote(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, selectedContent.contentId, {
				type: 'segments',
				data,
			}).then(() => {
				noteRef && noteRef.current && noteRef.current.refreshNote();
			});
		} else {
			ToastInfo('권한이 없습니다.');
		}
	};

	const handleToggleHighlight = item => {
		if (editable) {
			if (isHighlight) {
				deleteHighlight(
					cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
					selectedContent.contentId,
					`segments`,
					item.segmentId
				)
					.then(res => {
						switch (res.code) {
							case 200:
								ToastInfo('하이라이트가 삭제 되었습니다.');
								refreshContent();
								break;
							case 401:
								ToastError(0, '권한이 없습니다.');
								break;
							default:
								ToastError(res.message);
								break;
						}
					})
					.catch(e => {
						ToastError(1016);
					});
			} else {
				addHighlight(
					cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
					selectedContent.contentId,
					`segments`,
					item.segmentId
				)
					.then(res => {
						switch (res.code) {
							case 200:
								ToastInfo('하이라이트가 추가 되었습니다.');
								refreshContent();
								break;
							case 401:
								ToastError(0, '권한이 없습니다.');
								break;
							default:
								ToastError(res.message);
								break;
						}
					})
					.catch(e => {
						ToastError(1016);
					});
			}
		} else {
			ToastInfo('권한이 없습니다.');
		}
	};

	const thumbnail = getThumbnail(data.speakerId);
	const name = getSpeaker(data.speakerId);
	const { background, color } = getAvatarColor(data.speakerId);

	return (
		<Stack
			ref={containerRef}
			spacing={1.25}
			direction={'row'}
			className={`stt-row-container ${index}`}
			sx={{
				p: 1,
				borderRadius: 1,
				background: isHighlight ? '#F6FFF7' : '',
				...styles,
			}}
		>
			<Popup
				trigger={
					<Avatar
						ref={avatarRef}
						src={thumbnail || undefined}
						sx={theme => ({
							width: 32,
							height: 32,
							bgcolor: thumbnail ? 'transparent' : background,
							color: thumbnail ? 'inherit' : color,
							...theme.typography.headline4,
						})}
					>
						{!thumbnail && getInitials(name)}
					</Avatar>
				}
				keepTooltipInside='.main-content'
			>
				{close => (
					<SpeakerEdit
						segment={data}
						onChangeSegmentSpeaker={onChangeSegmentSpeaker}
						speakerId={data.speakerId}
						speakerList={speakerList}
						onClose={close}
					/>
				)}
			</Popup>

			<Stack spacing={1}>
				<Stack spacing={1} direction={'row'}>
					<Typography
						onClick={() => avatarRef.current.click()}
						sx={theme => ({ ...theme.typography.title2, color: theme.palette.grey[700] })}
					>
						{getSpeaker(data.speakerId)}
					</Typography>

					<Typography
						onClick={() => onSeekTime(data.startTime / 1000)}
						sx={theme => ({ ...theme.typography.body3, color: theme.palette.grey[400] })}
					>
						{convertTimeFormat(data.startTime)}
					</Typography>

					{isEdit && (
						<div
							className={`learning ${isLearning ? 'active' : ''}`}
							onClick={() => setIsLearning(prev => !prev)}
						>
							<LearningIcon />
							<div className='text'>학습</div>
						</div>
					)}
				</Stack>

				<Box
					ref={inputRef}
					className='stt-result'
					contentEditable={isEdit ? true : false}
					style={{ padding: isEdit ? '10px' : 0, background: isEdit ? '#fff' : 'transparent' }}
					suppressContentEditableWarning
					spellCheck={false}
					id={data.segmentId}
					start-time={data.startTime}
					onBlur={e => onChangeText(index, e.target.innerText)}
					onWheel={() => inputRef.current.blur()}
					// onInput={e => onChangeText(index, e.target.innerText)}
				>
					{isEdit
						? text
						: textHighlighted[index].map((part, partIndex) => (
								<Typography
									key={partIndex}
									sx={theme => ({
										backgroundColor:
											part.toLowerCase() === keyword.toLowerCase()
												? matchIndices[searchResult.currentIndex] &&
												  matchIndices[searchResult.currentIndex].dataIndex === index &&
												  matchIndices[searchResult.currentIndex].partIndex === partIndex
													? 'orange'
													: 'yellow'
												: 'inherit',
										...theme.typography.body2,
										color: theme.palette.grey[500],
									})}
								>
									{part}
								</Typography>
						  ))}
				</Box>
			</Stack>

			{/* {!isEdit && (
				<Popup
					trigger={
						<div className='dot'>
							<DotIcon />
						</div>
					}
					position={'bottom right'}
					keepTooltipInside='.main-content'
				>
					{close => (
						<Function
							onClickEdit={() => (editable ? onChangeMode(!isEdit) : ToastInfo('권한이 없습니다.'))}
							onClickHighlight={() => handleToggleHighlight(data)}
							onClickCopyText={() =>
								onCopyText(rowRef.current.innerText, () =>
									ToastInfo('내용이 성공적으로 복사되었습니다.', '내용 복사 완료')
								)
							}
							onClickPasteNote={handlePasteNote}
							onClose={close}
							isTranscription
							isHighlight={isHighlight}
						/>
					)}
				</Popup>
			)} */}
		</Stack>
	);
};

export default React.memo(SttRow);
