import { create } from 'zustand';

const useFilterStore = create(set => ({
	ORDER_LIST: [
		{ label: '최신 생성순', value: 'NEWEST_CREATE' },
		{ label: '오래된 생성순', value: 'OLDEST_CREATE' },
		{ label: '최신 수정순', value: 'NEWEST_UPDATE' },
		{ label: '제목 내림차순', value: 'TITLE_ASC' },
		{ label: '제목 오름차순', value: 'TITLE_DESC' },
	],

	SHARED_FILTER_LIST: [
		{ label: '전체', value: 'ALL' },
		{ label: '공유한', value: 'SHARE_BY_ME' },
		{ label: '공유받은', value: 'SHARE_TO_ME' },
	],

	TYPE_FILTER_LIST: [
		{ label: '전체', value: 'ALL' },
		{ label: '음성', value: 'AUDIO' },
		{ label: '영상', value: 'VIDEO' },
	],

	shareType: 'ALL',
	contentType: 'ALL',
	orderType: 'NEWEST_CREATE',
	onChangeShareType: shareType => {
		set({ shareType });
	},
	onChangeContentType: contentType => {
		set({ contentType });
	},
	onChangeOrderType: orderType => {
		set({ orderType });
	},

	getFilteredData: (data, shareType = 'ALL', contentType = 'ALL', orderType = 'NEWEST_CREATE') => {
		try {
			if (data && data.length) {
				return data
					.filter(item => {
						if (shareType === 'ALL') {
							return true;
						} else if (shareType === 'SHARE_TO_ME') {
							return item.userAccessRole !== 'OWNER';
						} else if (shareType === 'SHARE_BY_ME') {
							return item.shareUsers?.length && item.userAccessRole === 'OWNER';
						}
					})
					.filter(item => {
						if (contentType === 'ALL') {
							return true;
						} else if (contentType === 'AUDIO') {
							return item.type === 'AUDIO' || item.type === 'RECORD';
						} else if (contentType === 'VIDEO') {
							return item.type === 'VIDEO';
						}
					})
					.map((item, index) => ({ id: index, ...item }))
					.sort((a, b) => {
						if (orderType === 'NEWEST_CREATE') {
							return new Date(b.createAt) - new Date(a.createAt);
						} else if (orderType === 'OLDEST_CREATE') {
							return new Date(a.createAt) - new Date(b.createAt);
						} else if (orderType === 'NEWEST_UPDATE') {
							return new Date(b.updateAt) - new Date(a.updateAt);
						} else if (orderType === 'TITLE_ASC') {
							return a.title.localeCompare(b.title);
						} else if (orderType === 'TITLE_DESC') {
							return b.title.localeCompare(a.title);
						}
					});
			}
		} catch (e) {
			console.error('getFilteredData Exception', e);
		}
	},
}));

export default useFilterStore;
