import { Box, Button, SvgIcon, Tooltip, Typography } from '@mui/material';
import './DateRangePicker.css';

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { ReactComponent as CalendarIcon } from './img/calendar.svg';
import gregorian_ko_lowercase from './DateRangePickerLocale';

const DateRangePicker = ({ onChanged, onConfirmed }) => {
	const datePickerRef = useRef();
	const [dates, setDates] = useState([new DateObject().subtract(1, 'month'), new DateObject()]);

	useEffect(() => {
		if (dates) {
			const start = dates[0].format('YYYY-MM-DD');
			let end = null;
			if (dates[1]) {
				end = dates[1].format('YYYY-MM-DD');
			}
			onChanged && onChanged({ start: start, end: end });
		}
	}, [dates]);

	const onToday = () => {
		setDates([new DateObject()]);
	};

	const drawDay = date => {
		let day = <Typography>{date.format('ddd')}</Typography>;
		switch (Number(date.format('dd'))) {
			case 1: // 일요일
				return <Typography sx={{ color: '#F9463B' }}>{date.format('D')}</Typography>;
			case 7: // 토요일
			// return <Typography sx={{ color: '#1C6EFF' }}>{date.format('D')}</Typography>;
			default:
				return <Typography>{date.format('D')}</Typography>;
		}
	};

	const drawMonthTitle = month => {
		return (
			<Typography
				sx={{ fontSize: 20, color: '#292A2B', fontWeight: 600, fontFamily: 'Noto Sans' }}
			>{`${month}`}</Typography>
		);
	};
	const drawYearTitle = year => {
		return (
			<Typography
				sx={{ fontSize: 20, color: '#292A2B', fontWeight: 600, fontFamily: 'Noto Sans' }}
			>{`${year}년`}</Typography>
		);
	};

	return (
		<>
			<Typography variant='p' sx={{ fontSize: 16, color: 'rgba(41, 42, 43, 1)' }}>
				조회기간
			</Typography>
			<Tooltip title={'최근 1년 전 데이터까지 조회할 수 있습니다.'}>
				<InfoOutlinedIcon sx={{ color: 'var(--Dark-grey700, #A9AFB3)' }} />
			</Tooltip>

			<DatePicker
				containerClassName='custom-container'
				calendarPosition='bottom-center'
				mapDays={({ date }) => {
					return {
						children: drawDay(date),
					};
				}}
				// formatMonth={(month, year) => {
				// 	return drawMonthYearTitle(year, month);
				// }}
				// formatYear={(year, month) => {
				// 	return drawMonthYearTitle(year, month);
				// }}
				value={dates}
				onChange={setDates}
				range
				editable={false}
				monthYearSeparator=' '
				numberOfMonths={2}
				ref={datePickerRef}
				minDate={new DateObject().subtract(1, 'years')}
				maxDate={new DateObject()}
				format={'YYYY. MM. DD.'}
				locale={gregorian_ko_lowercase}
				containerStyle={{
					position: 'relative',
					width: 270,
					height: 38,
					borderRadius: '8px',
					border: '1px solid rgba(225, 228, 230, 1)',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						marginRight: '10px',
						paddingBottom: '10px',
						justifyContent: 'flex-end', // 오른쪽 정렬
						gap: '8px', // 버튼 간 간격
					}}
				>
					<Button
						onClick={onToday}
						sx={{ backgroundColor: 'white', color: '#1C6EFF', width: '85px', border: '1px solid #1C6EFF' }}
					>
						오늘
					</Button>
					<Button
						sx={{ backgroundColor: '#1C6EFF', color: 'white', width: '135px' }}
						onClick={() => {
							datePickerRef.current.closeCalendar();
							onConfirmed && onConfirmed(dates);
						}}
					>
						확인
					</Button>
				</Box>
			</DatePicker>
			<SvgIcon inheritViewBox sx={{ width: 24, height: 24, position: 'absolute', left: 110 }}>
				<CalendarIcon />
			</SvgIcon>
		</>
	);
};

export default DateRangePicker;
