import axios from 'axios';
import { create } from 'zustand';
import { ToastError, ToastInfo } from '../components/common/toast/Toast';

const useAttendeeStore = create(set => ({
	attendees: [],
	setAttendees: array => set({ attendees: array }),
	refreshAttendees: async (accessToken, contentId) => {
		set({ attendees: [] });

		const headers = {
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${accessToken}`,
		};

		const response = await axios.get(`${process.env.REACT_APP_API_URL}/contents/${contentId}/attendees`, {
			headers,
		});

		if (response && response.data && response.data.httpCode === 200) {
			set({ attendees: response.data.data.speakerInfo });
		}
	},
	onAddAttendee: async (accessToken, contentId, data, callback, exception) => {
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/contents/${contentId}/attendees`,
			{
				...data,
			},
			{
				headers,
			}
		);

		if (response && response.data && response.data.httpCode === 201) {
			set({ attendees: response.data.data.speakerInfo });

			const target = response.data.data.speakerInfo.find(speaker => speaker.displayName === data.name);

			callback && callback(target);
		} else {
			switch (response.data.httpCode) {
				case 410:
					ToastInfo('이미 추가된 참석자 입니다.');
					break;

				default:
					ToastError(response.data.httpCode, response.data.message);
					break;
			}

			exception && exception();
		}
	},
	onDeleteAttendee: async (accessToken, contentId, speakerId, callback, exception) => {
		console.log(contentId, speakerId);

		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		const response = await axios.delete(
			`${process.env.REACT_APP_API_URL}/contents/${contentId}/attendees/${speakerId}`,
			{
				headers,
			}
		);

		if (response && response.data && response.data.httpCode === 200) {
			set({ attendees: response.data.data.speakerInfo });

			callback && callback();
		} else {
			ToastError(response.data.httpCode, response.data.message);
			exception && exception(response.data);
		}
	},
}));

export default useAttendeeStore;
