import React, { forwardRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import './ContentV2.css';

import HeaderV2 from '../body/headerV2/HeaderV2';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import useAuthStore from '../../store/AuthStore';
import useContentsStore from '../../store/ContentsStore';
import { drawContentTypeV2 } from '../common/ContentIcon/ContentIcon';
import Popup from 'reactjs-popup';
import Share from '../popup/share/Share';
import Download from '../popup/download/Download';
import TimbloButton from '../button/TimbloButton';
import ReSummary from '../popup/resummary/ReSummary';

import DeleteConfirm from '../popup/content/delete-confirm/DeleteConfirm';
import { ToastError, ToastInfo } from '../common/toast/Toast';
import { getAvatarColor, getBlockInfo, getBlockTitle, isUUID, onCopyText } from '../../util/Util';
import { Box, Stack, Tab, Tabs, Typography, IconButton, InputBase, Paper, Button, Popover } from '@mui/material';
import { PageLoading, SttMaking } from '../page-status/PageLoading';
import SummaryBlock from './SummaryBlock';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { ReactComponent as RecordIcon } from './img/mic.svg';
import { ReactComponent as DesktopIcon } from './img/desktop.svg';
import { ReactComponent as ChatbotIcon } from './img/chatbot.svg';

import Transcription from './transcription/Transcription';
import TranscriptionVS from './transcription/TranscriptionVS';
import TranscriptionV2 from './transcription/TranscriptionV2';
import Note from './note/Note';
import MyTemplate from './template/MyTemplate';
import DataChip from './DataChip';
import useAttendeeStore from '../../store/AttendeeStore';
import BookmarkList from './bookmark/Bookmark';
import Chatbot from '../chatbot/Chatbot';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContextMenu from '../popup/contextMenu/ContextMenu';
import Empty from '../page-status/Empty';
import { getHighlightText, getHighlightTypo } from './common/HighlightText';

const TABS = [
	{ label: '전체 요약', value: 'summary' },
	{ label: '북마크', value: 'bookmark' },
	{ label: '노트', value: 'note' },
	{ label: 'My 템플릿', value: 'template' },
];

const BLOCK_ORDER = ['topics', 'speakerInfo', 'keywords', 'summary', 'issues', 'tasks', 'summaryTime'];

const ContentV2 = forwardRef(({}, ref) => {
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const [highlight, setHighlight] = useState(queryParams.get('highlight'));

	const noteRef = useRef();
	const transcriptionRef = useRef();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { auth } = useAuthStore();

	const {
		getContents,
		sttStatus,
		selectedContent,
		setSelectedContent,
		refreshContentFromContentId,
		updateContentTitle,
		deleteContents,
		addBookmark,
		deleteBookmark,
		onAppendDataToNote,
		updateContentKeywords,
		updateContentAttendee,
	} = useContentsStore();

	const { onAddAttendee, onDeleteAttendee } = useAttendeeStore();

	const [content, setContent] = useState(null);
	const [blocks, setBlocks] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [currentTab, setCurrentTab] = useState(TABS[0]);
	const [status, setStatus] = useState({ code: -1, message: '' });
	const [isEditable, setIsEditable] = useState(false);

	const [selectedSummaryTime, setSelectedSummaryTime] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	useLayoutEffect(() => {
		const contentId = location.pathname.split('/')[2];

		if (contentId && isUUID(contentId)) {
			refreshContent(contentId);
		}
	}, []);

	useEffect(() => {
		if (content) {
			setIsEditable(content.meta.permission !== 'VIEWER');

			const orderedData = getOrderedData(content);

			if (orderedData) {
				setBlocks(orderedData);
			}
		}
	}, [content]);

	useEffect(() => {
		if (sttStatus.list) {
			const contentId = location.pathname.split('/')[2];

			const item = sttStatus.list.find(item => item.contentId === contentId);

			if (item) {
				switch (item.status.toUpperCase()) {
					case 'DONE':
					case 'ERROR':
						refreshContent(contentId);
						setLoading(false);
						break;
					default:
						setLoading(true);
						break;
				}
			}
		}
	}, [sttStatus.list]);

	const refreshContent = contentId => {
		getContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId)
			.then(data => {
				switch (data.code) {
					case 200:
						setStatus({ code: 200, message: 'OK' });
						setContent(data.data);
						break;
					case 403:
					case 404:
						setStatus({ code: data.code, message: 'Loading...' });
						break;

					case 422:
						setStatus({ code: data.code, message: 'Stt Making' });
						break;

					case 423:
						//조회할 수 없는 컨텐츠입니다.
						setStatus({ code: data.code, message: 'Error' });
						break;
					default:
						//장애 발생
						setStatus({ code: data.code, message: 'Something happened' });
						ToastError(1002);
						break;
				}
			})
			.catch(e => {
				setStatus({ code: e.code, message: e.message });
			})
			.finally(() => setLoading(false));
	};

	const makeStreamUrl = useCallback(
		id => {
			let url = '';

			if (
				cookies[process.env.REACT_APP_COOKIE_ALIAS] &&
				cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken
			) {
				if (id) {
					url = `${process.env.REACT_APP_API_URL}/contents/download/${id}?accessToken=${
						cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken
					}`;
				}
			}

			return url;
		},
		[cookies]
	);

	const getOrderedData = data => {
		const orderedArray = [];
		BLOCK_ORDER.forEach(key => {
			if (data[key]) {
				orderedArray.push({ [key]: data[key] });
			} else if (data.aiResult && data.aiResult[key]) {
				orderedArray.push({ [key]: data.aiResult[key] });
			}
		});

		return orderedArray;
	};

	const handleTabChange = (event, text) => {
		const target = TABS.find(tab => tab.label === text);

		if (target) {
			setCurrentTab(target);
		}
	};

	const onCheckIsBookmarkBlock = key => {
		if (content && content.bookmarks) {
			if (key === 'summaryTime') {
				const target = content.bookmarks.find(bookmark => bookmark.key === 'summaryTime');
				return target && target.isAll === 'Y';
			}

			return content.bookmarks.some(bookmark => bookmark.key === key);
		} else {
			return false;
		}
	};

	const onToggleBookmark = block => {
		if (isEditable) {
			const key = Object.keys(block)[0];

			if (key) {
				if (onCheckIsBookmarkBlock(key)) {
					deleteBookmark(
						cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
						content.file.contentId,
						key,
						key === 'summaryTime' ? -1 : null
					)
						.then(res => {
							switch (res.code) {
								case 200:
									ToastInfo('북마크가 삭제 되었습니다.');
									refreshContent(content.file.contentId);
									break;
								case 401:
									ToastError(0, '권한이 없습니다.');
									break;
								default:
									ToastError(res.message);
									break;
							}
						})
						.catch(e => {
							ToastError(1016);
						});
				} else {
					addBookmark(
						cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
						content.file.contentId,
						key,
						null,
						key === 'summaryTime' ? -1 : null
					)
						.then(res => {
							switch (res.code) {
								case 200:
									ToastInfo('북마크가 추가 되었습니다.');
									refreshContent(content.file.contentId);
									break;
								case 401:
									ToastError(0, '권한이 없습니다.');
									break;
								default:
									ToastError(res.message);
									break;
							}
						})
						.catch(e => {
							ToastError(1016);
						});
				}
			}
		} else {
			ToastError(0, '권한이 없습니다.');
		}
	};

	const onCopyClipboard = text => {
		onCopyText(text, () => ToastInfo('텍스트가 복사되었습니다.'));
	};

	const onPasteNote = block => {
		if (isEditable) {
			const key = Object.keys(block)[0];

			if (key) {
				onAppendDataToNote(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, content.file.contentId, {
					itemKey: key,
					itemValue: block[key],
				});
			}
		} else {
			ToastError(0, '권한이 없습니다.');
		}
	};

	const drawLeftView = () => {
		switch (currentTab.value) {
			case 'summary':
				return blocks.map((block, index) => (
					<SummaryBlock
						key={index}
						isBookmark={onCheckIsBookmarkBlock(Object.keys(block)[0])}
						title={getBlockTitle(Object.keys(block)[0])}
						info={getBlockInfo(Object.keys(block)[0])}
						enableContextMenu
						onToggleBookmark={() => onToggleBookmark(block)}
						onCopyClipboard={onCopyClipboard}
						onPasteNote={() => onPasteNote(block)}
					>
						{drawBlock(block)}
					</SummaryBlock>
				));
			case 'bookmark':
				return (
					<BookmarkList
						contentId={content.meta.contentId}
						data={content.bookmarks}
						refreshContent={() => refreshContent(content.file.contentId)}
					/>
				);

			case 'note':
				return (
					<Note
						ref={noteRef}
						contentId={content.meta.contentId}
						editable={content.meta.permission !== 'VIEWER'}
					/>
				);

			case 'template':
				return (
					<MyTemplate
						content={content}
						contentId={content.meta.contentId}
						editable={content.meta.permission !== 'VIEWER'}
					/>
				);

			default:
				return <></>;
		}
	};

	const drawContentStatus = () => {
		switch (status.code) {
			case 404:
				return <Empty type='contents-result' />;

			case 413:
				return <Empty type='deleted-content' />;

			case 422:
				return <SttMaking />;

			case 423:
			default:
				return <Empty type='error' />;
		}
	};

	const drawBlock = block => {
		const key = Object.keys(block)[0];
		const value = block[key];

		if (value && value.length) {
			switch (key) {
				case 'speakerInfo': {
					return (
						<Stack direction={'row'} sx={{ flexWrap: 'wrap', gap: 1.5 }}>
							{value.map((item, index) => (
								<DataChip
									enableEdit={content.meta.permission !== 'VIEWER'}
									text={getHighlightTypo({
										highlightText: highlight,
										text: item.displayName ?? item.name,
										sx: {
											fontWeight: 500,
											fontFamily: 'Noto Sans KR',
										},
									})}
									index={index}
									onDelete={() => {
										onDeleteAttendee(
											cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
											content.file.contentId,
											item.speakerId,
											() => refreshContent(content.file.contentId)
										);
									}}
									onUpdate={newText => {
										updateContentAttendee(
											cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
											content.file.contentId,
											item.speakerId,
											newText,
											() => refreshContent(content.file.contentId),
											() => console.log('FAiled')
										);
									}}
									type={key}
								/>
							))}
							<AddButton
								onAdd={text => {
									onAddAttendee(
										cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
										content.file.contentId,
										{ displayName: text },
										() => refreshContent(content.file.contentId)
									);
								}}
							/>
						</Stack>
					);
				}

				case 'keywords': {
					return (
						<Stack direction={'row'} sx={{ flexWrap: 'wrap', gap: 1.5 }}>
							{value.map((item, index) => (
								<DataChip
									enableEdit={content.meta.permission !== 'VIEWER'}
									text={getHighlightTypo({
										highlightText: highlight,
										text: item,
										sx: { fontWeight: 500, fontFamily: 'Noto Sans KR' },
									})}
									index={index}
									onDelete={() => {
										const newBlocks = [...blocks];
										const keywords = newBlocks.find(
											b => Object.keys(b)[0] === 'keywords'
										)?.keywords;

										if (keywords) {
											const updatedKeywords = keywords.filter(text => text !== item);

											updateContentKeywords(
												cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
												content.file.contentId,
												updatedKeywords,
												() => refreshContent(content.file.contentId),
												() => console.log('FAiled')
											);
										}
									}}
									onUpdate={newText => {
										const newBlocks = [...blocks];
										const keywords = newBlocks.find(
											b => Object.keys(b)[0] === 'keywords'
										)?.keywords;

										if (keywords) {
											const updatedKeywords = keywords.map(word =>
												word === item ? newText : word
											);

											updateContentKeywords(
												cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
												content.file.contentId,
												updatedKeywords,
												() => refreshContent(content.file.contentId),
												() => console.log('FAiled')
											);
										}
									}}
									type={key}
								/>
							))}
							<AddButton
								onAdd={newText => {
									const newBlocks = [...blocks];
									const keywords = newBlocks.find(b => Object.keys(b)[0] === 'keywords')?.keywords;

									if (keywords) {
										const updatedKeywords = [...keywords, newText];

										updateContentKeywords(
											cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
											content.file.contentId,
											updatedKeywords,
											() => refreshContent(content.file.contentId),
											() => console.log('FAiled')
										);
									}
								}}
							/>
						</Stack>
					);
				}

				case 'summaryTime': {
					const onToggleBookmarkSummaryTime = () => {
						if (isEditable) {
							if (onCheckBookmarkSummaryTime(selectedSummaryTime)) {
								deleteBookmark(
									cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
									content.meta.contentId,
									'summaryTime',
									selectedSummaryTime.index
								)
									.then(res => {
										switch (res.code) {
											case 200:
												ToastInfo('북마크가 제거 되었습니다.');
												refreshContent(content.file.contentId);
												break;
											case 401:
												ToastError(0, '권한이 없습니다.');
												break;
											default:
												ToastError(res.message);
												break;
										}
									})
									.catch(e => {
										ToastError(1016);
									});
							} else {
								addBookmark(
									cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
									content.meta.contentId,
									'summaryTime',
									null,
									selectedSummaryTime.index
								)
									.then(res => {
										switch (res.code) {
											case 200:
												ToastInfo('북마크가 추가 되었습니다.');
												refreshContent(content.file.contentId);
												break;
											case 401:
												ToastError(0, '권한이 없습니다.');
												break;
											default:
												ToastError(res.message);
												break;
										}
									})
									.catch(e => {
										ToastError(1016);
									});
							}
						} else {
							ToastError(null, '권한이 없습니다.');
						}
					};

					const onPasteNoteSummaryTime = () => {
						if (isEditable) {
							onAppendDataToNote(
								cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
								content.meta.contentId,
								{ itemKey: 'summaryTime', itemValue: selectedSummaryTime }
							);
						} else {
							ToastError(null, '권한이 없습니다.');
						}
					};

					const onCheckBookmarkSummaryTime = block => {
						if (!block) {
							return false;
						}

						const summaryBookmarks = content.bookmarks.find(bookmark => bookmark.key === 'summaryTime');

						if (summaryBookmarks) {
							return summaryBookmarks.data.some(summary => summary.index === block.index);
						} else {
							return false;
						}
					};

					return (
						<Stack direction={'column'} spacing={4}>
							{value.map((summaryTime, index) => (
								<Paper
									sx={{
										boxShadow: 'none',
										background: onCheckBookmarkSummaryTime(summaryTime) ? '#F5FEF5' : 'inherit',
										'&:hover .function-button': {
											opacity: 1,
										},
										transition: `background 0.3s ease-in-out`,

										// anchorEl과 비교하여 선택된 버튼만 opacity를 1로 설정
										[`& .function-button-${index}`]: {
											opacity:
												anchorEl === document.querySelector(`.function-button-${index}`)
													? 1
													: 0,
										},
									}}
								>
									<Stack spacing={1}>
										<Typography
											sx={{
												fontSize: 18,
												fontWeight: 600,
												color: '#4D5256',
												fontFamily: 'Noto Sans KR',
												display: 'flex',
											}}
										>
											{getHighlightTypo({
												highlightText: highlight,
												text: summaryTime.index + '. ' + summaryTime.topic,
												sx: {
													fontSize: 18,
													fontWeight: 600,
													color: '#4D5256',
													fontFamily: 'Noto Sans KR',
													display: 'flex',
												},
											})}

											<IconButton
												className={`function-button function-button-${index}`}
												sx={{
													width: 24,
													height: 24,
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													border: '1px solid #E1E4E6',
													borderRadius: '4.8px',
													boxSizing: 'border-box',
													marginLeft: 'auto !important',
													opacity: 0,

													'&:hover': {
														border: '1px solid #5793FF',
													},

													'&:hover > svg': {
														color: '#5793FF',
													},
												}}
												onClick={event => {
													setAnchorEl(event.currentTarget);
													setSelectedSummaryTime(summaryTime);
												}}
											>
												<MoreVertIcon fontSize={'small'} />
											</IconButton>
										</Typography>

										{summaryTime.summary.map(summary => (
											<>
												{getHighlightTypo({
													highlightText: highlight,
													text: 'ㆍ' + summary,
													sx: {
														fontSize: 18,
														fontWeight: 500,
														fontFamily: 'Noto Sans KR',
														color: '#4D5256',
													},
												})}
											</>
										))}

										{summaryTime.issues &&
											summaryTime.issues.map(issue => (
												<Stack spacing={1} direction='row' alignItems={'center'}>
													<Box
														sx={{
															fontSize: 18,
															fontWeight: 500,
															fontFamily: 'Noto Sans KR',
															color: '#fff',
															background: '#35B54F',
															padding: `2px 8px`,
															borderRadius: '8px',
															marginLeft: '10px !important',
															flexShrink: 0,
														}}
													>
														이슈
													</Box>
													{getHighlightTypo({
														highlightText: highlight,
														text: issue,
														sx: {
															fontSize: 18,
															fontWeight: 500,
															fontFamily: 'Noto Sans KR',
															color: '#4D5256',
														},
													})}
												</Stack>
											))}

										{summaryTime.tasks &&
											summaryTime.tasks.map(task => (
												<Stack spacing={1} direction='row' alignItems={'center'}>
													<Box
														sx={{
															fontSize: 18,
															fontWeight: 500,
															fontFamily: 'Noto Sans KR',
															color: '#fff',
															background: '#FF7E29',
															padding: `2px 8px`,
															borderRadius: '8px',
															marginLeft: '10px !important',
															flexShrink: 0,
														}}
													>
														할일
													</Box>
													{getHighlightTypo({
														highlightText: highlight,
														text: task,
														sx: {
															fontSize: 18,
															fontWeight: 500,
															fontFamily: 'Noto Sans KR',
															color: '#4D5256',
														},
													})}
												</Stack>
											))}
									</Stack>
								</Paper>
							))}

							<Popover
								open={Boolean(anchorEl)}
								anchorEl={anchorEl}
								onClose={() => setAnchorEl(null)}
								anchorOrigin={{
									vertical: 30,
									horizontal: 0,
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 125,
								}}
							>
								<ContextMenu
									isBookmark={onCheckBookmarkSummaryTime(selectedSummaryTime)}
									onToggleBookmark={onToggleBookmarkSummaryTime}
									onCopyClipboard={() => onCopyClipboard(anchorEl.parentNode.parentNode.innerText)}
									onPasteNote={onPasteNoteSummaryTime}
									onClose={() => setAnchorEl(null)}
								/>
							</Popover>
						</Stack>
					);
				}

				default:
					return value.map(text =>
						getHighlightTypo({
							highlightText: highlight,
							text: text,
							sx: { fontSize: 18, fontWeight: 500, color: '#4D5256', fontFamily: 'Noto Sans KR' },
						})
					);
			}
		}
	};

	return (
		<>
			{content && (
				<HeaderV2
					style={{ background: '#f9fafb' }}
					text={content && content.meta ? content.meta.editedTitle ?? content.meta.title : ''}
					description={
						content && content.file
							? `생성일 ${moment(content.file.createAt)
									.locale('ko')
									.format('YY.MM.DD LT')} |  최종 수정일 ${moment(content.file.updateAt)
									.locale('ko')
									.format('YY.MM.DD LT')}  |  ${content.meta.lastUpdator}`
							: ''
					}
					icon={drawContentTypeV2(auth, content?.meta, 48, true)}
					enableEdit
					originTitle={content.meta.title}
					onChangeTitle={(text, onSuccess, onError) =>
						updateContentTitle(
							cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
							content.meta.contentId,
							text
						)
							.then(() => {
								onSuccess && onSuccess();
							})
							.catch(() => {
								onError && onError();
							})
					}
					buttonComponents={
						<>
							{/* <Popup
								trigger={<TimbloButton label={'재요약'} alias='refresh' />}
								position={'bottom right'}
								arrow={false}
							>
								{close => <ReSummary onSummary={type => close()} />}
							</Popup> */}

							<Popup
								trigger={<TimbloButton label={'공유'} alias='share' />}
								position={'bottom right'}
								arrow={false}
								nested
							>
								<Share contentId={content.meta.contentId} permission={content.meta.permission} />
							</Popup>
							<Popup
								trigger={<TimbloButton label={'다운로드'} alias='download' />}
								position={'bottom right'}
								arrow={false}
								nested
							>
								<Download content={content} />
							</Popup>

							<Popup
								trigger={<TimbloButton label={'삭제'} alias='delete' />}
								position={'bottom right'}
								arrow={false}
								nested
							>
								{close => (
									<DeleteConfirm
										title='콘텐츠를 삭제하시겠습니까?'
										onCancel={close}
										onConfirm={() => {
											if (content.meta.permission === 'OWNER') {
												deleteContents(
													cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
													{
														contentId: content.meta.contentId,
													}
												)
													.then(() => {
														ToastError(
															0,
															'선택한 콘텐츠가 삭제되었습니다.',
															'콘텐츠 삭제 완료'
														);

														navigate(`/contents`, { replace: true });
													})
													.catch(() => {
														ToastError(null, '권한이 없습니다.');
													});
											} else {
												ToastError(null, '권한이 없습니다.');
											}
										}}
									/>
								)}
							</Popup>
						</>
					}
				/>
			)}
			<Stack
				direction={'row'}
				sx={{
					width: '100%',
					height: '100%',
					borderTop: `1px solid #ced3d6`,
				}}
			>
				{isLoading ? (
					<Box
						sx={{
							width: '100%',
							height: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<PageLoading />
					</Box>
				) : (
					<>
						{status.code === 200 ? (
							<>
								<Stack
									sx={theme => ({
										flex: 0.6,
										minWidth: '500px',
										borderRight: `1px solid #CED3D6`,
									})}
								>
									<Box
										sx={theme => ({
											borderBottom: `1px solid #CED3D6`,
											px: 4,
											position: 'relative',
										})}
									>
										<Tabs
											value={currentTab.label}
											onChange={handleTabChange}
											TabIndicatorProps={{
												sx: theme => ({
													height: '4px',
													// position: 'relative',
												}),
											}}
										>
											{TABS.map((tab, idx) => (
												<Tab
													key={idx}
													value={tab.label}
													label={tab.label}
													disableRipple
													sx={theme => ({
														minWidth: 'auto',
														p: '16px 24px',
														fontSize: 20,
														fontWeight: 600,
														fontFamily: 'Noto Sans KR',

														// '&.Mui-selected': {
														// 	color: '#2A53FF',
														// },
													})}
												/>
											))}
										</Tabs>
										<Chatbot content={content} />
									</Box>

									<Box
										sx={{
											flex: 1,
											overflow: 'auto',
											padding: `16px 32px`,
											boxSizing: 'border-box',
										}}
									>
										{drawLeftView()}
									</Box>
								</Stack>
								<Stack
									spacing={2}
									sx={{
										flex: 0.4,
										minWidth: '40%',
										height: '100%',
										padding: '16px 32px',
										boxSizing: 'border-box',
									}}
								>
									<Stack spacing={2} direction={'row'} sx={{ alignItems: 'center' }}>
										<Typography
											sx={theme => ({
												fontSize: 20,
												fontWeight: 600,
												color: '#28323C',
												fontFamily: 'Noto Sans KR',
											})}
										>
											음성 기록
										</Typography>
										<Stack spacing={1} direction={'row'}>
											<Button
												sx={theme => ({
													minWidth: 0,
													padding: 0,
													width: 32,
													height: 32,
													border: 'none',
													pointerEvents: 'none',
												})}
											>
												<RecordIcon />
											</Button>
											<Button
												sx={theme => ({
													minWidth: 0,
													padding: 0,
													width: 32,
													height: 32,
													border: 'none',
													pointerEvents: 'none',
												})}
											>
												<DesktopIcon />
											</Button>
										</Stack>
									</Stack>

									{content && content.file ? (
										<TranscriptionV2
											isEditable={isEditable}
											type={content.file.mimeType.split('/')[0]}
											source={makeStreamUrl(content.meta.contentId)}
											contentId={content.meta.contentId}
											data={content.mergedSegments ?? content.segments}
											bookmarks={
												content.bookmarks.filter(
													bookmark => bookmark.key === 'mergedSegments'
												)?.[0]?.data
											}
											speakerInfo={content.speakerInfo}
											onRefresh={() => refreshContent(content.meta.contentId)}
										/>
									) : (
										<></>
									)}
								</Stack>
							</>
						) : (
							drawContentStatus()
						)}
					</>
				)}
			</Stack>
		</>
	);
});

export default ContentV2;

const AddButton = ({ onAdd }) => {
	const containerRef = useRef();
	const inputRef = useRef();
	const [isOpen, setIsOpen] = useState(false);
	const [text, setText] = useState('');

	useEffect(() => {
		const handleClickOutside = event => {
			if (containerRef.current && !containerRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (isOpen) {
			inputRef.current.focus();
		} else {
			if (text.trim() !== '') {
				onAdd && onAdd(text);
			}

			setText('');
		}
	}, [isOpen]);

	const onToggleOpen = e => {
		e.stopPropagation();
		setIsOpen(prev => !prev);
	};

	return (
		<Box
			ref={containerRef}
			sx={{
				display: 'flex',
				alignItems: 'center',
				position: 'relative',
				width: 160,
			}}
		>
			<Paper
				sx={{
					display: 'flex',
					alignItems: 'center',
					width: isOpen ? '157px' : '25px',
					height: '40px',
					borderRadius: '20px',
					padding: '0 8px',
					transition: 'width 0.3s ease-in-out, border 0.3s ease-in-out',
					border: isOpen ? '2px solid #5C63D8' : '2px solid transparent',
					position: 'relative',
					boxShadow: 'none',
				}}
			>
				<InputBase
					inputRef={inputRef}
					value={text}
					onChange={e => setText(e.target.value)}
					sx={{
						flex: 1,
						ml: isOpen ? 1 : 0,
						opacity: isOpen ? 1 : 0,
						transition: 'all 0.3s ease-in-out',
						paddingRight: '35px',
					}}
					onKeyDown={e => {
						if (e.key === 'Enter') {
							setIsOpen(false);
						} else if (e.key === 'Escape') {
							setText('');
							setIsOpen(false);
						}
					}}
					spellCheck={false}
				/>

				<Box sx={{ position: 'relative' }}>
					<IconButton
						onClick={onToggleOpen}
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-160%, -50%)',
							width: '20px',
							height: '20px',
							// backgroundColor: '#FFFFFF',
							borderRadius: '50%',
							boxShadow: '0 0 4px rgba(0,0,0,0.2)',
							zIndex: 1,
							'&:hover': {
								backgroundColor: '#F0F0F0',
							},
						}}
					>
						<AddIcon sx={{ fontSize: 16, color: '#4D5256', stroke: '#E1E4E6' }} />
					</IconButton>
				</Box>
			</Paper>
		</Box>
	);
};
