import { create } from 'zustand';
import axios from 'axios';

const useInboxStore = create(set => ({
	messages: [],
	refreshInbox: accessToken => {
		return new Promise(async (resolve, reject) => {
			console.log('[useInboxStore] refreshInbox');
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};
				const res = await axios.get(`${process.env.REACT_APP_API_URL}/inbox`, {
					headers,
				});

				switch (res.data.httpCode) {
					case 200:
						set(() => ({ messages: [...res.data.data] }));
						resolve({ code: 200, message: 'ok', data: res.data.data });
						break;
					case 401:
						//권한 없음
						resolve({ code: 401, message: res.data.message });
						break;
					default:
						reject();
						break;
				}
			} catch (e) {
				console.error(e);
				reject();
			}
		});
	},
}));

export default useInboxStore;
