// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/content/summaryV2/SummaryV2.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,SAAS;CACT,WAAW;CACX,YAAY;AACb","sourcesContent":[".summary-container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 10px;\n\twidth: 100%;\n\theight: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
