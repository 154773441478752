import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import './TranscriptionV2.css';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { Box, Button, FormControl, MenuItem, Select, Stack, SvgIcon, Typography, Switch, styled } from '@mui/material';

import { ReactComponent as SearchIcon } from './img/searchvs.svg';
import { ReactComponent as SoundIcon } from './img/soundvs.svg';
import { ReactComponent as PlaybackRateVsIcon } from './img/palybackvs.svg';
import { ReactComponent as SizeIcon } from './img/size.svg';
import { ReactComponent as PlayVsIcon } from './img/playvs.svg';
import { ReactComponent as PauseVsIcon } from './img/pausevs.svg';
import { ReactComponent as MutedIcon } from './img/muted.svg';
import { ReactComponent as ArrowIcon } from './img/arrow.svg';
import { ReactComponent as ClearIcon } from './img/clear.svg';
import { ReactComponent as PipIcon } from './img/pip.svg';

import { useCookies } from 'react-cookie';
import useContentsStore from '../../../store/ContentsStore';
import useFunctionStore from '../../../store/FunctionStore';
import { ToastError, ToastInfo } from '../../common/toast/Toast';
import { Virtuoso } from 'react-virtuoso';
import SttRowV2 from './SttRowV2';
import { onCopyText } from '../../../util/Util';
import DeleteButton from '../../common/button/DeleteButton';
import ModalConfirm from '../../common/modal/ModalConfirm';

const playRates = [0.5, 1, 1.5, 2];

const TranscriptionV2 = ({
	isEditable,
	type,
	contentId,
	source,
	data = [],
	speakerInfo,
	bookmarks,
	onRefresh = () => {},
}) => {
	const dataContainerRef = useRef();
	const virtualRef = useRef(null);
	const audioRef = useRef();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { addBookmark, deleteBookmark, onAppendDataToNote } = useContentsStore();

	const [isTracking, setIsTracking] = useState(false);
	const [keyword, setKeyword] = useState('');
	const [searchResult, setSearchResult] = useState({
		currentIndex: 0,
		total: 0,
	});

	//Audio State
	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);
	const [playing, setPlaying] = useState(false);
	const [isDragging, setIsDragging] = useState(false);
	const [playbackRate, setPlaybackRate] = useState(1);
	const [isMuted, setIsMuted] = useState(false);
	const [seekStep, setSeekStep] = useState({ prev: 5, next: 5 });
	const [isPip, setIsPip] = useState(false);
	const [onReady, setOnReady] = useState(false);

	const highlightedData = useMemo(() => {
		if (!keyword) {
			return data.map(item => [item.text]);
		}

		const escapedKeyword = keyword.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
		const regex = new RegExp(`(${escapedKeyword})`, 'gi');

		return data.map(item => {
			return item.text.split(regex);
		});
	}, [data, keyword]);

	useEffect(() => {
		if (!keyword) {
			setSearchResult({ currentIndex: 0, total: 0 });
		}
	}, [keyword]);

	const matchIndices = useMemo(() => {
		if (!keyword) return [];
		let indices = [];
		highlightedData.forEach((parts, dataIndex) => {
			parts.forEach((part, partIndex) => {
				if (part.toLowerCase() === keyword.toLowerCase()) {
					indices.push({ dataIndex, partIndex });
				}
			});
		});

		return indices;
	}, [highlightedData, keyword]);

	const totalMatches = matchIndices.length;

	useEffect(() => {
		const handleOnWheel = () => {
			setIsTracking(false);
		};

		if (dataContainerRef.current) {
			dataContainerRef.current.addEventListener('wheel', handleOnWheel);
		}

		return () => {
			if (document.pictureInPictureElement) {
				document.exitPictureInPicture();
			}

			if (dataContainerRef.current) {
				dataContainerRef.current.removeEventListener('wheel', handleOnWheel);
			}
		};
	}, []);

	useEffect(() => {
		if (playing) {
			if (data && data.length) {
				const milliseconds = currentTime * 1000;

				const target = findNextIndex(data, milliseconds);

				if (target > -1) {
					if (isTracking) {
						onScrollToIndex(target);
					}

					const listItems = document.querySelectorAll('.stt-row-container');

					if (listItems.length) {
						listItems.forEach(item => item.classList.remove('highlighted'));

						const elementTarget = Array.from(listItems).find(
							element =>
								element.classList.contains(`stt-row-container`) &&
								element.classList.contains(`${target}`)
						);

						if (elementTarget) {
							elementTarget.classList.add('highlighted');
						}
					}
				}
			}
		} else {
			const listItems = document.querySelectorAll('.stt-row-container');
			if (listItems.length) {
				listItems.forEach(item => item.classList.remove('highlighted'));
			}
		}
	}, [currentTime, playing, isTracking]);

	useEffect(() => {
		if (audioRef && audioRef.current) {
			if (playing) {
				setIsTracking(true);
				audioRef.current.play();
			} else {
				audioRef.current.pause();
			}
		}
	}, [playing]);

	useEffect(() => {
		if (isDragging) {
			setPlaying(false);
		}
	}, [isDragging]);

	useEffect(() => {
		let intervalId = null;

		if (type === 'video') {
			const videoElement = audioRef.current;

			if (!videoElement) return;

			if (isPip) {
				const updateCurrentTime = () => {
					setCurrentTime(videoElement.currentTime);
				};

				intervalId = setInterval(() => {
					if (playing) {
						updateCurrentTime();
					}
				}, 1000);
			}

			const handlePause = () => {
				setIsTracking(false);
				setPlaying(false);
			};

			const handlePlay = () => {
				setIsTracking(true);
				setPlaying(true);
			};

			videoElement.addEventListener('pause', handlePause);
			videoElement.addEventListener('play', handlePlay);

			return () => {
				clearInterval(intervalId);
				videoElement.removeEventListener('pause', handlePause);
				videoElement.removeEventListener('play', handlePlay);
			};
		}
	}, [playing, isPip, type]);

	useEffect(() => {
		if (virtualRef.current && matchIndices[searchResult.currentIndex]) {
			onScrollToIndex(matchIndices[searchResult.currentIndex].dataIndex, 'auto');
		}
	}, [searchResult, matchIndices]);

	useEffect(() => {
		if (type === 'video') {
			const videoElement = audioRef.current;

			const handleEnterPiP = () => {
				setIsPip(true);
			};

			const handleLeavePiP = () => {
				setIsPip(false);
			};

			videoElement.addEventListener('enterpictureinpicture', handleEnterPiP);
			videoElement.addEventListener('leavepictureinpicture', handleLeavePiP);

			return () => {
				videoElement.removeEventListener('enterpictureinpicture', handleEnterPiP);
				videoElement.removeEventListener('leavepictureinpicture', handleLeavePiP);
			};
		}
	}, [type, isPip]);

	const findNextIndex = (arr, time) => {
		let low = 0;
		let high = arr.length - 1;

		while (low <= high) {
			const mid = Math.floor((low + high) / 2);
			if (arr[mid].startTime <= time && (mid === arr.length - 1 || arr[mid + 1].startTime > time)) {
				return mid;
			} else if (arr[mid].startTime <= time) {
				low = mid + 1;
			} else {
				high = mid - 1;
			}
		}

		return -1;
	};

	const onScrollToIndex = (index, behavior = 'smooth') => {
		if (virtualRef.current) {
			virtualRef.current.scrollToIndex({
				index,
				align: 'center',
				behavior,
			});
		}
	};

	const onChangePlaybackRate = event => {
		setPlaybackRate(event.target.value);

		if (audioRef.current) {
			audioRef.current.playbackRate = event.target.value;
		}
	};

	const toggleMute = () => {
		if (audioRef.current) {
			const newMutedState = !isMuted;
			audioRef.current.muted = newMutedState;
			setIsMuted(newMutedState);
		}
	};

	const convertTimeFormat = milliseconds => {
		let seconds = Math.floor(milliseconds / 1000);
		let minutes = Math.floor(seconds / 60);
		let hours = Math.floor(minutes / 60);

		seconds = seconds % 60;
		minutes = minutes % 60;

		const pad = num => num.toString().padStart(2, '0');

		if (hours > 0) {
			return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
		} else {
			return `${pad(minutes)}:${pad(seconds)}`;
		}
	};

	const onSeekTime = useCallback(startSec => {
		if (audioRef && audioRef.current) {
			audioRef.current.currentTime = startSec;
			if (type === 'audio') {
				setPlaying(true);
			} else {
				audioRef.current.play();
			}
		}
	}, []);

	const onTogglePlay = () => {
		if (onReady) {
			if (audioRef.current) {
				if (playing) {
					audioRef.current.pause();
				} else {
					audioRef.current.play();
				}
				setPlaying(!playing);
			}
		} else {
			ToastError(null, '음성 파일이 로드되지 않아 재생할 수 없습니다.');
		}
	};

	const handleTimeUpdate = () => {
		if (audioRef && audioRef.current) {
			setCurrentTime(audioRef.current.currentTime);
		}
	};

	const handleLoadedMetadata = data => {
		setDuration(data.target.duration);

		setOnReady(true);
	};

	const onCheckBookmarkSegment = segment => {
		return bookmarks && bookmarks.some(bookmark => bookmark.segmentId === segment.segmentId);
	};

	const onToggleBookmark = segment => {
		if (isEditable) {
			if (onCheckBookmarkSegment(segment)) {
				deleteBookmark(
					cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
					contentId,
					'mergedSegments',
					segment.segmentId
				)
					.then(res => {
						switch (res.code) {
							case 200:
								ToastInfo('북마크가 삭제 되었습니다.');
								onRefresh();
								break;
							case 401:
								ToastError(0, '권한이 없습니다.');
								break;
							default:
								ToastError(res.message);
								break;
						}
					})
					.catch(e => {
						ToastError(1016);
					});
			} else {
				addBookmark(
					cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
					contentId,
					'mergedSegments',
					segment,
					segment.segmentId
				)
					.then(res => {
						switch (res.code) {
							case 200:
								ToastInfo('북마크가 추가 되었습니다.');
								onRefresh();
								break;
							case 401:
								ToastError(0, '권한이 없습니다.');
								break;
							default:
								ToastError(res.message);
								break;
						}
					})
					.catch(e => {
						ToastError(1016);
					});
			}
		} else {
			ToastError(0, '권한이 없습니다.');
		}
	};

	const onPasteNote = data => {
		if (isEditable) {
			onAppendDataToNote(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId, {
				itemKey: 'segments',
				itemValue: data,
			}).then(() => {
				// noteRef && noteRef.current && noteRef.current.refreshNote();
			});
		} else {
			ToastInfo('권한이 없습니다.');
		}
	};

	const drawPlayer = () => {
		const handleSliderChangeComplete = value => {
			audioRef.current.currentTime = value;
			setCurrentTime(value);
			setIsDragging(false);
			setPlaying(true);
		};

		return (
			<>
				{type === 'audio' && (
					<Box sx={{ display: 'none' }}>
						<audio
							ref={audioRef}
							src={source}
							preload='auto'
							onTimeUpdate={handleTimeUpdate}
							onLoadedMetadata={handleLoadedMetadata}
						/>
					</Box>
				)}

				{type === 'video' && (
					<>
						<Stack spacing={1} direction='column'>
							<Stack spacing={0.5} direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
								<SvgIcon inheritViewBox sx={{ width: 30, height: 30 }}>
									<PipIcon />
								</SvgIcon>
								<Typography
									sx={{ fontSize: 16, fontWeight: 500, fontFamily: 'Noto Sans KR', color: '#4D5256' }}
								>
									PIP 모드
								</Typography>
								<AntSwitch
									checked={isPip}
									onChange={() => {
										if (isPip) {
											document.exitPictureInPicture();
										} else {
											if (audioRef && audioRef.current) {
												audioRef.current.requestPictureInPicture();
											}
										}

										setIsPip(prev => !prev);
									}}
								/>
							</Stack>
							<Box
								style={{
									display: isPip ? 'none' : 'flex',
									flex: 1,
								}}
							>
								<video
									style={{ width: '100%', height: '100%', maxHeight: 360, objectFit: 'fill' }}
									ref={audioRef}
									src={source}
									preload='auto'
									controls
									onTimeUpdate={handleTimeUpdate}
									onLoadedMetadata={handleLoadedMetadata}
								/>
							</Box>
						</Stack>
					</>
				)}

				<Stack
					spacing={1.5}
					sx={{
						display: type === 'video' ? (!isPip ? 'none' : 'flex') : 'flex',
						flexShrink: 0,
					}}
				>
					<Stack spacing={1.5}>
						<Stack spacing={1}>
							<Slider
								value={currentTime}
								max={duration}
								step={0.01}
								onChange={value => {
									if (!isDragging) {
										setIsDragging(true);
									}

									handleSliderChangeComplete(value);
								}}
								onChangeComplete={handleSliderChangeComplete}
							/>
							<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
								<Typography sx={{ fontSize: 14, fontWeight: 500, color: '#878D91' }}>
									{convertTimeFormat(currentTime * 1000)}
								</Typography>
								<Typography sx={{ fontSize: 14, fontWeight: 500, color: '#878D91' }}>
									{convertTimeFormat(duration * 1000)}
								</Typography>
							</Stack>
						</Stack>
						<Stack direction={'row'} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
							{isMuted ? (
								<MutedIcon onClick={toggleMute} style={{ cursor: 'pointer' }} />
							) : (
								<SoundIcon onClick={toggleMute} style={{ cursor: 'pointer' }} />
							)}

							<Stack
								spacing={4}
								direction={'row'}
								sx={{ alignItems: 'center', justifyContent: 'space-between' }}
							>
								<Box
									onClick={() => onSeekTime(currentTime - Number(seekStep.prev))}
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										position: 'relative',
										cursor: 'pointer',
										userSelect: 'none',
									}}
								>
									<PlaybackRateVsIcon />
									<Box
										sx={theme => ({
											fontSize: 14,
											fontWeight: 600,
											color: '#374553',
											position: 'absolute',
										})}
									>
										{seekStep.prev}
									</Box>
								</Box>
								<Box
									onClick={onTogglePlay}
									sx={{
										display: 'flex',
										cursor: 'pointer',
									}}
								>
									{playing ? <PauseVsIcon /> : <PlayVsIcon />}
								</Box>
								<Box
									onClick={() => onSeekTime(currentTime + Number(seekStep.next))}
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										position: 'relative',
										cursor: 'pointer',
										transform: 'scaleX(-1)',
									}}
								>
									<PlaybackRateVsIcon />

									<Box
										sx={theme => ({
											fontSize: 14,
											fontWeight: 600,
											color: '#374553',
											position: 'absolute',
											transform: 'scaleX(-1)',
										})}
									>
										{seekStep.next}
									</Box>
								</Box>
							</Stack>

							<Box
								sx={theme => ({
									display: 'flex',
									minWidth: '54px',
									height: '30px',
									//backgroundColor: theme.palette.light[200],
									backgroundColor: 'hsla(192, 14%, 93%, 1)',
									borderRadius: '4px',
									boxSizing: 'border-box',
									px: 0.5,
								})}
							>
								<Select
									value={playbackRate}
									onChange={onChangePlaybackRate}
									disableUnderline
									variant='standard'
									displayEmpty
									MenuProps={{
										PaperProps: {
											style: {
												border: '1px solid hsla(192, 14%, 93%, 1)',
											},
										},
									}}
									style={{ minWidth: 60, minHeight: 30 }} // Select의 기본 Width 설정
									sx={theme => ({
										'& .MuiSelect-select': {
											p: 0,
											height: 30,
											display: 'flex',
											flexGrow: 1,
											alignItems: 'center',
											justifyContent: 'center',
											fontSize: 14,
											fontWeight: 600,
											fontFamily: 'Noto Sans KR',
											color: '#4D5256',
										},
									})}
								>
									{playRates.map(rate => (
										<MenuItem
											key={rate}
											value={rate}
											sx={theme => ({
												display: 'flex',
												minWidth: '54px',
												height: '30px',
												borderRadius: '4px',
												boxSizing: 'border-box',
												fontSize: 14,
												fontWeight: 600,
												fontFamily: 'Noto Sans KR',
												color: '#4D5256',
											})}
										>
											{rate}X
										</MenuItem>
									))}
								</Select>
							</Box>
						</Stack>
					</Stack>
				</Stack>
			</>
		);
	};

	const drawSearchBar = () => {
		return (
			<>
				<Stack
					spacing={1}
					direction={'row'}
					sx={theme => ({
						alignItems: 'center',
						flex: 1,
						height: '40px',
						border: `1px solid #E1E4E6`,
						backgroundColor: `#F9FAFB`,
						borderRadius: '8px',
					})}
				>
					<SearchIcon style={{ width: '24px', height: '24px', fontSize: '17px', marginLeft: '10px' }} />
					<input
						type='text'
						value={keyword}
						onChange={e => {
							setKeyword(e.target.value);
							setIsTracking(false);
						}}
						onKeyDown={e => {
							if (e.key === 'Escape') {
								setKeyword('');
							} else if (e.key === 'Enter') {
								setSearchResult(prev => ({
									...prev,
									currentIndex: (prev.currentIndex + 1) % totalMatches,
								}));
							}
						}}
						placeholder='검색어를 입력해주세요.'
						style={{
							display: 'flex',
							flexGrow: 1,
							backgroundColor: 'hsla(210, 20%, 98%, 1)',
							border: 'none',
							minWidth: '0px',
							outline: 'none',
							fontSize: '16px',
							fontWeight: 400,
							lineHeight: '22px',
							boxSizing: 'border-box',
						}}
					/>

					<Stack
						spacing={1.25}
						direction={'row'}
						sx={{
							alignItems: 'center',
							userSelect: 'none',
							color: '#858c99',
							fontSize: '12px',
							minWidth: '106px',
							paddingRight: '10px',
						}}
					>
						{keyword && (
							<>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										cursor: 'pointer',
									}}
								>
									{totalMatches > 0 ? searchResult.currentIndex + 1 : 0} / {totalMatches}
								</Box>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										cursor: 'pointer',
									}}
									onClick={() => {
										setSearchResult(prev => ({
											...prev,
											currentIndex: (prev.currentIndex - 1 + totalMatches) % totalMatches,
										}));
										setIsTracking(false);
									}}
								>
									<ArrowIcon />
								</Box>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										cursor: 'pointer',
										transform: 'scaleY(-1)',
									}}
									onClick={() => {
										setSearchResult(prev => ({
											...prev,
											currentIndex: (prev.currentIndex + 1) % totalMatches,
										}));

										setIsTracking(false);
									}}
								>
									<ArrowIcon />
								</Box>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										cursor: 'pointer',
									}}
									onClick={() => {
										setKeyword('');
										setSearchResult({
											currentIndex: 0,
											total: 0,
										});
									}}
								>
									<ClearIcon />
								</Box>
							</>
						)}
					</Stack>
				</Stack>
				<Button
					sx={{
						width: 77,
						boxSizing: 'border-box',
						fontSize: 16,
						fontWeight: 500,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						border: '1px solid #CED3D6',
						fontFamily: 'Noto Sans KR',
					}}
					variant='outlined'
					color='#4D5256'
				>
					교정
				</Button>
				<ModalConfirm
					title='음성 기록을 초기화할까요?'
					description='수정된 모든 음성 기록이 초기화됩니다.'
					onConfirm={() => ToastInfo('서비스 준비 중입니다.')}
				>
					<Button
						sx={{
							width: 77,
							height: '100%',
							boxSizing: 'border-box',
							fontSize: 16,
							fontWeight: 500,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							padding: '0',
							border: '1px solid #CED3D6',
							fontFamily: 'Noto Sans KR',
						}}
						variant='outlined'
						color='#4D5256'
					>
						초기화
					</Button>
				</ModalConfirm>
			</>
		);
	};

	const drawSttRows = () => {
		return (
			<Box ref={dataContainerRef} sx={{ width: '100%', height: '100%', overflow: 'auto', position: 'relative' }}>
				<Virtuoso
					ref={virtualRef}
					style={{ height: '100%' }}
					totalCount={data.length}
					itemContent={index => (
						<SttRowV2
							contentId={contentId}
							data={data[index]}
							index={index}
							speakerInfo={speakerInfo}
							searchResult={searchResult}
							matchIndices={matchIndices}
							highlightedData={highlightedData}
							keyword={keyword}
							onSeekTime={startTime => onSeekTime(startTime)}
							onToggleBookmark={() => onToggleBookmark(data[index])}
							onCopyClipboard={text => onCopyText(text, () => ToastInfo('텍스트가 복사되었습니다.'))}
							onPasteNote={() => onPasteNote(data[index])}
							onRefresh={onRefresh}
							isBookmark={onCheckBookmarkSegment(data[index])}
						/>
					)}
				/>
				{playing && !isTracking && (
					<Button
						//className='tracking'
						sx={{
							position: 'absolute',
							left: '42%',
							bottom: '40px',
							padding: '10px 15px',
							borderRadius: '10px',
							backgroundColor: '#fff',
							border: '1px solid #e9e9e9',
							cursor: 'pointer',
						}}
						onClick={() => setIsTracking(true)}
					>
						현재 위치로
					</Button>
				)}
			</Box>
		);
	};

	return (
		<Stack spacing={2} direction='column' sx={{ flex: 1 }}>
			<Box sx={{ width: '100%' }}>{drawPlayer()}</Box>
			<Box sx={{ width: '100%', height: 40, display: 'flex', gap: '8px' }}>{drawSearchBar()}</Box>
			<Box sx={{ width: '100%', flex: 1, overflow: 'auto' }}>{drawSttRows()}</Box>
		</Stack>
	);
};

export default TranscriptionV2;

const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 56,
	height: 32,
	padding: 0,
	display: 'flex',
	alignItems: 'center',
	'&:active': {
		'& .MuiSwitch-thumb': {
			width: 24,
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			transform: 'translateX(6px)',
		},
	},
	'& .MuiSwitch-switchBase': {
		padding: 2,
		'&.Mui-checked': {
			transform: 'translateX(28px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: '#1890ff',
				...theme.applyStyles('dark', {
					backgroundColor: '#177ddc',
				}),
			},
		},
	},
	'& .MuiSwitch-thumb': {
		boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
		width: 24,
		height: 24,
		borderRadius: '50%',
		transition: theme.transitions.create(['width'], {
			duration: 200,
		}),
		transform: 'translateY(2px)',
	},
	'& .MuiSwitch-track': {
		borderRadius: 24,
		opacity: 1,
		backgroundColor: 'rgba(0,0,0,.25)',
		boxSizing: 'border-box',
		...theme.applyStyles('dark', {
			backgroundColor: 'rgba(255,255,255,.35)',
		}),
	},
}));
