// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-container {
	position: relative;
	display: inline-block;
	cursor: pointer;
}

.tooltip-text {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 5px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
	visibility: visible;
	opacity: 1;
}

.tooltip-top {
	bottom: 125%;
	left: 50%;
	transform: translateX(-50%);
}

.tooltip-right {
	top: 50%;
	left: 125%;
	transform: translateY(-50%);
}

.tooltip-bottom {
	top: 125%;
	left: 50%;
	transform: translateX(-50%);
}

.tooltip-left {
	top: 50%;
	right: 125%;
	transform: translateY(-50%);
}
`, "",{"version":3,"sources":["webpack://./src/components/tooltip/Tooltip.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,qBAAqB;CACrB,eAAe;AAChB;;AAEA;CACC,kBAAkB;CAClB,YAAY;CACZ,uBAAuB;CACvB,WAAW;CACX,kBAAkB;CAClB,kBAAkB;CAClB,YAAY;CACZ,kBAAkB;CAClB,UAAU;CACV,UAAU;CACV,wBAAwB;AACzB;;AAEA;CACC,mBAAmB;CACnB,UAAU;AACX;;AAEA;CACC,YAAY;CACZ,SAAS;CACT,2BAA2B;AAC5B;;AAEA;CACC,QAAQ;CACR,UAAU;CACV,2BAA2B;AAC5B;;AAEA;CACC,SAAS;CACT,SAAS;CACT,2BAA2B;AAC5B;;AAEA;CACC,QAAQ;CACR,WAAW;CACX,2BAA2B;AAC5B","sourcesContent":[".tooltip-container {\n\tposition: relative;\n\tdisplay: inline-block;\n\tcursor: pointer;\n}\n\n.tooltip-text {\n\tvisibility: hidden;\n\twidth: 120px;\n\tbackground-color: black;\n\tcolor: #fff;\n\ttext-align: center;\n\tborder-radius: 5px;\n\tpadding: 5px;\n\tposition: absolute;\n\tz-index: 1;\n\topacity: 0;\n\ttransition: opacity 0.3s;\n}\n\n.tooltip-container:hover .tooltip-text {\n\tvisibility: visible;\n\topacity: 1;\n}\n\n.tooltip-top {\n\tbottom: 125%;\n\tleft: 50%;\n\ttransform: translateX(-50%);\n}\n\n.tooltip-right {\n\ttop: 50%;\n\tleft: 125%;\n\ttransform: translateY(-50%);\n}\n\n.tooltip-bottom {\n\ttop: 125%;\n\tleft: 50%;\n\ttransform: translateX(-50%);\n}\n\n.tooltip-left {\n\ttop: 50%;\n\tright: 125%;\n\ttransform: translateY(-50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
