import React, { useState } from 'react';
import styled from 'styled-components';
import InputField from './InputField';

import { ReactComponent as DeleteIcon } from './img/delete.svg';
import { ReactComponent as VisibleIcon } from './img/pw_visible.svg';
import { ReactComponent as InvisibleIcon } from './img/pw_invisible.svg';

function Input({ value, setValue, placeholder, type, pwRef = null, onEnter = () => {} }) {
	const [isFocused, setIsFocused] = useState(false);
	const [isVisiblePw, setIsVisiblePw] = useState(false);

	const drawRightButtons = () => {
		const onClearInput = () => {
			setValue('');
		};

		const onToggleVisiblePw = () => {
			setIsVisiblePw(prev => !prev);
		};

		switch (type) {
			case 'text':
				return (
					<Button onClick={onClearInput} style={{ visibility: value ? 'visible' : 'hidden' }}>
						<DeleteIcon />
					</Button>
				);

			case 'password':
				return (
					<>
						<Button onClick={onToggleVisiblePw}>{isVisiblePw ? <VisibleIcon /> : <InvisibleIcon />}</Button>
						<Button onClick={onClearInput} style={{ display: value ? 'flex' : 'none' }}>
							<DeleteIcon />
						</Button>
					</>
				);
		}
	};

	return (
		<Container isFocused={isFocused}>
			<Wrapper>
				<InputField
					pwRef={pwRef}
					value={value}
					setValue={setValue}
					placeholder={placeholder}
					setIsFocused={setIsFocused}
					type={type}
					isVisiblePw={isVisiblePw}
					onEnter={onEnter}
				/>
				{drawRightButtons()}
			</Wrapper>
		</Container>
	);
}

const Container = styled.div`
	align-items: center;
	border-bottom: 1px solid ${({ isFocused }) => (isFocused ? '#005cff' : '#000')};
	background-color: var(--light-background, #fff);
	display: flex;
	min-height: 48px;
	width: 100%;
	padding: 14px 8px;
	transition: border-color 0.2s;
	box-sizing: border-box;
`;

const Wrapper = styled.div`
	display: flex;
	gap: 10px;
	width: 100%;
	margin: auto 0;
`;

const Button = styled.button`
	display: flex;
	border: none;
	background-color: transparent;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	height: 25px;
	padding: 0px;
`;

export default Input;
