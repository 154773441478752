// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-list-container {
	/* min-width: 185px; */
	max-width: 500px;
	padding: 10px 20px;
	border: 1px solid #a9afb3;
	box-sizing: border-box;
	box-shadow: 0px 3px 4px 0px #00000026;
	background-color: #fff;
	border-radius: 5px;
}

.user-list-container > .top {
	display: flex;
	gap: 5px;
	margin-bottom: 12px;
	align-items: center;
}

.user-list-container > .top > .title {
	font-size: 10px;
	color: #181818;
}

.user-list-container > .top > .total {
	font-size: 14px;
	font-weight: 700;
	color: #2a53ff;
}

.user-list-container > .users {
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
}

.user-list-container > .users > .user {
	padding: 6px 14px;
	border-radius: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/popup/userList/UserList.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,gBAAgB;CAChB,kBAAkB;CAClB,yBAAyB;CACzB,sBAAsB;CACtB,qCAAqC;CACrC,sBAAsB;CACtB,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,QAAQ;CACR,mBAAmB;CACnB,mBAAmB;AACpB;;AAEA;CACC,eAAe;CACf,cAAc;AACf;;AAEA;CACC,eAAe;CACf,gBAAgB;CAChB,cAAc;AACf;;AAEA;CACC,aAAa;CACb,SAAS;CACT,eAAe;AAChB;;AAEA;CACC,iBAAiB;CACjB,mBAAmB;AACpB","sourcesContent":[".user-list-container {\n\t/* min-width: 185px; */\n\tmax-width: 500px;\n\tpadding: 10px 20px;\n\tborder: 1px solid #a9afb3;\n\tbox-sizing: border-box;\n\tbox-shadow: 0px 3px 4px 0px #00000026;\n\tbackground-color: #fff;\n\tborder-radius: 5px;\n}\n\n.user-list-container > .top {\n\tdisplay: flex;\n\tgap: 5px;\n\tmargin-bottom: 12px;\n\talign-items: center;\n}\n\n.user-list-container > .top > .title {\n\tfont-size: 10px;\n\tcolor: #181818;\n}\n\n.user-list-container > .top > .total {\n\tfont-size: 14px;\n\tfont-weight: 700;\n\tcolor: #2a53ff;\n}\n\n.user-list-container > .users {\n\tdisplay: flex;\n\tgap: 10px;\n\tflex-wrap: wrap;\n}\n\n.user-list-container > .users > .user {\n\tpadding: 6px 14px;\n\tborder-radius: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
