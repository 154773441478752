import React from 'react';
import { ReactComponent as QuestionIcon } from './img/question.svg';
import { ReactComponent as WarningIcon } from './img/warning.svg';

const Confirm = ({
	type,
	title,
	description,
	buttonStyle,
	onCancelText = 'Cancel',
	onConfirmText = 'Confirm',
	onCancel = () => {},
	onConfirm = () => {},
}) => {
	const drawIcon = () => {
		switch (type) {
			case 'question':
				return <QuestionIcon />;

			default:
				return <WarningIcon />;
		}
	};

	return (
		<div className='popup-confirm-container'>
			<div className='title'>
				<div className='image'>{drawIcon()}</div>
				<div className='text'>{title}</div>
			</div>
			<div className='description'>{description}</div>
			<div className='separator' />
			<div className='buttons'>
				<button className='cancel' onClick={onCancel}>
					{onCancelText}
				</button>
				<button className='confirm' style={buttonStyle} onClick={onConfirm}>
					{onConfirmText}
				</button>
			</div>
		</div>
	);
};

export default Confirm;
