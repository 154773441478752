import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { saveAs } from 'file-saver'; // 파일 저장을 위해 FileSaver.js 사용
import './MyTemplate.css';

import {
	ClassicEditor,
	AccessibilityHelp,
	AutoLink,
	Autosave,
	Bold,
	Essentials,
	FindAndReplace,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	Highlight,
	Italic,
	Link,
	Paragraph,
	SelectAll,
	Strikethrough,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	Underline,
	Undo,
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';

import useContentsStore from '../../../store/ContentsStore';
import { useCookies } from 'react-cookie';
import { Box, Button, Card, CardContent, Stack, Typography } from '@mui/material';
import TimbloButton from '../../button/TimbloButton';
import TemplateMenu from './TemplateMenu';
import TemplateDatas from './TemplateDatas';
import useTemplateStore from '../../../store/TemplateStore';

const editorConfig = {
	toolbar: {
		items: [
			'undo',
			'redo',
			'|',
			'findAndReplace',
			'selectAll',
			'|',
			'fontSize',
			'fontFamily',
			'fontColor',
			'fontBackgroundColor',
			'|',
			'bold',
			'italic',
			'underline',
			'strikethrough',
			'|',
			'link',
			'insertTable',
			'highlight',
			'|',
			'accessibilityHelp',
		],
		// shouldNotGroupWhenFull: false,
	},
	plugins: [
		AccessibilityHelp,
		AutoLink,
		Autosave,
		Bold,
		Essentials,
		FindAndReplace,
		FontBackgroundColor,
		FontColor,
		FontFamily,
		FontSize,
		Highlight,
		Italic,
		Link,
		Paragraph,
		SelectAll,
		Strikethrough,
		Table,
		TableCaption,
		TableCellProperties,
		TableColumnResize,
		TableProperties,
		TableToolbar,
		Underline,
		Undo,
	],
	fontFamily: {
		supportAllValues: true,
	},
	fontSize: {
		options: [10, 12, 14, 'default', 18, 20, 22],
		supportAllValues: true,
	},
	language: 'ko',
	table: {
		contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
	},
	isReadOnly: true,
};

const WAITING_TIME = 1500;

const MyTemplate = forwardRef(({ content, contentId, editable = true }, ref) => {
	const editorRef = useRef(null);
	const timerRef = useRef();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const [text, setText] = useState(null);
	const [instance, setInstance] = useState(null);
	const { selectedContent, getNoteData, updateNoteData } = useContentsStore();
	const { refreshTemplate, templates, getMynote, updateMynote } = useTemplateStore();

	useImperativeHandle(ref, () => ({
		refreshNote: refreshNote,
	}));

	useEffect(() => {
		if (contentId) {
			refreshNote();

			refreshTemplate(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId);
		}

		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, []);

	useEffect(() => {
		if (instance) {
			if (!editable) {
				instance.enableReadOnlyMode('lock');
			}
		}
	}, [instance]);

	useEffect(() => {}, [templates]);

	const refreshNote = () => {
		getNoteData(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId).then(data =>
			setText(data.content)
		);
	};

	const onSave = text => {
		//
		console.log('저장중');
		updateMynote(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId, text)
			.then(() => {
				console.log('[Template] saved');
			})
			.catch(() => {
				//장애 발생
			});

		// updateNoteData(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId, text);
	};

	const [editorData, setEditorData] = useState();

	const handleApplyTemplate = template => {
		const formattedContent = template.content.replace(/ /g, '&nbsp;').replace(/\n/g, '<br>');
		setEditorData(formattedContent); // 선택된 템플릿 데이터를 에디터에 반영
	};

	useEffect(() => {
		getMynote(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId)
			.then(data => {
				setEditorData(data.content);
			})
			.catch(e => {
				alert(`${e.message} (${e.code})`);
			});
	}, [contentId]);

	return (
		<Stack className='note-container'>
			<Stack sx={{ width: '100%', height: '100%' }}>
				<Stack justifyContent={'space-between'} direction={'row'} sx={{ padding: '16px 0' }}>
					<Typography sx={theme => ({ ...theme.typography.title7, display: 'flex', alignItems: 'center' })}>
						적용 가능한 템플릿
						<Typography
							component='span'
							sx={theme => ({
								color: `#292A2B`,
								ml: 1,
								mr: 0.25,
							})}
						>
							{templates.length}
						</Typography>
						개
						<TemplateMenu content={content} onApply={handleApplyTemplate} />
					</Typography>
					<TimbloButton
						label={'템플릿 다운로드'}
						alias='download'
						sx={theme => ({ ...theme.typography.body2 })}
						onClick={() => {
							// HTML -> Plain Text 변환 (개행 처리 포함)
							const tempDiv = document.createElement('div'); // 임시 div 생성
							tempDiv.innerHTML = editorRef.current.editor.getData(); // HTML 데이터 삽입

							// 개행 처리를 포함하여 텍스트 추출
							const textContent = tempDiv.innerHTML
								.replace(/<p>/g, '\n') // <p> 태그를 개행으로 변환
								.replace(/<\/p>/g, '') // </p> 태그 제거
								.replace(/<br\s*\/?>/g, '\n') // <br> 태그를 개행으로 변환
								.replace(/<[^>]+>/g, '') // 모든 HTML 태그 제거
								.replace(/&nbsp;/g, ' '); // &nbsp;를 스페이스로 변환

							// const textContent = tempDiv.textContent;

							// Blob을 생성하고 파일 다운로드
							const blob = new Blob([textContent.trim()], { type: 'text/plain;charset=utf-8' });
							saveAs(blob, content.meta.title + '.txt'); // 파일 이름 설정
						}}
					/>
				</Stack>
				<Box className='template-editor-main-container' sx={{ height: '100%' }}>
					<Box className='editor-container__editor' sx={{ width: '100%', height: '100%' }}>
						{text !== null && (
							<CKEditor
								ref={editorRef}
								editor={ClassicEditor}
								config={{
									...editorConfig,
									autosave: {
										waitingTime: WAITING_TIME,
										save(editor) {
											const doc = document.getElementsByClassName('ck-editor__editable');
											if (doc && doc[0]) {
												onSave(doc[0].innerHTML);
											} else {
												const data = editor.getData();
												onSave(data);
											}
										},
									},
								}}
								onReady={editor => setInstance(editor)}
								data={editorData}
							/>
						)}
					</Box>
				</Box>
			</Stack>
		</Stack>
	);
});

export default MyTemplate;
