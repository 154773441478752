import React, { useEffect, useLayoutEffect, useState } from 'react';
import './UserList.css';
import { getAvatarColor } from '../../../util/Util';

const UserList = ({ title, data = [] }) => {
	const [total, setTotal] = useState(0);

	useLayoutEffect(() => {
		if (data) {
			setTotal(data.length);
		} else {
			setTotal(0);
		}
	}, [data]);

	return (
		<div className='user-list-container'>
			<div className='top'>
				<div className='title'>{title}</div>
				<div className='total'>{total}</div>
			</div>
			<div className='users'>
				{data.map((item, index) => (
					<div
						className='user'
						style={{ background: getAvatarColor(index).background, color: getAvatarColor(index).color }}
					>
						{item.displayName ?? item.nickName ?? item.name ?? item.title}
					</div>
				))}
			</div>
		</div>
	);
};

export default UserList;
