import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import TextBox from '../../components/common/text-box/TextBox';

import Function from '../../components/popup/content/function/Function';
import Popup from 'reactjs-popup';
import Avatar from 'react-avatar';

import useContentsStore from '../../store/ContentsStore';
import { useCookies } from 'react-cookie';
import { ToastError, ToastInfo } from '../../components/common/toast/Toast';

import { getAvatarColor, onCopyText } from '../../util/Util';

import { ReactComponent as DotIcon } from './img/dot.svg';
import Empty from '../../components/page-status/Empty';

const Highlights = ({ data, isHome = false, onClickHighlight = () => {}, onToggleHighlight = () => {}, editable }) => {
	const summaryRef = useRef([]);
	const segmentsRef = useRef([]);

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { getContents, deleteHighlight, selectedContent, onAppendDataToNote } = useContentsStore();

	const [datas, setDatas] = useState([]);

	useEffect(() => {
		if (data && data.length) {
			setDatas(data.map(item => ({ contentId: item.contentId, type: item.key, data: item.data })));
		} else {
			setDatas([]);
		}
	}, [data]);

	const getTitleText = type => {
		switch (type) {
			case 'topics':
				return '주제';

			case 'speakerInfo':
				return ' 참석자';

			case 'keywords':
				return '키워드';

			case 'summary':
			case 'aiSummary':
				return '전체 요약';

			case 'summaryTime':
				return '구간별 주제 & 요약';

			case 'todoList':
			case 'tasks':
				return '할 일';

			case 'issues':
				return '이슈';

			case 'segments':
				return '음성 기록';

			default:
				return type;
		}
	};

	const handleOnClickContent = async contentId => {
		const response = await getContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, contentId);

		if (response.data) {
			onClickHighlight(response.data);
		} else {
			ToastError(response.code, response.message);
		}
	};

	const onDeleteSummaryTimeHighlight = item => {
		deleteHighlight(
			cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
			selectedContent.contentId,
			'summaryTime',
			item.index
		)
			.then(res => {
				switch (res.code) {
					case 200:
						ToastInfo('하이라이트가 삭제 되었습니다.');
						onToggleHighlight();
						break;
					case 401:
						ToastError(0, '권한이 없습니다.');
						break;
					default:
						ToastError(res.message);
						break;
				}
			})
			.catch(e => {
				ToastError(1016);
			});
	};

	const onClickCopySummaryTimeText = idx => {
		const text = summaryRef.current[idx].innerText;

		onCopyText(text, () => ToastInfo('내용이 성공적으로 복사되었습니다.', '내용 복사 완료'));
	};

	const onClickPasteNoteFromSummaryTime = item => {
		if (editable) {
			item = {
				type: 'summaryTime',
				data: item,
			};

			onAppendDataToNote(
				cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
				selectedContent.contentId,
				item
			);
		} else {
			ToastInfo('권한이 없습니다.');
		}
	};

	const onDeleteSegmentHighlight = item => {
		deleteHighlight(
			cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
			selectedContent.contentId,
			'segments',
			item.segmentId
		)
			.then(res => {
				switch (res.code) {
					case 200:
						ToastInfo('하이라이트가 삭제 되었습니다.');
						onToggleHighlight();
						break;
					case 401:
						ToastError(0, '권한이 없습니다.');
						break;
					default:
						ToastError(res.message);
						break;
				}
			})
			.catch(e => {
				ToastError(1016);
			});
	};

	const onClickCopySegmentText = idx => {
		const text = segmentsRef.current[idx].innerText;

		onCopyText(text, () => ToastInfo('내용이 성공적으로 복사되었습니다.', '내용 복사 완료'));
	};

	const onClickPasteNoteFromSegment = item => {
		if (editable) {
			item = {
				type: 'segments',
				data: item,
			};

			onAppendDataToNote(
				cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
				selectedContent.contentId,
				item
			);
		} else {
			ToastInfo('권한이 없습니다.');
		}
	};

	const drawBodyText = (type, data) => {
		switch (type) {
			case 'speakerInfo':
				return (
					<SpeakerList>
						{data.map((speaker, index) => (
							<div
								className='speaker'
								style={{
									backgroundColor: getAvatarColor(speaker.speakerId).background,
									color: getAvatarColor(speaker.speakerId).color,
								}}
							>
								{speaker.displayName ?? speaker.name}
							</div>
						))}
					</SpeakerList>
				);

			case 'keywords':
				return (
					<KeywordList>
						{data.map(keyword => (
							<div className='keyword'># {keyword}</div>
						))}
					</KeywordList>
				);

			case 'segments': {
				const getSpeaker = speakerId => {
					const speaker = data.speakerInfo && data.speakerInfo.find(item => item.speakerId === speakerId);
					if (speaker) {
						return speaker;
					} else {
						return { name: '알 수 없음' };
					}
				};

				const convertTimeFormat = milliseconds => {
					let seconds = Math.floor(milliseconds / 1000);
					let minutes = Math.floor(seconds / 60);
					let hours = Math.floor(minutes / 60);

					seconds = seconds % 60;
					minutes = minutes % 60;

					const pad = num => num.toString().padStart(2, '0');

					if (hours > 0) {
						return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
					} else {
						return `${pad(minutes)}:${pad(seconds)}`;
					}
				};

				return (
					<SegmentList>
						{data.map((item, index) => (
							<div className='row' key={index} ref={el => (segmentsRef.current[index] = el)}>
								<div className='top'>
									{!isHome && (
										<Popup
											trigger={
												<div className='dot'>
													<DotIcon />
												</div>
											}
											position={['bottom right', 'top right']}
											keepTooltipInside='.text-box-container'
										>
											{close => (
												<Function
													onClickHighlight={() => onDeleteSegmentHighlight(item)}
													onClickCopyText={() => onClickCopySegmentText(index)}
													onClickPasteNote={() => onClickPasteNoteFromSegment(item)}
													onClose={close}
													isHighlight
												/>
											)}
										</Popup>
									)}

									<div className='avatar'>
										<Avatar
											name={item.name}
											size={30}
											round
											textSizeRatio={2}
											color={getAvatarColor(item.speakerId).background}
											fgColor={getAvatarColor(item.speakerId).color}
											maxInitials={1}
										/>
									</div>
									<div className='name'>{item.name}</div>
									<div className='start-time'>{convertTimeFormat(item.startTime)}</div>
								</div>
								<div className='bottom'>
									<div className='text'>{item.text}</div>
								</div>
							</div>
						))}
					</SegmentList>
				);
			}

			case 'summaryTime':
				return (
					<SummaryTime>
						{data && data.length ?
							data.map((item, idx) => (
								<div className='section' key={idx} ref={el => (summaryRef.current[idx] = el)}>
									{!isHome && (
										<Popup
											trigger={
												<div className='dot'>
													<DotIcon />
												</div>
											}
											position={['bottom right', 'top right']}
											keepTooltipInside='.text-box-container'
										>
											{close => (
												<Function
													onClickHighlight={() => onDeleteSummaryTimeHighlight(item)}
													onClickCopyText={() => onClickCopySummaryTimeText(idx)}
													onClickPasteNote={() => onClickPasteNoteFromSummaryTime(item)}
													onClose={close}
													isHighlight
												/>
											)}
										</Popup>
									)}
									<div className='time'>{item.time}</div>
									<div className='title'>{item.topic}</div>
									<div className='summary'>
										{item['summary'].map(summary => (
											<li>{summary}</li>
										))}
									</div>
								</div>
							))
						:	<></>}
					</SummaryTime>
				);

			default:
				return (
					<Text>
						{data.map((text, idx) => (
							<li key={idx}>{text}</li>
						))}
					</Text>
				);
		}
	};

	const isUseFunction = item => {
		if (isHome) {
			return false;
		} else if (item.type === 'summaryTime') {
			return false;
		} else if (item.type === 'segments') {
			return false;
		} else {
			return true;
		}
	};

	return (
		<div className='summary-container'>
			{datas && datas.length ?
				datas.map(item => (
					<TextBox
						contentId={item.contentId}
						title={getTitleText(item.type)}
						body={drawBodyText(item.type, item.data)}
						type={item.type}
						data={item}
						style={{ cursor: 'pointer' }}
						useFunction={isUseFunction(item)}
						titleStyle={{
							display: 'flex',
							justifyContent: 'space-between',
							fontSize: '16px',
							fontWeight: 600,
							color: '#28323c',
						}}
						bodyStyle={{
							fontSize: '14px',
							fontWeight: 500,
							color: '#28323c',
						}}
						onClickBox={() => isHome && handleOnClickContent(item.contentId)}
						isHighlight
						refreshContent={onToggleHighlight}
						editable={editable}
					/>
				))
			:	<Empty type={'highlight'} />}
		</div>
	);
};

export default Highlights;

const KeywordList = styled.div`
	display: flex;
	gap: 10px;
	flex-wrap: wrap;

	& .keyword {
		border-radius: 20px;
		padding: 6px 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: var(--popup-submit-button-background-color);
		background: #f5f8ff;
		border: 1px solid var(--popup-border-color);
	}
`;

const SpeakerList = styled.div`
	display: flex;
	gap: 10px;
	flex-wrap: wrap;

	& .speaker {
		border-radius: 20px;
		padding: 6px 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
`;

const SegmentList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	& > .row {
		padding: 10px;
		box-sizing: border-box;
		position: relative;

		& > .top {
			display: flex;
			align-items: center;
			gap: 5px;
			padding-bottom: 10px;

			& > .dot {
				position: absolute;
				top: 20px;
				right: 0px;
				display: none;
			}

			& > .avatar {
				width: 30px;
				height: 30px;
				padding-right: 5px;
			}

			& > .name {
				font-size: var(--font-size-md, 14px);
				font-weight: var(--font-weight-semibold, 600);
				color: var(--main-title-text-color, #28323c);
			}

			& > .start-time {
				font-size: var(--font-size-sm, 12px);
				font-weight: var(--font-weight-regular, 400);
				color: var(--main-description-text-color, #858c99);
				cursor: pointer;
				user-select: none;
			}
		}
	}

	& > .row:hover {
		& > .top {
			& > .dot {
				display: block;
			}
		}
	}
`;

const SummaryTime = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;

	& > .section {
		display: flex;
		flex-direction: column;
		gap: 15px;
		position: relative;

		& > .dot {
			position: absolute;
			top: 0px;
			right: 0px;
			display: none;
		}

		& > .time {
			border-radius: 20px;
			padding: 4px 14px;
			background-color: #e2e5e9;
			font-size: var(--font-size-sm, 12px);
			font-weight: var(--font-weight-semibold, 600);
			color: var(--main-description-text-color, #858c99);
			align-self: flex-start;
		}

		& > .title {
			font-size: var(--font-size-md, 14px);
			font-weight: var(--font-weight-semibold, 600);
			color: var(--main-title-text-color, #28323c);
			padding-left: 10px;
		}

		& .summary {
			display: flex;
			flex-direction: column;
			gap: 10px;
			padding-left: 20px;
		}

		/* .section에 마우스 오버할 때 해당 .section 안의 .dot만 표시되도록 수정 */
		&:hover > .dot {
			display: block;
		}
	}
`;

const Text = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	& li {
		padding-left: 10px;
	}
`;

const Title = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 16px;
	font-weight: 600;
	color: #28323c;
	width: 100%;

	& > .dot {
		width: 20px;
		display: none;
		cursor: pointer;
	}
`;
