import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TimbloLogo from './img/sk-poc-logo.png';
import { HyperLink, Input, Label, LoginContainer, LoginHeader, LoginWrapper, Logo, LogoImage } from './LoginComponents';
import TimbloCheckboxItem from '../../components/common/CheckBox';
import ConfirmButton from '../../components/button/ConfirmButton';
import SocialLoginButton from './SocialLoginButton';
import AppleIcon from './img/apple-icon.svg';
import GoogleIcon from './img/google-icon.svg';
import axios from 'axios';
import EmailJoinForm from './join/EmailJoinFormV2';
import ProfileForm from './join/ProfileForm';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { Rectangle } from '@mui/icons-material';
import EmailJoinFormV2 from './join/EmailJoinFormV2';

const JoinPageV2 = () => {
	const navigate = useNavigate();
	const urlParams = new URLSearchParams(window.location.search);
	const [agreeRequired, setAgreeRequired] = useState(false);
	const [email, setEmail] = useState(urlParams.get('email'));
	const [emailCertifyResult, setEmailCertifyResult] = useState(null);
	const [isAllChecked, setIsAllChecked] = useState(false);
	const [step, setStep] = useState(1); //default : 1

	const [terms, setTerms] = useState([
		{
			id: 'a(uuid)',
			required: true,
			label: 'AI회의록 이용약관 동의',
			note: '내용입력 (markdown 형태의 약관 내용)',
			description: null,
			checked: false,
		},
		{
			id: 'b(uuid)',
			required: true,
			label: '개인정보 수집 및 이용동의',
			note: '내용입력 (markdown 형태의 약관 내용)',
			checked: false,
		},
	]);

	useEffect(() => {
		let uncheckedCnt = 0;
		terms.map(item => {
			if (item.required && !item.checked) {
				uncheckedCnt++;
			}
		});

		setAgreeRequired(!uncheckedCnt);
	}, [terms]);

	const drawProgress = () => {
		return (
			<Box sx={{ display: 'flex', gap: '8px', justifyContent: 'center', marginBottom: '40px' }}>
				{[1, 2, 3, 4].map((item, index) => (
					<div
						key={index}
						style={{
							width: '32px',
							height: '4px',
							backgroundColor: item <= step ? '#1C6EFF' : '#E1E4E6',
							border: 'none', // 불필요한 테두리 제거
							padding: '0', // 내부 여백 제거
							margin: '0', // 외부 여백 제거
						}}
					/>
				))}
			</Box>
		);
	};

	const getTitle = () => {
		switch (step) {
			case 1:
				return '이용약관';
			case 2:
				return '아이디 입력';
			case 3:
				return '비밀번호 입력';
			case 4:
				return '인증코드 입력';
			default:
		}
	};

	const drawTitle = () => {
		return (
			<Typography
				sx={{
					textAlign: 'center',
					color: '#292A2B',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					fontSize: '26px',
					fontWeight: '700',
					lineHeight: '34px',
					textTransform: 'capitalize',
					margin: '40px',
				}}
			>
				{getTitle()}
			</Typography>
		);
	};

	const drawTerms = () => {
		return (
			<>
				{drawTitle()}
				<TimbloCheckboxItem
					label={'약관 전체동의'}
					isMainCheckbox={true}
					onChanged={isChecked => {
						setIsAllChecked(isChecked);
						setTerms(
							terms.map(item => ({
								...item,
								checked: isChecked,
							}))
						);
					}}
					checked={isAllChecked}
				/>
				<Divider />
				{terms &&
					terms.map((item, index) => {
						return (
							<TimbloCheckboxItem
								key={index}
								{...item}
								onChanged={isChecked => {
									setTerms(
										terms.map(term =>
											term.id === item.id ? { ...term, checked: isChecked } : { ...term }
										)
									);
								}}
							/>
						);
					})}
				<ConfirmButton
					label={'다음'}
					isActive={agreeRequired}
					onClick={() => {
						setStep(2);
					}}
				/>
			</>
		);
	};

	const drawSelectJoinType = step => {
		return (
			<>
				{drawTitle()}

				{/* <SocialLoginButton icon={GoogleIcon} text='Google로 계속하기' provider='Google' />
				<SocialLoginButton icon={AppleIcon} text='Apple로 계속하기' provider='Apple' /> */}

				{/* 여기 부터 생략 */}
				<EmailJoinFormV2
					emailParam={email}
					emailCertifyResult={emailCertifyResult}
					setEmailCertifyResult={setEmailCertifyResult}
					step={step}
					setStep={setStep}
					onComplete={() => {
						navigate('/login');
					}}
				/>
			</>
		);
	};

	const drawProfileForm = () => {
		return (
			<ProfileForm
				email={email}
				accessToken={emailCertifyResult && emailCertifyResult.accessToken}
				onComplete={() => {
					navigate('/login');
				}}
			/>
		);
	};

	const drawStep = () => {
		switch (step) {
			case 1:
				return drawTerms();
			case 2:
			case 3:
			case 4:
				return drawSelectJoinType(step);
			case 5:
				return drawProfileForm();
		}
	};

	return (
		<Box>
			<Stack direction='row' alignItems='flex-start'>
				<a href='/login' style={{ display: 'inline-block' }}>
					<LogoImage
						loading='lazy'
						src={TimbloLogo}
						alt='Timblo Logo'
						style={{
							width: '152px',
							height: '88px',
							marginLeft: '30px',
							marginTop: '20px',
							cursor: 'pointer',
						}}
					/>
				</a>
			</Stack>
			<Stack
				sx={{
					marginTop: '140px',
				}}
			>
				<Box
					className='view'
					style={{
						width: '320px',
						height: '800px',
						margin: '0 auto',
					}}
				>
					{drawProgress()}
					{drawStep()}
				</Box>
			</Stack>
		</Box>
	);
};

export default JoinPageV2;
