import React from 'react';
import './Empty.css';
import styled from 'styled-components';

import { ReactComponent as EmptyContentsImg } from './img/empty_contents.svg';
import { ReactComponent as EmptyHighlightImg } from './img/empty_highlight.svg';
import { ReactComponent as EmptyIntegrateImg } from './img/empty_integrate.svg';
import { ReactComponent as EmptySearchResultImg } from './img/empty_search_result.svg';
import { ReactComponent as EmptySearchTermImg } from './img/empty_search_term.svg';
import { ReactComponent as EmptyContentResultImg } from './img/empty_content_result.svg';
import { ReactComponent as EmptyCalendarContents } from './img/empty_calendar_contents.svg';
import { ReactComponent as ErrorImg } from './img/error.svg';
import { ReactComponent as EmptyContentErrorImg } from './img/empty_contents_error.svg';

import { ReactComponent as RecordIcon } from './img/record.svg';
import { ReactComponent as UploadIcon } from './img/upload.svg';

const EMPTY_LIST = [
	{
		key: 'contents',
		title: '아직 음성 기록이 없어요!',
		description: (
			<>
				<div>파일을 업로드하거나 음성 녹음을 이용해 보세요.</div>
				<div style={{ fontSize: '14px', fontWeight: 500, color: '#C5C8CE' }}>
					*파일 최대 7시간 길이, 지원 형식: mp4, mp3, wav, m4a
				</div>
			</>
		),
	},

	{
		key: 'highlight',
		title: '아직 하이라이트가 없어요!',
		description: (
			<>
				<div>다시 보고 싶은 부분이 있다면</div>
				<div>하이라이트 기능을 이용해 보세요.</div>
			</>
		),
	},
	{
		key: 'calendar',
		title: '해당 날짜에 콘텐츠가 없어요.',
		description: '콘텐츠를 만들어보세요.',
	},

	{ key: 'integrate', title: '최근 연동된 파일이 없어요!', description: '연동을 이용해 보세요.' },

	{
		key: 'error',
		title: '음성 기록이 없습니다.',
		description: (
			<>
				<div>음성을 인식하지 못해 음성 기록이 저장되지 않았습니다.</div>
				<div>음성 파일을 확인해주세요.</div>
			</>
		),
	},
	{
		key: 'contents-result',
		title: '요청하신 파일을 인식할 수 없어요!',
		description: '파일 확인 후 다시 시도해주세요',
	},

	{ key: 'deleted-content', title: '삭제된 컨텐츠예요.', description: '컨텐츠를 복원 후 다시 요청해주세요.' },
	{ key: 'search-term', title: '최근 검색어가 없어요!', description: '검색을 이용해 보세요.' },
	{ key: 'search-result', title: '검색 결과가 없어요!', description: '다른 키워드로 검색해보세요.' },
];

const Empty = ({ type, title = '', description = '', onClickUpload = () => {}, onClickRecord = () => {} }) => {
	const drawTypeImg = () => {
		switch (type) {
			case 'contents':
				return <EmptyContentsImg />;

			case 'highlight':
				return <EmptyHighlightImg />;

			case 'integrate':
				return <EmptyIntegrateImg />;

			case 'calendar':
				return <EmptyCalendarContents />;

			case 'error':
				return <EmptyContentErrorImg />;

			case 'deleted-content':
			case 'contents-result':
				return <EmptyContentResultImg />;

			case 'search-term':
				return <EmptySearchTermImg />;

			case 'search-result':
				return <EmptySearchResultImg />;

			default:
				return <></>;
		}
	};

	const drawTitle = () => {
		const target = EMPTY_LIST.find(item => item.key === type);

		if (target) {
			return target.title;
		} else {
			return title;
		}
	};

	const drawDescription = () => {
		const target = EMPTY_LIST.find(item => item.key === type);

		if (target) {
			return target.description;
		} else {
			return description;
		}
	};

	return (
		<div className='empty-container'>
			<div className='image'>{drawTypeImg()}</div>
			<div className='title'>{drawTitle()}</div>
			<div className='description'>{drawDescription()}</div>
			{type === 'contents' && (
				<div className='buttons'>
					<Button className='upload' onClick={onClickUpload}>
						<UploadIcon /> 파일 업로드
					</Button>
					<Button className='record' onClick={onClickRecord}>
						<RecordIcon /> 음성녹음 시작
					</Button>
				</div>
			)}
		</div>
	);
};

export default Empty;

const Button = styled.button`
	width: 153px;
	height: 48px;
	border-radius: 40px;
	padding: 12px 16px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	gap: 8px;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: -0.5px;

	&.upload {
		background-color: #fff;
		color: #005cff;
		border: 1px solid #005cff;
	}

	&.record {
		background-color: #005cff;
		color: #fff;
		border: 1px solid transparent;
	}
`;
