import { create } from 'zustand';
import axios from 'axios';

const useTemplateStore = create(set => ({
	templates: [],
	refreshTemplate: (accessToken, contentId) => {
		return new Promise(async (resolve, reject) => {
			console.log('[useMessageStore] refreshTemplate');
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};
				const res = await axios.get(`${process.env.REACT_APP_API_URL}/contents/${contentId}/mynote/template`, {
					headers,
				});

				switch (res.data.httpCode) {
					case 200:
						set(() => ({ templates: [...res.data.data] }));
						resolve({ code: 200, message: 'ok', data: res.data.data });
						break;
					case 401:
						//권한 없음
						resolve({ code: 401, message: res.data.message });
						break;
					default:
						reject();
						break;
				}
			} catch (e) {
				console.error(e);
				reject();
			}
		});
	},
	getMynote: async (accessToken, contentId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};
				const response = await axios.get(`${process.env.REACT_APP_API_URL}/contents/${contentId}/mynote`, {
					headers,
				});
				if (response && response.data && response.data.httpCode === 200) {
					resolve(response.data.data);
				} else {
					reject({ code: response.data.httpCode, message: response.data.message });
				}
			} catch (e) {
				console.error(e);
				reject({ code: 400, message: 'error' });
			}
		});
	},

	updateMynote: async (accessToken, contentId, text) => {
		return new Promise(async (resolve, reject) => {
			try {
				const headers = {
					Authorization: `Bearer ${accessToken}`,
				};

				const response = await axios.patch(
					`${process.env.REACT_APP_API_URL}/contents/${contentId}/mynote`,
					{
						content: text,
					},
					{
						headers,
					}
				);

				if (response && response.data && response.data.httpCode !== 200) {
					reject();
				} else {
					resolve();
				}
			} catch (e) {
				console.error(e);
				reject();
			}
		});
	},
}));

export default useTemplateStore;
