import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './ItemV2.css';

import moment from 'moment';
import Popup from 'reactjs-popup';
import UserList from '../../popup/userList/UserList';
import Share from '../../popup/share/Share';
import useContentsStore from '../../../store/ContentsStore';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import useAuthStore from '../../../store/AuthStore';

import ReactLoading from 'react-loading';

import { ReactComponent as DotIcon } from './img/dot.svg';
import { ReactComponent as ShareIcon } from './img/share.svg';
import { ReactComponent as AudioIcon } from './img/voice.svg';
import { ReactComponent as VideoIcon } from './img/video.svg';
import { ReactComponent as DownloadIcon } from './img/download.svg';
import { ReactComponent as DeleteIcon } from './img/delete.svg';
import { ReactComponent as ErrorIcon } from './img/error.svg';
import { ReactComponent as ExportIcon } from './img/is_export.svg';
import { ReactComponent as SharedContentIcon } from './img/is_shared.svg';

import DeleteConfirm from '../../popup/content/delete-confirm/DeleteConfirm';
// import { ToastError, ToastInfo } from '../toast/Toast';
import { drawContentTypeV2 } from '../ContentIcon/ContentIcon';

const ItemV2 = ({ data, icon, onClickContent, isHome, isDisabled = false }) => {
	const titleRef = useRef();
	const keywordRef = useRef();

	const popupRef = useRef(null);
	const location = useLocation();

	const { auth } = useAuthStore();
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { sttStatus, refreshContentFromContentId, onContentsChanged, deleteContents, refreshHomeContents } =
		useContentsStore();
	const [isLoading, setLoading] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const [isClicked, setIsClicked] = useState(false);
	const [isOpenPopup, setIsOpenPopup] = useState(false);

	// Title Overflow
	const [isTitleOverflow, setIsTitleOverflow] = useState(false);
	const [isKeywordOverflow, setIsKeywordOverflow] = useState(false);

	useLayoutEffect(() => {
		if (data && data.isNew && data.transcribeStatus !== 'ERROR' && data.transcribeStatus !== 'DONE') {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [data]);

	useEffect(() => {
		const handleResize = () => {
			if (titleRef.current) {
				setIsTitleOverflow(titleRef.current.scrollWidth > titleRef.current.clientWidth);
			}

			if (keywordRef.current) {
				setIsKeywordOverflow(keywordRef.current.scrollWidth > keywordRef.current.clientWidth);
			}
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, [data]);

	useEffect(() => {
		if (sttStatus.list) {
			sttStatus.list
				.filter(item => item.isNew)
				.map(item => {
					if (item.contentId === data.contentId) {
						switch (item.status.toUpperCase()) {
							case 'DONE': {
								refreshContentFromContentId(
									cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
									item.contentId
								).finally(() => {
									setLoading(false);
									onContentsChanged({ contentId: item.contentId, status: 'DONE', isNew: false });
								});
								break;
							}
							case 'ERROR':
								setLoading(false);
								break;
							default: {
								const isDone = data.transcribeStatus === 'DONE';

								if (!isDone) {
									setLoading(true);
								}

								break;
							}
						}
					}
				});
		}
	}, [sttStatus.list]);

	const drawContentType = () => {
		if (isLoading) {
			return <ReactLoading type={'spinningBubbles'} color='#858c99' height={'35px'} width={'35px'} />;
		} else {
			return drawContentTypeV2(auth, data, 40);
		}
	};

	const convertTimeFormat = milliseconds => {
		let seconds = Math.floor(milliseconds / 1000);
		let minutes = Math.floor(seconds / 60);
		let hours = Math.floor(minutes / 60);

		seconds = seconds % 60;
		minutes = minutes % 60;

		const pad = num => num.toString().padStart(2, '0');

		return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
	};

	const drawKeywords = () => {
		return data.hashTag?.map(tag => <div className='keyword'>{`# ${tag}`}</div>);
	};

	return (
		<div
			className={`list-item-container-v2 ${isDisabled ? 'disabled' : isLoading ? 'loading' : ''}`}
			onClick={event => {
				event.stopPropagation();

				if (!isOpenPopup) {
					onClickContent(data);
				}
			}}
		>
			<div className='type'>
				{drawContentType()}
				{!isLoading && data.creatorPID === auth.user.pid && data.shareUsers?.length ? (
					<div className='status'>
						<ExportIcon />
					</div>
				) : null}
				{!isLoading && data.creatorPID !== auth.user.pid ? (
					<div className='status'>
						<SharedContentIcon />
					</div>
				) : null}
			</div>
			<Popup
				trigger={
					<div className='title'>
						<div ref={titleRef} className={`${isTitleOverflow ? 'overflow' : ''}`}>
							{data.editedTitle ?? data.title}
						</div>
					</div>
				}
				position={'top left'}
				on='hover'
				disabled={!isTitleOverflow}
			>
				<TextDetail data={<div className='title'>{data.editedTitle ?? data.title}</div>} />
			</Popup>

			<Popup
				trigger={
					<div className='keywords'>
						{/* {drawKeywords()} */}
						<div ref={keywordRef}>{drawKeywords()}</div>
					</div>
				}
				position={'top left'}
				on='hover'
				disabled={!isKeywordOverflow}
			>
				<TextDetail data={drawKeywords()} />
			</Popup>

			<div className='createdAt'>{moment(data.createAt).locale('ko').format('YYYY. MM. DD LT')}</div>
			<div className='duration'>{convertTimeFormat(data.duration)}</div>
			<Popup
				className='user-list-popup'
				trigger={
					<div className='participates' style={{ position: 'relative' }}>
						{data.speakerInfo && data.speakerInfo.length ? `${data.speakerInfo.length}명` : '-'}
					</div>
				}
				position={'bottom right'}
				on={'hover'}
				// disabled={data && data.speakerInfo && data.speakerInfo.length ? false : true}
				disabled={true}
			>
				<UserList title={'참석자'} data={data.speakerInfo} />
			</Popup>

			<Popup
				className='user-list-popup'
				ref={popupRef}
				key={isClicked}
				open={isHovered || isClicked}
				onOpen={() => setIsOpenPopup(true)}
				onClose={() => {
					setIsOpenPopup(false);
					setIsClicked(false);
					setIsHovered(false);
				}}
				// position={'top left'}
				keepTooltipInside={'.content-list-v2'}
				closeOnDocumentClick={false}
				// nested
				trigger={
					<button
						className={`share ${data.userAccessRole === 'VIEWER' ? 'disabled' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
						onClick={e => e.stopPropagation()}
					>
						<div
							style={{ display: 'flex' }}
							onClick={event => {
								event.stopPropagation();

								if (data.userAccessRole !== 'VIEWER') {
									setIsClicked(prev => !prev);
								}
							}}
						>
							<ShareIcon />
						</div>
						{data.shareUsers && data.shareUsers.length > 0 ? <div className='circle' /> : null}
					</button>
				}
				disabled={data.userAccessRole === 'VIEWER' ? true : false}
			>
				{close =>
					isClicked ? (
						<>
							<div
								className='overlay'
								style={{
									position: 'fixed',
									width: '100vw',
									height: '100vh',
									background: 'transparent',
									top: 0,
									left: 0,
									zIndex: 1,
								}}
								onClick={() => close()}
							/>
							<Share contentId={data.contentId} permission={data.userAccessRole} />
						</>
					) : (
						<>
							{data.shareUsers && data.shareUsers.filter(item => item.nickName).length ? (
								<UserList title='공유자' data={data.shareUsers} />
							) : null}
						</>
					)
				}
			</Popup>

			<Popup
				className='user-list-popup'
				trigger={
					<button className='button'>
						<DeleteIcon />
					</button>
				}
				disabled={isLoading ? true : false}
				position={'bottom right'}
				onOpen={() => setIsOpenPopup(true)}
				onClose={() => setIsOpenPopup(false)}
				keepTooltipInside={'.content-list-v2'}
			>
				{/* {close => <ItemButton data={data} onClose={close} />} */}
				{close => (
					<DeleteConfirm
						title='콘텐츠를 삭제하시겠습니까?'
						onCancel={close}
						onConfirm={() => {
							deleteContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, {
								contentId: data.contentId,
							})
								.then(() => {
									// ToastError(0, '선택한 콘텐츠가 삭제되었습니다.', '콘텐츠 삭제 완료');

									const currentLocation = location.pathname.split('/')[1];

									if (currentLocation === 'home') {
										refreshHomeContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken);
									}

									close();
								})
								.catch(() => {
									// ToastError(null, '권한이 없습니다.');
								});
						}}
					/>
				)}
			</Popup>
		</div>
	);
};

const TextDetail = ({ data }) => {
	return <div className='popup-item-detail'>{data}</div>;
};

const ItemButton = ({ data, onClose }) => {
	const location = useLocation();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { deleteContents, refreshHomeContents, downloadFile } = useContentsStore();

	return (
		<div className='popup-item-button'>
			<button
				onClick={event => {
					event.stopPropagation();
					downloadFile(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, data);
				}}
			>
				<DownloadIcon />
			</button>
			<Popup
				trigger={
					<button>
						<DeleteIcon />
					</button>
				}
				position={'bottom right'}
				nested
			>
				{close => (
					<DeleteConfirm
						title='콘텐츠를 삭제하시겠습니까?'
						onCancel={close}
						onConfirm={() => {
							deleteContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, {
								contentId: data.contentId,
							})
								.then(() => {
									// ToastError(0, '선택한 콘텐츠가 삭제되었습니다.', '콘텐츠 삭제 완료');

									const currentLocation = location.pathname.split('/')[1];

									if (currentLocation === 'home') {
										refreshHomeContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken);
									}

									close();

									onClose && onClose();
								})
								.catch(() => {
									// ToastInfo(null, '권한이 없습니다.');
								});
						}}
					/>
				)}
			</Popup>
		</div>
	);
};

export default ItemV2;
