// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
}

.empty-container > .image {
	width: 96px;
	height: 96px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.empty-container > .title {
	font-size: var(--font-size-xxl, 24px);
	font-weight: var(--font-weight-bold, 700);
	color: var(--main-title-text-color);
}

.empty-container > .description {
	font-size: var(--font-size-xl, 18px);
	font-weight: var(--font-weight-regular, 400);
	color: var(--main-description-text-color);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.empty-container > .buttons {
	display: flex;
	gap: 20px;
	margin-top: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/page-status/Empty.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;CACZ,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,mBAAmB;CACnB,QAAQ;AACT;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB;;AAEA;CACC,qCAAqC;CACrC,yCAAyC;CACzC,mCAAmC;AACpC;;AAEA;CACC,oCAAoC;CACpC,4CAA4C;CAC5C,yCAAyC;CACzC,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,SAAS;CACT,gBAAgB;AACjB","sourcesContent":[".empty-container {\n\twidth: 100%;\n\theight: 100%;\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n\tgap: 8px;\n}\n\n.empty-container > .image {\n\twidth: 96px;\n\theight: 96px;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.empty-container > .title {\n\tfont-size: var(--font-size-xxl, 24px);\n\tfont-weight: var(--font-weight-bold, 700);\n\tcolor: var(--main-title-text-color);\n}\n\n.empty-container > .description {\n\tfont-size: var(--font-size-xl, 18px);\n\tfont-weight: var(--font-weight-regular, 400);\n\tcolor: var(--main-description-text-color);\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.empty-container > .buttons {\n\tdisplay: flex;\n\tgap: 20px;\n\tmargin-top: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
