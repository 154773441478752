import React, { useEffect, useState } from 'react';
import './SummaryV2.css';
import styled from 'styled-components';

import TextBox from '../../common/text-box/TextBox';
import Popup from 'reactjs-popup';
import Function from '../../popup/content/function/Function';

import { ReactComponent as DotIcon } from './img/dot.svg';
import { getAvatarColor, getBlockTitle } from '../../../util/Util';

const ORDERS = {
	topics: 1,
	speakerInfo: 2,
	keywords: 3,
	summary: 4,
	tasks: 5,
	issues: 6,
};

const SummaryV2 = ({ contentId, data, highlights, onToggleHighlight = () => {}, editable = false }) => {
	const [datas, setDatas] = useState([]);

	useEffect(() => {
		if (data) {
			parsingData();
		}
	}, [data]);

	const parsingData = () => {
		const keys = Object.keys(data);

		const summaryData = keys.map(key => {
			return { type: key, data: data[key] };
		});

		summaryData.sort((a, b) => {
			return ORDERS[a.type] - ORDERS[b.type];
		});

		setDatas(summaryData);
	};

	const drawBodyText = (type, data) => {
		switch (type) {
			case 'speakers':
			case 'speakerInfo':
				return (
					<SpeakerList>
						{data.map((speaker, idx) => (
							<div
								key={idx}
								className='speaker'
								style={{
									backgroundColor: getAvatarColor(speaker.speakerId).background,
									color: getAvatarColor(speaker.speakerId).color,
								}}
							>
								{speaker.displayName ?? speaker.name}
							</div>
						))}
					</SpeakerList>
				);

			case 'keywords':
				return (
					<KeywordList>
						{data.map((keyword, idx) => (
							<div key={idx} className='keyword'>
								# {keyword}
							</div>
						))}
					</KeywordList>
				);

			case 'speakerSummary':
				return <></>;

			default:
				return (
					<Text>
						{data.map((text, idx) => (
							<li key={idx}>{text}</li>
						))}
					</Text>
				);
		}
	};

	return (
		<div className='summary-container'>
			{datas && datas.length ? (
				datas.map((item, idx) => (
					<TextBox
						key={idx}
						data={item}
						contentId={contentId}
						title={getBlockTitle(item.type)}
						type={item.type}
						useFunction={true}
						body={drawBodyText(item.type, item.data)}
						titleStyle={{
							display: 'flex',
							justifyContent: 'space-between',
							fontSize: '16px',
							fontWeight: 600,
							color: '#28323c',
						}}
						bodyStyle={{
							fontSize: '14px',
							fontWeight: 400,
							color: '#28323c',
						}}
						style={
							highlights.some(highlight => highlight.key === item.type)
								? { backgroundColor: '#F6FFF7' }
								: {}
						}
						isHighlight={highlights.some(highlight => highlight.key === item.type)}
						refreshContent={onToggleHighlight}
						editable={editable}
					/>
				))
			) : (
				<></>
			)}
		</div>
	);
};

export default SummaryV2;

const KeywordList = styled.div`
	display: flex;
	gap: 10px;
	flex-wrap: wrap;

	& .keyword {
		border-radius: 20px;
		padding: 4px 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #005cff;
		background: #f5f8ff;
		/* border: 1px solid var(--popup-border-color); */
	}
`;

const SpeakerList = styled.div`
	display: flex;
	gap: 10px;
	flex-wrap: wrap;

	& .speaker {
		border-radius: 20px;
		padding: 6px 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
`;

const Text = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	& li {
		padding-left: 10px;
	}
`;

const Title = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 16px;
	font-weight: 600;
	color: #28323c;
	width: 100%;

	& > .dot {
		width: 20px;
		display: none;
		cursor: pointer;
	}
`;
