import axios from 'axios';
import React from 'react';
import styled from 'styled-components';

const SocialLoginButton = ({ icon, text, provider, onError }) => {
	const onLogin = () => {
		const url = `${process.env.REACT_APP_API_URL}/auth/login/${provider}`;
		axios
			.post(url, {
				path: 'login',
			})
			.then(res => {
				if (res.data.httpCode === 200) {
					window.location.href = res.data.data.url;
				} else {
					onError && onError(res.data.httpCode);
				}
			});
	};

	return (
		<StyledButton onClick={onLogin}>
			<ButtonIcon loading='lazy' src={icon} alt={`${provider} icon`} />
			<ButtonText>{text}</ButtonText>
		</StyledButton>
	);
};

const StyledButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 10px;
	color: var(--grey-grey700, #28323c);
	text-align: center;
	padding: 10px;
	font: 600 16px/1 Noto Sans, sans-serif;
	border: none;
	border-radius: 5px;
	background-color: transparent;
	cursor: pointer;
	margin-top: 20px;
	border: 1px solid #c5c8ce;
`;

const ButtonIcon = styled.img`
	aspect-ratio: 1;
	object-fit: contain;
	object-position: center;
	width: 24px;
`;

const ButtonText = styled.span`
	flex-grow: 1;
`;

export default SocialLoginButton;
