import moment from 'moment';
import './RecorderV2.css';
import React, { useEffect, useState, useRef } from 'react';
import { VoiceVisualizer } from 'react-voice-visualizer';

import { ReactComponent as PauseIcon } from './img/pause.svg';
import { ReactComponent as PauseV2Icon } from './img/pause_v2.svg';
import { ReactComponent as RecordIcon } from './img/record.svg';
import { useCookies } from 'react-cookie';
import useAuthStore from '../../../store/AuthStore';
import useContentsStore from '../../../store/ContentsStore';
import { Toast, ToastComponent, ToastError, ToastInfo } from '../../common/toast/Toast';
import Popup from 'reactjs-popup';
import Confirm from '../confirm/Confirm';
import Loading from 'react-loading';
import { toast } from 'react-toastify';

import FileSaver from 'file-saver';
import { audioBufferToWav } from '../../../util/Util';
import Resampler from 'audio-resampler';

const BAR_COLOR = '#01A4FF';
const LEVEL_BAR_COUNT = 60;
const FORMAT = 'wav';

const RecorderV2 = ({ recorderControls, onClose = () => {}, onRecordComplete = () => {} }) => {
	const [currentDate, setCurrentDate] = useState(null);
	const [isUploading, setIsUploading] = useState(false);
	const [mediaStream, setMediaStream] = useState(null); // stream 상태 추가
	const audioContextRef = useRef(null);

	useEffect(() => {
		if (recorderControls.bufferFromRecordedBlob) {
			setIsUploading(true);

			const pad = num => num.toString().padStart(2, '0');
			const date = new Date();
			const fileName = `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}${pad(
				date.getHours()
			)}${pad(date.getMinutes())}${pad(date.getSeconds())}_Recording.${FORMAT}`;

			convertBlobToWav(recorderControls.bufferFromRecordedBlob)
				.then(file => {
					onRecordComplete(file, fileName);
				})
				.catch(() => {
					FileSaver.saveAs(recorderControls.recordedBlob, fileName);
				})
				.finally(() => {
					recorderControls.clearCanvas();
					onClose();
				});
		}
	}, [recorderControls.bufferFromRecordedBlob]);

	useEffect(() => {
		if (recorderControls.isRecordingInProgress) {
			setCurrentDate(moment());
		} else {
			setCurrentDate(null);
		}
	}, [recorderControls.isRecordingInProgress]);

	const convertBlobToWav = async audioBuffer => {
		try {
			const wavArrayBuffer = await audioBufferToWav(audioBuffer);
			const wavBlob = new Blob([wavArrayBuffer], { type: 'audio/wav' });

			return wavBlob;
		} catch {
			ToastInfo('파일 변환에 실패하여 원본 파일을 다운로드 합니다.');
		}
	};

	const getDurationFormat = seconds => {
		seconds = Math.floor(seconds / 1000);
		const hours = Math.floor(seconds / 3600);
		seconds %= 3600;
		const minutes = Math.floor(seconds / 60);
		seconds %= 60;

		const hoursFormatted = String(hours).padStart(2, '0');
		const minutesFormatted = String(minutes).padStart(2, '0');
		const secondsFormatted = String(seconds).padStart(2, '0');

		// const result = `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
		const result = (
			<>
				<span>{hoursFormatted}</span>
				<span>:</span>
				<span>{minutesFormatted}</span>
				<span>:</span>
				<span>{secondsFormatted}</span>
			</>
		);
		return result;
	};

	const drawLevelBar = () => {
		return Array.from({ length: LEVEL_BAR_COUNT }).map((_, idx) => (
			<div key={idx} className={`bar`} style={{ background: BAR_COLOR }} />
		));
	};

	const handleRecord = () => {
		if (recorderControls.isRecordingInProgress) {
			recorderControls.togglePauseResume();
		} else {
			const audioContext = new (window.AudioContext || window.webkitAudioContext)();
			audioContextRef.current = audioContext;

			navigator.mediaDevices
				.getUserMedia({
					audio: {
						noiseSuppression: false,
						echoCancellation: true,
						autoGainControl: false,
					},
				})
				.then(stream => {
					const source = audioContext.createMediaStreamSource(stream);

					// GainNode 생성 (볼륨 조절용)
					const gainNode = audioContext.createGain();
					source.connect(gainNode);
					recorderControls.startRecording();

					// mediaStream 상태에 stream 저장
					setMediaStream(stream); // 스트림을 상태로 저장
				})
				.catch(error => {
					console.error('Mic stream access failed:', error);
				});
		}
	};

	const onSaveRecord = () => {
		if (recorderControls.isRecordingInProgress) {
			setIsUploading(true);

			// mediaStream이 있다면 트랙을 중지
			if (mediaStream) {
				const tracks = mediaStream.getTracks();
				tracks.forEach(track => track.stop()); // 각 트랙 중지
			}

			// Reset the audio context reference if necessary
			audioContextRef.current.close();
			audioContextRef.current = null;
			recorderControls.stopRecording();

			// mediaStream도 초기화
			setMediaStream(null);

			return;
		} else {
			ToastInfo('녹음된 파일이 없습니다.');
		}
	};

	const drawRecordIcon = () => {
		return (
			<div className='white-circle' onClick={() => !isUploading && handleRecord()}>
				<div className='red-circle' />
			</div>
		);
	};

	return (
		<div className='record-v2-container'>
			<div className='title'>새로운 녹음</div>
			{/* <div className='date'>{currentDate && moment(currentDate).locale('ko').format('YYYY.MM.DD  HH:mm')}</div> */}
			<div className='duration'>
				<div className='circle' />
				<div className='time'>{getDurationFormat(recorderControls.recordingTime)}</div>
			</div>
			<div className='wave-form'>
				{recorderControls.isRecordingInProgress ? (
					<VoiceVisualizer
						fullscreen
						controls={recorderControls}
						height={80}
						isControlPanelShown={false}
						isDefaultUIShown={false}
						mainBarColor={BAR_COLOR}
					/>
				) : (
					<div className='level-bar'>{drawLevelBar()}</div>
				)}
			</div>

			<div className='record'>
				{recorderControls.isRecordingInProgress && (
					<Popup trigger={<button>취소</button>} position={'top left'} arrow={false} nested>
						{close => (
							<Confirm
								title={'녹음 파일을 삭제하시겠습니까?'}
								onCancelText='취소'
								onConfirmText='삭제'
								onCancel={() => {
									close();
								}}
								onConfirm={() => {
									recorderControls.clearCanvas();
									close();

									if (mediaStream) {
										const tracks = mediaStream.getTracks();
										tracks.forEach(track => track.stop()); // 각 트랙 중지
									}

									// Reset the audio context reference if necessary
									audioContextRef.current.close();
									audioContextRef.current = null;
									recorderControls.stopRecording();

									// mediaStream도 초기화
									setMediaStream(null);
								}}
							/>
						)}
					</Popup>
				)}
				{isUploading ? (
					<Loading type='spin' color={'red'} width={48} height={48} />
				) : recorderControls.isRecordingInProgress ? (
					recorderControls.isPausedRecording ? (
						drawRecordIcon()
					) : (
						<PauseV2Icon onClick={() => !isUploading && handleRecord()} />
					)
				) : (
					drawRecordIcon()
				)}
				{recorderControls.isRecordingInProgress && (
					<Popup trigger={<button className='save'>저장</button>} position={'top left'} arrow={false} nested>
						{close => (
							<Confirm
								type='question'
								title={'녹음 파일을 저장하시겠습니까?'}
								onCancelText='취소'
								onConfirmText='저장'
								buttonStyle={{ background: '#005CFF' }}
								onCancel={close}
								onConfirm={() => {
									onSaveRecord();

									close();
								}}
							/>
						)}
					</Popup>
				)}
			</div>
		</div>
	);
};

export default RecorderV2;
