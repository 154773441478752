import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import './Transcription.css';

import Slider from 'rc-slider';
import Loading from 'react-loading';
import 'rc-slider/assets/index.css';

import { ReactComponent as SearchIcon } from './img/search.svg';
import { ReactComponent as VolumeIcon } from './img/volume.svg';
import { ReactComponent as PlaybackRateIcon } from './img/playback.svg';
import { ReactComponent as SizeIcon } from './img/size.svg';
import { ReactComponent as PlayIcon } from './img/play.svg';
import { ReactComponent as PauseIcon } from './img/pause.svg';
import { ReactComponent as MutedIcon } from './img/muted.svg';
import { ReactComponent as ArrowIcon } from './img/arrow.svg';
import { ReactComponent as ClearIcon } from './img/clear.svg';

import { useCookies } from 'react-cookie';
import SttRow from './SttRow';
import useContentsStore from '../../../store/ContentsStore';
import useFunctionStore from '../../../store/FunctionStore';
import SearchAndReplace from '../../popup/replace/SearchAndReplace';
import { Virtuoso } from 'react-virtuoso';
import { ToastError } from '../../common/toast/Toast';

const playRates = [0.5, 1, 1.5, 2];

const Transcription = forwardRef(
	(
		{
			type,
			source,
			data,
			onChangeSegmentSpeaker = () => {},
			speakerInfo,
			noteRef,
			editable,
			highlights,
			refreshContent,
		},
		ref
	) => {
		const dataContainerRef = useRef();
		const virtualRef = useRef(null);
		const dataRef = useRef([]);

		const MERGE_MILLISECONDS = process.env.REACT_APP_MERGE_SENTENCE_MILLISECONDS ?? 30000;

		const [cookies, setCookie, removeCookie] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
		const audioRef = useRef();
		const [audioSrc, setAudioSrc] = useState(null);
		const [isEditMode, setIsEditMode] = useState(false);
		const [isUpdateLoading, setIsUpdateLoading] = useState(false);
		const { selectedContent, setSelectedContent } = useContentsStore();
		const { updateSegments } = useFunctionStore();

		const [mergedTexts, setMergedTexts] = useState([]);
		const [isTracking, setIsTracking] = useState(false);

		// Resize
		const [playerHeight, setPlayerHeight] = useState(120);
		const [isResizing, setIsResizing] = useState(false);

		// Highlights
		const [keyword, setKeyword] = useState('');
		const [searchResult, setSearchResult] = useState({
			currentIndex: 0,
			total: 0,
		});

		//Audio State
		const [currentTime, setCurrentTime] = useState(0);
		const [duration, setDuration] = useState(0);
		const [playing, setPlaying] = useState(false);
		const [isDragging, setIsDragging] = useState(false);
		const [playbackRate, setPlaybackRate] = useState(1);
		const [isMuted, setIsMuted] = useState(false);
		const [seekStep, setSeekStep] = useState({ prev: 5, next: 5 });
		const [isPip, setIsPip] = useState(false);
		const [onReady, setOnReady] = useState(false);

		useImperativeHandle(ref, () => ({
			onSeekTime: startSec => {
				onSeekTime(startSec);

				const target = findNextIndex(mergedTexts, startSec * 1000);

				if (target > -1) {
					onScrollToIndex(target);
				}
			},

			onSeekTime: startSec => {
				onSeekTime(startSec);

				const target = findNextIndex(mergedTexts, startSec * 1000);

				if (target > -1) {
					onScrollToIndex(target, 'auto');
				}
			},
			mergedTexts: mergedTexts,
		}));

		useEffect(() => {
			const handleOnWheel = () => {
				setIsTracking(false);
			};

			if (dataContainerRef.current) {
				dataContainerRef.current.addEventListener('wheel', handleOnWheel);
			}

			return () => {
				if (document.pictureInPictureElement) {
					document.exitPictureInPicture();
				}

				if (dataContainerRef.current) {
					dataContainerRef.current.removeEventListener('wheel', handleOnWheel);
				}
			};
		}, []);

		useEffect(() => {
			if (!isEditMode) {
				if (playing) {
					if (mergedTexts && mergedTexts.length) {
						const milliseconds = currentTime * 1000;

						const target = findNextIndex(mergedTexts, milliseconds);

						if (target > -1) {
							if (isTracking) {
								onScrollToIndex(target);
							}

							const listItems = document.querySelectorAll('.stt-row-container');

							if (listItems.length) {
								listItems.forEach(item => item.classList.remove('highlighted'));

								const elementTarget = Array.from(listItems).find(
									element =>
										element.classList.contains(`stt-row-container`) &&
										element.classList.contains(`${target}`)
								);

								if (elementTarget) {
									elementTarget.classList.add('highlighted');
								}
							}
						}
					}
				} else {
					const listItems = document.querySelectorAll('.stt-row-container');
					if (listItems.length) {
						listItems.forEach(item => item.classList.remove('highlighted'));
					}
				}
			} else {
				const listItems = document.querySelectorAll('.stt-row-container');
				if (listItems.length) {
					listItems.forEach(item => item.classList.remove('highlighted'));
				}
			}
		}, [currentTime, mergedTexts, playing, isEditMode, isTracking]);

		useEffect(() => {
			if (data.length) {
				data.map((item, index) => (dataRef.current[index] = item));
			} else {
				dataRef.current = [];
			}
		}, [data]);

		useEffect(() => {
			if (audioRef && audioRef.current) {
				if (playing) {
					setIsTracking(true);
					audioRef.current.play();
				} else {
					audioRef.current.pause();
				}
			}
		}, [playing]);

		useEffect(() => {
			if (isDragging) {
				setPlaying(false);
			}
		}, [isDragging]);

		useEffect(() => {
			if (isPip) {
				const videoElement = audioRef.current;

				if (!videoElement) return;

				const updateCurrentTime = () => {
					setCurrentTime(videoElement.currentTime);
				};

				const handlePause = () => {
					setPlaying(false);
				};

				const handlePlay = () => {
					setPlaying(true);
				};

				videoElement.addEventListener('pause', handlePause);
				videoElement.addEventListener('play', handlePlay);

				const intervalId = setInterval(() => {
					if (playing) {
						updateCurrentTime();
					}
				}, 1000);

				return () => {
					clearInterval(intervalId);
					videoElement.removeEventListener('pause', handlePause);
					videoElement.removeEventListener('play', handlePlay);
				};
			}
		}, [playing, isPip]);

		useEffect(() => {
			if (data && data.length) {
				const mergedSegments = [];
				let currentSegment = null;
				let currentStartTime = 0;

				data.forEach(segment => {
					if (!currentSegment) {
						currentSegment = { ...segment };
						currentStartTime = segment.startTime;
					} else if (
						segment.startTime < currentStartTime + Number(MERGE_MILLISECONDS) &&
						segment.speakerId === currentSegment.speakerId
					) {
						currentSegment.text += ' ' + segment.text;
						currentSegment.endTime = segment.endTime;
						currentSegment.duration = currentSegment.endTime - currentSegment.startTime;
					} else {
						mergedSegments.push(currentSegment);
						currentSegment = { ...segment };
						currentStartTime = segment.startTime;
					}
				});

				if (currentSegment) {
					mergedSegments.push(currentSegment);
				}
				setMergedTexts(mergedSegments);
			}
		}, [data]);

		const findNextIndex = (arr, time) => {
			let low = 0;
			let high = arr.length - 1;

			while (low <= high) {
				const mid = Math.floor((low + high) / 2);
				if (arr[mid].startTime <= time && (mid === arr.length - 1 || arr[mid + 1].startTime > time)) {
					return mid;
				} else if (arr[mid].startTime <= time) {
					low = mid + 1;
				} else {
					high = mid - 1;
				}
			}

			return -1;
		};

		const onScrollToIndex = (index, behavior = 'smooth') => {
			virtualRef.current.scrollToIndex({
				index,
				align: 'center',
				behavior,
			});
		};

		const highlightedData = useMemo(() => {
			if (!keyword) {
				setSearchResult({ currentIndex: 0, total: 0 });

				if (isEditMode) {
					return data.map(item => [item.text]);
				} else {
					return mergedTexts.map(item => [item.text]);
				}
			}

			if (isEditMode) {
				return data.map(item => {
					const regex = new RegExp(`(${keyword})`, 'gi');
					return item.text.split(regex);
				});
			} else {
				return mergedTexts.map(item => {
					const regex = new RegExp(`(${keyword})`, 'gi');
					return item.text.split(regex);
				});
			}
		}, [data, mergedTexts, keyword, isEditMode]);

		const matchIndices = useMemo(() => {
			if (!keyword) return [];
			let indices = [];
			highlightedData.forEach((parts, dataIndex) => {
				parts.forEach((part, partIndex) => {
					if (part.toLowerCase() === keyword.toLowerCase()) {
						indices.push({ dataIndex, partIndex });
					}
				});
			});

			return indices;
		}, [highlightedData, keyword]);

		const totalMatches = matchIndices.length;

		useEffect(() => {
			if (virtualRef.current && matchIndices[searchResult.currentIndex]) {
				onScrollToIndex(matchIndices[searchResult.currentIndex].dataIndex, 'auto');
			}
		}, [searchResult, matchIndices, isEditMode]);

		useEffect(() => {
			if (type === 'video') {
				const videoElement = audioRef.current;

				const handleEnterPiP = () => {
					setIsPip(true);
				};

				const handleLeavePiP = () => {
					setIsPip(false);
				};

				videoElement.addEventListener('enterpictureinpicture', handleEnterPiP);
				videoElement.addEventListener('leavepictureinpicture', handleLeavePiP);

				return () => {
					videoElement.removeEventListener('enterpictureinpicture', handleEnterPiP);
					videoElement.removeEventListener('leavepictureinpicture', handleLeavePiP);
				};
			}
		}, [type]);

		const onChangePlaybackRate = () => {
			const currentIndex = playRates.indexOf(playbackRate);
			const nextIndex = (currentIndex + 1) % playRates.length;
			const nextRate = playRates[nextIndex];

			setPlaybackRate(nextRate);

			if (audioRef.current) {
				audioRef.current.playbackRate = nextRate;
			}
		};

		const toggleMute = () => {
			if (audioRef.current) {
				const newMutedState = !isMuted;
				audioRef.current.muted = newMutedState;
				setIsMuted(newMutedState);
			}
		};

		const convertTimeFormat = milliseconds => {
			let seconds = Math.floor(milliseconds / 1000);
			let minutes = Math.floor(seconds / 60);
			let hours = Math.floor(minutes / 60);

			seconds = seconds % 60;
			minutes = minutes % 60;

			const pad = num => num.toString().padStart(2, '0');

			if (hours > 0) {
				return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
			} else {
				return `${pad(minutes)}:${pad(seconds)}`;
			}
		};

		const onSeekTime = useCallback(startSec => {
			if (audioRef && audioRef.current) {
				audioRef.current.currentTime = startSec;
				if (type === 'audio') {
					setPlaying(true);
				} else {
					audioRef.current.play();
				}
			}
		}, []);

		const onTogglePlay = () => {
			if (onReady) {
				if (audioRef.current) {
					if (playing) {
						audioRef.current.pause();
					} else {
						audioRef.current.play();
					}
					setPlaying(!playing);
				}
			} else {
				ToastError(null, '음성 파일이 로드되지 않아 재생할 수 없습니다.');
			}
		};

		const drawPlayer = () => {
			const handleSliderChangeComplete = value => {
				audioRef.current.currentTime = value;
				setCurrentTime(value);
				setIsDragging(false);
				setPlaying(true);
			};

			return (
				<>
					<div className='audio-instance'>
						{type === 'audio' && (
							<audio
								ref={audioRef}
								src={source}
								preload='auto'
								onTimeUpdate={handleTimeUpdate}
								onLoadedMetadata={handleLoadedMetadata}
							/>
						)}
					</div>

					{type === 'video' && (
						<div className='video-player' style={{ display: isPip ? 'none' : 'flex' }}>
							<video
								ref={audioRef}
								src={source}
								preload='auto'
								controls
								onTimeUpdate={handleTimeUpdate}
								onLoadedMetadata={handleLoadedMetadata}
							/>
						</div>
					)}
					<div
						className='audio-player'
						style={{ display: type === 'video' ? (!isPip ? 'none' : 'flex') : 'flex' }}
					>
						<div className='time'>
							<div className='start'>{convertTimeFormat(currentTime * 1000)}</div>
							<div className='end'>{convertTimeFormat(duration * 1000)}</div>
						</div>
						<div className='progress'>
							<Slider
								value={currentTime}
								max={duration}
								step={0.01}
								onChange={value => {
									if (!isDragging) {
										setIsDragging(true);
									}

									handleSliderChangeComplete(value);
								}}
								onChangeComplete={handleSliderChangeComplete}
							/>
						</div>
						<div className='buttons'>
							<div className='left'>
								{isMuted ? <MutedIcon onClick={toggleMute} /> : <VolumeIcon onClick={toggleMute} />}

								<button onClick={onChangePlaybackRate}>X{playbackRate}</button>
							</div>
							<div className='center'>
								<div className='prev' onClick={() => onSeekTime(currentTime - Number(seekStep.prev))}>
									<PlaybackRateIcon />
									<div>{seekStep.prev}</div>
								</div>
								<div className='play' onClick={onTogglePlay}>
									{playing ? <PauseIcon /> : <PlayIcon />}
								</div>
								<div className='next' onClick={() => onSeekTime(currentTime + Number(seekStep.next))}>
									<PlaybackRateIcon />
									<div>{seekStep.next}</div>
								</div>
							</div>
							<div className='right'>
								{type === 'video' && (
									<SizeIcon
										onClick={() => {
											document.exitPictureInPicture();
											setIsPip(false);
										}}
									/>
								)}
							</div>
						</div>
					</div>
				</>
			);
		};

		const handleTimeUpdate = () => {
			if (audioRef && audioRef.current) {
				setCurrentTime(audioRef.current.currentTime);
			}
		};

		const handleLoadedMetadata = data => {
			setDuration(data.target.duration);

			setOnReady(true);
		};

		const onUpdateSegments = () => {
			setIsUpdateLoading(true);

			const segments = dataRef.current;

			const diffSegments = segments.filter(segment => {
				const target = data.find(item => segment.segmentId === item.segmentId);

				if (target.text !== segment.text) {
					return true;
				} else {
					return false;
				}
			});

			if (diffSegments && diffSegments.length) {
				const updatedSegments = data.map(seg => {
					const target = diffSegments.find(item => item.segmentId === seg.segmentId);

					if (target) {
						return { ...seg, text: target.text };
					} else {
						return seg;
					}
				});

				updateSegments(
					cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
					selectedContent.contentId,
					diffSegments,
					() => {
						setSelectedContent({ ...selectedContent, segments: updatedSegments });
						setIsUpdateLoading(false);
						setIsEditMode(false);
						refreshContent();
					}
				);
			} else {
				setIsUpdateLoading(false);
				setIsEditMode(false);
			}
		};

		const handleChangeText = useCallback((index, text) => {
			return (dataRef.current[index] = { ...dataRef.current[index], text });
		}, []);

		return (
			<div className='transcription-container'>
				<div className='player'>{drawPlayer()}</div>
				<div className='search'>
					<SearchIcon />
					<input
						type='text'
						value={keyword}
						onChange={e => {
							setKeyword(e.target.value);
							setIsTracking(false);
						}}
						onKeyDown={e => {
							if (e.key === 'Escape') {
								setKeyword('');
							} else if (e.key === 'Enter') {
								setSearchResult(prev => ({
									...prev,
									currentIndex: (prev.currentIndex + 1) % totalMatches,
								}));
							}
						}}
						placeholder='검색어를 입력해주세요.'
					/>
					{keyword && (
						<>
							<div className='result'>
								<div className='count'>
									{totalMatches > 0 ? searchResult.currentIndex + 1 : 0} / {totalMatches}
								</div>
								<div
									className='prev'
									onClick={() => {
										setSearchResult(prev => ({
											...prev,
											currentIndex: (prev.currentIndex - 1 + totalMatches) % totalMatches,
										}));
										setIsTracking(false);
									}}
								>
									<ArrowIcon />
								</div>
								<div
									className='next'
									onClick={() => {
										setSearchResult(prev => ({
											...prev,
											currentIndex: (prev.currentIndex + 1) % totalMatches,
										}));

										setIsTracking(false);
									}}
								>
									<ArrowIcon />
								</div>
								<div
									className='clear'
									onClick={() => {
										setKeyword('');
										setSearchResult({
											currentIndex: 0,
											total: 0,
										});
									}}
								>
									<ClearIcon />
								</div>
							</div>
							{/* <Popup trigger={<button>바꾸기</button>} position={'bottom right'}>
                     {close => (
                        <SearchAndReplace
                           keyword={keyword}
                           setKeyword={setKeyword}
                           searchResult={searchResult}
                           onPrev={() =>
                              setSearchResult(prev => ({
                                 ...prev,
                                 currentIndex:
                                    prev.currentIndex - 1 < 1 ? prev.total : prev.currentIndex - 1,
                              }))
                           }
                           onNext={() =>
                              setSearchResult(prev => ({
                                 ...prev,
                                 currentIndex:
                                    prev.currentIndex + 1 > prev.total ? 1 : prev.currentIndex + 1,
                              }))
                           }
                           onReplaceAll={handleReplaceAll}
                           onReplace={handleReplaceWord}
                        />
                     )}
                  </Popup> */}
						</>
					)}
				</div>
				<div className={`stt ${type} ${isPip ? 'pip' : ''} ${isEditMode ? 'edit' : ''}`}>
					<div className='title'>
						음성 기록
						{isEditMode && (
							<button onClick={onUpdateSegments} disabled={isUpdateLoading ? true : false}>
								{isUpdateLoading ? <Loading type='spin' color='red' width={15} height={15} /> : '저장'}
							</button>
						)}
					</div>
					<div className='content' ref={dataContainerRef}>
						<Virtuoso
							ref={virtualRef}
							style={{ height: '100%' }}
							totalCount={isEditMode ? data.length : mergedTexts.length}
							itemContent={index => (
								<SttRow
									key={index}
									index={index}
									data={isEditMode ? data[index] : mergedTexts[index]}
									text={isEditMode ? dataRef.current[index].text : mergedTexts[index].text}
									onChangeText={(index, text) => handleChangeText(index, text)}
									onChangeSegmentSpeaker={onChangeSegmentSpeaker}
									speakerList={speakerInfo}
									onSeekTime={onSeekTime}
									editMode={isEditMode}
									onChangeMode={boolean => setIsEditMode(boolean)}
									noteRef={noteRef}
									editable={editable}
									isHighlight={
										highlights &&
										highlights[0] &&
										highlights[0].data &&
										highlights[0].data.some(
											highlight => highlight.segmentId === mergedTexts[index].segmentId
										)
									}
									refreshContent={refreshContent}
									keyword={keyword}
									textHighlighted={highlightedData}
									matchIndices={matchIndices}
									searchResult={searchResult}
									isLastElement={mergedTexts.length === index + 1}
								/>
							)}
						/>
					</div>
					{!isEditMode && playing && !isTracking && (
						<button className='tracking' onClick={() => setIsTracking(true)}>
							현재 위치로
						</button>
					)}
				</div>

				{type === 'audio' && (
					<div className='audio-instance'>
						<audio
							ref={audioRef}
							src={source}
							preload='auto'
							onTimeUpdate={handleTimeUpdate}
							onLoadedMetadata={handleLoadedMetadata}
						></audio>
					</div>
				)}
			</div>
		);
	}
);

export default React.memo(Transcription);
