import React, { useEffect, useLayoutEffect, useState } from 'react';
import './List.css';

import moment from 'moment';
import DateBox from './DateBox';
import useContentsStore from '../../store/ContentsStore';
import { getSortedContents } from '../../util/Util';
import { ToastError } from '../common/toast/Toast';
import { useCookies } from 'react-cookie';
import useListStore from '../../store/DisplayStore';
import Calendar from './Calendar';
import { PageLoading } from '../page-status/PageLoading';
import Empty from '../page-status/Empty';
import { useNavigate } from 'react-router-dom';

const List = ({ onClickContent = () => {}, onDownload = () => {}, onDelete = () => {}, sidebarRef }) => {
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [datas, setDatas] = useState({});

	const [cookies, setCookie, removeCookie] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { contents, uploads, refreshContents } = useContentsStore();
	const { displayType } = useListStore();

	useEffect(() => {
		refreshContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken)
			.catch(e => {
				console.error(e);
				ToastError(1003);
			})
			.finally(() => setIsLoading(false));
	}, []);

	useLayoutEffect(() => {
		console.log('contents : ', contents);

		if (contents) {
			onSortData();
		}
	}, [contents, uploads]);

	const onSortData = () => {
		const data = getSortedContents([...uploads.list, ...contents.list]);

		if (data && data.length) {
			const itemList = data.reduce((acc, item) => {
				const dateKey = moment(item.createAt).format('YYYYMMDD');

				if (!acc[dateKey]) {
					acc[dateKey] = [];
				}

				acc[dateKey].push(item);

				return acc;
			}, {});

			setDatas(itemList);
		} else {
			setDatas({});
		}
	};

	// const handleDragOver = event => {
	// 	event.preventDefault();
	// 	event.stopPropagation();
	// 	setIsDragging(true);
	// };

	// const handleDragLeave = event => {
	// 	event.preventDefault();
	// 	event.stopPropagation();
	// 	setIsDragging(false);
	// };

	// const handleDrop = e => {
	// 	e.preventDefault();
	// 	e.stopPropagation();
	// 	setIsDragging(false);

	// 	const files = e.dataTransfer.files;
	// 	console.log('Dropped files:', files);

	// 	// 파일 정보를 출력
	// 	Array.from(files).forEach(file => {
	// 		console.log(`File Name: ${file.name}, File Size: ${file.size} bytes, File Type: ${file.type}`);
	// 	});
	// };

	return (
		<div className={`list-container`}>
			{isLoading ? (
				<PageLoading />
			) : displayType === 'list' ? (
				<>
					{Object.keys(datas).length ? (
						Object.keys(datas)
							.reverse()
							.map(date => (
								<DateBox
									key={date}
									date={date}
									data={datas[date]}
									onClickContent={content => {
										onClickContent(content);
										navigate(`/content/${content.contentId}`);
									}}
									onDownload={onDownload}
									onDelete={onDelete}
								/>
							))
					) : (
						<div className='list-empty'>
							<Empty
								type='contents'
								onClickRecord={sidebarRef && sidebarRef.current && sidebarRef.current.onClickRecord}
								onClickUpload={sidebarRef && sidebarRef.current && sidebarRef.current.onClickUpload}
							/>
						</div>
					)}
				</>
			) : (
				<Calendar
					onClickContent={content => {
						onClickContent(content);
						navigate(`/content/${content.contentId}`);
					}}
				/>
			)}
		</div>
	);
};

export default List;
