import React, { useEffect, useRef, useState } from 'react';
import './SttRow.css';
import Avatar from 'react-avatar';
import { getAvatarColor, onCopyText } from '../../../util/Util';

import { ReactComponent as LearningIcon } from './img/learning.svg';
import { ReactComponent as DotIcon } from './img/dot.svg';
import Popup from 'reactjs-popup';
import Function from '../../popup/content/function/Function';
import Attendees from '../../popup/attendees/Attendees';
import SpeakerEdit from '../../popup/content/speakerEdit/SpeakerEdit';
import { ToastError, ToastInfo } from '../../common/toast/Toast';
import useContentsStore from '../../../store/ContentsStore';
import { useCookies } from 'react-cookie';
import useAuthStore from '../../../store/AuthStore';

const SttRow = ({
	index,
	data,
	text,
	onChangeText = () => {},
	onChangeSegmentSpeaker,
	speakerList = [],
	editMode = false,
	onChangeMode = () => {},
	onSeekTime = () => {},
	noteRef,
	editable,
	isHighlight,
	refreshContent,
	styles = {},
	keyword,
	textHighlighted,
	matchIndices,
	searchResult,
	isLastElement,
}) => {
	const containerRef = useRef();
	const inputRef = useRef();
	const rowRef = useRef();
	const avatarRef = useRef();
	const textRef = useRef(text);

	const [isEdit, setIsEdit] = useState(editMode);
	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);

	// Learning
	const [isLearning, setIsLearning] = useState(false);

	const { auth } = useAuthStore();
	const { selectedContent, onAppendDataToNote, addHighlight, deleteHighlight } = useContentsStore();

	useEffect(() => {
		return () => {
			if (text !== textRef.current) {
				onChangeText(index, textRef.current);
			}
		};
	}, []);

	useEffect(() => {
		const handleWheel = () => {
			if (document.activeElement === inputRef.current) {
				inputRef.current.blur();
			}
		};

		if (containerRef.current) {
			containerRef.current.addEventListener('wheel', handleWheel);
		}

		return () => {
			if (containerRef.current) {
				containerRef.current.removeEventListener('wheel', handleWheel);
			}
		};
	}, []);

	useEffect(() => {
		setIsEdit(editMode);
	}, [editMode]);

	const getSpeaker = speakerId => {
		const speaker = speakerList && speakerList.find(item => item.speakerId === speakerId);
		if (speaker) {
			return speaker.displayName ?? speaker.name;
		} else {
			return { name: '알 수 없음' };
		}
	};

	const getThumbnail = speakerId => {
		const speaker = speakerList && speakerList.find(item => item.speakerId === speakerId);
		if (speaker && speaker.thumbnailUrl) {
			if (auth && auth.user && auth.user.profile && auth.user.profile.email === speaker.email) {
				return auth.user.profile.thumbnailUrl;
			} else {
				return speaker.thumbnailUrl;
			}
		} else {
			return null;
		}
	};

	const convertTimeFormat = milliseconds => {
		let seconds = Math.floor(milliseconds / 1000);
		let minutes = Math.floor(seconds / 60);
		let hours = Math.floor(minutes / 60);

		seconds = seconds % 60;
		minutes = minutes % 60;

		const pad = num => num.toString().padStart(2, '0');

		if (hours > 0) {
			return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
		} else {
			return `${pad(minutes)}:${pad(seconds)}`;
		}
	};

	const handlePasteNote = () => {
		if (editable) {
			onAppendDataToNote(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, selectedContent.contentId, {
				type: 'segments',
				data,
			}).then(() => {
				noteRef && noteRef.current && noteRef.current.refreshNote();
			});
		} else {
			ToastInfo('권한이 없습니다.');
		}
	};

	const handleToggleHighlight = item => {
		if (editable) {
			if (isHighlight) {
				deleteHighlight(
					cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
					selectedContent.contentId,
					`segments`,
					item.segmentId
				)
					.then(res => {
						switch (res.code) {
							case 200:
								ToastInfo('하이라이트가 삭제 되었습니다.');
								refreshContent();
								break;
							case 401:
								ToastError(0, '권한이 없습니다.');
								break;
							default:
								ToastError(res.message);
								break;
						}
					})
					.catch(e => {
						ToastError(1016);
					});
			} else {
				addHighlight(
					cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken,
					selectedContent.contentId,
					`segments`,
					item.segmentId
				)
					.then(res => {
						switch (res.code) {
							case 200:
								ToastInfo('하이라이트가 추가 되었습니다.');
								refreshContent();
								break;
							case 401:
								ToastError(0, '권한이 없습니다.');
								break;
							default:
								ToastError(res.message);
								break;
						}
					})
					.catch(e => {
						ToastError(1016);
					});
			}
		} else {
			ToastInfo('권한이 없습니다.');
		}
	};

	return (
		<div
			ref={containerRef}
			className={`stt-row-container ${index}`}
			style={{ background: isHighlight ? '#F6FFF7' : '', ...styles }}
		>
			<Popup
				trigger={
					<div className='avatar'>
						<div ref={avatarRef}>
							<Avatar
								src={getThumbnail(data.speakerId)}
								name={getSpeaker(data.speakerId)}
								size={30}
								round
								textSizeRatio={2}
								color={getAvatarColor(data.speakerId).background}
								fgColor={getAvatarColor(data.speakerId).color}
								maxInitials={1}
							/>
						</div>
					</div>
				}
				keepTooltipInside='.main-content-v2'
			>
				{close =>
					editable && (
						<SpeakerEdit
							segment={data}
							onChangeSegmentSpeaker={onChangeSegmentSpeaker}
							speakerId={data.speakerId}
							speakerList={speakerList}
							onClose={close}
						/>
					)
				}
			</Popup>

			<div ref={rowRef} className='text'>
				<div className='speaker'>
					<div className='name' onClick={() => avatarRef.current.click()}>
						{getSpeaker(data.speakerId)}
					</div>

					<div className='start-time' onClick={() => onSeekTime(data.startTime / 1000)}>
						{convertTimeFormat(data.startTime)}
					</div>

					{isEdit && (
						<div
							className={`learning ${isLearning ? 'active' : ''}`}
							onClick={() => setIsLearning(prev => !prev)}
						>
							<LearningIcon />
							<div className='text'>학습</div>
						</div>
					)}
				</div>

				<div
					ref={inputRef}
					className='stt-result'
					contentEditable={isEdit ? true : false}
					style={{ padding: isEdit ? '10px' : 0, background: isEdit ? '#fff' : 'transparent' }}
					suppressContentEditableWarning
					spellCheck={false}
					id={data.segmentId}
					start-time={data.startTime}
					onBlur={e => onChangeText(index, e.target.innerText)}
					onWheel={() => inputRef.current.blur()}
				>
					{isEdit
						? text
						: textHighlighted[index].map((part, partIndex) => (
								<span
									key={partIndex}
									style={{
										backgroundColor:
											part.toLowerCase() === keyword.toLowerCase()
												? matchIndices[searchResult.currentIndex] &&
												  matchIndices[searchResult.currentIndex].dataIndex === index &&
												  matchIndices[searchResult.currentIndex].partIndex === partIndex
													? 'orange'
													: 'yellow'
												: 'inherit',
									}}
								>
									{part}
								</span>
						  ))}
				</div>
			</div>

			{!isEdit && (
				<Popup
					trigger={
						<div className='dot'>
							<DotIcon />
						</div>
					}
					position={'bottom right'}
					keepTooltipInside='.main-content-v2'
				>
					{close => (
						<Function
							onClickEdit={() => (editable ? onChangeMode(!isEdit) : ToastInfo('권한이 없습니다.'))}
							onClickHighlight={() => handleToggleHighlight(data)}
							onClickCopyText={() =>
								onCopyText(rowRef.current.innerText, () =>
									ToastInfo('내용이 성공적으로 복사되었습니다.', '내용 복사 완료')
								)
							}
							onClickPasteNote={handlePasteNote}
							onClose={close}
							isTranscription
							isHighlight={isHighlight}
						/>
					)}
				</Popup>
			)}
		</div>
	);
};

export default React.memo(SttRow);
