import './App.css';
import Main from './pages/Main';
import JoinPage from './pages/login/JoinPage';
import LoginPage from './pages/login/LoginPage';
import ContentsViewer from './pages/ContentsViewer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TimbloAuth from './pages/auth/TimbloAuth';
import { useEffect } from 'react';
import JoinPageV2 from './pages/login/JoinPageV2';

function App() {
	useEffect(() => {
		const handleBeforeUnload = event => {
			const shouldReload = window.confirm('변경사항이 사라질 수 있습니다. 새로고침 하시겠습니까?');

			if (shouldReload) {
				// 페이지를 새로고침
				window.location.reload();
			} else {
				// 새로고침 취소
				event.preventDefault();
			}
		};

		// 새로고침 전에 이벤트 리스너 추가
		window.addEventListener('beforeunload', handleBeforeUnload);

		// 컴포넌트 언마운트 시 이벤트 리스너 제거
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	return (
		<div className='App'>
			<Router>
				<Routes>
					<Route path='/' element={<LoginPage />} />
					<Route path='/join' element={<JoinPageV2 />} />
					<Route path='/join_old' element={<JoinPage />} />
					<Route path='/login' element={<LoginPage />} />
					<Route path='/main' element={<Main />} />
					<Route path='/contents' element={<ContentsViewer />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
