import axios from 'axios';
import { create } from 'zustand';
import { ToastError, ToastInfo } from '../components/common/toast/Toast';

const useFunctionStore = create(set => ({
	updateSegmentSpeaker: async (accessToken, contentId, startTime, speakerId, optionIndex, callback) => {
		try {
			const headers = {
				Authorization: `Bearer ${accessToken}`,
			};

			const options = ['onlyHere', 'fromHere', 'all'];

			const response = await axios.patch(
				`${process.env.REACT_APP_API_URL}/contents/${contentId}/transcription/${startTime}/speaker`,
				{
					newSpeakerId: speakerId,
					range: options[optionIndex],
				},
				{
					headers,
				}
			);

			if (response && response.data && response.data.httpCode === 200) {
				callback && callback(response.data.data.segments);
			}
		} catch (e) {}
	},

	updateSegments: async (accessToken, contentId, segments, callback) => {
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		const response = await axios.patch(
			`${process.env.REACT_APP_API_URL}/contents/${contentId}/transcription/segments/text`,
			{
				segments,
			},
			{
				headers,
			}
		);

		if (response && response.data) {
			const code = response.data.httpCode;

			switch (code) {
				case 200:
					ToastInfo('수정되었습니다.');
					callback && callback();
					break;

				default:
					ToastError(code);
					break;
			}
		}
	},
}));

export default useFunctionStore;
