import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import RightArrow from './img/right-arrow.svg';
import CheckBox from './img/checkbox.svg';
import CheckBoxChecked from './img/checkbox-checked.svg';

const TimbloCheckboxItem = ({
	label,
	description,
	isMainCheckbox = false,
	checked = false,
	required = false,
	onChanged,
}) => {
	return (
		<Box
			sx={{
				display: 'flex',
				width: '100%',
				alignItems: isMainCheckbox ? 'center' : 'flex-start',
				gap: 1,
				fontSize: 16,
				color: 'var(--grey-grey700, #28323c)',
				fontWeight: isMainCheckbox ? 500 : 400,
				lineHeight: 1,
				borderRadius: 1,
				margin: '10px',
			}}
		>
			<IconButton
				onClick={() => {
					onChanged && onChanged(!checked);
				}}
				sx={{
					p: 0,
					alignSelf: isMainCheckbox ? 'center' : 'flex-start',
				}}
			>
				<Box
					component='img'
					src={checked ? CheckBoxChecked : CheckBox}
					alt='Checkbox icon'
					sx={{
						color: '#E1E4E6',
						width: 24,
						aspectRatio: '1',
						objectFit: 'contain',
						objectPosition: 'center',
					}}
				/>
			</IconButton>
			<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
				<Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
					{!isMainCheckbox && required && (
						<Typography
							sx={{
								fontFamily: 'Noto Sans',
								color: 'var(--grey-grey400, #292A2B)',
								fontSize: '14px',
								fontWeight: '500',
								color: '#1C6EFF',
							}}
						>
							[필수]
						</Typography>
					)}

					<Typography
						sx={{
							fontFamily: 'Noto Sans',
							color: 'var(--grey-grey400, #292A2B)',
							fontSize: '14px',
							fontWeight: '500',
						}}
					>
						{label}
					</Typography>
				</Box>
				{description && (
					<Typography
						component='span'
						sx={{
							fontFamily: 'Noto Sans',
							color: 'var(--grey-grey400, #878D91)',
							fontSize: '14px',
							fontWeight: '500',
							mt: 0.5,
						}}
					>
						{description}
					</Typography>
				)}
			</Box>
			{!isMainCheckbox && (
				<Box
					component='img'
					src={RightArrow}
					alt='Arrow icon'
					sx={{
						width: 6,
						aspectRatio: '0.5',
						objectFit: 'contain',
						objectPosition: 'center',
						strokeWidth: 2,
						stroke: 'var(--grey-grey300, #c5c8ce)',
						alignSelf: 'center',
					}}
				/>
			)}
		</Box>
	);
};

export default TimbloCheckboxItem;
