import React, { useLayoutEffect, useEffect, useState, useRef } from 'react';
import { getAvatarColor } from '../../util/Util';

import CancelIcon from '@mui/icons-material/Cancel';

const DataChip = ({ text, index, onDelete = () => {}, onUpdate = () => {}, type, enableEdit }) => {
	const textRef = useRef(text);
	const chipRef = useRef();

	const [isEdit, setIsEdit] = useState(false);
	const [isHover, setIsHover] = useState(false);

	useEffect(() => {
		if (isEdit) {
			chipRef.current.focus();
		}
	}, [isEdit]);

	const getStyleFromType = () => {
		if (type) {
			switch (type) {
				case 'speakerInfo':
					return {
						background: !isEdit ? getAvatarColor(index).background : '#fff',
						color: !isEdit ? getAvatarColor(index).color : '#000',
						border: !isEdit ? '1px solid transparent' : `1px solid ${getAvatarColor(index).color} `,
					};

				case 'keywords':
					return {
						background: '#F3F5F7',
						color: `#417EEA`,
						border: `1px solid #ECEDEF`,
					};
			}
		}
	};

	return (
		<div
			ref={chipRef}
			className={`attendee-chip-container ${type === 'keywords' ? 'keyword' : ''} ${index}`}
			style={getStyleFromType()}
			contentEditable={isEdit}
			onClick={e => {
				e.stopPropagation();

				if (enableEdit) {
					setIsEdit(true);
				}
			}}
			onKeyDown={e => {
				if (enableEdit) {
					if (e.key === 'Enter') {
						setIsEdit(false);
						onUpdate(e.target.innerText.replace('# ', ''));
					} else if (e.key === 'Escape') {
						setIsEdit(false);
					}
				}
			}}
			onInput={e => (textRef.current = e.target.innerText)}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
			suppressContentEditableWarning
			spellCheck={false}
			onBlur={e => {
				if (enableEdit) {
					setIsEdit(false);

					if (textRef.current !== text) {
						onUpdate(textRef.current);
					}
				}
			}}
		>
			{text}
			{/* {textRef.current} */}
			{enableEdit && !isEdit && isHover && (
				<CancelIcon
					onClick={e => {
						e.stopPropagation();

						onDelete();
					}}
					sx={{
						width: 18,
						height: 18,
						position: 'absolute',
						right: 0,
						top: -5,
						zIndex: 1,
						color: '#A9AFB3',
						cursor: 'pointer',
					}}
				/>
			)}
		</div>
	);
};

export default DataChip;
