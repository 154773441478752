import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './Filter.css';
import {
	CONTENTS_FILTERS,
	drawContentsIcon,
	drawMainFilterIcon,
	getContentsFilterTitle,
	getMainFilterTitle,
	MAIN_FILTERS,
} from './FilterList';

import { ReactComponent as DeleteIcon } from './img/delete.svg';
import useContentsStore from '../../../store/ContentsStore';
import { useCookies } from 'react-cookie';
import { drawContentIcon } from '../../common/ContentIcon/ContentIcon';

const Filter = () => {
	const contentsRef = useRef();

	const [filterType, setFilterType] = useState('main');

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { applyedFilters, setApplyedFilters, refreshContents } = useContentsStore();

	useEffect(() => {
		const params = {};

		if (Object.keys(applyedFilters).length) {
			Object.keys(applyedFilters).forEach(key => {
				params[key] =
					typeof applyedFilters[key] === 'object' ? applyedFilters[key].join(',') : applyedFilters[key];
			});
		}

		refreshContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken, params);
	}, [applyedFilters]);

	const getFilterTitle = value => {
		switch (value) {
			case 'main':
				return '필터';

			case 'type':
				return '컨텐츠 종류';

			case 'title':
				return '제목';

			case 'keyword':
				return '키워드';

			case 'attendee':
				return '참석자';

			case 'owner':
				return '소유자';

			default:
				return 'Unknown';
		}
	};

	const handleApplyedFilter = (type, value) => {
		const updatedApplyed = { ...applyedFilters };

		if (updatedApplyed[type]) {
			if (typeof updatedApplyed[type] === 'object') {
				const valueIndex = updatedApplyed[type].findIndex(item => item === value);

				if (valueIndex >= 0) {
					updatedApplyed[type].splice(valueIndex, 1);

					if (!updatedApplyed[type].length) {
						delete updatedApplyed[type];
					}
				} else {
					updatedApplyed[type] = [...updatedApplyed[type], value];
				}
			}
		} else {
			updatedApplyed[type] = type === 'type' ? [value] : value;

			if (!value) {
				delete updatedApplyed[type];
			}
		}

		setApplyedFilters(updatedApplyed);

		setFilterType('main');
	};

	const drawFilterType = () => {
		switch (filterType) {
			case 'main':
				return <MainFilter onClickFilter={setFilterType} />;

			case 'type':
				return <ContentFilter ref={contentsRef} onClickFilter={handleApplyedFilter} />;

			case 'title':
				return (
					<TitleFilter
						title={applyedFilters['title'] ?? ''}
						onConfirm={keyword => handleApplyedFilter('title', keyword)}
						onClose={() => setFilterType('main')}
					/>
				);

			default:
				return <></>;
		}
	};

	const drawApplyedFilters = () => {
		const drawApplyedValue = value => {
			if (typeof value === 'object') {
				return (
					<div style={{ position: 'relative' }}>
						{value.map((item, idx) => (
							<div className='icon' style={{ position: 'absolute', left: `${idx * 12}px`, top: '2px' }}>
								{drawContentIcon(item, 18)}
							</div>
						))}
						<div style={{ paddingLeft: `${value.length * 12 + 5}px`, width: '40px', textAlign: 'center' }}>
							{value.length > 1 ? `${value.length}개` : getContentsFilterTitle(value[0])}
						</div>
					</div>
				);
			} else {
				return <div>{value}</div>;
			}
		};

		const handleDeleteApplyedFilter = type => {
			const updatedApplyed = { ...applyedFilters };

			delete updatedApplyed[type];

			setApplyedFilters(updatedApplyed);
		};

		return Object.keys(applyedFilters).map(filter => (
			<div className='filter'>
				<div className='icon'>{drawMainFilterIcon(filter)}</div>
				<div className='text'>{getMainFilterTitle(filter)}</div>
				<div className='equal'>=</div>
				<div className='value'>{drawApplyedValue(applyedFilters[filter])}</div>
				<div className='separator' />
				<button onClick={() => handleDeleteApplyedFilter(filter)}>
					<DeleteIcon />
				</button>
			</div>
		));
	};

	return (
		<div className={`popup-filter-container ${Object.keys(applyedFilters).length ? 'with-applyed' : ''}`}>
			<div className={`title ${filterType === 'main' ? 'main' : ''}`}>{getFilterTitle(filterType)}</div>
			<div className='separator' />
			<div className='filters'>{drawFilterType()}</div>
			{filterType === 'main' && Object.keys(applyedFilters).length ? (
				<>
					<div className='separator' />
					<div className='apply-filters'>{drawApplyedFilters()}</div>
				</>
			) : (
				<></>
			)}
		</div>
	);
};

export default Filter;

const MainFilter = ({ onClickFilter }) => {
	return MAIN_FILTERS.map(filter => (
		<div className='item' onClick={() => onClickFilter(filter.value)}>
			<div className='icon'>{drawMainFilterIcon(filter.value)}</div>
			<div className='text'>{filter.label}</div>
		</div>
	));
};

const ContentFilter = forwardRef(({ onClickFilter }, ref) => {
	return CONTENTS_FILTERS.map(filter => (
		<div className='item' onClick={() => onClickFilter('type', filter.value)}>
			<div className='icon'>{drawContentIcon(filter.value, 18)}</div>
			{/* <div className='icon'>{drawContentsIcon(filter.value)}</div> */}
			<div className='text'>{filter.label}</div>
		</div>
	));
});

const TitleFilter = ({ title = '', onConfirm, onClose }) => {
	const [keyword, setKeyword] = useState(title);

	return (
		<>
			<input
				type='text'
				value={keyword}
				onChange={e => setKeyword(e.target.value)}
				placeholder='콘텐츠 제목을 입력하세요.'
				onKeyDown={e => e.key === 'Enter' && keyword.trim() && onConfirm(keyword)}
			/>
			<div className='buttons'>
				<button className='cancel' onClick={() => onClose()}>
					취소
				</button>
				<button className='confirm' onClick={() => onConfirm(keyword)}>
					적용
				</button>
			</div>
		</>
	);
};
