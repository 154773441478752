import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './Home.css';

import Grid from '@mui/material/Grid2';
import { Box, Skeleton, styled, Tooltip, tooltipClasses, Typography } from '@mui/material';
import HomeCalendar from './items/HomeCalendar';
import useContentsStore from '../../store/ContentsStore';
import { useCookies } from 'react-cookie';
import HomeBookmark from './items/HomeBookmark';
import HomeContents from './items/HomeContents';
import HomeKeywords from './items/HomeKeywords';
import HeaderV2 from '../../components/body/headerV2/HeaderV2';
import { PageLoading } from '../../components/page-status/PageLoading';

const HOME_TOP_HEIGHT = 416;
const HOME_BOTTOM_HEIGHT = 350;

const HomeV2 = ({ onClickContent }) => {
	const headerRef = useRef();

	const [cookies] = useCookies([process.env.REACT_APP_COOKIE_ALIAS]);
	const { home, uploads, refreshHomeContents } = useContentsStore();
	const [isLoading, setIsLoading] = useState(true);

	useLayoutEffect(() => {
		refreshHomeContents(cookies[process.env.REACT_APP_COOKIE_ALIAS].accessToken).finally(() => setIsLoading(false));
	}, []);

	return (
		<>
			<HeaderV2 ref={headerRef} text='홈' enableSearch />
			<Box className='home-container'>
				{isLoading ? (
					<PageLoading />
				) : (
					<>
						{/* 첫 번째 줄 */}
						<Grid
							container
							rowSpacing={2}
							columnSpacing={3}
							wrap='nowrap' // 줄바꿈 방지
						>
							{/* 첫 번째 그리드 아이템: 500px x 500px */}
							<Grid
								item
								style={{
									width: 340,
									height: HOME_TOP_HEIGHT,
									minWidth: 340,
									minHeight: 416,
									border: '1px solid #E8E8E8', // 테두리 두께와 색상
									borderRadius: '8px', // 테두리 둥글기
								}}
							>
								<HomeCalendar onClickContent={onClickContent} />
							</Grid>

							{/* 두 번째 그리드 아이템: 가변 크기 */}
							<Grid
								item
								style={{
									flexGrow: 1, // 남은 공간을 모두 차지
									height: HOME_TOP_HEIGHT,
									border: '1px solid #E8E8E8', // 테두리 두께와 색상
									borderRadius: '8px', // 테두리 둥글기
								}}
							>
								<HomeContents />
							</Grid>
						</Grid>
						<Box height={24} /> {/* 20px 간격 */}
						{/* 두 번째 줄 */}
						<Grid
							container
							rowSpacing={5}
							columnSpacing={3}
							wrap='nowrap' // 줄바꿈 방지
						>
							{/* 네 번째 그리드 아이템: 가변 크기 */}
							<Grid
								item
								style={{
									minWidth: 920,
									border: '1px solid #E8E8E8', // 테두리 두께와 색상
									borderRadius: '8px', // 테두리 둥글기
									flexGrow: 1, // 남은 공간을 모두 차지
									height: HOME_BOTTOM_HEIGHT,
								}}
							>
								<HomeBookmark />
							</Grid>
							{/* 세 번째 그리드 아이템: 500px x 500px */}
							<Grid
								item
								style={{
									width: 456,
									minWidth: 456,
									// height: 327,
									border: '1px solid #E8E8E8', // 테두리 두께와 색상
									borderRadius: '8px', // 테두리 둥글기
									height: HOME_BOTTOM_HEIGHT,
								}}
							>
								<HomeKeywords
									onClickKeyword={keyword =>
										headerRef.current && headerRef.current.onClickKeyword(keyword)
									}
								/>
							</Grid>
						</Grid>
					</>
				)}
			</Box>
		</>
	);
};

export default HomeV2;
